import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import { useApolloClient } from "@apollo/client";
import { Tab, Tabs } from "react-bootstrap";
import FormsySelect from "../../elements/FormsySelect";
import { useDispatch, useSelector } from "react-redux";
import { executeGraphQLMutation, executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import { IS_USER_ACCESS_PAGE } from '../../../config/constants';
import { getMasterData } from "../../../store/action/allAction";

import {
  LEAD_CREATION_FORM,
  LEAD_CREATION_CSV,
  PARTNER_DROPDOWN_LIST,
  ASSIGN_LEAD_CSV,
} from "../../../services/leadCreate.gql";
import {
  convertCsvToJson,
  convertJsonToCsv,
  downloadFile,
} from "../../../helpers/helpers";
import * as yup from "yup";

const LEAD_FORM = FormFields["LEAD_CREATION_FORM"];
const BULK_LEAD_FORMAT = FormFields["LEAD_CREATION_BULK_FORMAT"];
const LEAD_ASSIGN_FORMAT = FormFields["LEAD_CREATION_ASSIGN_LEAD_FORMAT"];

const FULFILLMENT_TYPE_ENUM = ["ambak", "self"];
const LOAN_TYPE_ENUM = ["home loan", "llp"];
const LOAN_SUB_TYPE_ENUM = ["bt", "topup"];
const SOURCE_ENUM = [
  "Distribution",
  "Institutional",
  "Marketing",
  "Referrals",
  "Website",
];
const SUB_SOURCE_MAPPING_ENUM = {
  Distribution: ["DSA discovery", "CP discovery"],
  Institutional: ["Builder", "Relator", "Aggregator", "Others"],
  Marketing: ["Social Media", "Others"],
  Referrals: ["Internal Employees", "Friends and Family", "Others"],
  Website: ["UTM campaign"],
};
const LOAN_TYPE_MAPPING = {
  "home loan": 1,
  llp: 2,
};
const LOAN_SUB_TYPE_MAPPING = {
  bt: "1",
  topup: "2",
};
const SOURCE_MAPPING = {
  Distribution: 1,
  Institutional: 2,
  Marketing: 3,
  Referrals: 4,
  Website: 5,
};
const SUB_SOURCE_MAPPING = {
  "DSA discovery": 1,
  "CP discovery": 2,
  Builder: 3,
  Relator: 4,
  Aggregator: 5,
  Others: 6,
  "Social Media": 7,
  Others: 8,
  "Internal Employees": 9,
  "Friends and Family": 10,
  Others: 11,
  "UTM campaign": 12,
};

const leadSchema = yup.object().shape({
  fulfillment_type: yup.string().oneOf(FULFILLMENT_TYPE_ENUM).required(),
  loan_type: yup.string().lowercase().oneOf(LOAN_TYPE_ENUM).required(),
  loan_sub_type: yup
    .string()
    .lowercase()
    .oneOf([...LOAN_SUB_TYPE_ENUM, ""])
    .nullable(),
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  mobile: yup.string().length(10).required(),
  loan_amount: yup.string().required(),
  // rm_id: yup.number().required(),
  partner_id: yup.number().required(),
  source: yup.string().oneOf(SOURCE_ENUM).required(),
  sub_source: yup
    .string()
    .when("source", (source, schema) => {
      if (source && SUB_SOURCE_MAPPING_ENUM[source]) {
        return schema.oneOf(SUB_SOURCE_MAPPING_ENUM[source]);
      }
      return schema;
    })
    .required(),
});

const LeadsCreateForm = (props) => {
  const [leadCreate, setLeadCreate] = useState({});
  const [key, setKey] = useState("create leads");
  const [uploadedFileJson, setUploadedFileJson] = useState([]);
  const [partnerData, setPartnerData] = useState([]);
  const [isFileInvalid, setIsFileInvalid] = useState(false);
  const client = useApolloClient();
  const dispatch = useDispatch(); 

  const { masterdata } = useSelector(({ masterdata }) => ({ masterdata }));

  const getPartnerData = () => {
    executeGraphQLQuery(PARTNER_DROPDOWN_LIST, client).then((result) => {
      if (result?.data) {
        const jsonData = JSON.parse(result?.data?.partner_list_dropdown);
        if (jsonData?.status === 200) {
          const partnerList = jsonData.data.map(obj => {
            return {
              id: obj.partnerId,
              label: `${obj.PartnerName} - ${obj.partnerId}`,
              value: obj.partnerId,
            }
          });

          setPartnerData(partnerList);
        }
      }
    })
      .catch((errors) => {
        console.log(errors.message);
        toast.error("Something went wrong")
      });
  }

  useEffect(()=>{
    getPartnerData();
    if(!masterdata){
      dispatch(getMasterData(client));
    }
  },[])

  const setSourceOptions = (options) => {
    const listOfSources = [];
      options["source"] = options["subsource_type"]
        ?.filter((obj) => {
          if (!listOfSources.includes(obj.source_name)) {
            listOfSources.push(obj.source_name);
            return {
              id: obj.source_id,
              label: obj.source_name,
              value: obj.source_id,
            };
          }
        })
        .map((obj) => ({
          id: obj.source_id,
          label: obj.source_name,
          value: obj.source_id,
        }));
    return options;
  }

  let leadAssignAccess = IS_USER_ACCESS_PAGE("assign_lead", "assign_lead").is_edit_access;
  let leadAccess = IS_USER_ACCESS_PAGE("create_lead", "create_lead").is_edit_access;
  let leadUploadAccess = IS_USER_ACCESS_PAGE("bulk_upload_lead", "bulk_upload_lead").is_edit_access;

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options = setSourceOptions(options);
    return options;

  }, [masterdata.data]);

  useEffect(() => {
    ALL_OPTIONS["partner_id"] = partnerData;
  }, [partnerData]);

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setLeadCreate((current) => ({
      ...current,
      [id]: value,
    }));
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setLeadCreate((current) => ({
        ...current,
        [field_name]: data.value,
      }));
    }
  }

  const handleFileChange = async (event) => {
    const { files, id } = event.target;
    const fileNameElement =
      id === "fileInput_assignLead"
        ? document.getElementById("fileName_assignLead")
        : document.getElementById("fileName");

    if (files.length > 0) {
      const fileName = files[0].name;
      const jsonArray = await convertCsvToJson(files[0]);
      setUploadedFileJson(jsonArray);
      fileNameElement.textContent = "Selected file: " + fileName;
    } else {
      fileNameElement.textContent = "";
    }
  };

  const handleSourceChange = (source_id) => {
    const filteredSources = ALL_OPTIONS["subsource_type"].filter(
      (obj) => obj.source_id === source_id
    );
    ALL_OPTIONS["sub_source"] = filteredSources.map((obj) => ({
      id: +obj.id,
      label: obj.sub_source_name,
      value: +obj.id,
    }));
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name === "source") handleSourceChange(data.id);
      if (field_name === "partner_id" && data.id === 10454) {
        ALL_OPTIONS["source"] = ALL_OPTIONS["source"]?.filter(obj => obj.id !== 1 && obj.id !== 2);
      }
      if (field_name === "partner_id" && data.id !== 10454) setSourceOptions(ALL_OPTIONS);
      setLeadCreate((currentValue) => ({
        ...currentValue,
        [field_name]: data.id,
      }));
    }
  };

  const validateLead = async (lead) => {
    try {
      await leadSchema.validate(lead);
      return {
        error: false,
        message: "",
      };
    } catch (error) {
      return {
        error: true,
        message: error.message,
      };
    }
  };

  const handleDownloadErrorFile = () => {
    const csvData = convertJsonToCsv(uploadedFileJson);
    downloadFile(csvData, "text/csv", "error_file.csv");
    setIsFileInvalid(false);
  };

  const handleFormatDownload = (event) => {
    const { id } = event.target;
    let jsonArray = [],
      fileName = "";
    if (id === "bulkLeadFormat") {
      jsonArray = BULK_LEAD_FORMAT;
      fileName = "bulk_lead_file_format.csv";
    }
    if (id === "leadAssingFormat") {
      jsonArray = LEAD_ASSIGN_FORMAT;
      fileName = "lead_assign_file_format.csv";
    }
    const csvData = convertJsonToCsv(jsonArray);
    downloadFile(csvData, "text/csv", fileName);
  };

  const handleAssignLeadSubmit = () => {
    if (uploadedFileJson.length === 0) {
      return toast.error('Please upload file');
    }

    let assignlead = [];
    for (let i = 0; i < uploadedFileJson.length; i++) {
      assignlead.push({
        lead_id: Number(uploadedFileJson[i].lead_id),
        user_id: Number(uploadedFileJson[i].user_id),
      });
    }
    let mutation = ASSIGN_LEAD_CSV,
      variables = {
        BulkAssignLeadInput: {
          assignlead,
        },
      };
    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.update_assign_lead || null;
        if (response && !response) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err.message);
        toast.error('Got invalid value. Please check');
      });
  };

  const handleSubmit = async (event) => {
    if (Object.keys(leadCreate).length === 0 && uploadedFileJson.length === 0) {
      return toast.error("Either fill form or upload file");
    }

    if (uploadedFileJson.length > 0) {
      let errorCount = 0;
      const jsonClone = [],
        parsedJson = [];
      for (let i = 0; i < uploadedFileJson.length; i++) {
        const lead = uploadedFileJson[i];
        lead.fulfillment_type = lead.fulfillment_type
          ? lead.fulfillment_type
          : "ambak";
        const response = await validateLead(lead);
        if (response.error) {
          errorCount++;
          jsonClone.push({ ...lead, error: response.message });
        } else {
          parsedJson.push({
            ...leadSchema.cast(lead),
            loan_type: LOAN_TYPE_MAPPING[lead.loan_type.toLowerCase()],
            loan_sub_type:
              LOAN_SUB_TYPE_MAPPING[lead.loan_sub_type.toLowerCase()],
            loan_amount: lead.loan_amount.replaceAll(",", ""),
            source: SOURCE_MAPPING[lead.source],
            sub_source: SUB_SOURCE_MAPPING[lead.sub_source],
          });
          jsonClone.push({ ...lead, error: "" });
        }
      }

      if (errorCount > 0) {
        toast.error("Invalid File");
        setUploadedFileJson(jsonClone);
        setIsFileInvalid(true);
      } else {
        setIsFileInvalid(false);
        const responseArry = [];
        const mutation = LEAD_CREATION_FORM;
        try {
          for (let i = 0; i < parsedJson.length; i++) {
            const lead = parsedJson[i];
            const variables = {
              partner_id: lead.partner_id,
              api_called_by: "partner",
              newLeadInput: {
                first_name: lead.first_name,
                last_name: lead.last_name,
                mobile: lead.mobile,
                loan_type: +lead.loan_type,
                loan_sub_type: lead.loan_sub_type,
                fulfillment_type: lead.fulfillment_type,
                loan_amount: lead.loan_amount,
                source: lead?.source || null,
                sub_source: lead?.sub_source || null,
              },
            };

            const resp = await executeGraphQLMutation(
              mutation,
              variables,
              client
            );
            if (resp && !resp?.data?.create_loan) {
              throw new Error("Internal Server error");
            }
            responseArry.push(1);
          }

          if (parsedJson.length === responseArry.length) {
            toast.success("Success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else {
            toast.error("Internal Server error");
          }
        } catch (error) {
          console.log(error.message);
          toast.error("Internal Server error");
        }
      }
    } else {
      let mutation = LEAD_CREATION_FORM;
      let variables = {
        partner_id: +leadCreate.partner_id,
        api_called_by: "partner",
        newLeadInput: {
          first_name: leadCreate.first_name,
          last_name: leadCreate.last_name,
          mobile: leadCreate.mobile,
          loan_type: +leadCreate.loan_type,
          loan_sub_type: leadCreate.loan_sub_type,
          fulfillment_type: leadCreate.fulfillment_type,
          loan_amount: leadCreate.loan_amount,
          source: leadCreate.source,
          sub_source: leadCreate.sub_source,
        },
      };

      // Call executeGraphQLMutation function to send the mutation to the server
      executeGraphQLMutation(mutation, variables, client)
        .then((resp) => {
          if (resp && !resp?.data?.create_loan) {
            throw new Error("Something went wrong");
          }
          if( resp?.data?.create_loan?.is_duplicate == '1'){
            toast.error("Duplicate lead with this number");
          }else{
            toast.success("Success");
          }
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(` 🔻 ERROR : ${err.message}`);
          toast.error("Got Invalid value. Please Check");
        });
    }
  };

  return (
    <>
      <div style={{ padding: 40 }}>
        <div>
          <h2 style={{ marginTop: 20, marginBottom: 20 }}>
            Create and Assign Leads
          </h2>
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="createtabs"
          >
            {
              leadAccess === 1 ?
                <Tab
                  eventKey="create leads"
                  title={
                    <div
                      className={`tab-button ${key === "create leads" ? "active" : ""
                        }`}
                    >
                      Create Leads
                    </div>
                  }
                >
                  <div className="form-tab-right-panel">
                    <Formsy
                      className="lead-form-filed"
                      autoComplete="off"
                      onValid={() => null}
                      onInvalid={() => null}
                    >
                      <div className="row">
                        {LEAD_FORM.map((field, index) =>
                          [
                            "text",
                            "pattern-format",
                            "number",
                            "number-format",
                          ].includes(field.type) ? (
                            <fieldset className="form-filed col-md-6" key={index}>
                              <FormsyInputField
                                id={field.id}
                                name={field.name}
                                type={field.type}
                                value={(leadCreate && leadCreate[field.name]) || ""}
                                placeholder=" "
                                maxLength={field.maxLength}
                                label={field.label}
                                required={field.required}
                                thousandsGroupStyle="lakh"
                                thousandSeparator={","}
                                decimalSeparator={"."}
                                onChange={
                                  ["pattern-format", "number-format"].includes(field.type)
                                    ? (data) => handlePatternFormatChange(data, field.name)
                                    : handleInputChange
                                }
                              />
                            </fieldset>
                          ) : field.type === "dropdown" ? (
                            <fieldset
                              className="single-select col-md-6"
                              key={index}
                            >
                              <FormsySelect
                                name={field.name}
                                id={field.id}
                                inputProps={{
                                  options:
                                    ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                                  placeholder: field.label,
                                  className: "react-select",
                                  classNamePrefix: "react-select",
                                }}
                                required={field.required}
                                value={leadCreate && leadCreate[field.name]}
                                onChange={(data) =>
                                  handleSelectChange(data, field.name)
                                }
                              />
                            </fieldset>
                          ) : null
                        )}
                      </div>
                    </Formsy>
                  </div>
                  {
                    leadUploadAccess === 1 ?
                      <>
                        <div className="orcsvfile">
                          <div className="orcsvline"></div>
                          <div style={{ margin: "0 10px" }}>OR</div>
                          <div className="orcsvline"></div>
                        </div>
                        <div className="csvfileupload">
                          <input type="file" id="fileInput" onChange={handleFileChange} />
                          <label htmlFor="fileInput" className="file-label">
                            <span>+</span>
                            <p>Drag your CSV file here</p>
                          </label>
                          <p id="fileName"></p>
                        </div>
                      </> : null
                  }
                  {isFileInvalid && (
                    <div
                      className="downloadformat"
                      onClick={handleDownloadErrorFile}
                    >
                      Download error file
                    </div>
                  )}
                  <div className="btn-save-remarks">
                    <div className="submitbtnflex">
                      <button
                        type="submit"
                        className="btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      <div
                        className="downloadformat"
                        id="bulkLeadFormat"
                        onClick={handleFormatDownload}
                      >
                        Download format
                      </div>
                    </div>
                  </div>
                </Tab> : null
            }
            {
              leadAssignAccess ===1  ?
                <Tab
                  eventKey="assign leads"
                  title={
                    <div
                      className={`tab-button ${key === "assign leads" ? "active" : ""
                        }`}
                    >
                      Assign Leads
                    </div>
                  }
                >
                  <div className="csvfileupload">
                    <input
                      type="file"
                      id="fileInput_assignLead"
                      onChange={handleFileChange}
                    />
                    <label htmlFor="fileInput_assignLead" className="file-label">
                      <span>+</span>
                      <p>Drag your CSV file here</p>
                    </label>
                    <p id="fileName_assignLead"></p>
                  </div>

                  <div className="btn-save-remarks">
                    <div className="submitbtnflex">
                      <button
                        type="submit"
                        onClick={handleAssignLeadSubmit}
                        className="btn-primary"
                      >
                        Submit
                      </button>
                      <div
                        className="downloadformat"
                        id="leadAssingFormat"
                        onClick={handleFormatDownload}
                      >
                        Download format
                      </div>
                    </div>
                  </div>
                </Tab> : null
            }
          </Tabs>
        </div>
      </div>
    </>
  );
};
export default LeadsCreateForm;
