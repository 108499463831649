import csvToJsonLib from "csvtojson";

export const convertCsvToJson = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      const csvData = event.target.result;
      const jsonArray = await csvToJsonLib().fromString(csvData);
      resolve(jsonArray);
    };

    reader.onerror = (error) => reject(error);

    reader.readAsText(file);
  });
};

export const convertJsonToCsv = (json) => {
  const items = json.map((item) => Object.values(item));
  const header = Object.keys(json[0]);
  const csvData = [header.join(","), ...items.map((row) => row.join(","))].join(
    "\n"
  );
  return csvData;
};

export const downloadFile = (fileData, fileType, fileName) => {
  const blob = new Blob([fileData], { type: fileType });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

export const getNumberFormat_IN = (value) => {
  const formattedNumber = value.toLocaleString("en-IN");
  return formattedNumber;
};

export const sortStateListByTopStates = (state_list = []) => {
  const topStates = [
    "Delhi",
    "Haryana",
    "Karnataka",
    "Maharashtra",
    "Telangana",
    "Uttar Pradesh",
  ];

  const sortedStates = state_list.sort((a, b) => {
    const aTopIndex = topStates.indexOf(a.name);
    const bTopIndex = topStates.indexOf(b.name);

    if (aTopIndex !== -1 && bTopIndex !== -1) {
      return a.name.localeCompare(b.name);
    } else if (aTopIndex !== -1) {
      return -1;
    } else if (bTopIndex !== -1) {
      return 1;
    } else {
      return a.name.localeCompare(b.name);
    }
  });
  return sortedStates;
};

export const numberWithCommas = (value)=> {
  return (value==0)?'--': '₹ '+value.toLocaleString('en-IN')
}
