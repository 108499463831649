import React from "react";
import { IS_USER_ACCESS_PAGE } from "../../../../config/constants";
import { MasterService } from "../../../../services";
import { getCurrentUserDetails } from "../../pre-register-lead-manager/utility/getUserInfo";


export const MarkAsDemo = (partner_id) => {

  const markAsDemo = (partner_id) => {
    
    const userInfo = getCurrentUserDetails();
    const payload = {
      partner_id: partner_id,
      is_demo: "1",
      user_id: userInfo?.userId,
    };
    const confirm = window.confirm(
      "Are you sure you want to continue to mark this partner as demo ?"
    );
    if (confirm) {
      MasterService.post("partner/partner/mark-demo-partner", payload)
        .then((response) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  let demo_mark = IS_USER_ACCESS_PAGE("partner", "demo_mark")?.is_edit_access;

  return (
    <div>
      {demo_mark ? (
        <div
          className="mrg-r10"
          onClick={() =>
            markAsDemo(partner_id)
          }
          title="Mark as Demo Partner"
        >
          <div className="commoniconbtn">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" fill="currentColor" class="bi bi-person-slash" viewBox="0 0 16 16">
            <path d="M13.879 10.414a2.501 2.501 0 0 0-3.465 3.465zm.707.707-3.465 3.465a2.501 2.501 0 0 0 3.465-3.465m-4.56-1.096a3.5 3.5 0 1 1 4.949 4.95 3.5 3.5 0 0 1-4.95-4.95ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
          </svg>
          </div>
        </div>
      ) : null}
    </div>
  );
};
