import React, { useState, useEffect } from 'react';
import Modal from './../../elements/Modal';

const ReSubmissionDocument = ({leadId, documentId, documentParentId, filterData, actionType, showResubmissionModal, hideModalReSubmission, requestResubmission, user_id,uType,uId }) => {
    const [fieldData, setFieldData] = useState({ comment: '' });
    let docParams = [{
        id: 0,
        doc_id: documentId ? documentId : filterData.doc_id,
        parent_doc_id: documentParentId ? documentParentId : filterData.parent_id,
    }];
 
    const resubmissionForm = (e) => {
        e.preventDefault();        
        let resubmissionParams = {
            lead_id: leadId,
            user_id: user_id,
            doc_status: "0",
            doc_source:"web",
            update_action_type: 'documents',
            remark:fieldData.comment || '',
            customer_type: uType,
            customer_id: +uId,
            lead_docs: docParams,
            action_type: 'resubmission'
        };
        let message = "Resubmission Requested Successfully";
        requestResubmission(resubmissionParams, message);
        hideModalReSubmission();
        fieldData.comment = '';
        setFieldData({ ...fieldData });
    }

    const handleTeaxtAreaChange = (datefield, event) => {
        let fieldTempData = { ...fieldData };
        fieldTempData[event.target.name] = event.target.value;
        setFieldData({ ...fieldTempData });
    }
    let modal_title = actionType === "resubmission" ? "Request for Re-submission" : "Request for Doc Submission";
    return (
        <div className="mark-lost-popup">
            <Modal show={showResubmissionModal} handleClose={hideModalReSubmission} >
                <div className="modal-header">
                    <h2>{modal_title}</h2>
                </div>
                <div className="modal-body" >
                    <form onSubmit={resubmissionForm}>
                        <fieldset className="form-filed">
                            <div className="material">
                                <textarea onChange={handleTeaxtAreaChange.bind(null, 'comment')} placeholder=" " className="form-input" rows="4" name = "comment" value = {fieldData.comment}></textarea>
                                <label data-label="Comment" className="form-label"></label>
                            </div>
                        </fieldset>
                        <button className="btn-primary" type="submit">Submit</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}
export default ReSubmissionDocument;