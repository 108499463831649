import React from 'react';

class InputField extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        active: (props.locked && props.active) || false,
        value: props.value || "",
        id: props.id || "",
        name: props.name || "",
        type: props.type || "type",
        error: props.error || "",
        label: props.label || "",
        maxLength:props.maxLength || "",
        required:props.required || false,
        placeholder:props.placeholder|| props.label,
        is_label_required : props.is_label_required || false,
        form_field : props.form_field || false
      };
     // console.log(props)
    }
     
    changeValue = (event) => {
      let value = event.target.value;
      let name = event.target.name;
      if(value !== "" && (name === "acno" || name === "coacno") && !/^[0-9]+$/.test(value)){
      } else if (value !== "" && this.props.account_length > 0 && value.length > this.props.account_length&& (name === "acno" || name === "coacno")) {
      } else{
        if (/^[0-9]+$/.test(this.props.min)) value = Math.max(parseFloat(this.props.min), parseFloat(value));
        if (/^[0-9]+$/.test(this.props.max)) value = Math.min(parseFloat(this.props.max), parseFloat(value));
        event.target.value = value;
        this.setState({ value, error: "" });
        if (typeof this.props.onChange === 'function') {
          this.props.onChange(event);
        }
      }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ 
            error: nextProps.error,
            required: nextProps.required,
            value: nextProps.value
        });
    }
  
    onKeyPress = (event) => {
      // console.log('onKeyPress() > event', event);
      this.props.onKeyPress && this.props.onKeyPress(event)
      if (event.which === 13) {
        // console.log('onKeyPress() > event > ENTER CALL');
        // this.setState({ value: this.props.predicted });

      }
    }

  onPasteDisallow = (event) => {
    event.preventDefault();
  }
  onCopyDisallow = (event) => {
    event.preventDefault();
  }
  
    render() {
      const { active, value, error, label, id, type, name, required,maxLength ,is_label_required,form_field,placeholder } = this.state;
      const { predicted, locked, children  } = this.props;
    
      let fieldClassName = `form-field ${(locked ? active : active || value) &&
        "active"} ${locked && !active && "locked"}`;
      if(error) fieldClassName += ` field-error`
      return (
        <div className={!form_field ? fieldClassName:""}>
          {active &&
            value &&
            predicted &&
            predicted.includes(value) && <p className="predicted">{predicted}</p>}
            {/* <label htmlFor={id}>{label}</label> */}
           {!is_label_required ? <label htmlFor={id}>{label} {required && <span className="required" style={{ color: 'red' }}>*</span>}</label> : ""}
          <input
            id={id}
            className="form-control"
            type={type}
            value={value || ""}
            name={name}
            placeholder={placeholder}
            onChange={this.changeValue}
            disabled={this.props.disabled}
            onKeyPress={this.onKeyPress}
            onFocus={() => !locked && this.setState({ active: true })}
            onBlur={() => !locked && this.setState({ active: false })}
            maxLength={maxLength}
            autoComplete={this.props.autocomplete || ''}
            readOnly={this.props.readOnly}
            onPaste={this.props.RestrictOnPaste == 1 ? this.onPasteDisallow : ''}
            onCopy={this.props.RestrictOnCopy == 1 ? this.onCopyDisallow : ''}
            />
            {children}
          {error && <span className="error show">{error}</span>}
        </div>
      );
    }
  }
  
 
 
export default InputField;

