import Formsy from "formsy-react";
import React, { useEffect, useRef, useState } from "react";
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import FormsySelect from "../../elements/FormsySelect"; 
import Modal from "../../elements/Modal"; 
import Loader from '../../elements/Loader';
import { RotatingLines } from "react-loader-spinner";
import { useSelector,useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Tab, Nav } from 'react-bootstrap';
import dateFormat from "dateformat";
import { getLoanDetail } from '../../../store/action/allAction'; 
import {
  CHECK_CIBIL_CONSIGNMENT,
  GET_CIBIL_DETAILS,
  GET_DETAILS_BY_PANCARD_UPDATE,
  SEND_CONSIGNMENT_LINK,
  GET_CIBIL_INFO,
  GENERATE_CIBIL_PDF,
  GET_LAST_CIBIL_INFO
} from "../../../services/customer.gql";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { useLocation } from "react-router-dom";
import BlankCibilReport from "./BlankCibilReport";
import CibilReportDetail from './CibilReportDetail';

const CIBIL_REPORT_FIELDS = FormFields["CIBIL_REPORT"];

const CibilReport = () => {
  const client = useApolloClient();
  const location = useLocation();
  const dispatch = useDispatch();
  const [cibilDetails, setCibilDetails] = useState({});
  const [cibilCheckModal, setCibilCheckModal] = useState(false);
  const [isMobileFetched, setIsMobileFetched] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [userList, setUserList] = useState([false]);
  let [loading, setLoading] = useState(false);
  const [fetchMobileBtnDisabled, setFetchMobileBtnDisabled] = useState(false);
  const [resendLinkBtnDisabled, setResendLinkBtnDisabled] = useState(false); 
  const [activeTab, setActiveTab] = useState('customer'); 
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const { lead } = useSelector(({ lead }) => ({ lead }));
 
  useEffect(()=>{
    let options = [
      { 
        id: lead?.leadDetail?.customer?.customer_id,
        label: lead?.leadDetail?.customer?.first_name +' '+lead?.leadDetail?.customer?.last_name,
        value: lead?.leadDetail?.customer?.customer_id,
        type:'customer' 
      },
    ] 
    for(let coList of lead?.leadDetail?.co_applicant){ 
      options.push(
        { id: coList.id, label: coList.ca_first_name+' '+ coList.ca_last_name +' co-applicant', value: coList.id,type:'co-'+coList.id }
      )
    }
    setUserList(options);
  },[lead?.leadDetail?.customer])
 

  useEffect(() => {
    if (lead && lead?.leadDetail && lead?.leadDetail?.customer) { 
      const existingCibil = lead.leadDetail.lead_details?.cibil_score;
     // setCibilCheckModal(true);
      // if (existingCibil === null || existingCibil === "") {
      //   setCibilCheckModal(true);
      // }
      getLastCibilReport(lead.leadDetail.customer) 
      setActiveTab('customer')
      /* setCibilDetails((current) => ({
        ...current,
        pancard_no: lead.leadDetail.customer?.pancard_no,
        credit_score: 0,
        // credit_score: lead.leadDetail.lead_details.cibil_score || 0,
        first_name: lead.leadDetail.customer?.first_name,
        last_name: lead.leadDetail.customer?.last_name,
        mobile: lead.leadDetail.customer?.mobile,
        gender: lead.leadDetail.customer?.gender,
      })); */
    }
  }, [lead?.leadDetail.id, location]);

  const getColor = (value) => {
    if (value <= 400) {
      return "red";
    } else if (value <= 700) {
      return "yellow";
    } else {
      return "blue";
    }
  };

  const getText = (value) => { 
    value = value.toString();
    let cibilvalue = value?.split('-')?.[1] || value?.split('-')?.[0] || 0;
    if (cibilvalue <= 400) {
      return "Low";
    } else if (cibilvalue <= 700) {
      return "Medium";
    } else {
      return "Very Good";
    }
  };

  const handleModalClose = (event) => {
    if (
      !cibilDetails["credit_score"] ||
      cibilDetails["credit_score"] === "" ||
      cibilDetails["credit_score"] === 0
    ) {
      setCibilDetails((current) => ({
        ...current,
        cibilErrorMessage: "Consent not received",
      }));
    }
    setCibilCheckModal(false);
  };

  const handlePanChange = (event) => {
    const { id, value } = event.target;
    if (id === "pancard_no") setFetchMobileBtnDisabled(false);
    setCibilDetails((current) => ({
      ...current,
      [id]: value.toUpperCase(),
    }));
  };

  const callCheckConsignment = async (data) => {
    try {
      const variables = {
        checkConsignmentInput: data,
      };
      const response = await executeGraphQLMutation(
        CHECK_CIBIL_CONSIGNMENT(user_id, lead?.leadDetai?.id),
        variables,
        client
      );
      if (response.data?.check_cibil_consignment) {
        return JSON.parse(response.data?.check_cibil_consignment);
      } else {
        console.log(response.data);
        throw Error("Internal server error");
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      return {
        status: 500,
        message: error.message,
      };
    }
  };
  
  const callSendConsignmentLink = async (data) => {
    try {
      const variables = {
        sendConsignmentLinkInput: data,
      };
      const response = await executeGraphQLMutation(
        SEND_CONSIGNMENT_LINK(user_id, lead?.leadDetail?.id),
        variables,
        client
      );
      if (response.data?.send_consignment_link) {
        return JSON.parse(response.data?.send_consignment_link);
      } else {
        console.log(response.data);
        throw Error("Internal server error");
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      return {
        status: 500,
        message: error.message,
      };
    }
  };
  const callGetCibilDetails = async (data) => {
    try {
      const variables = {
        cibilDetailsInput: data,
      };
      const response = await executeGraphQLMutation(
        GET_CIBIL_DETAILS,
        variables,
        client
      );
      if (response.data?.get_cibil_details) {
        if(data.is_consigned ==1){ 
          setTimeout(async()=>{
            const result = await executeGraphQLQuery(GET_LAST_CIBIL_INFO(data.pan_card),client);
            if(result?.data?.last_cibil_info?.data) {   
              setCibilDetails((current) => ({
                ...current,
                cibilErrorMessage: null,
                ...result?.data?.last_cibil_info?.data,
                last_update_date:result?.data?.last_cibil_info.created_date
              })); 
            } 
          },100)
        }
        return JSON.parse(response.data?.get_cibil_details);
      } else {
        console.log(response.data);
        throw Error("Internal server error");
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      return {
        status: 500,
        message: error.message,
      };
    }
  };

  const cibilProcess = async (data) => {
    const getCibilResp = await callGetCibilDetails(data);
    if (getCibilResp.status === 200) {
      const consignLinkResp = await callSendConsignmentLink({
        pan_card: data.pan_card,
        mobile: data.mobile,
        partner_id: data.partner_id,
      });

      if (consignLinkResp.status === 200) {
        setCibilDetails((current) => ({
          ...current,
          cibilErrorMessage: "Consent not received",
        }));
        setTimeout(() => {
          setIsLoaderVisible(false);
          setCibilCheckModal(false);
          toast.success("Please come back after consent");
        }, 5000);
      }
    } else {
      setCibilDetails((current) => ({
        ...current,
        cibilErrorMessage: "Something went wrong!. Please try after some time",
      }));
      setTimeout(() => {
        setIsLoaderVisible(false);
        setCibilCheckModal(false);
      }, 2000);
      toast.error("Internal Server Error");
    }
  };

  const handleFetchMobile = async () => {
    try {
      setFetchMobileBtnDisabled(true);
      const pan_card = cibilDetails["pancard_no"];
      let customer_type = 'customer';
      let customer_id = lead?.leadDetail?.customer?.customer_id;
      if(activeTab != 'customer'){
        customer_type ='coapplicant'
        customer_id = activeTab?.split('-')?.[1] || 0; 
      } 
      const result = await executeGraphQLQuery(
        GET_DETAILS_BY_PANCARD_UPDATE(pan_card,customer_type,customer_id),
        client
      );
      if (result.data) {
        const jsonResp = JSON.parse(result.data?.get_details_by_pancard); 
        dispatch(getLoanDetail(lead?.leadDetail?.id, client))
        if (jsonResp.data?.response?.code === 200) {
          setCibilDetails((current) => ({
            ...current,
            mobile: jsonResp.data?.response?.mobile_no,
            first_name:
              jsonResp.data?.response?.firstName ||
              jsonResp.data?.response?.name,
            middle_name: jsonResp.data?.response?.middleName,
            last_name: jsonResp.data?.response?.lastName,
            dob: jsonResp.data?.response?.dob,
            gender: jsonResp.data?.response?.gender,
          }));
          setIsMobileFetched(true);
        } else {
          setIsMobileFetched(false);
          toast.error(jsonResp.data?.response?.message);
        }
      } else {
        toast.error("Internal Server Error");
      }
    } catch (error) {
      
      console.log(`🔻 ERROR : ${error.message}`);
      toast.error(error.message);
    }
  };

  const generatePdf = async () => {
    try { 
      setLoading(true);
      const pan_card = cibilDetails["pancard_no"];
      const result = await executeGraphQLQuery(
        GENERATE_CIBIL_PDF(pan_card,1, user_id, lead?.leadDetail?.id),
        client
      );
      if (result.data) {
        setLoading(false);
        const record =  result.data?.generate_cibil_report?.storePath;
        window.open(record,'_blank'); 
      } else {
        setLoading(false);
        toast.error("Internal Server Error");
      }
    } catch (error) { 
      toast.error(error.message);
    }
  };

  const handleFetchCibilReport = async () => {
    try {
      setIsLoaderVisible(true);
      const data = {
        pan_card: cibilDetails["pancard_no"],
        first_name: cibilDetails["first_name"],
        middle_name: cibilDetails["middle_name"],
        last_name: cibilDetails["last_name"],
        dob: cibilDetails["dob"],
        gender: cibilDetails["gender"],
        mobile: cibilDetails["mobile"],
        partner_id: `${lead?.leadDetail?.partner_id}`,
        user_id,
        partner_name: lead?.leadDetail?.partner_name,
        is_consigned: 0,
      };
      const exConsignResp = await callCheckConsignment({
        pan_card: data.pan_card,
        mobile: data.mobile,
        partner_id: data.partner_id,
      });
      if (exConsignResp.status === 200) {
        if (exConsignResp.data?.consign_status === 1) {
          const cibilResp = await callGetCibilDetails({
            ...data,
            is_consigned: 1,
          });
          if (cibilResp.status === 200) {
            setCibilDetails({ 
              cibilErrorMessage: null,
              pancard_no: lead.leadDetail.customer?.pancard_no,
              credit_score: 0, 
              first_name: lead.leadDetail.customer?.first_name,
              last_name: lead.leadDetail.customer?.last_name,
              mobile: lead.leadDetail.customer?.mobile,
              gender: lead.leadDetail.customer?.gender,
              ...cibilResp?.data
            })
            // setCibilDetails((current) => ({
            //   ...current,
            //   ...cibilResp?.data,
            //   cibilErrorMessage: null,
            // }));
            setIsLoaderVisible(false);
            setCibilCheckModal(false);
          } else if (cibilResp.status === 400) {
            console.log(cibilResp.errors);
            toast.error(`${cibilResp.message}`);
            setCibilDetails((current) => ({
              ...current,
              cibilErrorMessage: cibilResp.errors?.error || "Invalid details",
            }));
            setTimeout(() => {
              setIsLoaderVisible(false);
              setCibilCheckModal(false);
            }, 2000);
          }
        } else {
          toast.error("Please give consent using sent link");
          setCibilDetails((current) => ({
            ...current,
            cibilErrorMessage: "Consent not received",
          }));
          setTimeout(() => {
            setIsLoaderVisible(false);
            setCibilCheckModal(false);
          }, 2000);
        }
      } else {
        cibilProcess(data);
      }
    } catch (error) {
      console.log(`🔻 ERROR : ${error.message}`);
      toast.error(error.message);
      setCibilDetails((current) => ({
        ...current,
        cibilErrorMessage: "Something went wrong!. Please try after some time",
      }));
      setTimeout(() => {
        setIsLoaderVisible(false);
        setCibilCheckModal(false);
      }, 2000);
    }
  };

  const handleResendLink = () => {
    if (
      cibilDetails["pancard_no"] &&
      cibilDetails["first_name"] &&
      cibilDetails["mobile"] &&
      cibilDetails["dob"]
    ) {
      const data = {
        pan_card: cibilDetails["pancard_no"],
        first_name: cibilDetails["first_name"],
        middle_name: cibilDetails["middle_name"],
        last_name: cibilDetails["last_name"],
        dob: cibilDetails["dob"],
        gender: cibilDetails["gender"],
        mobile: cibilDetails["mobile"],
        partner_id: `${lead?.leadDetail?.partner_id}`,
        user_id,
        partner_name: lead?.leadDetail?.partner_name,
        is_consigned: 0,
      };
      setCibilCheckModal(true);
      setIsLoaderVisible(true);
      setResendLinkBtnDisabled(true);
      cibilProcess(data);
    } else {
      window.location.reload();
    }
  };

/*   useEffect(()=>{
    getCibilReport('BWNPK3502M')
  },[])
  const getCibilReport =  async (pan_card)=>{
    let report_type = 'report_summary,enquiry_details,loan_accounts,credit_utilization_details,payment_history,payment_history_details,credit_age'
    const result = await executeGraphQLQuery(
      GET_CIBIL_INFO(pan_card,report_type),
      client
    );
    if(result?.data?.cibil_info?.data) {
      setCibilRecord(result?.data?.cibil_info?.data) 
    }
  }  */ 
  const getLastCibilReport =  async (reqData)=>{ 
      let pan_card = reqData?.pancard_no;
      const result = await executeGraphQLQuery(GET_LAST_CIBIL_INFO(pan_card),client);
      if(result?.data?.last_cibil_info?.data) { 
        setCibilCheckModal(false);

        setCibilDetails((current) => ({
          ...current,
          cibilErrorMessage: null,
          ...result?.data?.last_cibil_info?.data,
          last_update_date:result?.data?.last_cibil_info.created_date,
          pancard_no: reqData?.pancard_no,
          credit_score: 0, 
          first_name: reqData?.first_name,
          last_name: reqData?.last_name,
          mobile: reqData?.mobile,
          gender: reqData?.gender,
        })); 
      }else{
        setCibilDetails((current) => ({
          ...current, 
          pancard_no: reqData?.pancard_no,
          credit_score: 0, 
          first_name: reqData?.first_name,
          last_name: reqData?.last_name,
          mobile: reqData?.mobile,
          gender: reqData?.gender,
        })); 
        setCibilCheckModal(true);
      }
    } 

  const handleSelectTab = (eventKey) => {   
    if(eventKey == 'customer'){
      getLastCibilReport(lead?.leadDetail?.customer) 
    }else{
      let coId = eventKey?.split('-')?.[1] || 0; 
      let co_applicant = lead?.leadDetail?.co_applicant.filter(ob=>ob.id==coId)?.[0] || {}; 
      let coRecord = {  
          pancard_no: co_applicant?.ca_pancard_no || '', 
          first_name: co_applicant?.ca_first_name || '',
          last_name: co_applicant?.ca_last_name || '',
          mobile: co_applicant?.ca_mobile || '',
          gender: co_applicant?.gender || '',  
      }
      getLastCibilReport(coRecord)  
    }
    setActiveTab(eventKey); 
  }   

  const handleSelectChange = (data) => {
    if(data.type == 'customer'){
      let reqData = lead?.leadDetail?.customer
      setCibilDetails((current) => ({
        ...current, 
        pancard_no: reqData?.pancard_no, 
        first_name: reqData?.first_name,
        last_name: reqData?.last_name,
        mobile: reqData?.mobile,
        gender: reqData?.gender,
      }));  
    }else{
      let coId = data.type?.split('-')?.[1] || 0; 
      let co_applicant = lead?.leadDetail?.co_applicant.filter(ob=>ob.id==coId)?.[0] || {}; 
      let reqData = {  
          pancard_no: co_applicant?.ca_pancard_no || '', 
          first_name: co_applicant?.ca_first_name || '',
          last_name: co_applicant?.ca_last_name || '',
          mobile: co_applicant?.ca_mobile || '',
          gender: co_applicant?.gender || '',  
      }
      setCibilDetails((current) => ({
        ...current, 
        pancard_no: reqData?.pancard_no, 
        first_name: reqData?.first_name,
        last_name: reqData?.last_name,
        mobile: reqData?.mobile,
        gender: reqData?.gender,
      }));  
    }
    setActiveTab(data.type);   
  }  
  return (
    <div className="image-form-outer">
      {loading ? <Loader/> : null}
      {/* CIBIL SCORE MODAL */}
      { cibilCheckModal ? (
        <>
          <div className="mark-lost-popup">
            <Modal
              show={cibilCheckModal}
              id="cibilCheckModal"
              handleClose={handleModalClose}
              removeHeight={true}
            >
              <div className="modal-header">
                <h2>Check Cibil Score</h2>
              </div>
              <div className="modal-body">
                {isLoaderVisible ? (
                  <>
                    <div style={{ textAlign: "center" }}>
                      <RotatingLines
                        visible={true}
                        height="80"
                        width="80"
                        strokeColor="grey"
                        strokeWidth="4"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                      <p>Waiting for customer's consent.</p>
                      <p>
                        A consent link has been shared with customer via SMS.
                      </p>
                      <p>
                        Please ask them to open the link and confirm enter the
                        OTP for confirmation.
                      </p>
                    </div>
                  </>
                ) : (
                  <>
                    <Formsy className="lead-form-filed" autoComplete="off">

                    <fieldset className="single-select col-md-12 "> 
                      <FormsySelect
                        name={"user_type"} 
                        inputProps={{
                          options: userList,
                          placeholder: "select field",
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value:  userList.filter(
                              ({ type }) => type == activeTab
                            ), 
                        }}
                        required={true}
                       // value={basicDetails && basicDetails[field.name]}
                        onChange={(data) =>
                          handleSelectChange(data)
                        }
                      /> 
                      </fieldset>
                      <fieldset className="form-filed col-md-12 ">
                        <FormsyInputField
                          id="pancard_no"
                          name="pancard_no"
                          type="text"
                          value={cibilDetails["pancard_no"] || ""}
                          placeholder=" "
                          maxLength="10"
                          label="Pan Card No."
                          onChange={handlePanChange}
                          format={true}
                          validations={"isValidPanCard"}
                          validationError={""}
                          readOnly={false}
                          required={true}
                        />
                      </fieldset>
                      {isMobileFetched && (
                        <>
                          <fieldset className="form-filed col-md-12 ">
                            <FormsyInputField
                              id="mobile"
                              name="mobile"
                              type="text"
                              value={cibilDetails["mobile"] || ""}
                              placeholder=" "
                              maxLength="10"
                              label="Mobile No."
                              onChange={handlePanChange}
                              format={true}
                              validations={"isValidPhoneNumber"}
                              validationError={""}
                              readOnly={false}
                              required={true}
                            />
                          </fieldset>
                        </>
                      )}
                      <div className="btn-save-remarks">
                        {isMobileFetched ? (
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={handleFetchCibilReport}
                          >
                            Fetch Cibil Report
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="btn-primary"
                            onClick={handleFetchMobile}
                            disabled={fetchMobileBtnDisabled}
                          >
                            Fetch mobile no.
                          </button>
                        )}
                      </div>
                    </Formsy>
                  </>
                )}
              </div>
            </Modal>
          </div>
        </>
      ) : cibilDetails["cibilErrorMessage"] ? (
        <BlankCibilReport
          cibilErrorMessage={cibilDetails["cibilErrorMessage"]}
          resendConsignmentLink={handleResendLink}
          resendConsignmentBtn={resendLinkBtnDisabled}
          handleSelectTab={handleSelectTab}
          activeTab={activeTab}
          lead={lead}
        />
      ) : (
        <>
          {/* LEFT PANEL */}
          <div className="image-tab-panel form-tab-right-panel">
            <div>Cibil Report</div>
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => null}
              onInvalid={() => null}
            >
              <div className="row">
                {CIBIL_REPORT_FIELDS.map((field, index) =>
                  ["text", "pattern-format", "number"].includes(field.type) ? (
                    <fieldset className="form-filed col-md-12" key={index}>
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={cibilDetails[field.name] || ""}
                        placeholder=" "
                        label={field.label}
                        format={field.ApplyNumberformat ? field.format : ""}
                        readOnly={field.readOnly}
                        required={field.required}
                      />
                    </fieldset>
                  ) : field.type === "date-picker" ? (
                    <fieldset className="form-filed col-md-12" key={index}>
                      <FormsyDatePicker
                        name="login_date"
                        selectedDate={
                          cibilDetails &&
                          cibilDetails[field.name] &&
                          new Date(cibilDetails[field.name])
                        }
                        placeholder={field.label}
                        maxDate={field.maxDate}
                        minDate={field.minDate}
                        value={(cibilDetails && cibilDetails[field.name]) || ""}
                        dateFormat="dd MMM, yyyy"
                      />
                    </fieldset>
                  ) : null
                )}
              </div>
            </Formsy>
          </div>

          {/* MIDDLE PANEL */}
          <div className="form-tab-right-panel">

          <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab} onSelect={handleSelectTab}>
                <Nav variant="pills" className="flex-column tab-line">
                    <div className="tab-list"> 
                        <Nav.Item className='nav-item'>
                            <Nav.Link active={activeTab == 'customer'} eventKey={'customer'} disabled={false}> Customer</Nav.Link>
                        </Nav.Item>
                        {
                            lead?.leadDetail.co_applicant?.length ? lead?.leadDetail.co_applicant.map((ob,key)=>{
                                return (
                                    <Nav.Item className='nav-item' key={key}>
                                        <Nav.Link active={activeTab == 'co-'+ob.id} eventKey={"co-"+ob.id}>{ob.ca_first_name} co applicant</Nav.Link>
                                    </Nav.Item>
                                )
                            }) : null
                        }  
                    </div>
                </Nav> 
            </Tab.Container>

            <div style={{ marginTop: 40 }}>

            <div class="cibilcircle">
                <img src="https://ambak.storage.googleapis.com/partner/2123/1724145263610.png" alt="circle" width="180" height="160" />
                <div class="textoverlay">
                    <div className="cibilscoreheading"> {cibilDetails?.mobile_res?.credit_score || -1}</div>
                    <div className="cibilsubstatus"> {cibilDetails?.mobile_res?.["cibil_label"]} </div>
                </div>
            </div>
              {/* <div className="cibilscorebox">
                <ReactStoreIndicator
                  width={300}
                  value={700}
                  minValue={300}
                  maxValue={900}
                  lineWidth={20}
                  lineGap={0}
                />
                <div className="cibilscore">
                  <div
                    style={{
                      fontSize: 40,
                      fontWeight: 600,
                      color: getColor(cibilDetails?.mobile_res?.cibil_score || 0),
                    }}
                  >
                    {cibilDetails?.mobile_res?.credit_score || -1}
                  </div>
                  <div
                    style={{
                      fontSize: 18,
                      color: getColor(cibilDetails?.mobile_res?.cibil_score || 0),
                    }}
                  >
                    {cibilDetails?.mobile_res?.["cibil_label"]} 
                  </div>
                </div>
                <div style={{ textAlign: "center", marginTop: 40 }}>
                  <span style={{ marginRight: 200 }}>{300}</span>{" "}
                  <span>{900}</span>
                </div>
              </div> */}
              <div className="cibilupdated">
                <span>Last Updated : </span>
                <span>{(cibilDetails["last_update_date"])? dateFormat(cibilDetails["last_update_date"], 'd mmm,yyyy hh:MM TT') : "N/A"}</span>
              </div>
              <div class="btn-save-remarks ctrs">
                <button type="submit" class="btn-primary" onClick={generatePdf}>Download Report</button> 
              </div>  
            </div>
          </div>

          {/* RIGHT PANEL */}
          <CibilReportDetail cibilDetails={cibilDetails} cibilRecord={cibilDetails}/> 
        </>
      )}
    </div>
  );
};
export default CibilReport;
