import React, { useEffect, useMemo, useState } from "react";
import Formsy from "formsy-react";
import { useDispatch, useSelector } from "react-redux";
import DocumentGallery from "../DocumentGallery";
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsySelect from "../../elements/FormsySelect";
import { useOutletContext, useNavigate, useParams } from "react-router-dom";
import LeadRemarkHistory from "../../lead-list/LeadRemarkHistory";
import Modal from "../../elements/Modal";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { SAVE_CUST_DETAILS } from "../../../services/customer.gql";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { getLoanDetail } from "../../../store/action/allAction";
import {
  CHECK_ASSIGN_USER,
  IS_USER_ACCESS_PAGE,
  DISBURSE_REJECTION_ID,
  AMOUNT_TYPE_OPTIONS,
} from "../../../config/constants";
import AdditionalIncome from "../../elements/AdditionalIncome";
// import Obligation from "../../elements/Obligation";
import BreObligations from "../BreOffer/BreObligations";

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date("1900-01-01 00:00:00");

const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" },
];

const FORM_FIELDS = FormFields["INCOME_DETAILS"];

const IncomeDetailsForm = (props) => {
  const [incomeDetails, setIncomeDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState(0);
  // const [moreDetailsCount, setMoreDetailsCount] = useState(0);
  const [employmentType, setEmploymentType] = useState(0);
  // const [obligationsCount, setObligationsCount] = useState(0);
  // const [obligationFormFields, setObligationFormFields] = useState([]);
  // const [moreDetailFormFields, setMoreDetailFormFields] = useState([]);
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    []
  );
  const { masterdata, lead, remarksHistory } = useSelector(
    ({ masterdata, lead }) => ({
      masterdata,
      lead,
      remarksHistory: lead.remarksHistory || [],
    })
  );
  const user_information = localStorage.getItem("user_information");
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;

  const [isFormTouch, setTouch] = useState({});
  const client = useApolloClient();
  const dispatch = useDispatch();
  // let navigate = useNavigate();
  // let { lead_id } = useParams();
  let accessCondition = !IS_USER_ACCESS_PAGE(
    "incomeDetail",
    "customer-basic-details"
  )?.is_edit_access;

  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || [];

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options["gender"] = GENDER_OPTIONS;
    options["amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["company_type"] = options["company_type"]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    options["additional_income_type"] = options["additional_income_type"]?.map(
      (obj) => ({
        ...obj,
        value: obj.id,
      })
    );
    options["filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    // options["obligation_type"] = options["obligation_type"]?.map((ob) => ({
    //   ...ob,
    //   value: +ob.id,
    // }));
    // options["filtered_obligation_type"] = options["obligation_type"]?.map(
    //   (ob) => ({
    //     ...ob,
    //     value: +ob.id,
    //   })
    // );
    // options["more_detail_type"] = options["more_detail_type"]?.map((ob) => ({
    //   ...ob,
    //   value: +ob.id,
    // }));
    // options["filtered_more_detail_type"] = options["more_detail_type"]?.map(
    //   (ob) => ({
    //     ...ob,
    //     value: +ob.id,
    //   })
    // );
    return options;
  }, [masterdata]);

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      let record = { ...lead.leadDetail.lead_details };
      record["business_proof"] = lead.leadDetail?.customer.business_proof;
      record["additional_income"] = lead.leadDetail?.customer.additional_income;
      record["is_additional_income"] =
        lead.leadDetail?.customer.is_additional_income;
      // record["obligation"] = lead.leadDetail?.customer.obligation;
      record["is_obligation"] = lead.leadDetail?.customer.is_obligation;
      record["existing_emi_amount"] = lead.leadDetail?.customer.existing_emi_amount;
      record["emi_ending_six_month"] = lead.leadDetail?.customer.emi_ending_six_month;

      if (record.is_additional_income === "yes") {
        for (let i = 0; i < record.additional_income.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`filtered_additional_income_type_${i + 1}`] = ALL_OPTIONS[
            `filtered_additional_income_type_${i}`
          ]?.filter(
            (obj) => obj.value !== record.additional_income[i].income_type_id
          );
        }
      }

      // if (record.is_obligation === "yes") {
      //   for (let i = 0; i < record.obligation?.length; i++) {
      //     handleAddObligations(i);
      //   }
      // }

      // if (record?.profession === 1) {
      //   ALL_OPTIONS["filtered_obligation_type"] = ALL_OPTIONS[
      //     "obligation_type"
      //   ].filter((obj) => obj.value !== 3);
      // }

      // if (record?.profession === 2) {
      //   ALL_OPTIONS["filtered_more_detail_type"] = ALL_OPTIONS[
      //     "more_detail_type"
      //   ].filter((obj) => obj.value === 3);
      // }

      setEmploymentType(record.profession || 0);
      setIncomeDetails(record);
    }
  }, []);
  // }, [lead]);

  const handleInputChange = (event) => {
    setTouch({
      ...isFormTouch,
      [event.target.id]: true,
    });
    if (event.target.id) {
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        [event.target.id]: event.target.value,
      }));
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name === "profession") {
        let filteredAddInc = [],
          filteredObli = [];
        // let filteredMoreDet = ALL_OPTIONS["more_detail_type"];
        setEmploymentType(data.value);
        if (data.value === 1) {
          filteredAddInc = ALL_OPTIONS["additional_income_type"];
          // filteredObli = ALL_OPTIONS["obligation_type"].filter(
          //   (obj) => obj.value !== 3
          // );
        } else {
          // if (data.value === 2) {
          //   filteredMoreDet = ALL_OPTIONS["more_detail_type"].filter(
          //     (obj) => obj.value === 3
          //   );
          // }
          filteredAddInc = ALL_OPTIONS["additional_income_type"].filter(
            (obj) => obj.value === 1 || obj.value === 5
          );
          // filteredObli = ALL_OPTIONS["obligation_type"];
        }
        ALL_OPTIONS["filtered_additional_income_type"] = filteredAddInc;
        // ALL_OPTIONS["filtered_obligation_type"] = filteredObli;
        // ALL_OPTIONS["filtered_more_detail_type"] = filteredMoreDet;
      }
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleSubmit = () => {
    const {
      profession,
      salary_credit_mode,
      company_name,
      company_type,
      is_itr_filled,
      is_form_16_filled,
      gross_monthly_salary,
      monthly_salary,
      annual_income,
      is_additional_income,
      additional_income,
      // obligation,
      business_proof,
      is_obligation,
      existing_emi_amount,
      emi_ending_six_month,
    } = incomeDetails;

    let lead_id = lead?.leadDetail?.id || "";
    const customer_id = lead?.leadDetail?.customer.customer_id;
    const customer = {
      is_additional_income,
      first_name: lead?.leadDetail?.customer.first_name,
      last_name: lead?.leadDetail?.customer.last_name,
      mobile: lead?.leadDetail?.customer.mobile,
      business_proof,
      is_obligation,
      ...(is_obligation === "yes"
        ? { existing_emi_amount, emi_ending_six_month }
        : {}),
    };

    if (additional_income?.length > 0) {
      const additionalIncome = additional_income?.map((income) => {
        return {
          ...(income.id ? { id: income?.id } : {}),
          lead_id,
          customer_id,
          customer_type: "customer",
          income_type_id: income.income_type_id || null,
          amount_type: income.amount_type,
          amount: income.amount,
          status: income.status,
        };
      });
      customer["additional_income"] = additionalIncome;
      customer["is_additional_income"] = "yes";
    }

    // if (obligation?.length > 0) {
    //   const obli = obligation?.map((income) => {
    //     return {
    //       ...(income.id ? { id: income?.id } : {}),
    //       lead_id,
    //       customer_id,
    //       customer_type: "customer",
    //       obligation_type_id: income.obligation_type_id,
    //       emi_amount: income.emi_amount,
    //       pending_emi_months: +income.pending_emi_months,
    //       status: income.status,
    //     };
    //   });
    //   customer["obligation"] = obli;
    //   customer["is_obligation"] = "yes";
    // }

    let reqBody = {
      profession,
      salary_credit_mode,
      ...(company_name ? { company_name } : {}),
      ...(company_type ? { company_type: `${company_type}` } : {}),
      is_itr_filled,
      is_form_16_filled,
      gross_monthly_salary,
      monthly_salary,
      annual_income,
    };

    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: reqBody,
          customer,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        dispatch(getLoanDetail(lead_id, client));
        // navigateToNext();
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  let IncomeDetailsForm = useMemo(() => {
    return FORM_FIELDS.map((field) => {
      if (field.name === "dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    });
  }, []);

  IncomeDetailsForm = useMemo(() => {
    if (incomeDetails) {
      // To Show nationality input text box in case of non phillipines
      if (!incomeDetails.existing_emi || incomeDetails.existing_emi != "yes") {
        return FORM_FIELDS.filter(
          (v) => !["no_of_emi", "existing_emi_amount"].includes(v.id)
        );
      }
      if (incomeDetails.nationality !== "other") {
        return FORM_FIELDS.filter((v) => v.id !== "other_nationality");
      }
    }
    return FORM_FIELDS;
  }, [incomeDetails]);

  // All the conditions can be combined here for button enable/disable
  const isSubmitDisabled = useMemo(() => {
    return lead.leadDetail.sub_status_id == 2 ? false : true;
  }, [allowSubmit, getContext]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  useEffect(() => {
    setFormFields(IncomeDetailsForm);
  }, []);

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields.filter(
        (obj) => obj.id === `additional_income_type_${index}`
      );
      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[additionalIncomeFormFields.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `additional_income_type_${index}` };
        setAdditionalIncomeCount(index + 1);
        setAdditionalIncomeFormFields((current) => [...current, dropDownObj]);
      }
    }
  };

  // const handleAddMoreDetails = (index) => {
  //   const dropDownObj = {
  //     id: `more_detail_amount_type_${index}`,
  //     name: `more_detail_amount_type_${index}`,
  //     readOnly: false,
  //     type: `dropdown`,
  //     label: `Amount type`,
  //     required: false,
  //     validations: null,
  //     validationError: "",
  //     ApplyNumberformat: false,
  //     optionsKey: `filtered_more_detail_type`,
  //   };

  //   const inputObj = {
  //     id: `more_detail_amount_${index}`,
  //     name: `more_detail_amount_${index}`,
  //     readOnly: false,
  //     type: "number-format",
  //     label: "Amount",
  //     required: false,
  //     validations: "isNumeric",
  //     validationError: "",
  //     ApplyNumberformat: false,
  //   };
  //   setMoreDetailsCount(index + 1);
  //   setMoreDetailFormFields((current) => [...current, dropDownObj, inputObj]);
  // };

  // const handleAddObligations = (index) => {
  //   if (index < 5) {
  //     const isExisting = obligationFormFields.filter(
  //       (obj) => obj.id === `obligation_type_${index}`
  //     );
  //     if (
  //       isExisting.length === 0 ||
  //       obligationFormFields[obligationFormFields.length - 1].canAddMore
  //     ) {
  //       const dropDownObj = { id: `obligation_type_${index}` };

  //       setObligationsCount(index + 1);
  //       setObligationFormFields((current) => [...current, dropDownObj]);
  //     }
  //   }
  // };

  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    setIncomeDetails((current) => ({
      ...current,
      [name]: checked ? "1" : "0",
    }));
  };

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`filtered_additional_income_type_${index + 1}`] = ALL_OPTIONS[
        `filtered_additional_income_type_${index}`
      ].filter((obj) => obj.value !== data.value);
    }
  };

  const handleRemoveAdditionalIncome = (index) => {
    const existingAddIncomeField = [...additionalIncomeFormFields];
    const existingAddIncome = [...incomeDetails["additional_income"]];
    const removedEle = {
      ...existingAddIncome[index],
      status: "0",
      income_type_id: null,
      amount_type: "",
      amount: "",
    };
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[
        existingAddIncomeField.length - 1
      ].canAddMore = true;
    }
    existingAddIncome.splice(index, 1);
    existingAddIncome.push(removedEle);
    setAdditionalIncomeCount(additionalIncomeCount - 1);
    setAdditionalIncomeFormFields(existingAddIncomeField);
    setIncomeDetails((currentValue) => ({
      ...currentValue,
      additional_income: existingAddIncome,
    }));
  };

  const handleSetAdditionalIncomeData = (data) => {
    setIncomeDetails((currentValue) => ({
      ...currentValue,
      additional_income: data,
    }));
  };

  // const handleRemoveObligation = (index) => {
  //   const existingObField = [...obligationFormFields];
  //   const existingOb = [...incomeDetails["obligation"]];
  //   const removedEle = {
  //     ...existingOb[index],
  //     status: "0",
  //     obligation_type_id: null,
  //     emi_amount: "",
  //     pending_emi_months: null,
  //   };
  //   existingObField.splice(index, 1);
  //   if (existingObField.length > 0) {
  //     existingObField[existingObField.length - 1].canAddMore = true;
  //   }
  //   existingOb.splice(index, 1);
  //   existingOb.push(removedEle);
  //   setObligationsCount(obligationsCount - 1);
  //   setObligationFormFields(existingObField);
  //   setIncomeDetails((currentValue) => ({
  //     ...currentValue,
  //     obligation: existingOb,
  //   }));
  // };

  const handleSetObligationData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      setIncomeDetails((currentValue) => ({
        ...currentValue,
        ...(obj ? { ...obj } : { [key]: value }),
      }));
    }
  };

  const handleRadioChange = (event) => {
    const { id, name } = event.target;

    // add slight delay to state updates and component re-renders
    setTimeout(() => {
      setIncomeDetails((current) => ({
        ...current,
        [name]: id,
      }));
    }, 0);
  };

  const sectionInfo = {
    section: "Customer details",
    sub_section: "Basic details",
  };
  const rejectDisable =
    lead.leadDetail.reject_reason_id > 0 &&
    !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) &&
    [2, 3].includes(lead.leadDetail.status_id)
      ? true
      : false;
  const IS_LOST = lead?.leadDetail?.status_id == 5 ? true : false;
  return (
    <div
      className="image-form-outer"
      key={lead.leadDetail && lead.leadDetail?.status_id}
    >
      <div className="image-tab-panel">
        <DocumentGallery
          documentCategoryList={documentCategoryList}
          doc_id="1"
        />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Income Details</h2>
          </div>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {formFields.map((field, index) =>
                (employmentType === 0 && field.name === "annual_income") ||
                (employmentType === 0 && field.name === "company_type") ||
                (employmentType === 0 && field.name === "company_name") ||
                (employmentType === 0 && field.name === "salary_credit_mode") ||
                (employmentType !== 1 && field.name === "monthly_salary") ||
                (employmentType !== 1 &&
                  field.name === "gross_monthly_salary") ||
                (employmentType === 1 && field.name === "annual_income") ||
                (employmentType === 2 && field.name === "salary_credit_mode") ||
                (employmentType === 3 && field.name === "salary_credit_mode") ||
                (employmentType === 3 && field.name === "company_type") ||
                (employmentType === 3 &&
                  field.name === "company_name") ? null : [
                    "text",
                    "pattern-format",
                    "number-format",
                    "number",
                  ].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 " key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={(incomeDetails && incomeDetails[field.name]) || ""}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      label={field.label}
                      onChange={
                        ["pattern-format", "number-format"].includes(field.type)
                          ? (data) =>
                              handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={
                        isFormTouch[field.name] ? field.validations : null
                      }
                      validationError={
                        incomeDetails[field.name] ||
                        incomeDetails[field.name] == ""
                          ? isFormTouch[field.name]
                            ? field.validationError
                            : ""
                          : ""
                      }
                      readOnly={field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset className="single-select col-md-6" key={index}>
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS[field.optionsKey] &&
                          ALL_OPTIONS[field.optionsKey].filter(
                            ({ value }) => value == incomeDetails[field.name]
                          ),
                        isDisabled: field.readOnly,
                      }}
                      required={field.required}
                      value={incomeDetails && incomeDetails[field.name]}
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                  </fieldset>
                ) : null
              )}
            </div>

            {/* ADDITIONAL INCOME */}
            {employmentType !== 0 && (
              <>
                {additionalIncomeFormFields.map((data, index) => (
                  <>
                    <div className="additionalincome" key={index}>
                      Additional Income - {index + 1}
                    </div>
                    <AdditionalIncome
                      index={index}
                      ALL_OPTIONS={ALL_OPTIONS}
                      setAdditionalIncomeType={handleAdditionalIncomeType}
                      removeAdditionalIncome={handleRemoveAdditionalIncome}
                      setAdditionalIncomeData={handleSetAdditionalIncomeData}
                      additionalIncomeData={incomeDetails["additional_income"]}
                      key={index}
                    />
                  </>
                ))}

                {additionalIncomeCount < 5 && (
                  <div
                    className="additionalincome"
                    onClick={() =>
                      handleAddAdditionalIncome(additionalIncomeCount)
                    }
                  >
                    <span>+</span>
                    <div>Additional Income</div>
                  </div>
                )}
              </>
            )}

            {employmentType !== 0 && <hr></hr>}

            {/* OBLIGATION */}
            {employmentType !== 0 && (
              <>
                <h4 className="additionalincome">
                  Does the customer have any obligation?
                </h4>
                <div className="propertyflex">
                  <input
                    type="radio"
                    id="yes"
                    name="is_obligation"
                    onChange={handleRadioChange}
                    checked={
                      incomeDetails["is_obligation"] === "yes" ? true : false
                    }
                  />
                  <label htmlFor="yes">Yes</label>

                  <input
                    type="radio"
                    id="no"
                    name="is_obligation"
                    onChange={handleRadioChange}
                    checked={
                      incomeDetails["is_obligation"] === "no" ? true : false
                    }
                  />
                  <label htmlFor="no">No</label>
                </div>

                {incomeDetails["is_obligation"] === "yes" && (
                  <BreObligations
                    setObligationDetails={handleSetObligationData}
                    customerType={"customer"}
                  />
                )}
                {/* {obligationFormFields.map((data, index) => (
                  <>
                    <div className="additionalincome" key={index}>
                      Obligation - {index + 1}
                    </div>
                    <Obligation
                      index={index}
                      ALL_OPTIONS={ALL_OPTIONS}
                      removeObligation={handleRemoveObligation}
                      setObligationData={handleSetObligationData}
                      obligationData={incomeDetails["obligation"]}
                      employmentType={employmentType}
                      key={index}
                    />
                  </>
                ))}

                {obligationsCount < 5 && (
                  <div
                    className="additionalincome"
                    onClick={() => handleAddObligations(obligationsCount)}
                  >
                    <span>+</span>
                    <div>
                      {employmentType === 1
                        ? "EMI based Obligations"
                        : "Monthly Obligations"}
                    </div>
                  </div>
                )} */}
              </>
            )}

            {employmentType === 1 ? (
              <>
                <div className="property-identified">
                  <label>Have you filled Form 16B ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="is_form_16_filled"
                      id="is_form_16_filled"
                      value="Yes"
                      checked={
                        incomeDetails["is_form_16_filled"] === "1"
                          ? true
                          : false
                      }
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : employmentType === 2 || employmentType === 3 ? (
              <>
                <div className="property-identified">
                  <label>Have you filled ITR ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="is_itr_filled"
                      id="is_itr_filled"
                      value="Yes"
                      checked={
                        incomeDetails["is_itr_filled"] === "1" ? true : false
                      }
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : null}

            {employmentType === 3 ? (
              <>
                <div className="property-identified">
                  <label>Do you have business proof ?</label>
                  <label className="toggle-label">No</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      name="business_proof"
                      id="business_proof"
                      value="1"
                      checked={
                        incomeDetails["business_proof"] == "1" ? true : false
                      }
                      onChange={handleCheckBoxChange}
                    />
                    <span className="slider"></span>
                  </label>
                  <label className="toggle-label">Yes</label>
                </div>
              </>
            ) : null}

            <div className="btn-save-remarks">
              <button
                type="submit"
                className="btn-primary"
                disabled={!allowSubmit || IS_LOST}
              >
                Save
              </button>
              {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine} disabled={rejectDisable}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine}>
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory
            showremarkform={showremarkform}
            markAsFreezed={getContext.markAsFreezed}
            accessCondition={accessCondition}
            sectionInfo={sectionInfo}
          />
        </Modal>
      </div>
    </div>
  );
};
export default IncomeDetailsForm;
