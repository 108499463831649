import React from 'react';
import { Navigate } from 'react-router-dom';

const BeforeLogin = ({ component: Component }) => { 
     
    let record = localStorage.getItem("loggedIn"); 
     if(!record){ 
        return <Navigate to='/login'  />
    }else{ 
        return (
            <Component />
        );
    }
    
};

export default BeforeLogin;