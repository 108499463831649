import React, { useState } from "react";
import CibilReportTab from './CibilReportTab';
import { numberWithCommas } from "../../../helpers/helpers";

const CibilReportDetail = (props) => {

  const [tabType, setTabtype] = useState(null)

  const getInfo = (type) => {
    setTabtype(type) 
  } 
  let cibilDetails = props.cibilRecord;
 
   return (
    <div className="form-tab-right-panel">
      {
        tabType != null ?
          <CibilReportTab setTabtype={setTabtype} tabType={tabType} cibilRecord={props.cibilRecord} />
          :
          <div>
            {
              props?.cibilRecord?.mobile_res?.credit_score > 0 ?
                <div className="loan" style={{ cursor: 'pointer' }}> 
                 <div className="cibilincomecard">
                    <div className="cibilincome">
                        <div>Estimated Annual Income</div>
                        <div className="cibilprice"> {numberWithCommas(Math.round(props.cibilRecord?.report_summary?.estimated_annual_income/12)) || 0}</div>
                    </div>
                    <div className="cibilincome">
                        <div>Sanctioned Amount</div>
                        <div className="cibilprice"> {numberWithCommas(Math.round(props.cibilRecord?.report_summary?.total_sanctioned_loan_amount))}</div>
                    </div>
                </div>
                <div className="cibilincomecard">
                    <div className="cibilincome">
                        <div>Monthly Obligation</div>
                        <div className="cibilprice"> {numberWithCommas(Math.round(props.cibilRecord?.report_summary?.current_monthly_obligations))}</div>
                    </div>
                    <div className="cibilincome">
                        <div>Outstanding Balance</div>
                        <div className="cibilprice"> {numberWithCommas(Math.round(props.cibilRecord?.report_summary?.current_outstanding))}</div>
                    </div>
                </div>

                <div className="loan"  style={{cursor:'pointer'}}>
                    <h3 className="cibilscoreheading">Loan Accounts </h3>
                </div>

                <div className="cibilloancard" onClick={() => getInfo('loan_accounts')}>
                    <div className="totalaccount"> Total Loans : <span> {props.cibilRecord?.report_summary?.total_loans}</span></div>

                    <div className="acountdetails">
                        <div className="activeaccount">
                            <div className="accountommon">{props.cibilRecord?.report_summary?.total_active_loans}</div>
                            <div>Active</div>
                        </div>
                        <div className="activeaccount">
                            <div className="accountommon">{props.cibilRecord?.report_summary?.total_overdue_accounts || 0}</div>
                            <div>Overdue </div>
                        </div>
                        <div className="activeaccount">
                            <div className="accountommon"> {numberWithCommas(Math.round(props.cibilRecord?.report_summary?.max_overdue_amount)) || 0}</div>
                            <div>Overdue Amount</div>
                        </div>
                    </div>
                </div> 
 
                <br/><br/>
                </div>
                :
                <div className="new-profile"> 
                  <div className="cc-util-card row">
                    <div className="col-sm-12">
                      <h2>Credit Report Not Available </h2>
                      <span>This Proifle is New to Credit</span>
                    </div>
                  </div>
                </div>

            }
 
            <h3 className="cibilscoreheading">Credit Score Insights</h3>
            <div>
              <div style={{ marginBottom: 20 }}>High Impact</div>
              <div className="cibilhistory" style={{ cursor: 'pointer' }} onClick={() => getInfo('payment_history')}>
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <i
                      className="ic-Call-history"
                      style={{ fontSize: 18 }}
                    ></i>
                  </div>
                  <div>
                    <div className="cibilstatus">
                      Payment History 
                    </div>
                    <div className="cibilsubstatus">
                      Never miss due date 
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                    {(props?.cibilRecord?.mobile_res?.credit_score > 0)? cibilDetails?.mobile_res?.["repayment_history"]?.value+' %' || "N/A" :'N/A'}
                  </div>
                  <div>
                    {(props?.cibilRecord?.mobile_res?.credit_score > 0) ? cibilDetails?.mobile_res?.["repayment_history"]?.value_label || "N/A" :"N/A"}
                  </div>
                </div>
              </div>
              <div className="cibilhistory" style={{ cursor: 'pointer' }} onClick={() => getInfo('credit_utilization')}>
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <i
                      className="ic-Call-history"
                      style={{ fontSize: 18 }}
                    ></i>
                  </div>
                  <div>
                    <div className="cibilstatus">
                      Credit Card Utilisation 
                    </div>
                    <div className="cibilsubstatus">
                      Keep outstanding &lt; 30 % 
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                  {(props?.cibilRecord?.mobile_res?.credit_score > 0) ? cibilDetails?.mobile_res?.["credit_utilization"]?.value+' %' || "N/A" :"N/A"}  
                  </div>
                  <div>
                    {(props?.cibilRecord?.mobile_res?.credit_score > 0) ? cibilDetails?.mobile_res?.["credit_utilization"]?.value_label || "N/A" :"N/A"}  
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div style={{ marginBottom: 20 }}>Medium Impact</div>
              <div className="cibilhistory" style={{ cursor: 'pointer' }} onClick={() => getInfo('credit_age')}>
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <i
                      className="ic-Call-history"
                      style={{ fontSize: 18 }}
                    ></i>
                  </div>
                  <div>
                    <div className="cibilstatus">
                      Credit Age
                    </div>
                    <div className="cibilsubstatus">
                      Above 5 year in excellent
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                    { (props?.cibilRecord?.mobile_res?.credit_score > 0) ? cibilDetails?.mobile_res?.["credit_age"]?.value || "N/A":"N/A"}
                  </div>
                  <div>
                    {(props?.cibilRecord?.mobile_res?.credit_score > 0) ? cibilDetails?.mobile_res?.["credit_age"]?.value_label || "N/A" :"N/A"}
                  </div>
                </div>
              </div>
            </div>


            <div>
              <div style={{ marginBottom: 20 }}>Low Impact</div>
              <div className="cibilhistory" style={{ cursor: 'pointer' }} onClick={() => getInfo('enquires')}>
                <div className="cibilicon">
                  <div className="cibilhighscore">
                    <i
                      className="ic-Call-history"
                      style={{ fontSize: 18 }}
                    ></i>
                  </div>
                  <div>
                    <div className="cibilstatus">
                      Enquires in last 6 months
                    </div>
                    <div className="cibilsubstatus">
                      Avoid too many enquires
                    </div>
                  </div>
                </div>

                <div>
                  <div className="cibilcolorper">
                  {(cibilDetails?.mobile_res?.["enquiries"]?.value ==0)? 0 : cibilDetails?.mobile_res?.["enquiries"]?.value || "N/A"} 
                  </div>
                  <div>
                  {(cibilDetails?.mobile_res?.["enquiries"]?.value ==0)?'Excellent':cibilDetails?.mobile_res?.["enquiries"]?.value_label || "N/A"} 
                  </div>
                </div>
              </div>
            </div>
          </div>
      }

    </div>
  );
};
export default CibilReportDetail;
