import React, { Component } from "react";
// import InputField from './../../elements/InputField'
// import Button from '../../elements/Button'
// import MasterService from './../../../service/MasterService';
import { withTranslation } from "react-i18next";
// import { toast } from 'react-toastify';
import { connect } from "react-redux";
import moment from "moment";


class KycAllComment extends Component {
  constructor(props) {
      super(props);
      this.state = {
          index: props.currentIndex,
          comments: props.comment,
          errors: {},
      };
  }

  render() {
      const { formData, errors, comments } = this.state;
      let css = '';
      if (errors) {
          css = `.form-error {display:block}`;
      }
      let kycComment = comments.reverse();
      return (
          <div className="row">
              <div className="col-sm-6 col-md-12 form-group">
                  {kycComment.length > 0 ?
                      <table className="table table-bordered">
                          <thead>
                              <tr>
                                  <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.action')}</th>
                                  <th style={{width: "60%"}}>{this.props.t('dealerDetails.kyc_details.comment')}</th>
                                  <th style={{width: "20%"}}>{this.props.t('dealerDetails.kyc_details.comment_date')}</th>
                              </tr>
                          </thead>
                          <tbody>
                              {kycComment.map((comment, index) =>
                                  <tr key={index}>
                                      <td style={{width: "20%"}}>{comment.comment_flag == "1" ? this.props.t('dealerDetails.kyc_details.active') : this.props.t('dealerDetails.kyc_details.inactive')} </td>
                                      <td style={{width: "60%"}}>{comment.comment}</td>
                                      <td style={{width: "20%"}}>{moment(comment.created_date).format('YYYY-MM-DD')}</td>
                                  </tr>
                              )}
                          </tbody>
                      </table>
                      : 'no records found'}
              </div>
          </div>
      );
  }
}

const mapStateToProps = state => {
  return {
      auth_token: state.authToken,
      user_loggedin: state.userLoggedIn,
      dealer_id: state.dealer_id
  };
}

export default withTranslation('dealers')(connect(mapStateToProps)(KycAllComment));