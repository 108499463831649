import { gql } from '@apollo/client';

// export const SAVE_REFERENCE = (lead_id) => { 
//     return gql`
//     mutation save_reference_details($saveReferenceInput: ReferenceInput!) {
//         save_reference_details(saveReferenceInput: $saveReferenceInput) {
//             id
//             lead_id
//             name
//             phone
//             email
//             pincode
//             state
//             city
//             address
//         }
//     }
//     }`
// }

export const SAVE_REFERENCE = gql`
    mutation save_reference_details($saveReferenceInput: ReferenceInput!) {
        save_reference_details(saveReferenceInput: $saveReferenceInput) {
            id
            lead_id
            name
            phone
            email
            pincode
            state
            city
            address
        }
    
    }`

export const GET_REFERENCE_DETAILS = (lead_id) => { 
    return gql`
    query{
        get_references_details(lead_id:${lead_id})
        {
            name
            id
            phone
            email
            pincode
            city
            state
            address
        }
    }`
}


