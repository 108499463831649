import React from "react";


const Accordion = (props) => {
    return (
        <div className="accordion">{props.children}</div>
    )
}

const AccordionItemContext = React.createContext({
    expanded: false,
    toggleExpansion: () => { }
});

class AccordionItem extends React.Component {
    constructor(props) {
        super(props)

        this.toggleExpansion = () => {
            this.setState({ expanded: !this.state.expanded })
        }

        this.state = {
            expanded: false,
            toggleExpansion: this.toggleExpansion
        }
    }
    render() {
        return (
            <AccordionItemContext.Provider value={this.state}>
                <div className="accordion-item">
                    {this.props.children}
                </div>

            </AccordionItemContext.Provider>
        )
    }
}

const AccordionHeader = (props) => {
    return (
        <AccordionItemContext.Consumer>
            {({ expanded, toggleExpansion }) => (
                <div className={"accordion-header " + (expanded ? 'active' : '')}>

                    <button onClick={toggleExpansion}>
                        {props.children}
                        {expanded ? '' : ''}
                    </button>
                </div>
            )}
        </AccordionItemContext.Consumer>
    )
}

const AccordionPanel = (props) => {
    return (
        <AccordionItemContext.Consumer>
            {({ expanded }) => <div className={"accordion-panel " + (expanded ? 'expanded' : '')}>{props.children}</div>}
        </AccordionItemContext.Consumer>
    )
}

class FeatureFunction extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            assigned_feature_list: this.props.assigned_feature_list || [],
            userData:this.props.userData || {}
        };
    }

    static getDerivedStateFromProps = (nextProps, state) => {
        let updatedProps = {};
        if (
            nextProps && nextProps.assigned_feature_list &&
            nextProps.assigned_feature_list !== state.assigned_feature_list
        ) {
            updatedProps["assigned_feature_list"] = nextProps.assigned_feature_list;

        }
        if (
            nextProps && nextProps.userData &&
            nextProps.userData !== state.userData
        ) {
            updatedProps["userData"] = nextProps.userData;

        }
        return updatedProps;

    };

    render() {
        const { assigned_feature_list,userData } = this.state;
        return (
            <div className=" accordain-arrow ">
                <Accordion>
                    <AccordionItem>
                        <AccordionHeader>
                            Features assigned to {userData ?<span>{userData.role_assigned}</span>: null}
                    </AccordionHeader>
                        <AccordionPanel>

                            <ul>
                                {assigned_feature_list.length > 0 ?
                                    assigned_feature_list.map((data, index) =>
                                        <React.Fragment key={data.id}>
                                            <li>{data.name}</li>
                                        </React.Fragment>
                                    )
                                    : null}

                            </ul>

                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </div >
        )
    }
}



class AssignMoreFeatures extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            additional_feature_list: this.props.additional_feature_list || [],
            search:''
        };
    }

    static getDerivedStateFromProps = (nextProps, state) => {
        let updatedProps = {};
        if (
            nextProps && nextProps.additional_feature_list &&
            nextProps.additional_feature_list !== state.additional_feature_list
        ) {
            updatedProps["additional_feature_list"] = nextProps.additional_feature_list;

        }
        return updatedProps;

    };

    handleChange = (key,index,event) => {
        this.props.handleChange(key,event,index)
    }
    searchFeature = (event) => {
        let value = event.target.value;
        this.setState({search:value})
    }

    
    render() {
        let {additional_feature_list,search} = this.state;
        if(search)
        additional_feature_list = additional_feature_list.filter(e=>(e.label.toLowerCase()).includes(search.toLowerCase()))
        return (
            <div className="accordain-arrow ">
                <Accordion>
                    <AccordionItem>
                        <AccordionHeader>
                            Assign More Features
                        </AccordionHeader>
                        <AccordionPanel>
                            <div className="assign-more-search-list">
                                <div className="search-bx">
                                    <input type="" id="" placeholder="Search User" onChange={this.searchFeature.bind(this)}/>
                                    <i className="ic-search"></i>
                                </div>
                                
                                <div className="user-list assign-featue-list">
                                    <ul>
                                    {additional_feature_list.length > 0 ?
                                    additional_feature_list.map((data, index) =>
                                        <React.Fragment key={data.value}>
                                        <li className="custom-control custom-checkbox">
                                            <input id={'feature_'+data.value} type="checkbox" className="custom-control-input" checked={data.isChecked} onChange={this.handleChange.bind(this,'additional_feature_id',data.value)}/>
                                            <label htmlFor={'feature_'+data.value} className="custom-control-label">
                                                {data.label}
                                            </label>
                                            <div className="user-permission">
                                                <div className="input-radio">
                                                    <input id={'radio1_'+index} name={'radio_'+data.value} type="radio" 
                                                        checked={Number(data.access_type) === 0}
                                                        onChange={this.handleChange.bind(this,'additional_feature_radio',data.value)}
                                                    />
                                                    <label htmlFor={'radio1_'+index} className="radio-label">View</label>
                                                </div>

                                                <div className="input-radio">
                                                    <input id={'radio2_'+index} name={'radio_'+data.value} type="radio" 
                                                        checked={Number(data.access_type) === 1}
                                                        onChange={this.handleChange.bind(this,'additional_feature_radio',data.value)}
                                                    />
                                                    <label htmlFor={'radio2_'+index} className="radio-label">Can Edit</label>
                                                </div>

                                            </div>
                                        </li>
                                        </React.Fragment>
                                    )
                                    : null}
                                        
                                    </ul>
                                </div>
                            </div>
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            </div >
        )
    }
}



export { FeatureFunction, AssignMoreFeatures, }