import React from 'react';

function BlackListModal({ isOpen, togglePopup, children,width }) {
  return (
    <>
      {isOpen && (
        <div className="modal-popup" onClick={togglePopup}>
          <div className="modal-context"
          //  style={{ width: width ? width : 'auto' }} 
           onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={togglePopup}>&times;</span>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default BlackListModal;
