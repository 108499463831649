export function executeGraphQLQuery(query, client, header = '') {

  return client.query({
    query: query
  })
    .then(res => {
      if (res?.errors?.[0]?.statusCode === 403 ||
        res?.errors?.[0]?.message.includes('Invalid Token')) {
        localStorage.clear();
        window.location.href = '/login';
      }
      return res;
    })
    .catch(err => {
      const { networkError } = err;
      if (networkError.statusCode === 401) {
        localStorage.clear()
        window.location.href = '/login';
      }
    });
}


export function executeGraphQLMutation(mutation, variables, client) {

  return client.mutate({
    mutation: mutation,
    variables,
  })
}
