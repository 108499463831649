import { gql } from '@apollo/client';

export const LEADS_COUNT_DATA = (filterData) => gql`
query
{
  leads(
    page_no: 1
    api_called_by: web
    filter_params: {search_text: "${filterData.id}", column: "last_updated_date", sort: "desc", search_type: "partner_id"}
  ) {
    lead_list {
      id
      fulfillment_type
      is_login_verified
      is_sanction_verified
      is_status_verified
      is_disbursal_verified
      created_date
      updated_date
      sub_status_id
      followup_date
      followup_type
      loan_type
      loan_sub_type
      status_id
      partner_name
      followup_status
      status_info {
        statuslang {
          status_name
          __typename
        }
        __typename
      }
      sub_status_info {
        substatuslang {
          sub_status_name
          __typename
        }
        __typename
      }
      customer {
        first_name
        last_name
        mobile
        __typename
      }
      co_applicant {
        ca_first_name
        ca_last_name
        ca_mobile
        __typename
      }
      rmdetails {
        label
        mobile
        __typename
      }
      splitpayment {
        disbursed_date
        disbursed_amount
        __typename
      }
      lead_details {
        bank {
          id
          banklang {
            bank_name
            __typename
          }
          __typename
        }
        tenure
        login_date
        approval_date
        loan_amount
        login_amount
        approved_amount
        annual_income
        __typename
      }
      __typename
    }
    pagination {
      page_no
      totalrecords
      nextpage
      prevpage
      totalpage
      __typename
    }
    __typename
  }
}
`;
