import React, { useEffect, useState } from "react";
import Modal from "../../../../elements/partnerModal";
import "../../pre-register-lead.css";
import { MasterService } from "../../../../../services";
import Loader from "../../../../elements/Loader";

export const PreLeadActivity = ({ show, closeModal, leadId}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getLeadActivityLogs();
  }, []);

  const getLeadActivityLogs = async () => {
    const params = {
      lead_id: leadId ,
    };
    setIsLoading(true);
    try {
      const response = await MasterService.get(
        "/partner/partner/get-pre-lead-activity-log",
        params
      );
      setData(response.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleOnCloseModal = () => {
    setData([]);
    closeModal();
    setIsLoading(false);
  }

  const customStyle = {
    width: "80%",
  }

  return (
    <Modal show={show} handleClose={handleOnCloseModal} customStyle={customStyle }>
      {isLoading ? <Loader /> : null}
      <div className="pre-lead-activity">
        <h2>Pre-Lead Activity Log</h2>
        <table className="activity-log-table">
          <thead>
            <tr>
              <th>Activity Name</th>
              <th>Comment</th>
              <th>Source</th>
              <th>Activity Date</th>
              <th>Activity Done By</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((log, index) => (
                <tr key={index}>
                  <td>
                    <b>{log.logm_activity_name}</b>
                    {log.logm_activity_name == "Form Edited" && (
                      <span className="activity-details">
                      <strong>Previous:</strong> {log.log_prev_value || "N/A"}<br/>
                      <strong>Current:</strong> {log.log_current_value || "N/A"}
                    </span>
                    )}
                  </td>
                  <td>{log.log_comment}</td>
                  <td>{log.log_source}</td>
                  <td>{new Date(log.log_created_date).toLocaleString()}</td>
                  <td>
                    <span className="activity-details">
                      <strong>Name: </strong>{log.log_created_by_name}<br/>
                      <strong>Email: </strong>{log.log_created_by_email}
                    </span>
                  </td>
                </tr>
              ))
            ) : null}
          </tbody>
        </table>
        {!data.length &&(
          <div style={{display: 'flex', justifyContent: 'center', padding: 40}}>No Activity found</div> 
        )}
      </div>
    </Modal>
  );
};

export default PreLeadActivity;
