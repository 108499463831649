import React from "react";
import DateFormate from "dateformat";
import { Tab, Nav } from 'react-bootstrap';
const LeadHistory = ({ historyList, isLoading, t }) => {
  return (
    <div className="comment-wrap">
      <Tab.Container id="left-tabs-example" defaultActiveKey="first">
        <Nav variant="pills" className="flex-column tab-line nav nav-pills">
          <div className="tab-list">
            <h3 eventKey="first">Loan History</h3>
          </div>
        </Nav>

        <div className="modal-body">
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <table>
                {historyList.length ? (
                  <tbody>
                    {historyList &&
                      historyList.map((timelinedata, index) => {

                        // let subheading = (timelinedata?.sub_status_details?.substatuslang?.sub_status_name) ? ' - ' + timelinedata?.sub_status_details?.substatuslang?.sub_status_name : ''
                        // let heading = timelinedata?.title+" "+subheading

                        // if(timelinedata.rejection_type){                           
                        //   if(timelinedata.rejection_type =='ops_reject'){
                        //     heading = 'Verification Failed'
                        //   }else if(['bank_hard_reject'].includes(timelinedata.rejection_type)){
                        //     heading = 'Bank Denied - Hard Reject'
                        //   } else if(['bank_soft_reject'].includes(timelinedata.rejection_type)){
                        //     heading = 'Bank Denied - Soft Reject'
                        //   } else if (timelinedata.rejection_type == 'lost'){
                        //     heading = "Verification Failed"
                        //   } 
                        // }

                        return (
                          <tr className="p-relative" key={index}>
                            <td>
                              <span className="date">
                                {timelinedata && timelinedata.created_date
                                  ? DateFormate(
                                    timelinedata.created_date,
                                    "dd mmm"
                                  )
                                  : ""}
                              </span>
                              <span className="time">
                                {timelinedata && timelinedata.created_date
                                  ? DateFormate(
                                    timelinedata.created_date,
                                    "h:MM TT"
                                  )
                                  : ""}
                              </span>
                            </td>
                            <td>
                              <div className="history-list-outer">
                                <div>
                                  <span className="heading">
                                    {timelinedata?.title}
                                  </span>
                                  <span className="Subheading">
                                    {timelinedata?.sub_title ? timelinedata?.sub_title + ` - ` : ""}
                                    {timelinedata?.remark ? timelinedata?.remark + ` - `:""}
                                    {timelinedata?.reason_list.map(v=>v.name)[0]}
                                    {/* {(timelinedata?.checklist_details?.checklist_name) ? timelinedata.checklist_details.checklist_name : ""}
                                    {(timelinedata?.checklist_sub_status_details?.name) ? ' - ' + timelinedata.checklist_sub_status_details.name : ""}
                                    {(timelinedata?.history_type === 'followup' && timelinedata?.followup_details?.comment) ? timelinedata?.followup_details?.comment : ""} */}
                                  </span>
                                  
                                </div>
                              </div>
                            </td>
                            <td>
                              {timelinedata.user_details && timelinedata.user_details.added_by_name ? (
                                <p className="listingdate user-name">
                                  {timelinedata.user_details.added_by_name}
                                </p>
                              ) : (
                                ""
                              )}
                            </td>


                          </tr>
                        )
                      })}
                  </tbody>
                ) : (
                  <h4>
                    <center className="t-danger">
                      {!isLoading && t("loanBoxLang.Customer_History_Not_Found")}
                    </center>
                  </h4>
                )}
              </table>
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  )
}

export default LeadHistory;