import React, { useEffect, useMemo, useState } from 'react';
import Formsy, { addValidationRule } from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/coApplicantDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import Modal from '../../elements/Modal';
import { SAVE_CUSTOMER_DETAILS } from '../../../services/customer.gql';
import { executeGraphQLMutation } from '../../../common/executeGraphQLQuery';
import { toast } from 'react-toastify';
import { useApolloClient } from '@apollo/client';
import { getLoanDetail } from '../../../store/action/allAction';
import { useOutletContext } from 'react-router-dom';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';

const FORM_FIELDS = FormFields["PROPERTY_IDENTIFIED"];

// addValidationRule("matchAccNo", (values, value) => {
//   if (values.account_number) {
//     return !values.re_account_number ? 'Please Re-Enter Account Number' : values.account_number !== values.re_account_number ? "Account No. does not match" : true
//   }
//   return true;
// })

const PropertyIdentifiedForm = (props) => {
  const [propertyIdentified, setPropertyIdentified] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false);
  const [activeTab, setActiveTab] = useState("coApplicant_0")
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const client = useApolloClient();
  const dispatch = useDispatch();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  let accessCondition = !IS_USER_ACCESS_PAGE("leadDetails", "customer-bank-details")?.is_edit_access;
  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;  

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let co_applicatndata = lead?.leadDetail?.co_applicant || []
      const newCoApplicants = co_applicatndata.map(data => {
        let newAppObject = {}

        newAppObject.id = data.co_applicant_id || 0;
        // newAppObject.ca_existing_emi_amount=data.ca_existing_emi_amount || '';
        // newAppObject.ca_annual_income = data.ca_annual_income || '';
        // newAppObject.ca_profession = data.ca_profession || '';
        // newAppObject.ca_salary_credit_mode = data.ca_salary_credit_mode || '';
        // newAppObject.ca_company_name = data.ca_company_name || '';
        // newAppObject.ca_company_type = data.ca_company_type || '';
        // newAppObject.ca_loan_amount = data.ca_loan_amount || '';
        // newAppObject.ca_tenure = +data.ca_tenure || 0;

        newAppObject.ca_first_name = data.ca_first_name || '';
        newAppObject.ca_last_name = data.ca_last_name || '';
        newAppObject.ca_gender = data.ca_gender || '';
        newAppObject.ca_mobile = data.ca_mobile || '';
        newAppObject.ca_email = data.ca_email || '';
        newAppObject.ca_dob = data.ca_dob || '';
        newAppObject.ca_aadhar_no = data.ca_aadhar_no || '';
        newAppObject.ca_pancard_no = data.ca_pancard_no || '';
        newAppObject.ca_annual_income = data.ca_annual_income || '';

        newAppObject.ca_property_value= data.ca_property_value || '';
        newAppObject.ca_property_type= data.ca_property_type || '';
        newAppObject.ca_usage_type= data.ca_usage_type || '';
        newAppObject.ca_agreement_type= data.ca_agreement_type || '';

        return newAppObject;
      })
      setPropertyIdentified(newCoApplicants);
      if (co_applicatndata.length === 0) newCoapplicant();
    }
  }, [lead]);

  const newCoapplicant = () => {
    const newCoApplicants = [{
        ca_property_value: "",
        ca_property_type: "",
        ca_usage_type: "",
        ca_agreement_type: "",
    }];
    setPropertyIdentified(newCoApplicants);
  };

  const addMoreFields = () => {
    const newCoApplicants = [...propertyIdentified, {
        ca_property_value: "",
        ca_property_type: "",
        ca_usage_type: "",
        ca_agreement_type: "",
    }];

    setPropertyIdentified(newCoApplicants);
    setActiveTab(`coApplicant_${newCoApplicants.length - 1}`);
  };

  const handleInputChange = event => {
    if (event.target.id) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedPropertyIdentified = [...propertyIdentified];
      updatedPropertyIdentified[activetabIndex][event.target.id] = event.target.value;
      setPropertyIdentified(updatedPropertyIdentified);
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedPropertyIdentified = [...propertyIdentified];
      updatedPropertyIdentified[activetabIndex][field_name] = data.value;
      setPropertyIdentified(updatedPropertyIdentified);
    }
  }

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let mutation = SAVE_CUSTOMER_DETAILS(user_id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          co_applicant: propertyIdentified
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getLoanDetail(lead_id, client));
    })
      .catch(err => {
        toast.error(err.message);
      })
  }

  let PropertyIdentifiedForm = FORM_FIELDS;

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    return options;
  }, [masterdata]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedPropertyIdentified = [...propertyIdentified];
      updatedPropertyIdentified[activetabIndex][field_name] = (field_name === 'ca_tenure') ? +data.value : data.value;
      setPropertyIdentified(updatedPropertyIdentified);
    }
  }

  const sectionInfo = { section: "Customer details", sub_section: "Bank details" }

  return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="4" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Co Applicant Details</span>
            <h2>Property Details</h2>
          </div>

          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
              {propertyIdentified.length && propertyIdentified.map((coApplicant, index) =>
                (propertyIdentified[index].ca_first_name) ?
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setActiveTab(`coApplicant_${index}`)}
                      eventkey={`coApplicant_${index}`}
                      active={activeTab === `coApplicant_${index}`}
                    >
                      {propertyIdentified[index].ca_first_name}
                    </Nav.Link>
                  </Nav.Item> :
                  <Nav.Item>
                    <Nav.Link
                      onClick={() => setActiveTab(`coApplicant_${index}`)}
                      eventkey={`coApplicant_${index}`}
                      active={activeTab === `coApplicant_${index}`}
                    >
                      Add New
                    </Nav.Link>
                  </Nav.Item>
              )}
            </div>
          </Nav>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">

              {PropertyIdentifiedForm.map((field) => (
                propertyIdentified.map((propertyIdentifiedData, index) => (`coApplicant_${index}` === activeTab) && (

                  ["text", "password", "pattern-format", "number-format"].includes(field.type) ? (
                    <fieldset class="form-filed col-md-6 ">
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={propertyIdentifiedData && propertyIdentifiedData[field.name]}
                        placeholder=" "
                        thousandsGroupStyle="lakh"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        onChange={
                          ["pattern-format", "number-format"].includes(field.type)
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={field.validations}
                        validationError={
                          propertyIdentifiedData[field.name] ? field.validationError : ""
                        }
                        required={field.required}
                      />
                    </fieldset>
                  ) :

                    field.type === "dropdown" ? (
                      <fieldset class="single-select col-md-6">
                        <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == propertyIdentifiedData[field.name])[0],
                            isDisabled: field.readOnly
                          }}
                          required={field.required}
                          value={propertyIdentifiedData[field.name]}
                          onChange={(data) => handleSelectChange(data, field.name)}
                        />
                      </fieldset>
                    ) : null
                ))))}

            </div>

            <div className="btn-save-remarks">
              <button type="submit" className="btn-primary">
                save
              </button>
              {/* <button className="btn-primary-outline" type="button" onClick={addMoreFields}>
                Add Property Details
              </button> */}
              {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
            </div>
          </Formsy>
        </div>
      </div>
      <div className="view-timeline-popup">
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed}
              accessCondition={accessCondition} sectionInfo={sectionInfo} />
          </Modal>
        </div>
      </div>
    </div>
  );
}
export default PropertyIdentifiedForm;