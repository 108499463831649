
const leadReducer = (state = '', action) => {
    switch (action.type) {
        
        case "GET_LEAD_DETAIL":
			return {
				...state,
				leadDetail: action.payload,
			};
		case "GET_LEAD_REMARKS_HISTORY":
			return {
				...state,
				remarksHistory: action.payload,
			};  
		case "LEAD_SORTING_INFO":
			return {
				...state,
				leadSorting: action.payload,
			}; 
		case "SET_DOCUMENT": 
			return {
				...state,
				documentList: action.payload,
			};	

		default:
            return state
    }
}

export default leadReducer;
