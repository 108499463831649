import React from "react"; 
const TemplateList = (props) => { 
 
    const setTemplate = (value)=>{
        props.hideTemplate()
        props.setInputValue(value)
    }

    return (
        <>
            <div className="modal-header">
                <h2>select a template to send the user</h2>
            </div>
            {
                props.list && props.list.length ? props.list.map((ob,key)=>{
                    return <div className="sendstatus" onClick={()=>setTemplate(ob.template)} key={key}>{ob.name}</div>
                })  
                : null
            } 
        </>
    );
};

export default TemplateList;
