import React from "react";  
import { Tab, Nav } from 'react-bootstrap';

const BlankCibilReport = ({
  cibilErrorMessage,
  resendConsignmentLink,
  resendConsignmentBtn,
  handleSelectTab,
  activeTab,
  lead
}) => {
  const handleSendLink = () => {
    resendConsignmentLink();
  }; 

  return (
    <>
      {/* LEFT PANEL */}
      <div className="image-tab-panel form-tab-right-panel">
        <div></div>
      </div>

      {/* MIDDLE PANEL */}
      <div className="form-tab-right-panel">

      <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab} onSelect={handleSelectTab}>
          <Nav variant="pills" className="flex-column tab-line">
            <div className="tab-list"> 
                  <Nav.Item className='nav-item'>
                      <Nav.Link active={activeTab == 'customer'} eventKey={'customer'} disabled={false}> Customer</Nav.Link>
                  </Nav.Item>
                  {
                      lead?.leadDetail.co_applicant?.length ? lead?.leadDetail.co_applicant.map((ob,key)=>{
                          return (
                              <Nav.Item className='nav-item' key={key}>
                                  <Nav.Link active={activeTab == 'co-'+ob.id} eventKey={"co-"+ob.id}>{ob.ca_first_name} co applicant</Nav.Link>
                              </Nav.Item>
                          )
                      }) : null
                  }  
              </div>
          </Nav> 
        </Tab.Container>
        <div style={{ marginTop: 40 }}>
          <div className="cibilscorebox">
            <div className="cibilscore"></div>
          </div>
          <div className="cibilupdated">
            <h2>{cibilErrorMessage}</h2>
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={handleSendLink}
              className="cibil-fetch-btn"
              disabled={resendConsignmentBtn}
            >
              Resend link
            </button>
          </div>
        </div>
      </div>

      {/* RIGHT PANEL */}
      <div className="form-tab-right-panel">
        <div>
          <h3 className="cibilscoreheading"></h3>
          <div>
            <div style={{ marginBottom: 20 }}></div>
          </div>

          <div>
            <div style={{ marginBottom: 20 }}></div>
            <div className="cibilhistory">
              <div>
                <div className="cibilcolorper"></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlankCibilReport;
