import { gql } from '@apollo/client';
export const DASHBOARD_QUERY =  (user_id, fulfillment_type="self", assign_to=0) => {
  return gql`{
  lead_dashboard(user_id: ${user_id},role_id:1,api_called_by: web, loan_type:1, fulfillment_type: "${fulfillment_type}", assign_to: ${assign_to}){
    name
    header
    sub_type
    lead_count
    filter_params{
      doc_status
      status_id
      sub_status_id
      source
      is_revisit
      is_otp_verified
    }
		card_styles {
			icon_className
			max_icon_paths
		}
  }
}
`};

export const INSURANCE_DASHBOARD_QUERY =  (fulfillment_type="self") => {
  return gql`{
  insurance_lead_dashboard(fulfillment_type: "${fulfillment_type}"){
    name
    sub_type
    lead_count
    filter_params{
      status_id
    }
		card_styles {
			icon_className
			max_icon_paths
		}
  }
}
`};
