 
var CryptoJS = require("crypto-js"); 
const CONSTANT = require("./constants"); 
const SECRET_KEY = 'AMBEK@#$%1234' 

module.exports.encode = (data) => {
  data = CryptoJS.AES.encrypt(data.toString(),SECRET_KEY) 
  data = encodeURIComponent(data?.toString(),SECRET_KEY)
  return data;
};

module.exports.decode = (data) => {
  data = CryptoJS.AES.decrypt(decodeURIComponent(data,SECRET_KEY),SECRET_KEY) 
  data = data?.toString(CryptoJS.enc.Utf8)
  return data;
};
 
