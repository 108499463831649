import React, { useContext, useState } from "react";
import './apf.css';
import { APFContext } from "../context/apfContextApi";

const ApfAddComponent = () => {
    const { isNew, setIsNew } = useContext(APFContext);


    return (
        <div className="container-fluid">
            <div title="Add New Apf record" className="Add-button-container">
                <button className="add-button" onClick={() => setIsNew(true)}>
                    <span className="add-icon">+</span>
                    Add APF
                </button>
            </div>
        </div>
    );
};

export default ApfAddComponent;
