import React,{useEffect} from 'react';

import { useDispatch } from 'react-redux';
import {loggedIn } from './store/reducers/userReducer';

//import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.scss';

import 'react-toastify/dist/ReactToastify.css';
import MainRoute from './routes/routes';

const App = () => {
  useEffect(() => {
    dispatch(loggedIn());
  }, [])

    // const isLoggedIn = useSelector(checkUser);
    const dispatch=useDispatch()
    
    return (
     <MainRoute/>
    );
}

export default App;

