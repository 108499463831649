import React, { useEffect, useState } from "react";
import { MasterService } from "../../services";
import { getDateAndTimeFormat } from "../partner/pre-register-lead-manager/utility/dateFormateUtility";
import Loader from "../elements/Loader";

const PartnerHistory = (props) => {
  const partnerId = props?.partnerId;
  const [partnerHistory, setViewHistoryData] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const [showCallHistory, setShowCallHistory] = useState(false);
  const [tab, setTab] = useState('Partner History');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchPartnerHistory(partnerId);
    fetchCallHistory(partnerId);
  },[]);

  const fetchPartnerHistory = (id) => {
    setIsLoading(true);
    MasterService.get(
      `partner/partner/get-activity-hstory/?partner_id=${id}`
    ).then((response) => {
      setViewHistoryData(response?.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }).catch((error) => {
      setIsLoading(false);
      console.error(error);
    });
  };

  const fetchCallHistory = async (leadId) => {
    const params = {
      partner_id: leadId ,
    };
    setIsLoading(true);
    try {
      const response = await MasterService.get(
        "partner/partner-call/partner-call-details-activity",
        params
      );
      setCallHistory(response?.data?.data);
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const handleButtonClick = async (event, tabName) => {
    event.preventDefault();
    setShowCallHistory(false);
    setTab(tabName);
    if(tabName === 'Call History') {
      setShowCallHistory(true);
    }
  }

  const renderToggleButton = () => {
    return(
      <div style={styles.toggleButtonConatainer}>
        <button
              style={{
                ...styles.wiredButton,
                borderBottomWidth: tab === 'Partner History' ? '2px' : '0px',
              }}
              onClick={(e) => handleButtonClick(e, 'Partner History')}>
            {'Partner History'}
          </button>
          <button
              style={{
                ...styles.wiredButton,
                borderBottomWidth: tab === 'Call History' ? '2px' : '0px'
              }}
              onClick={(e) => handleButtonClick(e, 'Call History')}>
              {'Call History'}
        </button>
      </div>
    );
  }

  const renderHistory = () => {
    return(
      <div className="container mt-4" >
        <div>
        <h1 style={{paddingTop:40,display:"flex",justifyContent:"center"}}>{tab}</h1>
         {renderToggleButton()}
        </div>
        <div className="table-responsive">
          {showCallHistory ? renderCallHistory() : renderActivityHistory()}
        </div>
      </div>
    )
  }

  const renderCallHistory = () => {

    return (
      <table className="activity-log-table">
      <thead>
        <tr style={{position:'sticky', top: 0}}>
          <th>Call Status</th>
          <th>Title</th>
          <th>Remarks</th>
          <th>Comment</th>
          <th>Activity Date</th>
          <th>Next Follow-up Date</th>
          <th>Activity Done By</th>
        </tr>
      </thead>
      <tbody>
        {callHistory.length ? (
          callHistory.map((log, index) => (
            <tr key={index}>
              <td>
                {/* <b></b> */}
                <span className="activity-details">
                  <strong>Status: </strong>{log.status_name}<br/>
                  <strong>Sub Status: </strong>{log.sub_status_name}<br/>
                </span>
              </td>
              <td>{log.title || "N/A"}</td>
              <td>{log.remarks || "N/A"}</td>
              <td>{log.comment || "N/A"}</td>
              <td>{getDateAndTimeFormat(log.created_date)}</td>
              <td>{log.followup_date ? getDateAndTimeFormat(log.followup_date): "N/A"}</td>
              <td>
                {`AMB${log.created_by}`}
              <span className="activity-details">
                  <strong>Name: </strong>{log.created_by_name}<br/>
                  <strong>Email: </strong>{log.created_by_email}
                </span>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="7">No call activity available.</td>
          </tr>
        )}
      </tbody>
    </table>
    );
  }

  const renderActivityDetails = (rowData) => {
    const logData = JSON.parse(rowData?.log_data || '{}');
    const previousValues = logData.previousValues || {};
    const currentValues = logData.currentValues || {};

    return (
      <>
        <strong>Previous:</strong>
        <ul>
          {Object.entries(previousValues).map(([key, value]) => (
            <li key={key}>
              {key}: {value || "N/A"}
            </li>
          ))}
        </ul>
        <strong>Current:</strong>
        <ul>
          {Object.entries(currentValues).map(([key, value]) => (
            <li key={key}>
              {key}: {value || "N/A"}
            </li>
          ))}
        </ul>
      </>
    );
}

  const renderActivityHistory = () => {
    return(
      <table className="activity-log-table">
        <thead>
          <tr style={{position:'sticky', top: 0}}>
            <th className="text-left">Created Date</th>
            <th className="text-left">Activity Name</th>
            <th className="text-left">Source</th>
            <th className="text-left">Created By</th>
          </tr>
          
        </thead>
        <tbody>
          {partnerHistory && partnerHistory.length > 0 ? (
            partnerHistory.map((rowData, index) => (
              <tr key={index}>
                <td>
                  {rowData?.created_date ? new Date(rowData?.created_date).toLocaleDateString() : "N/A"}{" "}
                  {rowData?.created_date ? new Date(rowData?.created_date).toLocaleTimeString() : ""}
                </td>
              
                <td>{rowData?.activity_name}
                 {rowData?.activity_name === "Bank Details Changed" && (
                    <span className="activity-details">
                      {renderActivityDetails(rowData)}
                    </span>
                  )}
                  {rowData?.activity_name === "Pan Number and Name Change" && (
                    <span className="activity-details">
                      {renderActivityDetails(rowData)}
                    </span>
                  )}
                </td>
                <td>{rowData?.source}</td>
                <td>
                  {rowData.created_by > 0 ? <>
                    <div>AMB{rowData?.created_by}</div>
                  <div> {rowData?.created_by_name}</div> 
                  <div>{rowData?.created_by_email}</div>
                  </> : ""}
                  
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No data available</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  return (
    <>
      {isLoading ? <Loader></Loader> : null}
      {renderHistory()}
    </>
  );
};


const styles = {
  toggleButtonConatainer: {display: 'flex', flexDirection: 'row'},
  wiredButton: {
    backgroundColor: "transparent",
    color: "#6147FF",
    borderTop: "0px",
    borderLeft: "0px",
    borderRight: "0px",
    borderBottom: "2px solid #6147FF",
    padding: "10px 20px",
    cursor: "pointer",
    fontSize: "14px",
    fontWeight: "bold",
    textAlign: "center",
    marginRight: "10px",
  },
};

export default PartnerHistory;
