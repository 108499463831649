import MasterService from "./MasterService";

const getCurrentUserDetails = () => {
  const userInformation = localStorage.getItem("user_information");
  let userInfo = {};
  if (userInformation) {
    try {
      const parsedUserInformation = JSON.parse(userInformation);
      if (parsedUserInformation?.user_data?.user_id) {
        userInfo = {
          userId: parsedUserInformation?.user_data.user_id,
        };
      }
      return userInfo;
    } catch (error) {
      console.error("Error parsing JSON from localStorage", error);
    }
  } else {
    return false;
  }
};

const createActivityLog = async (data) => {
  const userInfo = getCurrentUserDetails();

  console.log("data under create activity log service", data);

  const payload = {
    lead_id: data?.leadId ?? null,
    user_id: userInfo?.userId ?? null,
    activity_id: data?.activityId ?? null,
    prev_value: data?.prevValue ?? '',
    current_value: data?.currentValue ?? '',
    comment: data?.comment ?? "",
    source: data?.source ?? "Sangam Backend",
    status: 1,
    created_by: userInfo?.userId ?? '',
    updated_by: userInfo?.userId ?? '',
  };
  try {
    const log = await MasterService.post(
      "/partner/partner/create-pre-lead-activity-log",
      payload
    );
  } catch (error) {
    console.log(error);
  }
};


const lostLeadActivity = async (log) => {
  try {
    const payload = {
      leadId : log?.leadId,
      userId : log?.user_id,
      activityId : log.aId ?? 5,
      comment: log.comment ?? 'Mark as Lost'
  }
  await createActivityLog(payload);
  } catch (error) {
    console.log(error);
  }
}

const registerAsPartner = async (log) => {
  try {
    const payload = {
      leadId : log?.leadId,
      userId : log?.user_id,
      activityId : log.aId ?? 3,
      comment: log.comment ?? 'Registered as Partner'
  }
  await createActivityLog(payload);
  } catch (error) {
    console.log(error);
  }
}


const preLeadFormActivity = async (log) => {
  try {
    const payload = {
      leadId : log?.leadId,
      userId : log?.userId,
      activityId : log.aId ?? 2,
      prevValue: `${log.fieldName} : ${log.previousValue}` ?? null,
      currentValue: `${log.fieldName} : ${log.currentValue}` ?? null,
      comment: log.comment ?? ''
  }
  await createActivityLog(payload);
  } catch (error) {
    console.log(error);
  }
}


export {
  createActivityLog,
  lostLeadActivity,
  preLeadFormActivity,
  registerAsPartner,
};
