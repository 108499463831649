import Select from 'react-select';
import { InputOption, MultiSelectValueContainer } from './MultiselectCustomComponent';
function getDropdownButtonLabel({ placeholderButtonLabel, value }) {
    if (value && value.length === 0) {
        return `${placeholderButtonLabel}`;
    } else if (value && value.some((o) => o.value === "all")) {
        return `All selected (${value.length - 1})`;
    } else if (value && value.length) {
        return `${value.length} selected`;
    }
}
function MultiSelect({ options, values, onChange, name, placeholder,optionLabel='label',optionValue='value', isDisabled=false, isClearable = false }) {
    return (
        <Select components={{ Option: InputOption, ValueContainer: MultiSelectValueContainer }}
            isMulti
            getDropdownButtonLabel={getDropdownButtonLabel}
            name={name}
            options={options}
            placeholderButtonLabel={placeholder}
            placeholder={placeholder}
            value={(options instanceof Array && values && options.filter((e) =>
                [...(values instanceof Array ? values : []),].includes(e[optionValue])
            )) || []
            }
            getOptionLabel={(obj) => obj[optionLabel]}
            getOptionValue={(obj) => obj[optionValue]}
            onChange={(e, event) => onChange(name, e, true,optionValue, optionLabel, event)}
            disabled="disabled"
            isDisabled={isDisabled}
            isReadOnly
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            isClearable={isClearable}
            className="react-select react-multiselect-opt"
            classNamePrefix="react-select"
        />
    )
}
export default MultiSelect;