import React, { useContext } from "react";
import { APFContextProvider } from "./context/apfContextApi";
import APFSearchComponent from "./components/apfSearch.component";
import APFEditComponent from "./components/apfEdit.component";
import ApfAddComponent from "./components/apfAdd.component";


const APF = () => {
  
  return (
    <>
    <APFContextProvider>
      <ApfAddComponent></ApfAddComponent>
      <APFSearchComponent></APFSearchComponent>
      <APFEditComponent></APFEditComponent>
    </APFContextProvider>
    </>
  );
};

export default APF;
