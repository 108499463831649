import MasterService from './MasterService';

const sfaService = {
    ...MasterService,

    async getRMList(postdata){
     
        const response= await this.post('/sfa/user/get-users', postdata);
       
        const userList = response?.data?.data?.user_list;
        const users = userList && userList.map(user => ({ id: user.id, profession_name: (user.name+' ('+user.email+')') }));
        return users;
    }

}

export default sfaService;