import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MasterService } from "../../../../services";
import { getStatusUtility } from "../utility/getStatusUtility";

export const ShareLead = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [params, setParams] = useState({ 
    status_id: ["10","11"],
    page_no : 1,
    is_lead: "1",
    status: "",
    list_type: "",
    search_text: id
  });

//   {
//     "page_no": 1,
//     "status_id": "",
//     "is_lead": "1",
//     "status": "",
//     "list_type": "",
//     "search_by": "agent_code",
//     "search_text": "11206"
// }

  useEffect(() => {
    let newParams = { ...params, search_text: id };

    if(id.length === 10) {
      newParams.search_by = "dealership_contact";
    } else if(id.length < 10) {
      newParams.search_by = "agent_code";
    } else {
      newParams.search_by = "dealership_contact";
      newParams.is_shared = "1";
    }

    setParams(newParams);
    fetchLeadData(newParams);
  }, [id]); // Dependency array with 'id' to run the effect only when 'id' changes

  const fetchLeadData = (params) => {
    MasterService.post('dealer/dealer/dealer-list', params).then((response) => {
      if (response?.data?.data?.length) {
        navigate(`/partner-lead-detals/${response?.data?.data[0]?.dealer_id_hash}`, {
          state: {
            lead: response?.data?.data[0],
            status: getStatusUtility(response?.data?.data[0]?.status).status,
          }
        });
      } else {
        navigate(`/no-lead-found`, { replace: true });
      }
    }).catch((error) => {
      console.log("error", error);
    });
  };

  return (
    <div>
      No Lead Found
    </div>
  );
};
