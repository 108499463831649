import React from "react";

const MismatchDataDetail = ({ keyArry, data }) => {
  return (
    <>
      <div className="modal-header">
        <h2>Mismatch Data Details</h2>
      </div>
      <div className="modal-body" style={{overflowY: "scroll"}}>
        <table>
          <thead>
            <tr>
              <th>S No.</th>
              <th>Key</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {keyArry && keyArry.map((key, index) => (
              data[key] !== null ? (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{key}</td>
                    <td>{data[key]}</td>
                </tr>
              ) : null
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default MismatchDataDetail;
