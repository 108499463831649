import React from 'react';
import SendDoc from './SendDoc';
import { useSelector } from 'react-redux';

const SendFinancer = (props) => {
  const pagetab=[{key:'Documents Category List',is_co_borrower:0,type:"borrower",doc:"10,11,1,2,3,4,5,6"}];
  
  const sectionInfo = {section : "Sent to financier" , sub_section : ""}
    return (
      <>
        <SendDoc template_title="Sent to Financier" template_for="send_to_financier" pagetab={pagetab} is_completed={true} sectionInfo={sectionInfo}/>
      </>

    )
}


export default SendFinancer;