import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { APFContext } from "../context/apfContextApi";

const APFSearchComponent = () => {
    const {
        cityList,
        builderList, 
        projectList,
        reqData,
        selectedBuilderValue, 
        selectedProjectValue, 
        handlechange, 
        getBankId,
        bankList,
        handleReset,
        setIsEdit,
      } = useContext(APFContext);

  return(
    <>
      <div
        className="search-wrap"
        // style={{ marginTop: "50px", height: "120px" }}
      >
        <div className="row">
          <div className="col-sm-3">
            <label>City</label>
            <Select
              placeholder={"City"}
              id="City"
              onChange={(City) => handlechange("city", City)}
              options={cityList}
              label={"Select City"}
              name="City"
              getOptionLabel={({ name }) => name}
              getOptionValue={({ id }) => id}
            />
          </div>
          <div className="col-sm-3">
            <label>Builder</label>
            <Select
              placeholder={"Builder Name"}
              id="Builder"
              onChange={(data) => handlechange("builder", data)}
              options={builderList}
              label={"Select Builder Name"}
              name="Builder"
              value={selectedBuilderValue}
              getOptionLabel={({ builder_name }) => builderList.length > 0 ? builder_name : ''}
              getOptionValue={({ id }) => id}
            />
          </div>
          <div className="col-sm-3">
            <label>Project</label>
            <Select
              placeholder={"Project Type"}
              id="project_type"
              onChange={(data) => handlechange("project_type", data)}
              options={projectList}
              label={"Select Product Type"}
              name="project_type"
              value={selectedProjectValue}
              getOptionLabel={({ project_name }) => projectList.length > 0 ? project_name : ''}
              getOptionValue={({ id }) => id}
            />
          </div>
          <div className="col-md-2 resetbtnalignright">
            <div className="btn-submit-reset top-btn-none">
              <button
                type="submit"
                className="btn btn-primary mrg-r10"
                onClick={getBankId}
              >
                {"Search"}
              </button>
              <button
                type="reset"
                className="btn-reset"
                onClick={handleReset}
                disabled={!builderList.length > 0}
              >
                {"Reset"}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card">
        <table className="activity-table">
          <thead>
            <tr>
              <th>City</th>
              <th>Builder</th>
              <th>Project</th>
              <th>Lenders</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reqData.cityName ? (
              <tr>
                <td>{reqData?.cityName ?? ''}</td>
                <td>{selectedBuilderValue?.builder_name ?? ''}</td>
                <td>{selectedProjectValue?.project_name ?? ''}</td>
                <td>
                  {bankList &&
                    bankList.map((bank) => (
                      <div>
                        <ul className="banklogoflex">
                          <li>
                            <img src={bank.logo} alt="Bank Logo" />
                            <h4>{bank.banklang.bank_name}</h4>
                          </li>
                        </ul>
                      </div>
                    ))}
                </td>
                {bankList.length > 0 && (
                  <td>
                    <div onClick={() => setIsEdit(true)}>
                      <ul className="banklogoflex">
                        <li>
                          <a>edit</a>
                        </li>
                      </ul>
                    </div>
                  </td>
                )}
              </tr>
            ) : 
              <tr>
                <td colSpan="5">Please Select Data</td>
              </tr>  
            }
          </tbody>
        </table>
      </div>
    </>
  );
      
};

export default APFSearchComponent;