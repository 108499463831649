import React, { useState, useEffect } from 'react';
import LoanRibbenBox from './LoanRibbenBox';
import LeadDetailTabs from './LoanFiledTabs';
import { useDispatch, useSelector } from "react-redux";
import { useApolloClient } from '@apollo/client';
import { LEAD_DETAIL_QUERY, GET_DOCUMENTS_LIST, GET_DOCUMENTS_LIST_UPDATED } from '../../services/leads.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { useParams } from 'react-router-dom';
import Loader from '../elements/Loader';
import { toast } from 'react-toastify';
import crypto from '../../config/crypto'
import { getLeadRemarks } from '../../store/action/allAction';
import { useTranslation } from 'react-i18next';


const LeadDetail = () => {
    const { t } = useTranslation('language');
    const dispatch = useDispatch();
    const leadInfo = useSelector((state)=>state.lead.leadDetail); 
    const params = useParams();
    const client = useApolloClient();
    const [tipOpen, toggleTip] = useState(false);
    const [tipOpen1, toggleTip1] = useState(false);
    const [tipOpen2, toggleTip2] = useState(false);
    const [tipOpen3, toggleTip3] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [documentCategoryList, setDocumentCategoryList] = useState({});
    const [existingDocumentCategoryList, setExistingDocumentCategoryList] = useState([]);
    const [documentOtherCategoryList, setDocumentOtherCategoryList] = useState([]);
    const getLeadDetail =(lead_id) => {
        setIsLoading(true);
        if(!lead_id) lead_id = 0;
		executeGraphQLQuery(LEAD_DETAIL_QUERY(lead_id),client).then((response) => {
			if (response && response.data) {
				let data = response.data.get_lead_detail[0];
				if (data) {
                    let payload = data
					dispatch({ type: "GET_LEAD_DETAIL", payload });
                    setIsLoading(false);
				}
                else{
                    let payload = {}
					dispatch({ type: "GET_LEAD_DETAIL", payload });
                    setIsLoading(false);
                    toast.error("Lead not found")
                }   
			}
            else{
                if(response?.errors?.length)
                toast.error(response?.errors?.[0].message,{
                    toastId: 'timeout',
                }); 
                setIsLoading(false);
            }
            
        })
        .catch((error) => {
            setIsLoading(false);
            toast.error(error.toString().replace('ApolloError:',''));
        });
    }

    const docCategoryListUpdated = () => {
        if(leadInfo){
            const obj = {
                property_type_id: leadInfo?.lead_details?.property_type,
                property_sub_type_id: leadInfo?.lead_details?.property_sub_type,
                profession_type_id: leadInfo?.lead_details?.profession,
                product_type_id: leadInfo?.loan_type,
                ...(leadInfo?.loan_sub_type ? {product_sub_type_id: leadInfo?.loan_sub_type} : {} ),
            }
            executeGraphQLQuery(GET_DOCUMENTS_LIST_UPDATED(obj), client).then((response) => {
                if (response && response.data) {
                    const { customer_doc, co_applicant_doc, co_doc_list, doc_list } = response.data.get_doc_list;
                    let customerDoc = [], docList = [];
                    if (customer_doc.length) {
                        customerDoc = customer_doc;
                        docList = doc_list;
                    } else {
                        customerDoc = doc_list.map((item) => {
                            const filteredChildren = item.child.filter((child) => child.is_required === "1");
                            if (filteredChildren.length > 0) return { ...item, child: filteredChildren };
                            return null;
                        }).filter((item) => item !== null);
                        docList = doc_list.map((item) => {
                            const filteredChildren = item.child.filter((child) => child.is_required !== "1");
                            if (filteredChildren.length > 0) return { ...item, child: filteredChildren };
                            return null;
                        }).filter((item) => item !== null);
                    }
                    setDocumentCategoryList({get_document_list: customerDoc, co_app_doc_list: co_applicant_doc, co_app_other_doc_list: co_doc_list});
                    setDocumentOtherCategoryList(docList);
                    //dispatch({ type: "SET_DOCUMENT", payload : response.data }); 
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    }

    const docCategoryList = () => {
        if(leadInfo){
            let ob = {
                profession: leadInfo?.lead_details?.profession || 1,
                loan_type: leadInfo?.loan_type || 1,
                property_type: leadInfo.lead_details?.property_type || 1
            }
            executeGraphQLQuery(GET_DOCUMENTS_LIST(ob.property_type,ob.loan_type,ob.loan_type), client).then((response) => {
                if (response && response.data) {
                    setExistingDocumentCategoryList(response.data);
                    //dispatch({ type: "SET_DOCUMENT", payload : response.data }); 
                }
            }).catch((error) => {
                    console.log(error);
            });
        } 
    }    

    let lead_id = crypto.decode(params.lead_id)
    useEffect(() => {
        docCategoryList();
        docCategoryListUpdated();
    }, [leadInfo])

    useEffect(() => {
        getLeadDetail(parseInt(lead_id))
        dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)));
      }, [lead_id]);
 

        return (
            <div className="container-fluid">
                <LoanRibbenBox 
                    tipOpen={tipOpen}
                    tipOpen1={tipOpen1}
                    tipOpen2={tipOpen2}
                    tipOpen3={tipOpen3}
                    toggleTip={toggleTip}
                    toggleTip1={toggleTip1}
                    toggleTip2={toggleTip2}
                    toggleTip3={toggleTip3}
                />
                { isLoading ? <Loader/> : ''}
                <LeadDetailTabs 
                    getLeadDetail={getLeadDetail} 
                    docCategoryList ={documentCategoryList}
                    exDocCategoryList={existingDocumentCategoryList}
                    docOtherCategoryList ={documentOtherCategoryList}
                    toggleTip={toggleTip}
                    toggleTip1={toggleTip1}
                    toggleTip2={toggleTip2}
                    toggleTip3={toggleTip3}
                    t={t}
                />
            </div>
        )
}
export default LeadDetail;