import { useApolloClient } from '@apollo/client';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../elements/Loader';
import { executeGraphQLMutation } from './../../common/executeGraphQLQuery';
import { SAVE_LOAN_DETAILS } from './../../services/customer.gql';
import { getLoanDetail } from './../../store/action/allAction';
import FormsyInputField from './../elements/FormsyInputField';
import FormsySelect from './../elements/FormsySelect';
import DocumentGallery from './DocumentGallery';
import FormFields from "./formFields/leadFormField.json";

const SUBVENSION_CROSS_FORM = FormFields["SUBVENSION_CROSS_SELL"];

const DisbursedLead = (props) => {
  const [subvension, setSubvensionData] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const client = useApolloClient();
  const dispatch = useDispatch();
  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let [loading, setIsLoading] = useState(false);
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({ masterdata, lead }));

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      let record = {
        subvension_type_id: lead?.leadDetail?.lead_details?.subvension_type_id,
        subvension_amount: lead?.leadDetail?.lead_details?.subvension_amount,
        cross_sell_type_id: lead?.leadDetail?.lead_details?.cross_sell_type_id,
        cross_sell_product_amount: lead?.leadDetail?.lead_details?.cross_sell_product_amount,
      };
      setSubvensionData(record);
    }
  }, [lead]);

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    return options;
  }, [masterdata]);

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setSubvensionData(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleInputChange = event => {
    if (event.target.id) {
      setSubvensionData(currentValue => ({
        ...currentValue,
        [event.target.id]: event.target.value
      }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setSubvensionData(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";

    let mutation = SAVE_LOAN_DETAILS(authUser?.id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details: {
            subvension_type_id: +subvension?.subvension_type_id,
            subvension_amount: +subvension?.subvension_amount || 0,
            cross_sell_type_id: +subvension?.cross_sell_type_id,
            cross_sell_product_amount: +subvension?.cross_sell_product_amount || 0,
          }
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getLoanDetail(lead_id, client))

    })
      .catch(err => {
        toast.error(err.message);
      })
  }



  const isSubmitDisabled = useMemo(() => {
    return (lead?.leadDetail?.status_id != 4) ? true : false;
  }, [allowSubmit, getContext]);

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="65" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>Subvention & Cross Sell</h2>
          </div>

          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {SUBVENSION_CROSS_FORM.map((field) =>
                ["text", "number-format"].includes(field.type) ? (
                  <fieldset class="form-filed col-md-6 ">
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={subvension[field.name]}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={','}
                      decimalSeparator={'.'}
                      label={field.label}
                      onChange={
                        field.type === "number-format"
                          ? (data) => handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={field.validations}
                      validationError={
                        subvension[field.name] ? field.validationError : ""
                      }
                      readOnly={field.readOnly || (!+subvension.subvension_type_id && field.name === 'subvension_amount') || (!+subvension.cross_sell_type_id && field.name === 'cross_sell_product_amount')}
                      required={field.required}
                    />
                  </fieldset>
                ) :
                  field.type === "dropdown" ? (
                    <fieldset class="single-select col-md-6">
                      <FormsySelect
                        name={field.name}
                        id={field.id}
                        inputProps={{
                          options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                          placeholder: field.label,
                          className: "react-select",
                          classNamePrefix: "react-select",
                          value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ id }) => id == subvension[field.name]),
                          isDisabled: field.readOnly
                        }}
                        required={field.required}
                        value={field.name}
                        onChange={(data) => handleSelectChange(data, field.name)}
                      />
                    </fieldset>
                  ) : null
              )
              }
            </div>
            <div className="btn-save-remarks">
              <button type="submit" className="btn-primary" disabled={isSubmitDisabled || !allowSubmit}>Update</button>
            </div>
          </Formsy>
        </div>
      </div>
    </div>
  );
}

export default DisbursedLead;