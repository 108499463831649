import { gql } from "@apollo/client";

export const GET_BANK_OFFERS = gql`
  mutation get_bank_offers($bankOfferInput: BankOfferInput!) {
    get_bank_offers(bankOfferInput: $bankOfferInput)
  }
`;

export const GET_BANKER_RECORD_BY_ID = (bank_id) => gql`
query {
  get_banker_records_by_id(bank_id: ${bank_id}) {
    id
    name
    email
    phone
    bank
    bank_id
    designation
    branch
    pincode
    escalation_matrix
  }
}`;

export const GET_BANK_POC_MAPPING = (lead_id) => gql`
query {
  get_bank_poc_mapping(lead_id: ${lead_id})
}`;

export const SAVE_BANK_POC_MAPPING = gql`
  mutation save_bank_poc_mapping($bank_mapping_list:SaveBankPOCMappingListInput!){
    save_bank_poc_mapping(bank_mapping_list:$bank_mapping_list)
  }
`;

export const SAVE_BANK_RECORD = gql`
  mutation save_banker_records($createBankerRecordInput:CreateBankerRecordInput!){
    save_banker_records(createBankerRecordInput:$createBankerRecordInput){
      id
      name
      bank
      bank_id
    }
  }
`;