import Formsy from "formsy-react";
import React, { useEffect, useState } from "react";
import FormsySelect from "./FormsySelect";
import FormsyInputField from "./FormsyInputField";

const AdditionalIncome = ({
  index,
  ALL_OPTIONS,
  setAdditionalIncomeType,
  removeAdditionalIncome,
  setAdditionalIncomeData,
  additionalIncomeData,
  isCoApp = false,
}) => {
  const [additionalIncome, setAdditionalIncome] = useState([]);
  const [isFormTouch, setTouch] = useState({});

  let incomeType = "income_type_id";
  let incomeTypeOption = `filtered_additional_income_type_${index}`;
  let amountType = "amount_type";
  let amountTypeOption = "amount_type";
  let amount = "amount";

  if (isCoApp) {
    incomeType = `ca_${incomeType}`;
    incomeTypeOption = `ca_${incomeTypeOption}`;
    amountType = `ca_${amountType}`;
    amountTypeOption = `ca_${amountTypeOption}`;
    amount = `ca_${amount}`;
  }

  useEffect(() => {
    if (additionalIncomeData && additionalIncomeData.length > 0) {
      setAdditionalIncome(additionalIncomeData);
    }
  }, [additionalIncomeData]);

  const handleSelectChange = (data, field_name, index) => {
    if (data && field_name) {
      if (field_name === incomeType) {
        setAdditionalIncomeType(data, index);
      }
      const exAddInc = [...additionalIncome];
      exAddInc[index] = {
        ...exAddInc[index],
        [field_name]: data.value,
        status: "1"
      };
      setAdditionalIncome(exAddInc);
      setAdditionalIncomeData(exAddInc);
    }
  };

  const handlePatternFormatChange = (data, field_name, index) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      const exAddInc = [...additionalIncome];
      exAddInc[index] = {
        ...exAddInc[index],
        [field_name]: data.value,
        status: "1"
      };
      setAdditionalIncome(exAddInc);
      setAdditionalIncomeData(exAddInc);
    }
  };

  const handleRemoveAdditionaIncome = (index) => {
    removeAdditionalIncome(index);
  };

  return (
    <>
      <Formsy
        className="lead-form-filed"
        autoComplete="off"
        onValid={() => null}
        onInvalid={() => null}
      >
        <div className="row">
          <fieldset className="single-select col-md-6">
            <FormsySelect
              name={incomeType}
              id={incomeType}
              inputProps={{
                options: ALL_OPTIONS[incomeTypeOption],
                placeholder: "Additional income type",
                className: "react-select",
                classNamePrefix: "react-select",
                value:
                  ALL_OPTIONS &&
                  ALL_OPTIONS[incomeTypeOption] &&
                  ALL_OPTIONS[incomeTypeOption].filter(
                    ({ value }) =>
                      value == additionalIncome[index]?.[incomeType]
                  ),
                isDisabled: false,
              }}
              required={false}
              value={additionalIncome && additionalIncome[index]?.[incomeType]}
              onChange={(data) => handleSelectChange(data, incomeType, index)}
            />
          </fieldset>
          <fieldset className="single-select col-md-6">
            <FormsySelect
              name={amountType}
              id={amountType}
              inputProps={{
                options: ALL_OPTIONS && ALL_OPTIONS[amountTypeOption],
                placeholder: "Amount type",
                className: "react-select",
                classNamePrefix: "react-select",
                value:
                  ALL_OPTIONS &&
                  ALL_OPTIONS[amountTypeOption] &&
                  ALL_OPTIONS[amountTypeOption].filter(
                    ({ value }) =>
                      value == additionalIncome[index]?.[amountType]
                  ),
                isDisabled: false,
              }}
              required={false}
              value={additionalIncome && additionalIncome[index]?.[amountType]}
              onChange={(data) => handleSelectChange(data, amountType, index)}
            />
          </fieldset>
          <fieldset className={"form-filed col-md-6"}>
            <FormsyInputField
              id={amount}
              name={amount}
              type={"number-format"}
              value={additionalIncome[index]?.[amount] || ""}
              placeholder=" "
              thousandsGroupStyle="lakh"
              thousandSeparator={","}
              decimalSeparator={"."}
              label={"Amount"}
              format={""}
              validations={isFormTouch[amount] ? "isNumeric" : null}
              validationError={""}
              readOnly={false}
              required={false}
              onChange={(data) =>
                handlePatternFormatChange(data, amount, index)
              }
            />
          </fieldset>
          <div
            type="button"
            className="fetchAadharbtn"
            onClick={() => handleRemoveAdditionaIncome(index)}
          >
            Remove
          </div>
        </div>
      </Formsy>
    </>
  );
};

export default AdditionalIncome;
