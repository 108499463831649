import React, { useEffect, useState } from "react";
import EmiCharts from "./EmiCharts";
import { EMI_FORM_FIELD,EMI_CALCULATOR } from '../../services/leads.gql';
import { executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import Loader from '../elements/Loader';


const EmiCalculator = () => {
    const client=useApolloClient();
    const [formData, setFormData] = useState({
        loan_amount: { default: 0},
        interest_rate: { default: 0},
        loan_tenure: { default: 0},
    });
    let [loading, setLoading] = useState(false);
    const [calculatedEmi, setCalculatedEmi] = useState({
        loan_amount: 10338053,
        roi: 5338053,
        loan_tenure: 43075,
    })

    const handleInput = (e) => {
        const name = e.target.name;
        const val = e.target.value;
        if (!isNaN(val) || val === '') {
            let data = {}
            setFormData((prevData) => ({ ...prevData, [name]: { ...prevData[name], default: val } }));

            if (name === "loan_amount") {
                data = {
                    "loan_amount": val,
                    "roi": formData?.interest_rate?.default,
                    "loan_tenure": formData?.loan_tenure?.default
                }
            }
            if (name === "loan_tenure") {
                data = {
                    "loan_amount": formData?.loan_amount?.default,
                    "roi": formData?.interest_rate?.default,
                    "loan_tenure": val
                }
            }
            if (name === "interest_rate") {
                data = {
                    "loan_amount": formData?.loan_amount?.default,
                    "roi": val,
                    "loan_tenure": formData?.loan_tenure?.default
                }
            }
            handleSubmit(data)
        }
    };
 
    const fetchData = async () => { 
        
        executeGraphQLQuery(EMI_FORM_FIELD(),client).then((response) => { 
            if(response?.data?.emi_cal_form){ 
                let result = response.data.emi_cal_form?.emi_calulator
                if(result){
                    setFormData((prevData) => ({
                        ...prevData,
                        loan_amount: result?.loan_amount,
                        interest_rate: result?.interest_rate,
                        loan_tenure: result?.loan_tenure
                    }));
                } 
            }
         })
         .catch((error) => {  }); 
    };

    const handleSubmit = async (data) => {  
        setLoading(true)
        executeGraphQLQuery(EMI_CALCULATOR(data),client).then((response) => { 
            setLoading(false)
            if(response?.data?.emi_calcultor){ 
                let result = response.data.emi_calcultor
                if(result){ 
                    setCalculatedEmi((prevData) => ({
                        ...prevData,
                        loan_amount: result?.total_pay,
                        roi: result?.interest_amount,
                        loan_tenure: result?.emi
                    }));
                } 
            }
         })
         .catch((error) => { 
            setLoading(false)
          }); 
    }


    useEffect(() => {
        fetchData();
    }, []);

    
    

    const formatCurrency = (amount) => {
        const formattedAmount = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR'
        }).format(amount);
        return formattedAmount.replace(/\.00$/, '');
    };

    useEffect(() => {
        const rangeInputs = document.querySelectorAll('input[type="range"].slider-progress');
        rangeInputs.forEach((e) => {
            e.style.setProperty('--value', e.value);
            e.style.setProperty('--min', e.min === '' ? '0' : e.min);
            e.style.setProperty('--max', e.max === '' ? '100' : e.max);
            e.addEventListener('input', (event) => {
                e.style.setProperty('--value', event.target.value);
            });
        });
        return () => {
            rangeInputs.forEach((e) => {
                e.removeEventListener('input', () => {});
            });
        };
    }, [formData]);

    const principal = calculatedEmi?.loan_amount || 0; 
    const interest = calculatedEmi?.roi || 0; 
    

    return (
        <>
           {loading ? <Loader/> : null}
            <div className="box" id="emi">
                <div className="emicontainer">
                    <div>
                        <div className="mainheadingcard">
                            <div className="headingposition">
                                <h1 className="mainheading">EMI Calculator</h1>
                            </div>
                           
                        </div>
                        <div className="pccrad">
                            <div className="emicard">
                                <div className="loancard">
                                    <div className="containerstyle">
                                     <EmiCharts emi={interest} total={principal} />
                                        <div className="textstyle">
                                            <div className="totalamount">Amount</div>
                                        </div>

                                    </div>
                                    <div className="flotright">
                                        <div className="para">Are you all set to apply? Take the leap and begin your home loan journey.</div>
                                        <div className="pchidebtn">
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="amountcard">
                                        <div className="emiamount">
                                            <div className="emicardnew">
                                                <div className="emiborder">
                                                    <div>
                                                        <span className="emispan">Principal Amount</span>
                                                    </div>
                                                    <div className="emiprice">{formatCurrency(formData?.loan_amount?.default)}</div>
                                                </div>
                                                <div className="emiline"></div>
                                                <div className="emiborder">
                                                    <div>
                                                        <span className="emispan">Interest Amount</span>
                                                    </div>
                                                    <div className="emiprice">{formatCurrency(calculatedEmi?.roi)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="flotleft">

                                <div className="newemibox">
                                    <div className="emicardnew">
                                        <div className="emiborder">
                                            <div>
                                                <div className="emispannew">Monthly EMI Pay</div>
                                            </div>
                                            <div className="emiprice">{formatCurrency(calculatedEmi?.loan_tenure)}</div>
                                        </div>
                                        <div className="emiline"></div>
                                        <div className="emiborder">
                                            <div>
                                                <div className="emispannew">Total Amount</div>
                                            </div>
                                            <div className="emiprice">{formatCurrency(calculatedEmi?.loan_amount)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="emibox">
                                    <div className="mainamount">
                                        <div>Loan Amount</div>
                                        <input
                                            type="text"
                                            id="loan_amount"
                                            name="loan_amount"
                                            value={formData?.loan_amount?.default}
                                            onChange={handleInput}
                                            maxLength={10}
                                        />
                                    </div>
                                    <div className="range">
                                        <input
                                            type="range"
                                            id="loan_amount"
                                            name="loan_amount"
                                            min={formData?.loan_amount.min}
                                            max={formData?.loan_amount.max}
                                            step="500000"
                                            className="styled-slider slider-progress"
                                            value={formData?.loan_amount?.default}
                                            onChange={handleInput}
                                        />
                                    </div>
                                    <div className="emicommon">
                                        <div>₹ 5 lakh</div>
                                        <div>₹ 10 cr</div>
                                    </div>

                                    <div className="maintenure">
                                        <div>Tenure</div>
                                        <input
                                            type="text"
                                            id="loan_tenure"
                                            name="loan_tenure"
                                            value={formData?.loan_tenure?.default}
                                            onChange={handleInput}
                                            maxLength={2}
                                        />
                                    </div>
                                    <div className="range">
                                        <input
                                            type="range"
                                            id="loan_tenure"
                                            name="loan_tenure"
                                            min={formData?.loan_tenure.min}
                                            max={formData?.loan_tenure.max}
                                            className="styled-slider slider-progress"
                                            value={formData?.loan_tenure?.default}
                                            onChange={handleInput}
                                        />
                                    </div>

                                    <div className="emicommon">
                                        <div>5 years</div>
                                        <div>30 years</div>
                                    </div>

                                    <div className="mainrate">
                                        <div>Interest Rate (% P.A.)</div>
                                        <input
                                            type="text"
                                            id="interest_rate"
                                            name="interest_rate"
                                            value={formData?.interest_rate?.default}
                                            onChange={handleInput}
                                            maxLength={4}
                                        />
                                    </div>
                                    <div className="range">
                                        <input
                                            type="range"
                                            id="interest_rate"
                                            name="interest_rate"
                                            min={formData?.interest_rate.min}
                                            max={formData?.interest_rate.max}
                                            step="0.1"
                                            className="styled-slider slider-progress"
                                            value={formData?.interest_rate?.default}
                                            onChange={handleInput}
                                        />
                                    </div>
                                    <div className="emicommon">
                                        <div>7%</div>
                                        <div>25 %</div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
};


export default EmiCalculator;
