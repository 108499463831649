import { useApolloClient } from '@apollo/client';
import dateFormat from 'dateformat';
import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import { toast } from 'react-toastify';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { CHECK_ASSIGN_USER, DISBURSE_REJECTION_ID, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import { GET_DETAILS_BY_PANCARD, SAVE_CUST_DETAILS } from '../../../services/customer.gql';
import GeneralService from '../../../services/generalService';
import { getLoanDetail, getMasterCityState, setCityList } from '../../../store/action/allAction';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsyInputField from '../../elements/FormsyInputField';
import FormsySelect from '../../elements/FormsySelect';
import Modal from '../../elements/Modal';
import LeadRemarkHistory from '../../lead-list/LeadRemarkHistory';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/coApplicantDetailsFormFields.json";

const MaskData = require('maskdata');
const maskOptions = {
  maskWith: "*",
  unmaskedStartDigits: 0, 
  unmaskedEndDigits: 4,
  maskSpace: false
};

const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 75);
const GENDER_OPTIONS = [
  { id: "1", label: "Male", value: "male" },
  { id: "2", label: "Female", value: "female" }
]

const FORM_FIELDS = FormFields["BASIC_DETAILS"];

const CA_BasicDetailsForm = (props) => {
  const [basicDetails, setBasicDetails] = useState([]);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [ViewTimeLine, setViewTimeLine] = useState(false);
  const [showremarkform, setShowremarkform] = useState(false); 
  const [activeTab,setActiveTab] = useState("coApplicant_0")
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const [isFormTouch,setTouch] = useState({})
  const client = useApolloClient();
  const dispatch = useDispatch();
  const context = useOutletContext();
  const documentCategoryList = context.docList || []
  let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "co-borrower-basic-details")?.is_edit_access;
  if (accessCondition !== 1 ) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }
  useEffect(()=>{  
    if(!masterdata.data || !masterdata.data.state_list){
      dispatch(getMasterCityState())
    } 
  },[])
  let activetabIndex = activeTab.split('_');
  activetabIndex = activetabIndex[1]; 
  useEffect(()=>{
    let options = masterdata ? { ...masterdata.data } : {};   
    if (options?.city && options?.city?.length) { 
      let record  = options.city.filter(ob=>ob.state_id == basicDetails[activetabIndex]?.ca_cra_state).map(data => {
        data.value = data.id
        data.label = data.label
        return data;
      }) 
      dispatch(setCityList(record))
    } 
  },[basicDetails[activetabIndex]?.ca_cra_state]) 

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let co_applicatndata = lead?.leadDetail?.co_applicant || []
      const newCoApplicants = co_applicatndata.map(data => {
        let newAppObject = {}
        newAppObject.id = data.id || 0;
        newAppObject.ca_annual_income = data.ca_annual_income || '';
        if(data.ca_profession) newAppObject.ca_profession = data.ca_profession || '';
        if(data.ca_salary_credit_mode) newAppObject.ca_salary_credit_mode = data.ca_salary_credit_mode || '';
        newAppObject.ca_company_name = data.ca_company_name || '';
        newAppObject.ca_company_type = data.ca_company_type || '';
        newAppObject.ca_loan_amount = data.ca_loan_amount || '';
        newAppObject.ca_tenure = +data.ca_tenure || 0;

        newAppObject.ca_first_name = data.ca_first_name || '';
        newAppObject.ca_last_name = data.ca_last_name || '';
        newAppObject.ca_gender = data.ca_gender || '';
        newAppObject.relationship_with_customer = data.relationship_with_customer || '';
        newAppObject.ca_mobile = data.ca_mobile || '';
        newAppObject.masked_ca_mobile = MaskData.maskCard(data.ca_mobile, maskOptions);
        newAppObject.ca_email = data.ca_email || '';
        newAppObject.ca_marital_status = data.ca_marital_status || '';
        newAppObject.ca_dob = data.ca_dob || '';
        newAppObject.ca_aadhar_no = data.ca_aadhar_no || '';
        let aadhar_no = (data && data.ca_aadhar_no) ? data?.ca_aadhar_no?.match(/.{1,4}/g).join(' '):'';
        newAppObject.masked_ca_aadhar_no = MaskData.maskCard(aadhar_no, maskOptions);
        newAppObject.aadhar_touch = 1;
        newAppObject.ca_pancard_no = data.ca_pancard_no || '';
        newAppObject.ca_annual_income = data.ca_annual_income || '';
        newAppObject.ca_mother_name = data.ca_mother_name || '';
        newAppObject.ca_qualification = data.ca_qualification || '';

        newAppObject.ca_cra_address1 = data.ca_cra_address1 || '';
        newAppObject.ca_cra_address2 = data.ca_cra_address2 || '';
        newAppObject.ca_cra_state = data.ca_cra_state || '';
        newAppObject.ca_cra_city = data.ca_cra_city || ''; 
        newAppObject.ca_cra_pincode = data.ca_cra_pincode || '';
        newAppObject.ca_type = data.ca_type || '';
        newAppObject.same_as_cus_addr = data.same_as_cus_addr || '0';
        newAppObject.same_as_cus_addr = data.same_as_cus_addr || '0';

        newAppObject.isFetchAadharVisible = false;

        if((newAppObject.ca_pancard_no !== null && newAppObject.ca_pancard_no !== "") && (newAppObject.ca_aadhar_no === null || newAppObject.ca_aadhar_no === "")) {
          newAppObject.isFetchAadharVisible = true;
        }

        return newAppObject;
      })
      setBasicDetails(newCoApplicants);
      if(co_applicatndata.length === 0) newCoapplicant();
      
    }
  }, [lead]);

  const newCoapplicant = () => {
    const newCoApplicants = [{
      ca_first_name: "",
      ca_last_name: "",
      ca_gender: "",
      relationship_with_customer:"",
      ca_mobile: "",
      ca_email: "",
      ca_dob: "",
      ca_aadhar_no: "",
      ca_pancard_no: "",
      ca_annual_income: ""
    }];
    setBasicDetails(newCoApplicants);
  };


  const addMoreFields = () => {
    const newCoApplicants = [...basicDetails, {
      ca_first_name: "",
      ca_last_name: "",
      ca_gender: "",
      relationship_with_customer:"",
      ca_mobile: "",
      ca_email: "",
      ca_dob: "",
      ca_aadhar_no: "",
      ca_pancard_no: "",
      ca_annual_income: ""
    }];
    if(basicDetails[basicDetails.length-1].id){ 
      setBasicDetails(newCoApplicants);
      setActiveTab(`coApplicant_${newCoApplicants.length - 1}`);
    } 
  };

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    options["qualification"] = options["qualification"].map(obj => ({ ...obj, value: obj.id}));
    options["marital_status"] = options["marital_status"].map(obj => ({ ...obj, value: obj.id}));
    options["gender"] = GENDER_OPTIONS;
    options["ca_type"] = [
      { label: "Financing",value:"financing"},
      { label: "Non Financing",value:"non_financing"}
    ] 
    
    return options;
  }, [masterdata]);

  const handleInputChange = event => {
    let { id, value } = event.target;
    setTouch({
      ...isFormTouch,
      [id]:true
    })
    if (id) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedBasicDetails = [...basicDetails];
      if (id === 'ca_pancard_no') {
        let inputVal = value.toUpperCase();
        updatedBasicDetails[activetabIndex][id] = inputVal;
        const isValidPan = /^[A-Z]{3}[CHFATBLJGP][A-Z]\d{4}[A-Z]$/.test(inputVal);
        if (isValidPan &&
          (updatedBasicDetails[activetabIndex]["ca_aadhar_no"] === "" ||
            updatedBasicDetails[activetabIndex]["ca_aadhar_no"] === null ||
            updatedBasicDetails[activetabIndex]["ca_aadhar_no"] === undefined
          )
        ) {
          updatedBasicDetails[activetabIndex]["isFetchAadharVisible"] = true;
        } else {
          updatedBasicDetails[activetabIndex]["isFetchAadharVisible"] = false;
        }
      } else {
        if(id =='ca_cra_pincode' && value.length ==6){ 
          setCityStateByPin(value)
        } 
        let finalValue = value;
        if (id == 'ca_aadhar_no'){
          finalValue = finalValue.replace(/\W/gi, '').replace(/(.{4})/g, '$1 ');
          finalValue = /^[0-9\sX]+$/.test(finalValue) ? finalValue : "XXXX XXXX";
          updatedBasicDetails[activetabIndex].aadhar_touch = 0
        } 
        updatedBasicDetails[activetabIndex][id] = finalValue.trim();
      }
  
      setBasicDetails(updatedBasicDetails);
    }
  } 
 
  const setCityStateByPin = async (pincode)=>{ 
    try {
      let response = await GeneralService.stateCityByPincode(pincode); 
      if (response?.data?.data?.length) {
        let item = response.data.data[0] 
        let newArray  = basicDetails;
        newArray[activetabIndex].ca_cra_pincode = pincode;
        newArray[activetabIndex].ca_cra_state = item?.state_id;
        newArray[activetabIndex].ca_cra_city = item?.city_id; 
        setBasicDetails(newArray)
      }  
    } catch (error) {
      console.error('Error :', error);
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (field_name && data.value !== undefined) {
      setTouch({
        ...isFormTouch,
        [field_name]:true
      })
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedBasicDetails = [...basicDetails];
      updatedBasicDetails[activetabIndex][field_name] = data.value;
      setBasicDetails(updatedBasicDetails);
    }
  }

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedBasicDetails = [...basicDetails];
      updatedBasicDetails[activetabIndex][field_name] = date;
      setBasicDetails(updatedBasicDetails);
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if(field_name.includes('state')){ 
        let options = masterdata ? { ...masterdata.data } : {}; 
        if (options?.city && options?.city?.length) {
          let record  = options.city.filter(ob=>ob.state_id==data.value).map(data => {
            data.value = data.id
            data.label = data.label
            return data;
          })
          dispatch(setCityList(record))
        }
      } 
      let activetabIndex = activeTab.split('_');
      activetabIndex = activetabIndex[1];
      const updatedBasicDetails = [...basicDetails];
      updatedBasicDetails[activetabIndex][field_name] = data.value;
      setBasicDetails(updatedBasicDetails);
    }
  }

  const handleSubmit = () => { 
    let lead_id = lead?.leadDetail?.id || "";  
    let newbasicDetails = basicDetails.map((ob)=>{
      return {
        id: ob.id,
        ca_annual_income: ob.ca_annual_income,
        ca_first_name: ob.ca_first_name,
        ca_last_name: ob.ca_last_name,
        ...(ob.relationship_with_customer && {relationship_with_customer: ob.relationship_with_customer}),
        ca_mobile: ob.ca_mobile,
        ca_email : (ob.ca_email)? ob.ca_email: null, 
        ...(ob.ca_marital_status && {ca_marital_status: ob.ca_marital_status}),
        ...(ob.ca_gender && {ca_gender: ob.ca_gender}),
        ...(ob.ca_dob && {ca_dob: ob.ca_dob}),
        ca_type: ob.ca_type, 
        ca_aadhar_no: ob.ca_aadhar_no.trim().replace(/\s/g,'') || '',
        ca_pancard_no: ob.ca_pancard_no, 
        ca_mother_name: ob.ca_mother_name,
        ca_qualification: ob.ca_qualification,
        ca_cra_pincode: ob.ca_cra_pincode,
        ca_cra_address1: ob.ca_cra_address1,
        ca_cra_address2: ob.ca_cra_address2,
        ...(+ob.ca_cra_state && {ca_cra_state: +ob.ca_cra_state}),
        ...(+ob.ca_cra_city && {ca_cra_city: +ob.ca_cra_city}),
        ca_cra_state: +ob.ca_cra_state,
        ca_cra_city: +ob.ca_cra_city,
        same_as_cus_addr: ob.same_as_cus_addr
      }
    })  
    let activetabIndex = activeTab.split('_');
    activetabIndex = activetabIndex[1];
    newbasicDetails = (newbasicDetails[activetabIndex])? [newbasicDetails[activetabIndex]]: newbasicDetails 

    let mutation = SAVE_CUST_DETAILS(user_id),
      variables = {
        api_called_by: 'web',
        LeadAdditionalInput: {
          lead_id: lead_id,
          co_applicant: newbasicDetails
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.LeadAdditionalInput || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      } 

      toast.success("Success");
      setTouch({})
      dispatch(getLoanDetail(lead_id, client))

    })
      .catch(err => {
        toast.error(err.message);
      })
  }

  let BasicDetailsForm = useMemo(() => {
    return FORM_FIELDS.map(field => {
      if (field.name === "ca_dob") {
        field["maxDate"] = DOB_MAX_DATE;
        field["minDate"] = DOB_MIN_DATE;
      }
      return field;
    })
  }, []);

  BasicDetailsForm = useMemo(() => {
    if (basicDetails) {
      if (basicDetails.cb_nationality !== "other") {
        return FORM_FIELDS.filter(v => v.id !== "cb_other_nationality");
      }
    }
    return FORM_FIELDS;
  }, [basicDetails]);

  const showModalViewTimeLine = () => {
    setViewTimeLine(true);
    setShowremarkform(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideModalViewTimeLine = () => {
    setViewTimeLine(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleFetchAadhar = async (event) => {
    try {
      const { id } = event.target;
      const aadharField = document.getElementById(`ca_aadhar_no`);
      const pan_card = basicDetails[id]["ca_pancard_no"];
      
      executeGraphQLQuery(GET_DETAILS_BY_PANCARD(pan_card), client)
      .then((result) => {
        if (result.data) {
          const jsonResp = JSON.parse(result.data?.get_details_by_pancard);
          if (jsonResp.status === 200) {
            if (jsonResp.data?.response?.maskedAadhaar) {
              const finalArr = basicDetails.map((obj, index) => {
                if (index == id) {
                  return {
                    ...obj,
                    ca_aadhar_no: jsonResp.data?.response?.maskedAadhaar,
                    masked_ca_aadhar_no: jsonResp.data?.response?.maskedAadhaar,
                    isFetchAadharVisible: false,
                  }
                } else {
                  return obj;
                }
              });
              setBasicDetails(finalArr);
              aadharField.readOnly = true;
            } else {
              const finalArr = basicDetails.map((obj, index) => {
                if (index == id) {
                  return {
                    ...obj,
                    ca_aadhar_no: `XXXXXXXX`,
                    masked_ca_aadhar_no: `XXXXXXXX`,
                    isFetchAadharVisible: false,
                  }
                } else {
                  return obj;
                }
              });
              setBasicDetails(finalArr);
              toast.error(jsonResp.data?.response?.message);
              aadharField.readOnly = false;
            };
          } else {
            console.log(jsonResp);
            aadharField.readOnly = false;
            const finalArr = basicDetails.map((obj, index) => {
              if (index == id) {
                return {
                  ...obj,
                  ca_aadhar_no: `XXXXXXXX`,
                  masked_ca_aadhar_no: `XXXXXXXX`,
                  isFetchAadharVisible: false,
                }
              } else {
                return obj;
              }
            });
            setBasicDetails(finalArr);
            toast.error("Internal server error");
          }
        } else {
          console.log(result);
          aadharField.readOnly = false;
          const finalArr = basicDetails.map((obj, index) => {
            if (index == id) {
              return {
                ...obj,
                ca_aadhar_no: "",
                masked_ca_aadhar_no: "",
                isFetchAadharVisible: false,
              }
            } else {
              return obj;
            }
          });
          setBasicDetails(finalArr);
          toast.error("Internal server error");
        } 
      })
      .catch((error) => {
        console.error('ERROR : ', error.message);
      });
    } catch (error) {
      console.log(`ERROR : ${error.message}`);
      toast.error(error.message);
    }
  }

  const setAddress = (event)=>{ 
    let customerData = lead?.leadDetail?.customer || {} 
    let newArray  = [...basicDetails];
    if(event.target.checked){
      newArray[activetabIndex].ca_cra_pincode = customerData.cra_pincode;
      newArray[activetabIndex].ca_cra_state = customerData?.cra_state;
      newArray[activetabIndex].ca_cra_city = customerData?.cra_city; 
      newArray[activetabIndex].ca_cra_address1 = customerData?.cra_address1; 
      newArray[activetabIndex].ca_cra_address2 = customerData?.cra_address2; 
      newArray[activetabIndex].same_as_cus_addr = '1'
    }else{ 
      newArray[activetabIndex].same_as_cus_addr = '0'
    }  
    setBasicDetails(newArray)   
  }
  const sectionInfo = { section: "Co-borrower details", sub_section: "Basic details" } 
   
  const rejectDisable = (lead.leadDetail.reject_reason_id > 0 && !DISBURSE_REJECTION_ID.includes(lead.leadDetail.reject_reason_id) && [2, 3].includes(lead.leadDetail.status_id)) ? true : false 
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false 
  
  return (
    <div className="image-form-outer">
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="8" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <span className="main-heading">Co-applicant details</span>
            {/* <h2>Basic Details</h2> */}
          </div>
          
          <Nav variant="pills" className="flex-column" style={{overflow:"hidden",overflowX:"auto"}}>
            <div className="tab-list">
              {basicDetails.length && basicDetails.map((coApplicant, index) =>
                (basicDetails[index].ca_first_name)?
                <Nav.Item key={index}>
                  <Nav.Link
                    onClick={() => setActiveTab(`coApplicant_${index}`)}
                    eventkey={`coApplicant_${index}`}
                    active={activeTab === `coApplicant_${index}`}
                  >
                    {basicDetails[index].ca_first_name}
                  </Nav.Link>
                </Nav.Item> :
                <Nav.Item key={index}>
                  <Nav.Link
                    onClick={() => setActiveTab(`coApplicant_${index}`)}
                    eventkey={`coApplicant_${index}`}
                    active={activeTab === `coApplicant_${index}`}
                    disabled={rejectDisable}
                  >
                  N/A
                  </Nav.Link>
                </Nav.Item>
              )}
            </div>
          </Nav>
         
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">

              {BasicDetailsForm.map((field,index) =>
                basicDetails.map((basicDetailData, index) => (`coApplicant_${index}` === activeTab) && (
                  ["text", "pattern-format", "number","number-format"].includes(field.type) ? (
                    <fieldset className="form-filed col-md-6 " key={index}>
                      <FormsyInputField
                        id={field.id}
                        name={field.name}
                        type={field.type}
                        value={basicDetailData && (field.name==="ca_mobile" || (field.name==="ca_aadhar_no" && basicDetailData.aadhar_touch )) ? (basicDetailData[`masked_${field.name}`] || '') : (basicDetailData[field.name] || '')}
                        placeholder=" "
                        thousandsGroupStyle="lakh"
                        thousandSeparator={','}
                        decimalSeparator={'.'}
                        label={field.label}
                        maxLength={field.maxLength}
                        disabled={(['ca_cra_pincode','ca_cra_address1','ca_cra_address2'].includes(field.name) && basicDetailData.same_as_cus_addr =='1')? true: false}
                        onChange={
                          ["pattern-format", "number-format"].includes(field.type)
                            ? (data) => handlePatternFormatChange(data, field.name)
                            : handleInputChange
                        }
                        format={field.ApplyNumberformat ? field.format : ""}
                        validations={isFormTouch[field.name] ? field.validations :null}
                        validationError={
                          basicDetailData[field.name] || basicDetailData[field.name] =='' ? (isFormTouch[field.name])? field.validationError :"" : ""
                        }
                        required={field.required} 
                        readOnly={(field.name==="ca_aadhar_no" && basicDetailData.aadhar_touch && basicDetailData.ca_aadhar_no !=null && basicDetailData.ca_aadhar_no !='')?true: field.readOnly}
                      />
                      {field.name === "ca_pancard_no" && basicDetailData.isFetchAadharVisible && (
                        <div
                          type="submit"
                          className="fetchAadharbtn"
                          onClick={handleFetchAadhar}
                          id={`${index}`}
                        >
                          Fetch Aadhar
                        </div>
                      )}
                    </fieldset>
                  ) :
                    field.type === "date-picker" ? (
                      <fieldset className="form-filed col-md-6 " key={index}>
                        <FormsyDatePicker
                          name="dob"
                          selectedDate={basicDetailData && basicDetailData[field.name] && new Date(basicDetailData[field.name])}
                          placeholder={field.label}
                          maxDate={field.maxDate}
                          minDate={field.minDate}
                          value={basicDetailData && basicDetailData[field.name] ? dateFormat(basicDetailData[field.name], "dd mmm, yyyy") : null}
                          onDateSelect={(date) => handleDateChange(date, field.name)}
                          dateFormat="dd MMM, yyyy"
                          validations={field.validations}
                          validationError={
                            basicDetailData[field.name] ? field.validationError : ""
                          }
                          required={field.required}
                          hasError={(basicDetailData.hasOwnProperty(field.name) && !basicDetailData[field.name]) ? true : false}
                        />
                      </fieldset>
                    ) :
                      field.type === "dropdown" ? (
                        <fieldset className="single-select col-md-6" key={index}>
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == basicDetailData[field.name]),
                              isDisabled: (['ca_cra_state','ca_cra_city'].includes(field.name) && basicDetailData.same_as_cus_addr =='1')? true: false
                            }}
                            required={field.required}
                            value={basicDetailData && basicDetailData[field.name]}
                            onChange={(data) => handleSelectChange(data, field.name)} 
                          />
                        </fieldset>
                      ) :  field.type === "checkbox" ? (
                        <fieldset className="single-select col-md-12" key={index}> 
                          <input type="checkbox" name={field.name} value="" 
                           onChange={(e) => setAddress(e)} checked={(basicDetailData[field.name] =='1' ? true: false)} 
                          />
                          <label> &nbsp;Same as customer address</label>
                        </fieldset>
                      ) : null
                ))
              )
              }
            </div>

            <div className="btn-save-remarks">
              <button type="submit" className="btn-primary" disabled={!allowSubmit || IS_LOST}>Save</button>
              <button className="btn-primary-outline" type="button" onClick={addMoreFields} disabled={rejectDisable || IS_LOST}>
              Add Co-Applicant
              </button>
              {/* <button type="button" className="btn-primary-outline" onClick={showModalViewTimeLine} disabled={rejectDisable}>Remarks
                <span className="m-xs-l">({remarksHistory && remarksHistory.length || 0})</span>
              </button> */}
            </div>
          </Formsy>
        </div>

      </div>
      <div className="view-timeline-popup">
        <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
          <div className="modal-header">
            <h2>Remark History</h2>
          </div>
          <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={context.markAsFreezed} accessCondition={accessCondition} sectionInfo={sectionInfo} />
        </Modal>
      </div>
    </div>
  );
}
export default CA_BasicDetailsForm;