import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

//import logo from "../../webroot/images/ambak_logo.svg";
import ls from "local-storage"
import Select from 'react-select';
import { toast } from 'react-toastify';
import MasterService from '../../services/MasterService';
import secureStorage from '../../config/partnerEncrypt';

import Loader from '../elements/Loader';
import InputField from '../elements/PartnerInput';
import {createActivityLog} from '../../services/preLeadActivityService';
import generalService from '../../services/generalService';



const Register = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, SetErrors] = useState({});
    const [login_detail, SetLoginDetail] = useState({yodha_code: '', lead_yodha_code:''});
    const [reloadCaptcha, setReloadCaptcha] = useState(0);
    const [showOtp, setShowOtp] = useState(false);
    const [otpTimer, setOtpTimer] = useState(60);
    const [otpValue, setOtpValue] = useState('');
    const [activeTab, setActiveTab] = useState('partner');
    const [isPreLeadsRegForm, setIsPreLeadsRegForm] = useState(false);
    const [isSingleForm, setIsSingleForm] = useState(false);
    const [getProfessionlist, setProfessionlist] = useState([
        {
            "id": 1,
            "profession_name": "DSA"
        },
        {
            "id": 2,
            "profession_name": "CA"
        },
        {
            "id": 3,
            "profession_name": "Property Dealer"
        },
        {
            "id": 4,
            "profession_name": "Financial Consultant"
        }
    ]);
    const [getSubSource ,setgetSubSource] = useState([])
    const [isDisabled, setIsDisabled] = useState(false);
    const [cityList, setCityList] = useState([]);

    //Changes for gmail login//
    let requestedUser = (new URLSearchParams(window.location.search)).get("userdata");

    const handleTabChange = (tabKey) => {
        setActiveTab(tabKey);
    };

    useEffect(() => {
        if (!otpTimer) return;

        const intervalId = setInterval(() => {
            setOtpTimer(otpTimer - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [otpTimer]);


    useEffect (() => {
        sub_source()
        checkParams();
        getUserInfo();
        getCityList();
    }, [])

    const getCityList = async () => {
        let response = await generalService.stateCityList();
        const city = response.data.data.city;
        setCityList(city);
      };

    const getUserInfo = () => {
        const userInformation = localStorage.getItem('user_information');
        if (userInformation) {
            try {
            const parsedUserInformation = JSON.parse(userInformation);
            if (parsedUserInformation.yodha_code) {
                SetLoginDetail(prevState => ({
                ...prevState,
                yodha_code: parsedUserInformation.yodha_code,
                lead_yodha_code: parsedUserInformation.yodha_code
                }));
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
            } catch (error) {
            console.error("Error parsing JSON from localStorage", error);
            }
        } else {
            setIsDisabled(false);
        }
    };

    const checkParams = () => {
        const params = window.location.search;
        if(params === '?pre_leads' || params === '?new_partner') {
                setIsSingleForm(true);
            if(params === '?pre_leads') {
                setIsPreLeadsRegForm(true);
                setActiveTab('leads');
            }
        }
    }

    //Login with gmail changes

    const isValidatedLead = async (value) => {
        let tempLoginDetail = { ...login_detail };
        let formIsValid = true;
        let tempErrors = {};
        if (!tempLoginDetail["lead_name"]) {
            formIsValid = false;
            tempErrors["lead_name"] = 'Name is required';
        }
        if (!tempLoginDetail["subsource_id"]) {
            formIsValid = false;
            tempErrors["subsource_id"] = 'Sub Source is required';
        }
        if (tempLoginDetail["lead_email"] && !new RegExp("^[\\w-]+(\\.[\\w-]+)*@([\\w-]+\\.)+[a-zA-Z]{2,7}$").test(tempLoginDetail["lead_email"])) {
            formIsValid = false;
            tempErrors["lead_email"] = "Invalid email address";
        }

        if (!tempLoginDetail["lead_mobile"]) {
            formIsValid = false;
            tempErrors["lead_mobile"] = 'Mobile is required';
        } else if (!new RegExp("^[6-9]\\d{9}$").test(tempLoginDetail["lead_mobile"])) {
            formIsValid = false;
            tempErrors["lead_mobile"] = "Invalid mobile number";
        }
        if (!tempLoginDetail["lead_yodha_code"]) {
            formIsValid = false;
            tempErrors["lead_yodha_code"] = 'Yodha Code is required';
        }
        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        SetErrors(tempErrors);
        return formIsValid;
    }


    const isValidate = async (value) => {
        let tempLoginDetail = { ...login_detail };
        console.log ("tempLoginDetailtempLoginDetailtempLoginDetailtempLoginDetailtempLoginDetailtempLoginDetail" , tempLoginDetail)
        let formIsValid = true;
        let tempErrors = {};
        if (!tempLoginDetail["name"]) {
            formIsValid = false;
            tempErrors["name"] = 'Name is required';
        }
        if (tempLoginDetail["email"] && !new RegExp("^[\\w-]+(\\.[\\w-]+)*@([\\w-]+\\.)+[a-zA-Z]{2,7}$").test(tempLoginDetail["email"])) {
            formIsValid = false;
            tempErrors["email"] = "Invalid email address";
        }
        if (!tempLoginDetail["subsource_id"]) {
            formIsValid = false;
            tempErrors["subsource_id"] = 'Sub Source is required';
        }
        if (!tempLoginDetail["mobile"]) {
            formIsValid = false;
            tempErrors["mobile"] = 'Mobile is required';
        } else if (!new RegExp("^[6-9]\\d{9}$").test(tempLoginDetail["mobile"])) {
            formIsValid = false;
            tempErrors["mobile"] = "Invalid mobile number";
        }
        if (!tempLoginDetail["yodha_code"]) {
            formIsValid = false;
            tempErrors["yodha_code"] = 'Yodha Code is required';
        }
        if (showOtp && !tempLoginDetail["otp"]) {
            formIsValid = false;
            tempErrors["otp"] = "OTP is required";
        }
        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        SetErrors(tempErrors);
        console.log ("tempErrorstempErrorstempErrorstempErrors" ,tempErrors)
        return formIsValid;
    }


    const handleInputChange = async (target_name, type, event) => {
      
        console.log ("eventeventeventeventeventeventeventeventeventevent" ,target_name)
        let temp_login_details = { ...login_detail };
        let tempErrors = { ...errors }
        if (target_name === 'pan') {
            event.target.value = event.target.value.toUpperCase();
        }
        if (target_name == "subsource_id") {
            temp_login_details[target_name] = event.id;
            tempErrors[target_name] = ""
        }
        else {
            temp_login_details[target_name] = event.target.value ;
            tempErrors[target_name] = ""
        }
      
        
        
        SetErrors(tempErrors)
        SetLoginDetail(temp_login_details);
    }

    const handleCityChange = (selected) => {
        let temp_login_details = { ...login_detail };
        let tempErrors = { ...errors }
        temp_login_details.city_id = selected.id;
        tempErrors.city_id = ""
        SetErrors(tempErrors)
        SetLoginDetail(temp_login_details);
      };

    const handleLeadOptionChange = (sname, oValue) => {
        console.log("sname", sname, oValue)
        let temp_login_details = { ...login_detail };
        console.log ("temp_login_detailstemp_login_detailstemp_login_detailstemp_login_details" , temp_login_details)
        let tempErrors = { ...errors }
        if (sname == 'premium_code') {
            temp_login_details['premium_code'] = oValue.code;
            delete errors[sname];
        } else {
            if (oValue == null)
                temp_login_details[sname] = 0;
            else
                temp_login_details[sname] = (oValue.id) != null ? oValue.id : oValue.target.value;
        }
        SetErrors(tempErrors)
        SetLoginDetail(temp_login_details);
    }

    const handleRegistration = async () => {
        let res = await isValidate()
        if (res) {
            setLoading(true);
            console.log("login_detail", login_detail)
            //let changed_details = this.checkIfDetailChanged(this.state.basic_details, this.state.previous_basic_details, this.state.owner_details, this.state.previous_owner_details);
            if (login_detail) {
                const requestData = {
                    name: login_detail?.name,
                    email: login_detail?.email,
                    mobile: login_detail?.mobile,
                    subsource_id:login_detail?.subsource_id,
                    sfa_referral_user_id: login_detail?.yodha_code > 0 ? +login_detail?.yodha_code : 0,
                    //assigned_bm_id: login_detail?.yodha_code > 0 ? +login_detail?.yodha_code : 0,
                    is_pan_verified: 2,
                    step: 1,
                    user_type: "1",
                    partner_id: 0,
                    user_id: 0,
                    term_condition: true,
                    source: "Direct Partner sales",
                    // pan_no: login_detail?.pan,
                    //pan_number: login_detail?.pan,
                    profession_id: login_detail?.profession_id,
                    otp: +login_detail?.otp,
                    updated_by:login_detail?.yodha_code > 0 ? +login_detail?.yodha_code : 0,
                    sub_source_id:login_detail?.subsource_id
                    
                }
                let token = JSON.parse(secureStorage.storage.user_information)
        
                requestData.updated_by = token.user_data.user_id
                console.log("requestDatas", requestData);
                await MasterService.post('/partner/partner/save-basic-details', requestData)
                    .then(response => {
                        setLoading(false);
                        console.log("response.data", response.data);
                        if (response && response.data.status == 200 && response.status == 200) {
                            toast.success("Partner created Successfully!")
                            setTimeout(() => {
                                // let url2go = '/partner-register';
                                // window.location.href = url2go;
                                window.location.reload();
                            }, 2000)
                        } else {
                            toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                            setTimeout(() => {
                                setShowOtp(false)
                            }, 1200)
                        }
                    })
                    .catch(error => console.log(error))
            }

        } else {
        }
    }

    // const handleNewLeads = async () => {
    //     let res = await isValidatedLead("send_otp")
    //     if (res) {
    //         setLoading(true);
    //         let params = {
    //             name:login_detail.lead_name,
    //             email:login_detail.lead_email,
    //             mobile: login_detail.lead_mobile,
    //             sfa_user_id:login_detail.lead_yodha_code,
    //             source: "Direct Partner sales",
    //             sub_source_id: login_detail.subsource_id
    //         }
    //         let token = JSON.parse(secureStorage.storage.user_information)
        
    //         params.updated_by = token.user_data.user_id
    //         MasterService.post('/partner/partner/joinus', params)
    //             .then(function (response) {
    //                 setLoading(false);
    //                 if (response && response.data.status == 200 && response.status == 200) {
    //                     toast.success("Message Sent!")
    //                 }  else {
    //                     if (response.data.error && response.data.error.length > 0) {
    //                         response.data.error.forEach(element => {
    //                             toast.error(element);
    //                             setLoading(false)
    //                         });
    //                     } else if (response.data.message) {
    //                         toast.error(response.data.message);
    //                         setLoading(false)
    //                     } else {
    //                         setLoading(false);
    //                         toast.error("Failed");
    //                     }
    //                 }
    //             })
    //             .catch(function (error) {
    //                 toast.error(error);
    //                 setLoading(false);
    //             });
    //     }
    // }
    const handleNewLeads = async () => {
        let res = await isValidatedLead("send_otp")
        if (res) {
            setLoading(true);
            const requestData = {
                name:login_detail.lead_name,
                email:login_detail.lead_email,
                mobile: login_detail.lead_mobile,
                sfa_referral_user_id: login_detail?.lead_yodha_code > 0 ? +login_detail?.lead_yodha_code : 0,
                //assigned_bm_id: login_detail?.lead_yodha_code > 0 ? +login_detail?.lead_yodha_code : 0,
                is_pan_verified: 2,
                step: 1,
                status:10,
                is_lead: 1,
                user_type: "1",
                partner_id: 0,
                user_id: 0,
                term_condition: true,
                source: "Direct Partner sales",
                pan_no: login_detail?.pan,
                pan_number: login_detail?.pan,
                profession_id: login_detail?.profession_id,
                otp: +login_detail?.otp,
                updated_by:login_detail?.yodha_code > 0 ? +login_detail?.yodha_code : 0,
                sub_source_id: login_detail.subsource_id,
                city_id: login_detail?.city_id,
            }
            await MasterService.post('/partner/partner/save-basic-details', requestData)
                .then(function (response) {
                    setLoading(false);
                    if (response && response.data.status == 200 && response.status == 200) {
                        toast.success("Message Sent!")
                        logActivity(response.data.data).then();
                            setTimeout(() => {
                                // let url2go = '/partner-register';
                                // window.location.href = url2go;
                                window.location.reload();
                            }, 2000)
                    }  else {
                        toast.error((response.data.message && response.data.message.length > 0) ? response.data.message[0] : "Error");
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const logActivity = async (data) => {
        const activityLogPayload = {
            leadId : data?.response?.dealer_id,
            userId : data?.response?.user_id,
            activityId : 1,
            comment: 'New Partner Lead Created'
        }
        await createActivityLog(activityLogPayload);
    }

    const sub_source = async() => {
        MasterService.get('/partner/partner/get-sub-source')
        .then(function(response) {
            setgetSubSource(response.data.data);
        })
    }
    
    const handleSendOTP = async () => {
        let res = await isValidate("send_otp")
        if (res) {
            setLoading(true);
            let params = {
                mobile: login_detail.mobile,
                source: "onboarding",
                api_called_by: "check_valid_mobile"
            }
            MasterService.post('/account/user/send_otp', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        // if (response.data.data) {
                            login_detail.otp = '';
                            setShowOtp(true)
                            setLoading(false);
                            setOtpTimer(60)
                            toast.success(response.data.message)
                        // } else if (response.data.error && response.data.error.length > 0) {
                        //     response.data.error.forEach(element => {
                        //         toast.error(element);
                        //         setLoading(false)
                        //     });
                        // }
                    } else {
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setLoading(false)
                        } else {
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const getHeading = () => {
        if (activeTab === 'partner') {
            return 'Partner Registration';
        } else if (activeTab === 'leads') {
            return 'Create Leads';
        }
        return '';
    };

    const customStyles = {
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? 'white' : state.isFocused ? '#2684ff' : 'white',
          color: 'black',
        }),
      };

    const renderSingleForm = () => {
        return (
            <div className="container-fluid">
            {loading ? <Loader /> : null}
                <div className="row">
                    <div className='login-main'>
                        <div className="login-outer">
                            <div className="register">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "40px" }}>
                                    <img src='https://storage.googleapis.com/ambak/logo/ambak_logo.svg' alt='logo' width={230} height={100} />
                                </div>
                                <div className="login-heading">
                                    <h2 style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>{getHeading()}</h2>
                                </div>
                                <Tab.Container id="" activeKey={activeTab} onSelect={handleTabChange}>
                                    <div className="login-tab">
                                        {!isPreLeadsRegForm ? (
                                            <Tab.Content>
                                                <Tab.Pane eventKey="partner">
                                                <div className="login-form">
                                                
                                                    {!showOtp &&
                                                        <div className='row'>
                                                            <div className='col-md-6'>
                                                                <fieldset className="">
                                                                    <div className={errors.login_username ? "material error" : "material"}>
                                                                        <InputField type="text" name="name" id="name" label="Name" placeholder=" " value={login_detail.name} onChange={handleInputChange.bind(null, 'name', 'text')} required={true} />
                                                                        <i className="ic-person icn-login"></i>
                                                                        <label data-label="Name*" className="form-label"></label>
                                                                        {errors.name && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.name}</span>}
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            <div className='col-md-6'>
                                                                <fieldset className="">
                                                                    <div className={errors.password ? "material error" : "material"}>
                                                                        <InputField type="text" name="mobile" id="mobile" label="Mobile" placeholder=" " maxLength={10} value={login_detail.mobile} onChange={handleInputChange.bind(null, 'mobile', 'text')} required={true} />
                                                                        <i className="ic-mobile icn-login"></i>
                                                                        <label data-label="Mobile*" className="form-label"></label>
                                                                        {errors.mobile && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.mobile}</span>}
                                                                    </div>
                                                                </fieldset>
                                                            </div>
    
                                                            {/* <div className='col-md-6'>
                                                                <fieldset className="">
                                                                    <div className={errors.password ? "material error" : "material"}>
                                                                        <InputField style={styles.disabledButton} type="number" name="yodha_code" id="yodha_code" label="Yodha Code" placeholder=" " value={login_detail.yodha_code} onChange={handleInputChange.bind(null, 'yodha_code', 'text')} required={true} />
                                                                        <i className="ic-password icn-login"></i>
                                                                        <label data-label="Yodha Code*" className="form-label"></label>
                                                                        {errors.yodha_code && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.yodha_code}</span>}
                                                                    </div>
                                                                </fieldset>
                                                            </div>  */}

                                                            <div className='col-md-6'>
                                                                <fieldset className="">
                                                                <div className={errors.password ? "material error" : "material"}>
                                                                    <InputField
                                                                    style={isDisabled ? styles.disabledButton : {}}
                                                                    type="number"
                                                                    name="yodha_code"
                                                                    id="yodha_code"
                                                                    label="Yodha Code"
                                                                    placeholder=" "
                                                                    value={login_detail.yodha_code}
                                                                    onChange={handleInputChange.bind(null, 'yodha_code', 'text')}
                                                                    required={true}
                                                                    disabled={isDisabled}
                                                                    />
                                                                    <i className="ic-password icn-login"></i>
                                                                    <label data-label="Yodha Code*" className="form-label"></label>
                                                                    {errors.yodha_code && (
                                                                    <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>
                                                                        {errors.yodha_code}
                                                                    </span>
                                                                    )}
                                                                </div>
                                                                </fieldset>
                                                            </div>
                                                            
                                                            <div className='col-md-6'>
                                                                <fieldset className="">
                                                                    <div className={errors.email ? "material " : "material"}>
                                                                        <InputField type="email" name="email" id="email" label="Email" placeholder=" " value={login_detail.email} onChange={handleInputChange.bind(null, 'email', 'text')} />
                                                                        <i className="ic-email1 icn-login"></i>
                                                                        <label data-label="Email" className="form-label"></label>
                                                                        {errors.email && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.email}</span>}
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                            
                                                            {/* <div className='col-md-6'>
                                                                <fieldset className="">
                                                                    <div className={errors.pan ? "material error" : "material"}>
                                                                        <InputField type="text" maxLength={10} name="pan" id="pan" label="Email" placeholder=" " value={login_detail.pan} onChange={handleInputChange.bind(null, 'pan', 'text')} />
                                                                        <i className="ic-cd-Dealer2 icn-login"></i>
                                                                        <label data-label="PAN Card" className="form-label"></label>
                                                                        {errors.pan && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.pan}</span>}
                                                                    </div>
                                                                </fieldset>
                                                            </div> */}

                                                                    <div className='col-md-6'>
                                                                        <Select
                                                                            id="subsource_id"
                                                                            styles={customStyles}
                                                                            options={getSubSource}
                                                                            onChange={handleLeadOptionChange.bind(this, 'subsource_id')}
                                                                            name="subsource_id"
                                                                            placeholder="Sub Source*"
                                                                            value={login_detail.subsource}
                                                                            getOptionLabel={({ subsource }) => subsource}
                                                                        />
                                                                        {errors.subsource_id && <span className="erroe-txt" style={{ color: "red",marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.subsource_id}</span>}
                                                            </div>
                                                        
                                                            <div className='col-md-6 newregistration'>
                                                                <fieldset >
                                                                    <div>
                                                                        <Select
                                                                            id="profession_id"
                                                                            options={getProfessionlist}
                                                                            onChange={handleLeadOptionChange.bind(this, 'profession_id')}
                                                                            name="profession_id"
                                                                            placeholder="Profession List"
                                                                            value={getProfessionlist && getProfessionlist.filter(({ id }) => id == login_detail.profession_id)}
                                                                            getOptionLabel={({ profession_name }) => profession_name}
                                                                            getOptionValue={({ id }) => id}
                                                                        />
                                                                    </div>
                                                                </fieldset>
                                                            </div>
                                                        
                                                            <div className='col-md-6 newregistration'>
                                                                <fieldset >
                                                                
                                                                </fieldset>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                                                                    <button type="button" onClick={handleSendOTP.bind(this)} className="btn-primary" >Submit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
    
                                                    {showOtp &&
                                                        <div>
                                                            <fieldset className="">
                                                                <div className={errors.otp ? "material error" : "material"}>
                                                                    <InputField type="text" name="otp" id="otp" maxLength={6} label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                    <i className="ic-otp icn-login">
                                                                        <i className="path1"></i>
                                                                        <i className="path2"></i>
                                                                        <i className="path3"></i>
                                                                        <i className="path4"></i>
                                                                    </i>
                                                                    <label data-label="OTP*" className="form-label"></label>
                                                                    {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                    {otpTimer > 0 ?
                                                                        <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                        <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                    }
    
                                                                    <div className="otp-success-icon" style={{ display: "none" }} >
                                                                        <i className="ic-check"></i>
                                                                    </div>
    
                                                                </div>
                                                            </fieldset>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                {showOtp && <button type="button" className="btn-primary" onClick={() => { handleRegistration(true) }}>Submit</button>}
                                                            </div>
                                                        </div>}
                                                </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                        ) : (
                                            <Tab.Content>
                                                <Tab.Pane eventKey="leads">
                                                    <div className="login-form">
                                                            <div className='row'>
                                                                <div className='col-md-6'>
                                                                    <fieldset className="">
                                                                        <div className={errors.login_username ? "material error" : "material"}>
                                                                            <InputField type="text" name="lead_name" id="lead_name" label="Name" placeholder=" " value={login_detail.lead_name} onChange={handleInputChange.bind(null, 'lead_name', 'text')} required={true} />
                                                                            <i className="ic-person icn-login"></i>
                                                                            <label data-label="Name*" className="form-label"></label>
                                                                            {errors.lead_name && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_name}</span>}
                                                                        </div>
                                                                    </fieldset>
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <fieldset className="">
                                                                        <div className={errors.password ? "material error" : "material"}>
                                                                            <InputField type="text" name="lead_mobile" id="lead_mobile" label="lead_mobile" placeholder=" " maxLength={10} value={login_detail.lead_mobile} onChange={handleInputChange.bind(null, 'lead_mobile', 'text')} required={true} />
                                                                            <i className="ic-mobile icn-login"></i>
                                                                            <label data-label="Mobile*" className="form-label"></label>
                                                                            {errors.lead_mobile && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_mobile}</span>}
                                                                        </div>
                                                                    </fieldset>
                                                                </div>

                                                                {/* <div className='col-md-6'>
                                                                    <fieldset className="">
                                                                        <div className={errors.password ? "material error" : "material"}>
                                                                            <InputField 
                                                                            type="number" 
                                                                            name="lead_yodha_code" 
                                                                            id="lead_yodha_code" 
                                                                            label="Yodha Code" 
                                                                            placeholder=" " value={login_detail.lead_yodha_code} 
                                                                            onChange={handleInputChange.bind(null, 'lead_yodha_code', 'text')} 
                                                                            required={true} />
                                                                            <i className="ic-password icn-login"></i>
                                                                            <label data-label="Yodha Code*" className="form-label"></label>
                                                                            {errors.lead_yodha_code && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_yodha_code}</span>}
                                                                        </div>
                                                                    </fieldset>
                                                                </div> */}
                                                                
                                                                <div className='col-md-6'>
                                                                    <fieldset className="">
                                                                    <div className={errors.password ? "material error" : "material"}>
                                                                        <InputField
                                                                        style={isDisabled ? styles.disabledButton : {}}
                                                                        type="number"
                                                                        name="lead_yodha_code"
                                                                        id="lead_yodha_code"
                                                                        label="Yodha Code"
                                                                        placeholder=" "
                                                                        value={login_detail?.lead_yodha_code ?? ''}
                                                                        onChange={handleInputChange.bind(null, 'lead_yodha_code', 'text')}
                                                                        required={true}
                                                                        disabled={isDisabled}
                                                                        />
                                                                        <i className="ic-password icn-login"></i>
                                                                        <label data-label="Yodha Code*" className="form-label"></label>
                                                                        {errors.lead_yodha_code && (
                                                                        <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>
                                                                            {errors.lead_yodha_code}
                                                                        </span>
                                                                        )}
                                                                    </div>
                                                                    </fieldset>
                                                                </div>
                                                                {/* <div className='col-md-6'>
                                                                    <fieldset className="">
                                                                        <div className={errors.password ? "material error" : "material"}>
                                                                            <InputField type="text" name="lead_subsource" id="lead_subsource" label="Subsource" placeholder=" " value={login_detail.lead_subsource} onChange={handleInputChange.bind(null, 'lead_subsource', 'text')} required={true} />
                                                                            <i className="ic-password icn-login"></i>
                                                                            <label data-label="Yodha lead_subsource*" className="form-label"></label>
                                                                            {errors.lead_yodha_code && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_yodha_code}</span>}
                                                                        </div>
                                                                    </fieldset>
                                                                </div> */}
                                                                <div className='col-md-6'>
                                                                            <Select
                                                                                id="subsource_id"
                                                                                styles={customStyles}
                                                                                options={getSubSource}
                                                                                onChange={handleInputChange.bind(this, 'subsource_id', 'text')}
                                                                                name="subsource_id"
                                                                                placeholder="Sub Source*"
                                                                                value={login_detail.subsource}
                                                                                getOptionLabel={({ subsource }) => subsource}
                                                                            />
                                                                            {errors.subsource_id && <span className="erroe-txt" style={{ color: "red",marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.subsource_id}</span>}
                                                                </div>
                                                                <div className='col-md-6'>
                                                                    <fieldset className="">
                                                                        <div className={errors.email ? "material " : "material"}>
                                                                            <InputField type="email" name="lead_email" id="lead_email" label="Email" placeholder=" " value={login_detail.lead_email} onChange={handleInputChange.bind(null, 'lead_email', 'text')} />
                                                                            <i className="ic-email1 icn-login"></i>
                                                                            <label data-label="Email" className="form-label"></label>
                                                                            {errors.lead_email && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_email}</span>}
                                                                        </div>
                                                                    </fieldset>
                                                                </div>

                                                                <div className='col-md-6'>
                                                                    <Select
                                                                        id="city_id"
                                                                        options={cityList}
                                                                        onChange={handleCityChange}
                                                                        name="city_id"
                                                                        placeholder={"Select city..."}
                                                                        getOptionLabel={(option) => option.name}
                                                                        getOptionValue={(option) => option.id}
                                                                        value={cityList.find(
                                                                        (option) => option.id === login_detail?.city_id
                                                                        )}
                                                                    />
                                                                </div>

                                                                <div className='col-md-12'>
                                                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                                                                        <button type="button" onClick={handleNewLeads.bind(this)} className="btn-primary" >Submit & Invite</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        {showOtp && activeTab!=='leads' &&
                                                            <div>
                                                                <fieldset className="">
                                                                    <div className={errors.otp ? "material error" : "material"}>
                                                                        <InputField type="text" name="otp" id="otp" maxLength={6} label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                        <i className="ic-otp icn-login">
                                                                            <i className="path1"></i>
                                                                            <i className="path2"></i>
                                                                            <i className="path3"></i>
                                                                            <i className="path4"></i>
                                                                        </i>
                                                                        <label data-label="OTP*" className="form-label"></label>
                                                                        {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                        {otpTimer > 0 ?
                                                                            <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                            <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                        }

                                                                        <div className="otp-success-icon" style={{ display: "none" }} >
                                                                            <i className="ic-check"></i>
                                                                        </div>

                                                                    </div>
                                                                </fieldset>
                                                                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                    {showOtp && <button type="button" className="btn-primary" onClick={() => { handleRegistration(true) }}>Submit</button>}
                                                                </div>
                                                            </div>
                                                        }

                                                    </div>
                                                </Tab.Pane>
                                            </Tab.Content>
                                            )}
                                        </div>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        )
    }


    const renderMultiForm = () => {
        return (
            <div className="container-fluid">
            {loading ? <Loader /> : null}
            
            <div className="row">
                <div className='login-main'>
                    <div className="login-outer">
                        <div className="register">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "40px" }}>
                                <img src='https://storage.googleapis.com/ambak/logo/ambak_logo.svg' alt='logo' width={230} height={100} />
                            </div>
                            <div className="login-heading">
                                <h2 style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: "30px" }}>{getHeading()}</h2>
                            </div>
                            <Tab.Container id="" activeKey={activeTab} onSelect={handleTabChange}>
                                <div className="login-tab">
                                    <div className='tab-bg-new'>
                                        <Nav variant="tabs" className="tab-list">
                                            <Nav.Item>
                                                <Nav.Link eventKey="partner">Registration</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                            <Nav.Link eventKey="leads">Partner Leads</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="partner">
                                            <div className="login-form">
                                            
                                                {!showOtp &&
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.login_username ? "material error" : "material"}>
                                                                    <InputField type="text" name="name" id="name" label="Name" placeholder=" " value={login_detail.name} onChange={handleInputChange.bind(null, 'name', 'text')} required={true} />
                                                                    <i className="ic-person icn-login"></i>
                                                                    <label data-label="Name*" className="form-label"></label>
                                                                    {errors.name && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.name}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.password ? "material error" : "material"}>
                                                                    <InputField type="text" name="mobile" id="mobile" label="Mobile" placeholder=" " maxLength={10} value={login_detail.mobile} onChange={handleInputChange.bind(null, 'mobile', 'text')} required={true} />
                                                                    <i className="ic-mobile icn-login"></i>
                                                                    <label data-label="Mobile*" className="form-label"></label>
                                                                    {errors.mobile && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.mobile}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.password ? "material error" : "material"}>
                                                                    <InputField type="number" name="yodha_code" id="yodha_code" label="Yodha Code" placeholder=" " value={login_detail.yodha_code} onChange={handleInputChange.bind(null, 'yodha_code', 'text')} required={true} />
                                                                    <i className="ic-password icn-login"></i>
                                                                    <label data-label="Yodha Code*" className="form-label"></label>
                                                                    {errors.yodha_code && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.yodha_code}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div> 
                                                        
                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.email ? "material " : "material"}>
                                                                    <InputField type="email" name="email" id="email" label="Email" placeholder=" " value={login_detail.email} onChange={handleInputChange.bind(null, 'email', 'text')} />
                                                                    <i className="ic-email1 icn-login"></i>
                                                                    <label data-label="Email" className="form-label"></label>
                                                                    {errors.email && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.email}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        
                                                        {/* <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.pan ? "material error" : "material"}>
                                                                    <InputField type="text" maxLength={10} name="pan" id="pan" label="Email" placeholder=" " value={login_detail.pan} onChange={handleInputChange.bind(null, 'pan', 'text')} />
                                                                    <i className="ic-cd-Dealer2 icn-login"></i>
                                                                    <label data-label="PAN Card" className="form-label"></label>
                                                                    {errors.pan && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.pan}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div> */}
                                                      <div className='col-md-6'>
                                                                    <Select
                                                                        id="subsource_id"
                                                                        styles={customStyles}
                                                                        options={getSubSource}
                                                                        onChange={handleLeadOptionChange.bind(this, 'subsource_id')}
                                                                        name="subsource_id"
                                                                        placeholder="Sub Source*"
                                                                        value={login_detail.subsource}
                                                                        getOptionLabel={({ subsource }) => subsource}
                                                                    />
                                                                    {errors.subsource_id && <span className="erroe-txt" style={{ color: "red",marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.subsource_id}</span>}
                                                        </div>
                                                        <div className='col-md-6 newregistration'>
                                                            <fieldset >
                                                                <div>
                                                                    <Select
                                                                        id="profession_id"
                                                                        options={getProfessionlist}
                                                                        onChange={handleLeadOptionChange.bind(this, 'profession_id')}
                                                                        name="profession_id"
                                                                        placeholder="Profession List"
                                                                        value={getProfessionlist && getProfessionlist.filter(({ id }) => id == login_detail.profession_id)}
                                                                        getOptionLabel={({ profession_name }) => profession_name}
                                                                        getOptionValue={({ id }) => id}
                                                                    />
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                       
                                                        <div className='col-md-6 newregistration'>
                                                            <fieldset >
                                                               
                                                            </fieldset>
                                                        </div>
                                                        <div className='col-md-12'>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                                                                <button type="button" onClick={handleSendOTP.bind(this)} className="btn-primary" >Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }

                                                {showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" maxLength={6} label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP*" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            {showOtp && <button type="button" className="btn-primary" onClick={() => { handleRegistration(true) }}>Submit</button>}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>

                                    <Tab.Content>
                                        <Tab.Pane eventKey="leads">
                                            <div className="login-form">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.login_username ? "material error" : "material"}>
                                                                    <InputField type="text" name="lead_name" id="lead_name" label="Name" placeholder=" " value={login_detail.lead_name} onChange={handleInputChange.bind(null, 'lead_name', 'text')} required={true} />
                                                                    <i className="ic-person icn-login"></i>
                                                                    <label data-label="Name*" className="form-label"></label>
                                                                    {errors.lead_name && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_name}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.password ? "material error" : "material"}>
                                                                    <InputField type="text" name="lead_mobile" id="lead_mobile" label="lead_mobile" placeholder=" " maxLength={10} value={login_detail.lead_mobile} onChange={handleInputChange.bind(null, 'lead_mobile', 'text')} required={true} />
                                                                    <i className="ic-mobile icn-login"></i>
                                                                    <label data-label="Mobile*" className="form-label"></label>
                                                                    {errors.lead_mobile && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_mobile}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.password ? "material error" : "material"}>
                                                                    <InputField type="number" name="lead_yodha_code" id="lead_yodha_code" label="Yodha Code" placeholder=" " value={login_detail.lead_yodha_code} onChange={handleInputChange.bind(null, 'lead_yodha_code', 'text')} required={true} />
                                                                    <i className="ic-password icn-login"></i>
                                                                    <label data-label="Yodha Code*" className="form-label"></label>
                                                                    {errors.lead_yodha_code && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_yodha_code}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        {/* <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.password ? "material error" : "material"}>
                                                                    <InputField type="text" name="lead_subsource" id="lead_subsource" label="Subsource" placeholder=" " value={login_detail.lead_subsource} onChange={handleInputChange.bind(null, 'lead_subsource', 'text')} required={true} />
                                                                    <i className="ic-password icn-login"></i>
                                                                    <label data-label="Yodha lead_subsource*" className="form-label"></label>
                                                                    {errors.lead_yodha_code && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_yodha_code}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div> */}
                                                        <div className='col-md-6'>
                                                                    <Select
                                                                        id="subsource_id"
                                                                        styles={customStyles}
                                                                        options={getSubSource}
                                                                        onChange={handleInputChange.bind(this, 'subsource_id', 'text')}
                                                                        name="subsource_id"
                                                                        placeholder="Sub Source*"
                                                                        value={login_detail.subsource}
                                                                        getOptionLabel={({ subsource }) => subsource}
                                                                    />
                                                                    {errors.subsource_id && <span className="erroe-txt" style={{ color: "red",marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.subsource_id}</span>}
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <fieldset className="">
                                                                <div className={errors.email ? "material " : "material"}>
                                                                    <InputField type="email" name="lead_email" id="lead_email" label="Email" placeholder=" " value={login_detail.lead_email} onChange={handleInputChange.bind(null, 'lead_email', 'text')} />
                                                                    <i className="ic-email1 icn-login"></i>
                                                                    <label data-label="Email" className="form-label"></label>
                                                                    {errors.lead_email && <span className="erroe-txt" style={{ color: "red", display: "block", marginTop: "-16px", fontWeight: "normal", fontSize: "10px", lineHeight: "15px", marginBottom: "20px" }}>{errors.lead_email}</span>}
                                                                </div>
                                                            </fieldset>
                                                        </div>

                                                        <div className='col-md-12'>
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                                                                <button type="button" onClick={handleNewLeads.bind(this)} className="btn-primary" >Submit</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                {showOtp && activeTab!=='leads' &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" maxLength={6} label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP*" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            {showOtp && <button type="button" className="btn-primary" onClick={() => { handleRegistration(true) }}>Submit</button>}
                                                        </div>
                                                    </div>
                                                }

                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }


    return isSingleForm ? renderSingleForm() : renderMultiForm()
}

const styles = {
    disabledButton: {
        cursor: "not-allowed",
        backgroundColor: "#e9ecef",
      opacity: 0.5,
    },
  };

export default Register;
