import React, { useEffect, useState, useRef } from "react";
import MasterService from "../../services/MasterService";
import { Navigate } from "react-router-dom";
import DateFormate from "dateformat";
import {
  executeGraphQLMutation,
  executeGraphQLQuery
} from "../../common/partnerExecuteGraphQLQuery";
import {executeGraphQLQueryFinex} from "../../common/executeGraphQLQueryFinex.js"
import { toast } from "react-toastify";
import {
  PAYOUT__LIST_WITH_FILTERS,
  PAYOUT_LEADS_COUNTS,
  PARTNER_DISBURSAL_LIST,
  CREATE_INVOICE,
  INVOICE_LISTING,
  INVOICE_LISTING_DATA,
  GET_GST_MATCHED_LIST,
  GET_USER_LIST_PAYOUT,
  GST_INVOICE_LISTING,
} from "../../services/payout.gql.js";
import secureStorage from "./../../config/encrypt";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  SCHEME_LIST,
  FINEX_MASTER_LIST,
  FINEX_BANK_LIST,
} from "../../services/scheme.gql";
import Pagination from "../elements/pagination";
import {
  USERS_FOR_VIEW_AMBAK_LEADS,
  IS_USER_ACCESS_PAGE,
} from "../../config/constants";
import Loader from "../elements/Loader.js";
import { useApolloClient } from "@apollo/client";
import GstTableContent from "./GstTableContent.js";
const moment = require("moment");

const PayoutListing = (props) => {
  const client = useApolloClient();
  const filter_params = props.filter_params;
  const DataFIlter = props.DataFIlter;

  const filterParams = {};
  filterParams[filter_params.filter_params] = isNaN(+filter_params.app_id)
    ? filter_params.app_id
    : +filter_params.app_id;

  let Status = props.state.status;
  const partner_id = props.filterVariables.partner_id;
  const app_id = props.filterVariables.app_id;
  const filterOptions = props.filterOptions;
  let counter = props.counter;

  let buttonClick = props.buttonClick;

  const [filterData, setFilterData] = useState([]);
  const [page_no, setPageNo] = useState(1);
  const [partnerListing, setPartnerData] = useState([]);
  const [invoiceListData, setInvoiceListData] = useState([]);
  const [invoicedata, setInvoicedata] = useState([]);
  const [payoutListing, setPayoutListing] = useState([]);
  const [paginationdata, setPaginations] = useState({});
  const [eligiblepaginationdata, seteligiblepaginationdata] = useState(0);
  const [pageloading, setpageloading] = useState(true);
  const [selectedRow, setSelectedRow] = useState(null);
  const [showEye, setShowEye] = useState(null);
  const [selected_partner_id, setSelectedPartnerID] = useState(0);
  const [payoutListingShow, setPayoutListingShow] = useState(null);
  const [payoutRow, setPayoutRow] = useState(null);
  const [schemeList, setschemeList] = useState([]);
  const [payoutApprovalIds, setPayoutIdsApproval] = useState([]);
  const [showSave, setShowSave] = useState(false);
  const [leads_counts, setLeadsCounts] = useState([]);
  const [totalLeads, setTotalLeads] = useState(0);
  const [productType, setProductType] = useState([]);
  const [financers, setFinancers] = useState([]);
  const [fulfillmenttypes, setFulfillmentTypes] = useState([]);
  const [payout_comments, setPayoutComments] = useState("");
  const [selected_amount, setSelectedAmount] = useState(0);
  const [user_list, setUserList] = useState([]);
  const [financersBanks, setFinancersPersonal] = useState([]);
  const [visible, SetVisible] = useState(true);
  const [originalData, setOriginalData] = useState([]);
  const [gstStatus, setGSTStatus] = useState("matched");
  const [gstStatusData, setGstStatusData] = useState([]);
  const [gstStatusDataPagination, setGstStatusDataPagination] = useState({});
  const [gstStatusDataPageNo, setGstStatusDataPageNo] = useState(1);


  let approve_invoice =  IS_USER_ACCESS_PAGE("payout", "approve_invoice")?.is_edit_access;
console.log("filter_params",filter_params)

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Navigate to={"/login"} />;
    }
  };

  const afterLoginSetState = (loginResponse) => {
    setpageloading(false);
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return <Navigate to={"/"} />;
    }
  };

  const handleRowClick = (index) => {
    setSelectedRow(selectedRow === index ? null : index);
    setPayoutComments("");
  };

  const handlePayoutListing = (index, partnerid) => {
    setShowEye(selectedRow === index ? null : index);
    if (payoutApprovalIds && payoutApprovalIds.length > 0) {
      if (
        window.confirm(
          "Already added payouts for invoice, Do you want to delete old invoice data"
        )
      ) {
        setPayoutIdsApproval([]);
        setSelectedAmount(0);
      } else {
        return false;
      }
    }
    setPayoutListingShow(payoutListingShow === index ? null : index);

    setPayoutComments("");
    setSelectedPartnerID(partnerid);
    if (partnerid) {
      getPayoutListWithFilters("", partnerid, 1);
    }

    getInvoiceData("", partnerid, 1);
  };

  const handlePayoutRowClick = (index) => {
    setPayoutRow(payoutRow === index ? null : index);
    setPayoutComments("");
  };

  const getInvoiceList = async (status, partner_id, pageNumber) => {
   // alert(pageNumber)
  
    if (
      Status === "invoice_created" ||
      Status === "payment_pending" ||
      Status === "paid" ||
      Status === "gst_payment_pending" ||
      Status === "gst_paid"
    ) {
      setpageloading(true);
      getDealerList()
      const payout_filters = {
        partner_id: partner_id,
        is_paging: "no",
        status: Status,
        source: "web",
      };
    
      if (filter_params.filter_params === "tranch_id") {
        payout_filters["tranche_id"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "partner_name") {
        payout_filters["partner_name"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "account_number") {
        payout_filters["account_number"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "utr_no") {
        payout_filters["utr_no"] = filter_params.app_id;
      }

      if (filter_params.filter_params === "lead_id") {
        payout_filters["lead_id"] = +filter_params.app_id;
      }

      if (filter_params.filter_params === "payout_id") {
        payout_filters["payout_id"] = +filter_params.app_id;
      }

      if (filter_params.filter_params === "partner_code") {
        payout_filters["partner_ids"] = [+filter_params.app_id];
      }
      if (filter_params.filter_params === "Customer_Name") {
        payout_filters["customer_name"] = filter_params.app_id;
      }
   
      if (filter_params.invoice_creation_date && filter_params.invoice_creation_date!="") {
        payout_filters["invoice_creation_date"] = filter_params.invoice_creation_date;
      }
      
      if (filter_params.signing_date  && filter_params.signing_date!="") {
        payout_filters["signing_date"] = filter_params.signing_date;
      }

      if (filter_params.paid_date  && filter_params.paid_date!="") {
        payout_filters["paid_date"] = filter_params.paid_date;
      }

      if (filter_params.filter_params === "Customer_Name") {
        payout_filters["customer_name"] = filter_params.app_id;
      }
      if (filter_params.filter_params === "app_id") {
        payout_filters["app_id"] = +filter_params.app_id;
      }
     
      if (filter_params.filter_params === "Scheme_ID") {
        payout_filters["scheme_id"] = +filter_params.app_id;
      }

      if (filter_params.disbursal_date_from !== "") {
        payout_filters["disbursal_date_from"] =
          filter_params.disbursal_date_from;
      }
      if (filter_params.disbursal_date_to !== "") {
        payout_filters["disbursal_date_to"] = filter_params.disbursal_date_to;
      }
      if (filter_params.product_type !== "") {
        payout_filters["product_type"] = filter_params.product_type;
      }
      // if (filter_params.Select_Scheme !== "") {
      //   payout_filters["scheme_id"] = filter_params.scheme_id;
      // }

      if (DataFIlter.financier_id && DataFIlter.financier_id.length > 0) {
        payout_filters["financersids"] = DataFIlter.financier_id;
      }
      if (DataFIlter.partnerName && DataFIlter.partnerName.length > 0) {
        payout_filters["partner_ids"] = DataFIlter.partnerName;
      }

      if (status && status != "") payout_filters.paid_status = Status;

      const page_no = pageNumber ? pageNumber : 1
      delete payout_filters.is_paging;
      const variables = {
        filter_params: payout_filters,
        page_no: page_no,
      };
      try {
        let result = "";
        if(Status === 'gst_payment_pending') {  
           result = await executeGraphQLMutation(
            GST_INVOICE_LISTING(page_no),
            variables,
            page_no
          );
          if (result.data.gst_pending_list.length == 0) {
            setPayoutListing([]);
            setPaginations({});
            setpageloading(false);
  
            setInvoiceListData([]); //t
          } else {
            const invoices = result.data.gst_pending_list[0]?.invoices;
            setPayoutListing(invoices);
            setPaginations(result.data.gst_pending_list[0].pagination);
            setInvoiceListData(invoices);
          }
          if (result) {
            if (result.data.gst_pending_list && result.data.gst_pending_list.length > 0) {
              const disbursals = result.data.gst_pending_list[0].invoice;
  
              setPayoutListing(disbursals);
              setpageloading(false);
            }
          } else {
            setInvoiceListData([]);
            setPayoutListing([]);
            setPaginations({});
            setpageloading(false);
          }
    //For Status => invoice_created,payment_pending,paid,gst_paid
        } else {
         result = await executeGraphQLMutation(
          INVOICE_LISTING(page_no),
          variables,
          page_no
        );
      
        if (result.data.invoice_list.length == 0) {
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);

          setInvoiceListData([]); //t
        } else {
          const invoices = result.data.invoice_list[0]?.invoices;
          setPayoutListing(invoices);
          setPaginations(result.data.invoice_list[0].pagination);
          setInvoiceListData(invoices);
        }
        if (result) {
          if (result.data.invoice_list && result.data.invoice_list.length > 0) {
            const disbursals = result.data.invoice_list[0].invoice;

            setPayoutListing(disbursals);
            setpageloading(false);
          }
        } else {
          setInvoiceListData([]);
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
      }  
      } catch (error) {
        toast.error("Failed");
        console.error("error", error);
      }
    }
  };

  const getInvoiceData = async (status, partner_id, pageNumber) => {
    if (
      Status === "invoice_created" ||
      Status === "payment_pending" ||
      Status === "paid" ||
      Status === "gst_payment_pending" ||
      Status === "gst_paid"
    ) {
      setpageloading(true);

      try {
        const result = await executeGraphQLMutation(
          INVOICE_LISTING_DATA(partner_id)
        );
        if (result.data.invoice_payouts.length == 0) {
          setpageloading(false);
        } else {
          const invoices = result.data.invoice_payouts;

          setInvoicedata(invoices);
          setOriginalData(invoices);
          setpageloading(false);
        }
      } catch (error) {
        toast.error("Failed");
        console.error("error", error);
      }
    }
  };

  const getPayoutListWithFilters = async (status, partner_id, pageNumber) => {
    if (Status === "pending") {
      setpageloading(true);
      const payout_filters = {
        partner_id: partner_id,
        is_paging: "no",
        paid_status: "pending",
        source: "web",
      };
      const variables = {
        filter_params: payout_filters,
        page_no: pageNumber ? pageNumber : 1,
      };
      try {
        const result = await executeGraphQLMutation(
          PAYOUT__LIST_WITH_FILTERS,
          variables,
          pageNumber
        );
        if (result.data.getDisbursalLeads.length == 0) {
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
        if (result) {
          if (
            result.data.getDisbursalLeads &&
            result.data.getDisbursalLeads.length > 0
          ) {
            const disbursals = result.data.getDisbursalLeads[0].disbursals;
            setPayoutListing(disbursals);
          //  console.log("disbursals",)
            setpageloading(false);
          }
        } else {
          setPartnerData([]);
          setPayoutListing([]);
          setPaginations({});
          setpageloading(false);
        }
      } catch (error) {
        toast.error("Failed");
        console.error(error);
      }
    }
  };

  const getPartnersDisbursalList = async (
    status = "pending",
    current_page = 1
  ) => {
   
    
    setpageloading(true);
    const payout_filters = { ...filterData };
    if (filter_params.filter_params === "lead_id") {
      payout_filters["lead_id"] = +filter_params.app_id;
    }

    if (filter_params.filter_params === "tranch_id") {
      payout_filters["tranche_id"] = filter_params.app_id;
    }
    if (filter_params.filter_params === "partner_name") {
      payout_filters["partner_name"] = filter_params.app_id;
    }
    
    if (filter_params.filter_params === "partner_code") {
      payout_filters["partner_ids"] = typeof filterParams.partner_code ==='number' ? [filterParams.partner_code] : filter_params.app_id;
    }
    if (filter_params.filter_params === "Customer_Name") {
      payout_filters["customer_name"] = filter_params.app_id;
    }
    if (filter_params.filter_params === "Scheme_ID") {
      payout_filters["scheme_id"] = +filter_params.app_id;
    }
    if (filter_params.disbursal_date_from !== "") {
      payout_filters["disbursal_date_from"] = filter_params.disbursal_date_from;
    }
    if (filter_params.disbursal_date_to !== "") {
      payout_filters["disbursal_date_to"] = filter_params.disbursal_date_to;
    }
    if (filter_params.product_type !== "") {
      payout_filters["product_type"] = filter_params.product_type;
    }
    if (filter_params.Select_Scheme !== "") {
      payout_filters["scheme_id"] = filter_params.scheme_id;
    }

    if (filter_params.filter_params === "payout_id") {
      payout_filters["payout_id"] = +filter_params.app_id;
    }

    if (filter_params.filter_params === "app_id") {
      payout_filters["app_id"] = filter_params.app_id.toString();
    }

    if (DataFIlter.financier_id && DataFIlter.financier_id.length > 0) {
      payout_filters["financersids"] = DataFIlter.financier_id;
    }
    if (DataFIlter.partnerName && DataFIlter.partnerName.length > 0) {
      payout_filters["partner_ids"] = DataFIlter.partnerName;
    }

    // if (status && status != "")
    //     payout_filters.paid_status = "pending"

    payout_filters.status = !Status ? Status : Status;

    const pageNumber = current_page ? current_page : page_no;
    if (
      payout_filters.product_type_ids &&
      payout_filters.product_type_ids.length > 0
    ) {
      payout_filters.product_type = payout_filters.product_type_ids[0];
    }
    if (status && status != "") {
      payout_filters.status = status;
    }
    if (payout_filters.financier_id && payout_filters.financier_id.length > 0) {
      payout_filters.financersids = payout_filters.financier_id;
    }
    delete payout_filters.schemefinancers;
    delete payout_filters.product_type_ids;
    delete payout_filters.financier_id;
    const variables = {
      filter_params: payout_filters,
    };

    try {
      const result = await executeGraphQLMutation(
        PARTNER_DISBURSAL_LIST(page_no),
        variables
      );
    console.log("result.data.partner_disbursals",result)
      if (result.data.partner_disbursals.length == 0) {
        setPartnerData([]);
        setPayoutListing([]);
        setPaginations({});
        setpageloading(false);
      }
      if (result) {
        if (
          result.data.partner_disbursals &&
          result.data.partner_disbursals.length > 0
        ) {
          const disbursals = result.data.partner_disbursals[0].disbursals;
          setPartnerData(disbursals);
          seteligiblepaginationdata(
            result.data.partner_disbursals[0].pagination
          );
          setpageloading(false);
        }
      } else {
        setPartnerData([]);
        setPaginations({});
        setpageloading(false);
      }
    } catch (error) {
      console.log("error",error)
    //  toast.error(error);
      setpageloading(false);
    }
  };

  useEffect(() => {
    getDealerList();
    getSchemeList();
    updateReduxData();
    // getPayoutListWithFilters();
    getInvoiceData();
    getFinexMasterData();
    getFinexPersonalBankData();
  }, []);
  useEffect(() => {
    getInvoiceList();
  }, [counter]);
  useEffect(() => {
    getInvoiceList();
    getPartnersDisbursalList("pending",1);
  }, [buttonClick]);

  useEffect(() => {
    if (Object.keys(filterOptions)) {
      const filteredData = originalData.filter(
        (obj) => obj.id == filterOptions.partner_id
      );
      setOriginalData(invoicedata);
      setInvoicedata(filteredData);
    }
  }, [filterOptions]);

  useEffect(() => {
     const pageNo = gstStatusDataPageNo;
    getGstMatchedList(pageNo);
  }, [gstStatusDataPageNo]);

  useEffect(() => {
    setGstStatusDataPageNo(1);
    getGstMatchedList(1);
  }, [gstStatus]);

  const getSchemeList = async () => {
    const partnerArray = [];
    const schemeData = await executeGraphQLQuery(SCHEME_LIST);
    const data = schemeData.data.getAllSchemsList;
    data &&
      data.map((list) => {
        const partnerData = {
          label: list.id,
          value: list.scheme_name,
        };
        partnerArray.push(partnerData);
      });
    setschemeList(partnerArray);
  };

  const getleadsCounts = async () => {
    const leadscounts = await executeGraphQLQuery(PAYOUT_LEADS_COUNTS);
    const data = leadscounts.data.leads_count;

    let total = 0;
    data &&
      data.map((list) => {
        total = total + parseInt(list.counts);
      });
    if (total > 0) {
      setTotalLeads(total);
    }
    setLeadsCounts(data);
  };
  const handleApprovalCheckBox = (
    e,
    payout_id,
    selectedRow,
    amount,
    partner_id
  ) => {
    // if(partner_id!=selected_partner_id){
    //    if(window.confirm("You have already added another partner payouts, Do you want to remove it")){
    //     setPayoutIdsApproval([])
    //     setSelectedAmount(0)
    //     let showsaveValue = payoutApprovalIds.length > 0 ? true : false
    //     setShowSave(showsaveValue)
    //    }
    // }
    let calculated_amount = selected_amount;
    if (e.target.checked == true) {
      payoutApprovalIds.push(payout_id);
      calculated_amount = parseFloat(selected_amount) + parseFloat(amount);
    } else {
      const index = payoutApprovalIds.indexOf(payout_id);
      if (index !== -1) {
        payoutApprovalIds.splice(index, 1);
      }
      calculated_amount = parseFloat(selected_amount) - parseFloat(amount);
    }

    setSelectedAmount(calculated_amount);
    setPayoutIdsApproval(payoutApprovalIds);
    let showsaveValue = payoutApprovalIds.length > 0 ? true : false;
    setShowSave(showsaveValue);
  };

  const createInvoice = async (partner_id) => {
    setpageloading(true);
    const variables = {
      createinvoiceargs: {
        payout_ids: payoutApprovalIds,
        partner_id: partner_id,
      },
    };
    try {
      const result = await executeGraphQLMutation(CREATE_INVOICE, variables);
      if (result && result.data && result.data.create_invoice) {
        setpageloading(false);
        toast.success(result.data.create_invoice || "Success");
        setTimeout(() => {
          window.location.reload(false); // Reload the page after a delay
        }, 1000); // 1-second delay
      } else {
        throw new Error("Invoice creation failed"); // Handle unexpected result structure
      }
    } catch (error) {
      toast.error("Failed to create invoice");
      console.error(error);
      setpageloading(false);
    }
  };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [];
      const producttypedata = [];
      const fulfillmenttypeData = [];
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancers(bankData);
      }
      if (product_type && product_type.length > 0) {
        product_type.map((ptype) => {
          producttypedata.push({ id: ptype.id, name: ptype.label });
        });
        setProductType(producttypedata);
      }
      const fulfillmenttype =
        data.fulfillment_type && data.fulfillment_type.length > 0
          ? data.fulfillment_type
          : [];
      if (fulfillmenttype && fulfillmenttype.length > 0) {
        fulfillmenttype.map((ptype) => {
          fulfillmenttypeData.push({ id: ptype.id, name: ptype.label });
        });
        setFulfillmentTypes(fulfillmenttypeData);
      }
    }
  };

  const getGstMatchedList = async (page_no) => {
    const variables = {
      filter_params: {
        matched_status: gstStatus,
      },
      page_no
    };
    if (gstStatus === "matched") {
      return false;
    }
    executeGraphQLMutation(GET_GST_MATCHED_LIST, variables, client)
      .then((response) => {
        if (response?.data?.gst_matching_list) {
          setGstStatusData(response?.data?.gst_matching_list[0].list);
          setGstStatusDataPagination(response?.data?.gst_matching_list[0].pagination);
        }
      })
      .catch((err) => console.log(err));
  };

  const getFinexPersonalBankData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_BANK_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.bank_pa) {
      const bankData = [];
      const data = masterData.data.bank_pa;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      const product_type =
        data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
        });
        setFinancersPersonal(bankData);
      }
    }
  };


  const getDealerList = async () => {
    let user_list = [], userlist = [];
    setUserList(userlist)
    const response = await executeGraphQLQueryFinex(GET_USER_LIST_PAYOUT).catch(
      (err) => console.log(err)
    );
        if (response && response.data && response.data.get_user_list) {
            user_list = response.data.get_user_list;
            user_list && user_list.map((v) => {
                userlist.push({ value: v.user_id, label: v.name })
                return v;
            })
            console.log("userlist",userlist)
            setUserList(userlist);
        }
  
};

  // const getDealerList = () => {
  //   MasterService.get("account/user/user_list")
  //     .then(function (response) {
  //       if (response.data.status == 200) {
  //         if (response.data.data.length) {
  //           setUserList(response.data.data);
  //         } else {
  //           setUserList([]);
  //         }
  //       }
  //     })
  //     .catch(function (response) {});
  // };

  let role;
  if (secureStorage.getItem("loginUserInfo")) {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    if (loginResponse && loginResponse.data && loginResponse.data.user_data) {
      role = loginResponse.data.user_data.role;
    }
  }

  const onPaginate = (pageNumber) => {
    setPageNo(pageNumber);

    if (Status === "pending") {
      getPartnersDisbursalList("pending" , pageNumber)
    }

    if(Status === "invoice_created" ||
    Status === "payment_pending" ||
    Status === "paid" ){
      getInvoiceList(Status, 0, pageNumber)
    }
    
 
  };

  const handleGstClaimedStatus = (status) => {
    setGSTStatus(status);
  };

  const handleGstDataPageNo = (pageNo) => {
    setGstStatusDataPageNo(pageNo);
  }

  const calculateTotalGst = (partner) => {
    const { cgst_amount, igst_amount, sgst_amount } = partner;
    return cgst_amount + igst_amount + sgst_amount;
  };

  const claimedTotalGst = (partner) => {
    const { cgst, igst, sgst } = partner;
    return cgst + igst + sgst;
  };

  return (
    <>
      {pageloading ? <Loader /> : null}

      {Status === "gst_payment_pending" && (
        <>
          <div className="row">
            <div className="col-sm-8">
              <ul className="list-inline nav-tabs">
                <li
                  className={
                    gstStatus === "matched" ? "tabs-item active" : "tabs-item"
                  }
                  onClick={() => handleGstClaimedStatus("matched")}
                >
                  <div>Matched</div>
                </li>
                <li
                  className={
                    gstStatus === "not matched"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleGstClaimedStatus("not matched")}
                >
                  <div>Not Matched</div>
                </li>
                <li
                  className={
                    gstStatus === "other vendors"
                      ? "tabs-item active"
                      : "tabs-item"
                  }
                  onClick={() => handleGstClaimedStatus("other vendors")}
                >
                  <div>Other Vendors</div>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}

      {Status === "gst_payment_pending" &&
      (gstStatus === "not matched" || gstStatus === "other vendors") ? (
        <>
          <GstTableContent gstStatusData={gstStatusData} gstStatusDataPagination={gstStatusDataPagination} gstStatusDataPageNo={gstStatusDataPageNo} setGstDataPageNo={handleGstDataPageNo} gstStatus = {gstStatus} />
        </>
      ) : (
        <>
          {Status === "invoice_created" ||
          Status === "payment_pending" ||
          Status === "gst_payment_pending" ||
          Status === "paid" || Status === "gst_paid" ? (
            <div className={"clearfix"}>
              <div className="table-responsive">
                <table className="table table-bordered tablefontsize">
                  <thead>
                    <tr>
                      <th>Inv. Id</th>
                      <th>Partner Details</th>
                      <th>Payout Amt</th>
                      <th>Disb Date</th>


                      {Status === "invoice_created" ||
                      Status === "payment_pending" ? (
                        <th>Bank Details</th>
                      ) : Status === "paid" || Status === "gst_payment_pending" || Status === "gst_paid" ? (
                        <th>Payment Details</th>
                      ) : null}

                       {Status === "gst_payment_pending" || Status === "gst_paid" ? (
                        <th>Gst Amt </th>
                     ) : null}

                       {Status === "gst_paid" ? (
                        <th>GST payment details</th>
                     ) : null}

                       {Status === "gst_payment_pending" ? (
                        <th>Gst Flags </th>
                     ) : null}

                      <th>Leads</th>
                      <th>Action</th>
                      
                      {/* <th colSpan={2}></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceListData &&
                      invoiceListData.map((partner, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                {" "}
                                {partner.id}
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Partner Code :{" "}
                                <span> AMK{partner.partner_id}</span>
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                Partner Name :{" "}
                                <span>{partner.partner_name}</span>
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                GSTIN :{" "}
                                <span>{partner.gst_number}</span>
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Total Amount:{partner.payout_amount}
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                PAT Amount : {partner.pat}
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                TDS Aount : {partner.tds}
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Acknowledgment Date:&nbsp;&nbsp;&nbsp;{" "}
                                {partner.created_at
                                  ? DateFormate(
                                      new Date(partner.created_at),
                                      "dS mmm yyyy"
                                    )
                                  : null}
                              </div>
                              {partner.approved_at ? (
                                <div style={{ fontSize: 13, fontWeight: 600 }}>
                                  Approved Date:
                                  {partner.approved_at
                                    ? DateFormate(
                                        new Date(partner.approved_at),
                                        "dS mmm yyyy"
                                      )
                                    : null}
                                </div>
                              ) : null}

                              {partner.paid_date ? (
                                <div style={{ fontSize: 13, fontWeight: 600 }}>
                                  Paid Date:
                                  {partner.paid_date
                                    ? DateFormate(
                                        new Date(partner.paid_date),
                                        "dS mmm yyyy"
                                      )
                                    : null}
                                </div>
                              ) : null}
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                A/C:{" "}
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.bankdetails?.bank_account_no}
                                </span>
                              </div>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                ISFC:
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.bankdetails?.bank_ifsc_code}
                                </span>
                              </div>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                beneficiary Name:
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.bankdetails?.beneficiary_name}
                                </span>
                              </div>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                UTR Number:
                                <span style={{ opacity: 0.6 }}>
                                  {partner?.utr_no}
                                </span>
                              </div>
                            </td>
                            { partner.paid_status === "gst_paid" ? (
                            <td>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Total Amt:
                                <span style={{ opacity: 0.6 }}>
                                {calculateTotalGst(partner)}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Cgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.cgst_amount}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Igst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.igst_amount}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                sgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.sgst_amount}
                                </span>
                               </div>
                              
                            </td>
                            ) : ""}
                             { partner.paid_status === "gst_paid" ? (
                            <td>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               GST amount:
                                <span style={{ opacity: 0.6 }}>
                                {calculateTotalGst(partner)}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               Paid date:
                                <span style={{ opacity: 0.6 }}>
                               
                                {partner.gst_paid_date
                                    ? DateFormate(
                                        new Date(partner.gst_paid_date),
                                        "dS mmm yyyy"
                                      )
                                    : null}
                                </span>
                               </div>

                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               UTR:
                                <span style={{ opacity: 0.6 }}>
                                {partner.gst_utr_no}
                                </span>
                               </div>                              
                              
                            </td>
                            ) : ""}
                               { partner.paid_status === "gst_payment_pending" ? (
                               <td> 
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Cgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.cgst}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Igst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.igst}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                                sgst Amt:
                                <span style={{ opacity: 0.6 }}>
                                {partner.sgst}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               Total Claimed:
                                <span style={{ opacity: 0.6 }}>
                                {claimedTotalGst(partner)}
                                </span>
                               </div>
                               <div style={{ fontSize: 13, fontWeight: 600 }}>
                               Total Calculated:
                                <span style={{ opacity: 0.6 }}>
                                {calculateTotalGst(partner)}
                                </span>
                               </div>                              
                            </td>
                            ) : ""}

                         { partner.paid_status === "gst_payment_pending"  ? (                          
                            <td>
                              <p  style={{ color: "red" }}>{partner.is_reverse_charge && partner.is_reverse_charge >0 ? 'Reverse charges applicable' :"" }</p>
                              <p  style={{ color: "red" }}>{partner.is_itc && partner.is_itc >0 ? 'ITC not available' :"" }</p>
                              <p  style={{ color: "red" }}>{partner.is_claim_date && partner.is_claim_date >0 ? 'Claim date incorrect' :"" }</p>
                              <p  style={{ color: "red" }}>{partner.is_gst_calc && partner.is_gst_calc >0 ? 'GST calculations mismatch' :"" }</p>
                            </td>
                            ) : ""}



                            <td
                              onClick={() =>
                                handlePayoutListing(index, partner.id)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  fontSize: 13,
                                  fontWeight: 600,
                                }}
                              >
                                {partner.total_lead}
                              </div>
                              <i
                                className="ic-keyboard_arrow_down"
                                style={{ fontSize: 24, marginLeft: -8 }}
                              ></i>
                              {/* <img
                            src={require("./../../ruleEngine/assets/images/downarrow.svg")}
                            alt=""
                            onClick={() =>
                              handlePayoutListing(index, partner.id)
                            }
                            style={{ cursor: "pointer" }}
                          /> */}
                            </td>

                            { //Status === "gst_payment_pending" ? null : (
                              <>
                                <td>
                                  <div style={{ display: "flex" }}>
                                    <div className="btn-group btn-group-small mrg-r10">
                                      <div
                                        className="btn btn-default"
                                        title="Mark as View"
                                        onClick={() =>
                                          handlePayoutListing(index, partner.id)
                                        }
                                      >
                                        <i className="ic-remove_red_eyevisibility icons-normal"></i>
                                      </div>
                                    </div>
                                    <div>
                                      <a
                                        target="_blank"
                                        href={
                                          partner.paid_status ==
                                          "invoice_created"
                                            ? partner.pending_invoice_url
                                            : partner.approved_invoice_url
                                        }
                                      >
                                        <div className="btn-group btn-group-small mrg-r10">
                                          <div
                                            className="btn btn-default"
                                            title="Mark as View"
                                          >
                                            <i
                                              className="ic-pdf icons-normal"
                                              style={{
                                                fontSize: 16,
                                                color: "gray",
                                              }}
                                            ></i>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  </div>
                                </td>
                              </>
                           // )
                           }
                          </tr>

                          {selected_partner_id &&
                          selected_partner_id == partner.id &&
                          showEye === index ? (
                            <tr
                              style={{
                                backgroundColor: "rgb(245,245,245)",
                                paddingRight: "40px",
                                paddingLeft: "40px",
                              }}
                            >
                              <td colSpan={8}>
                                <div className="table-responsive">
                                  <table className="table table-bordered tablefontsize">
                                    <thead>
                                      <tr>
                                        <th className="payouttable">
                                          {"Lead Details"}
                                        </th>
                                        <th className="payouttable">
                                          {"Scheme Details"}
                                        </th>
                                        <th className="payouttable">
                                          {"Disb. Amount"}
                                        </th>
                                        <th className="payouttable">
                                          {"Bank"}
                                        </th>
                                        <th className="payouttable">
                                          {"Dist. Date"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout%"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout Details"}
                                        </th>
                                        <th className="payouttable">{"PAT"}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {invoicedata &&
                                        invoicedata.map(
                                          (partnerlead, index) => (
                                            <React.Fragment key={index}>
                                              <tr
                                                onClick={() =>
                                                  handleRowClick(index)
                                                }
                                              >
                                                <td>
                                                  <div>
                                                    ID :{" "}
                                                    <span>
                                                      {
                                                        partnerlead?.payouts
                                                          ?.partnerleads
                                                          ?.lead_id
                                                      }
                                                    </span>
                                                  </div>
                                                  <div>
                                                    Tranche :{" "}
                                                    <span>
                                                      {
                                                        partnerlead?.payouts
                                                          ?.partnerleads
                                                          ?.tranche_id
                                                      }
                                                    </span>
                                                  </div>
                                                  <div>
                                                    App:{" "}
                                                    <span>
                                                      {
                                                        partnerlead?.payouts
                                                          ?.partnerleads?.app_id
                                                      }
                                                    </span>
                                                  </div>
                                                </td>

                                                <td>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Partner Code:
                                                    <span>
                                                      {" "}
                                                      AMK{partner.partner_id}
                                                    </span>
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Partner Name:
                                                    {
                                                      partnerlead?.payouts
                                                        ?.scheme?.scheme_name
                                                    }
                                                  </div>
                                                </td>
                                                <td>
                                                  {
                                                    partnerlead?.payouts
                                                      ?.partnerleads
                                                      ?.disbursal_amount
                                                  }
                                                </td>
                                                <td>
                                                  {financers &&
                                                    financers.length > 0 &&
                                                    partnerlead.id &&
                                                    financers.filter(
                                                      (bank) =>
                                                        bank.id ==
                                                        partnerlead.payouts
                                                          .partnerleads
                                                          .financer_id
                                                    )[0]?.name}
                                                </td>
                                                <td>
                                                  {DateFormate(
                                                    new Date(
                                                      partnerlead.payouts.partnerleads.disbursal_date
                                                    ),
                                                    "dS mmm yyyy"
                                                  )}
                                                </td>
                                                <td>
                                                  <div>
                                                    {" "}
                                                    Payout:{" "}
                                                    {
                                                      partnerlead?.payouts
                                                        ?.payout_per
                                                    }
                                                    %
                                                  </div>
                                                  <div>
                                                    {" "}
                                                    Paid:{" "}
                                                    {
                                                      partnerlead?.payouts
                                                        ?.paid_per
                                                    }
                                                    %
                                                  </div>
                                                </td>
                                                <td>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    Payout:
                                                    {partnerlead?.payout_amount}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    PAT amount:
                                                    {partnerlead?.pat}
                                                  </div>
                                                  <div
                                                    style={{
                                                      fontSize: 13,
                                                      fontWeight: 600,
                                                    }}
                                                  >
                                                    TDS amount:
                                                    {partnerlead?.tds}
                                                  </div>
                                                </td>
                                                <td>
                                                  {partnerlead?.pat ??
                                                    0 -
                                                      (partnerlead?.pat ?? 0) -
                                                      (partnerlead?.tds ?? 0)}
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                          )
                                        )}
                                    </tbody>
                                    {payoutApprovalIds &&
                                    payoutApprovalIds.length > 0 ? (
                                      <tr>
                                        <td colSpan={8}>Total</td>
                                        <td colSpan={2}>{selected_amount}</td>
                                        <td colSpan={2}>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                              createInvoice(partner.partner_id)
                                            }
                                            title="View History"
                                          >
                                            Create Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </React.Fragment>
                      ))}
                    {partnerListing.length === 0 && (
                      <tr>
                        <td colSpan={3}>No payout found!</td>
                      </tr>
                    )}

                    <tr>
                      <td colSpan={3}>
                        {" "}
                        <Pagination
                          pageCount={
                            paginationdata && paginationdata.totalpage
                              ? paginationdata.totalpage
                              : 0
                          }
                          activePage={
                            filterData.page_no ? filterData.page_no : 1
                          }
                          onPaginate={onPaginate}
                        />
                      </td>
                      <td>
                        Total:{" "}
                        {paginationdata && paginationdata.totalrecords
                          ? paginationdata.totalrecords
                          : 0}{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : Status === "pending" ? (
            <div className="clearfix">
              <div className="table-responsive">
                <table className="table table-bordered tablefontsize">
                  <thead>
                    <tr>
                      <th>Partner Details</th>

                      <th>Disb.Details</th>
                      <th>Payable Amount</th>
                      <th>Action</th>
                      {/* <th colSpan={5}></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {partnerListing &&
                      partnerListing.map((partner, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Partner Code :{" "}
                                <span> AMK{partner.partner_id}</span>
                              </div>
                              <div style={{ opacity: 0.6 }}>
                                Partner Name :{" "}
                                <span>{partner.partner_name}</span>
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                Total Amt :{" "}
                                <span>
                                  {new Intl.NumberFormat("en-IN").format(
                                    partner.total_disbursal_amount
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div style={{ fontSize: 13, fontWeight: 600 }}>
                                <span>
                                  {new Intl.NumberFormat("en-IN").format(
                                    partner.total_payout_amount
                                  )}
                                </span>
                                <div style={{ opacity: 0.6 }}>
                                  PAT AMOUNT : <span>{partner.total_pat}</span>
                                </div>
                                <div style={{ opacity: 0.6 }}>
                                  TDS AMOUNT : <span>{partner.total_tds}</span>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div style={{ display: "flex" }}>
                                <div className="btn-group btn-group-small mrg-r10">
                                  <div
                                    className="btn btn-default"
                                    title="Mark as View"
                                    onClick={() =>
                                      handlePayoutListing(
                                        index,
                                        partner.partner_id
                                      )
                                    }
                                  >
                                    <i className="ic-remove_red_eyevisibility icons-normal"></i>
                                  </div>
                                </div>
                              </div>
                              {/* <div
                            className="btn-group btn-group-small mrg-r10"
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button
                              type="button"
                              className="btn btn-default"
                              onClick={() =>
                                handlePayoutListing(index, partner.partner_id)
                              }
                              title="View History"
                            >
                              <i className="ic-remove_red_eyevisibility icons-normal"></i>
                            </button>
                          </div> */}
                            </td>
                          </tr>
                          {selected_partner_id &&
                          selected_partner_id == partner.partner_id &&
                          visible ? (
                            <tr
                              style={{
                                backgroundColor: "rgb(245,245,245)",
                                paddingRight: "40px",
                                paddingLeft: "40px",
                              }}
                            >
                              <td colSpan={4}>
                                <div className="table-responsive" width={100}>
                                  <table className="table table-bordered tablefontsize">
                                    <thead>
                                      <tr>
                                        <th className="payouttable">
                                          {"Lead ID"}
                                        </th>
                                        <th className="payouttable">
                                          {"Tranche ID"}
                                        </th>
                                        <th className="payouttable">
                                          {"Loan ID"}
                                        </th>
                                        <th className="payouttable">
                                          {"Customer Name"}
                                        </th>
                                        <th className="payouttable">
                                          {"Full. Type"}
                                        </th>
                                        <th className="payouttable">
                                          {"Product"}
                                        </th>
                                        <th className="payouttable">
                                          {"Bank"}
                                        </th>
                                        <th className="payouttable">
                                          {"Disbursed"}
                                        </th>
                                        <th className="payouttable">
                                          {"Payout"}
                                        </th>
                                        <th className="payouttable">{"TDS"}</th>
                                        <th className="payouttable">{"PAT"}</th>
                                        <th className="payouttable">
                                          {"Paid"}
                                        </th>
                                        <th className="payouttable">
                                          {"Pending"}
                                        </th>
                                        <th className="payouttable">
                                          {"Disbursed Date"}
                                        </th>
                                        <th className="payouttable">{""}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {payoutListing &&
                                        payoutListing.map(
                                          (partnerlead, index) => (
                                            <React.Fragment key={index}>
                                              <tr
                                                onClick={() =>
                                                  handleRowClick(index)
                                                }
                                              >
                                                <td>{partnerlead.lead_id}</td>
                                                <td>
                                                  {partnerlead.tranche_id}
                                                </td>
                                                <td>{partnerlead.app_id}</td>
                                                <td>
                                                  {partnerlead.customer_name}
                                                </td>
                                                <td>
                                                  {partnerlead.fulfillment_type}
                                                </td>
                                                <td>
                                                  {productType &&
                                                    productType.length > 0 &&
                                                    productType.filter(
                                                      (type) =>
                                                        type.id ==
                                                        partnerlead.product_type
                                                    )[0]?.name}
                                                </td>
                                                <td>
                                                  {financers &&
                                                    financers.length > 0 &&
                                                    partnerlead.financer_id &&
                                                    financers.filter(
                                                      (bank) =>
                                                        bank.id ==
                                                        partnerlead.financer_id
                                                    )[0]?.name}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.disbursal_amount
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.payout_total
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.tds_total
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.pat_total
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.payout_paid
                                                  )}
                                                </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "en-IN"
                                                  ).format(
                                                    partnerlead.payout_pending
                                                  )}
                                                </td>
                                                <td>
                                                  {DateFormate(
                                                    new Date(
                                                      partnerlead.disbursal_date
                                                    ),
                                                    "dS mmm yyyy"
                                                  )}
                                                </td>
                                                <td
                                                  style={{
                                                    fontSize: 20,
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  {selectedRow === index
                                                    ? "-"
                                                    : "+"}
                                                </td>
                                              </tr>
                                              {selectedRow === index ? (
                                                <tr>
                                                  <td colSpan={14}>
                                                    <table className="table table-bordered tablefontsize">
                                                      <thead>
                                                        <tr>
                                                          <th
                                                            onClick={() =>
                                                              handlePayoutRowClick(
                                                                index
                                                              )
                                                            }
                                                            className="payouttable"
                                                            style={{
                                                              fontSize: 20,
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                            {"ID"}{" "}
                                                            {payoutRow === index
                                                              ? "-"
                                                              : "+"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Payout Type"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Date"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Scheme"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Payout Per"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Per."}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"Payout Amount"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"TDS"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"TDS %"}
                                                          </th>
                                                          <th className="payouttable">
                                                            {"PAT"}
                                                          </th>
                                                          <th className="payouttable">
                                                            Paid Status
                                                          </th>
                                                          <th className="payouttable">
                                                            Created By
                                                          </th>
                                                          <th className="payouttable">
                                                            Updated By
                                                          </th>
                                                          <th className="payouttable">
                                                            Approval
                                                          </th>
                                                        </tr>
                                                      </thead>

                                                      <tbody>
                                                        {partnerlead.payouts &&
                                                          partnerlead.payouts
                                                            .length > 0 &&
                                                          partnerlead.payouts.map(
                                                            (payout, index) => (
                                                              <React.Fragment
                                                                key={payout.id}
                                                              >
                                                                <tr
                                                                  key={
                                                                    payout.id
                                                                  }
                                                                >
                                                                  <td>
                                                                    {payout.id}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      payout.payout_type
                                                                    }
                                                                  </td>

                                                                  <td>
                                                                    {DateFormate(
                                                                      new Date(
                                                                        payout.created_at
                                                                      ),
                                                                      "dS mmm yyyy"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {payout.scheme &&
                                                                    payout
                                                                      .scheme
                                                                      .scheme_name
                                                                      ? payout
                                                                          .scheme
                                                                          .scheme_name
                                                                      : ""}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      payout.payout_per
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {payout.paid_per
                                                                      ? payout.paid_per
                                                                      : 100}{" "}
                                                                    %
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      payout.payout_amount
                                                                    }
                                                                  </td>
                                                                  <td>
                                                                    {payout.tds}
                                                                  </td>
                                                                  <td>
                                                                    {
                                                                      payout.tds_per
                                                                    }
                                                                    %
                                                                  </td>
                                                                  <td>
                                                                    {payout.pat}
                                                                  </td>
                                                                  <td>
                                                                    {payout.paid_status.replace(
                                                                      "_",
                                                                      " "
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {payout.created_by &&
                                                                    user_list.length >
                                                                      0
                                                                      ? user_list.filter(
                                                                          (
                                                                            user
                                                                          ) =>
                                                                            user.user_id ==
                                                                            payout.created_by
                                                                        )[0]
                                                                          ?.name
                                                                      : "system"}
                                                                  </td>
                                                                  <td>
                                                                    {payout.updated_by &&
                                                                    user_list.length >
                                                                      0
                                                                      ? user_list.filter(
                                                                          (
                                                                            user
                                                                          ) =>
                                                                            user.user_id ==
                                                                            payout.updated_by
                                                                        )[0]
                                                                          ?.name
                                                                      : ""}
                                                                  </td>
                                                                  <td>
                                                                    {approve_invoice ===
                                                                      1 &&
                                                                      (payout.paid_status ===
                                                                      "pending" ? (
                                                                        <input
                                                                          type="checkbox"
                                                                          name="paid_status"
                                                                          label="Verify"
                                                                          id="paid_status"
                                                                          key="paid_status"
                                                                          value={
                                                                            payout.id
                                                                          }
                                                                          checked={
                                                                            payoutApprovalIds &&
                                                                            payoutApprovalIds.length >
                                                                              0 &&
                                                                            payoutApprovalIds.includes(
                                                                              payout.id
                                                                            )
                                                                          }
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            handleApprovalCheckBox(
                                                                              e,
                                                                              payout.id,
                                                                              selectedRow,
                                                                              payout.pat,
                                                                              partner.partner_id
                                                                            )
                                                                          }
                                                                        />
                                                                      ) : (
                                                                        "-"
                                                                      ))}
                                                                  </td>
                                                                </tr>
                                                              </React.Fragment>
                                                            )
                                                          )}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              ) : (
                                                ""
                                              )}
                                            </React.Fragment>
                                          )
                                        )}
                                    </tbody>
                                    {payoutApprovalIds &&
                                    payoutApprovalIds.length > 0 ? (
                                      <tr>
                                        <td colSpan={8}>Total</td>
                                        <td colSpan={2}>{selected_amount}</td>
                                        <td colSpan={2}>
                                          <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={() =>
                                              createInvoice(partner.partner_id)
                                            }
                                            title="View History"
                                          >
                                            Create Invoice
                                          </button>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </table>
                                </div>
                              </td>
                            </tr>
                          ) : null}
                        </React.Fragment>
                      ))}
                    {!partnerListing ||
                      (partnerListing.length === 0 && (
                        <tr>
                          <td colSpan={3}>No payout found!</td>
                        </tr>
                      ))}
                  </tbody>
                  <tr>
                    <td colSpan={3}>
                      {" "}
                      <Pagination
                        pageCount={
                          eligiblepaginationdata &&
                          eligiblepaginationdata.totalpage
                            ? eligiblepaginationdata.totalpage
                            : 0
                        }
                        activePage={filterData.page_no ? filterData.page_no : 1}
                        onPaginate={onPaginate}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {eligiblepaginationdata &&
                      eligiblepaginationdata.totalrecords
                        ? eligiblepaginationdata.totalrecords
                        : 0}{" "}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(PayoutListing)
);
