import React from "react";
import DateFormate from "dateformat";
import { Tab, Nav } from 'react-bootstrap';
const FollowupHistory = ({historyList,isLoading,t}) => {
    return (
        <div className="comment-wrap">
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          {/* <Nav variant="pills" className="flex-column tab-line nav nav-pills">
            <div className="tab-list">
                <h3 eventKey="first">Followup History</h3>
            </div>
          </Nav> */}

          <div className="modal-body">
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <table>
                  {historyList?.length ? (
                    <tbody>
                      {historyList &&
                        historyList.map((timelinedata, index) => (
                          <tr className="p-relative" key={index}>
                            <td>
                              <span className="date">
                                {timelinedata && timelinedata.followup_date
                                  ? DateFormate(
                                      timelinedata.followup_date,
                                      "dd mmm"
                                    )
                                  : ""}
                              </span>
                              <span className="time">
                                {timelinedata && timelinedata?.followup_date
                                  ? DateFormate(
                                      timelinedata?.followup_date,
                                      "h:MM TT"
                                    )
                                  : ""}
                              </span>
                            </td>
                            <td>
                              <div className="history-list-outer"> 
                              <div>
                                <span className="heading">
                                    <p>Followup Remark</p> 
                                </span>
                                <span className="Subheading">
                                    { (timelinedata && timelinedata?.comment) ? timelinedata?.comment : ""}
                                </span>
                              </div>
                              </div>
                            </td>
                            {/* <td>
                                {(timelinedata && timelinedata?.followup_type) ? (
                                    <p className="listingdate user-name">
                                        {(timelinedata.followup_type === 'call') ? 'Call' : 'Doc Pickup'}
                                    </p>
                                ) : (
                                    ""
                                )}
                            </td> */}
                                 
                            
                          </tr>
                        ))}
                    </tbody>
                  ) : (
                    <h4>
                      <center className="t-danger">
                        {!isLoading && "Followup history not found"}
                      </center>
                    </h4>
                  )}
                </table>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
     </div>
    )
}

export default FollowupHistory;