/* eslint-disable */
import React, { Component } from "react";

class ConfirmPopUp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showmodal: true
        };
        //this.setWrapperRef = this.setWrapperRef.bind(this);
        //this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    render() {
        return (
            <div className="mark-lost-popup">
                <div className="modal display-block" tabIndex="-1" role="dialog">
                    <section className="modal-main" role="document">
                        <div className="modal-header">
                            <h2 className="modal-title">{this.props.title || 'Confirm'}</h2>
                            <button type="button" className="close_icn" data-dismiss="modal" aria-label="Close" onClick={() => {
                                this.props.confirmNo();
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <h4>{this.props.message || "Are you sure to want to delete?"}</h4><br></br>
                        </div>
                        
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary btn-lg" onClick={() => {
                                this.props.confirmYes();
                            }}>Yes</button> &nbsp;&nbsp;
                            
                            <button type="button" className="btn btn-line" onClick={() => {
                                this.props.confirmNo();
                            }}>No</button>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}
export default ConfirmPopUp;
