import React, { createContext, useEffect, useState } from 'react';
import { getCurrentUserDetails } from '../../partner/pre-register-lead-manager/utility/getUserInfo';
import generalService from '../../../services/generalService';
import { MasterService } from '../../../services';
import { executeGraphQLQueryFinex } from '../../../common/executeGraphQLQueryFinex';
import { FINEX_MASTER } from '../../../services/scheme.gql';

// Create the context
export const APFContext = createContext();

// Create a provider component
const topMetroCities = ["Delhi", "New Delhi", "Noida", "Bangalore", "Mumbai", "Pune","Hyderabad"];

export const APFContextProvider = ({ children }) => {
    const bankarr = [];
    const [cityList, setCityList] = useState([]);
    const [builderList, setBuilderList] = useState([]);
    const [reqData, setReqData] = useState({});
    const [projectList, setProjectList] = useState([]);
    const [bankList, setBankList] = useState([]);
    const [resultList, setResultList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [allBankList, setAllBankList] = useState([]);
    const [ isNew, setIsNew ]= useState(false);
    const [error, setError] = useState({});

  
    useEffect(() => {
      const userInfo = getCurrentUserDetails();
      const { access_token } = userInfo || {};
      if(!access_token || !localStorage.getItem('isLogin')) {
         localStorage.setItem('isLogin' , true);
         window.location.reload();
      }
      getCityList();
    }, []);

    useEffect(() => {
        getbankList();
        setBuilderList([]);
        setProjectList([]);
        setReqData({});
        setBankList([]);
    }, [isNew]);
  
    useEffect(() => {
      if (reqData.city) {
        getBuilderList();
      }
    }, [reqData.city]);
    useEffect(() => {
      if (reqData.builder) {
        getProjectList();
      }
    }, [reqData.builder]);
  
    const getCityList = async () => {
      let response = await generalService.stateCityList();
      const city = response.data.data.city;
      const filteredCities = city.filter(city => topMetroCities.includes(city.name));
      const remainingCities = city.filter(city => !topMetroCities.includes(city.name));
      const sortedCityList = [...filteredCities, ...remainingCities];
      setCityList(sortedCityList);
    };
  
    const getBuilderList = async () => {
      if (reqData.city) {
        const obj = {
          cityId: reqData.city,
        };
        let response = await MasterService.get(
          "/partner/apf-controller/apf-builder",
          obj
        );
        setBuilderList(response.data.data);
      }
    };
  
    const getProjectList = async () => {
      if (reqData.builder) {
        const obj = {
          cityId: reqData.city,
          builderId: reqData.builder.builder_id,
        };
        let response = await MasterService.get(
          "/partner/apf-controller/apf-project",
          obj
        );
        setProjectList(response.data.data);
      }
    };
  
    const getBankId = async () => {
      if (reqData.city && reqData.builder && reqData.project_type) {
        const obj = {
          cityId: reqData.city,
          builderId: reqData.builder.builder_id,
          projectId: reqData.project_type.project_id,
        };
        let response = await MasterService.get(
          "/partner/apf-controller/apf-bank",
          obj
        );
        
        if (response && response.data && response.data.data) {
            const result = response.data.data;
            const filterResult = filterUniqueByBankId(result)
            setResultList(filterResult);
            result.map((data) => {
                bankarr.push(data.bank_id);
            });
          getbankList(bankarr);
        }
      }
    };

    const filterUniqueByBankId = (data) => {
        const uniqueBankIds = [];
        return data.filter(item => {
            if (!uniqueBankIds.includes(item.bank_id)) {
                uniqueBankIds.push(item.bank_id);
                return true;
            }
            return false;
        });
    };
    
  
    const getbankList = async (bankarr = []) => {
      const masterData = await executeGraphQLQueryFinex(FINEX_MASTER()).catch(
        (err) => console.log(err)
      );
      setAllBankList(masterData?.data?.masterdata?.bank_list)
      const filteredBanks = masterData.data.masterdata.bank_list.filter((bank) =>
        bankarr.includes(bank.id)
      );
      setBankList(filteredBanks);
    };
    const handlechange = (sname, ovalue) => {
      if(sname === 'city') {
        setBuilderList([]);
        setProjectList([]);
        setReqData({});
        setBankList([]);
        setReqData((prevState) => ({ ...prevState, [sname]: ovalue.id, cityName: ovalue.name }));
      }
      if (sname === "builder") {
        // setReqData((prevState) => ({ ...prevState, [sname]: ovalue.id }));
        setBankList([]);
        setReqData((prevState) => ({ ...prevState, [sname]: ovalue }));
      }
      if (sname === "project_type") {
        setBankList([]);
        setReqData((prevState) => ({ ...prevState, [sname]: ovalue }));
        // setReqData((prevState) => ({ ...prevState, [sname]: ovalue.id }));'
      }
    };
  
    const handleReset = () => {
      window.location.reload();
    };
  
    const selectedBuilderValue = builderList?.find(({ builder_id }) => builder_id === reqData?.builder?.builder_id) || null;
    const selectedProjectValue = projectList?.find(({ project_id }) => project_id === reqData?.project_type?.project_id) || null;
  

  const value = {
    cityList,
    builderList,
    projectList,
    bankList,
    allBankList,
    resultList,
    handlechange,
    getBankId,
    handleReset,
    selectedBuilderValue,
    selectedProjectValue,
    reqData,
    isEdit,
    setIsEdit,
    isNew,
    setIsNew,
    error,
    setError,
  };

  return (
    <APFContext.Provider value={value}>
      {children}
    </APFContext.Provider>
  );
};
