import React from "react";
import docdummyimg from "../../../webroot/images/docdummyimg.png";
import callimg from "../../../webroot/images/calliconnew.png";

const LoanDetailsTab = ({ customerDetails, loanDetails, user }) => {
  return (
    <>
      <div className="pcdoccard">
        <div className="loandetailscardpc">
          <div className="docloandetailscard">
            <div className="dcoimgcenter">
              <img src={docdummyimg} alt="icon" width={100} height={100} />
              <div>
                <h3 className="docloanname">
                  {customerDetails.first_name} {customerDetails.last_name}
                </h3>
              </div>
            </div>
            <div className="docloancommon">
              <div>Loan Amount</div>
              <div className="docloanprice">
                ₹ {loanDetails.loan_amount || "0"}
              </div>
            </div>
            <div className="docloancommon">
              <div>Loan Type</div>
              <div className="docloanprice">{loanDetails.loan_type || "-"}</div>
            </div>
            <div className="docloancommon">
              <div>Property Type</div>
              <div className="docloanprice">
                {loanDetails.property_type || "-"}
              </div>
            </div>
            <div className="docloancommon">
              <div>Property Identified</div>
              <div className="docloanprice">
                {loanDetails.is_property_identified || "-"}
              </div>
            </div>
            <div className="docloancommon">
              <div>Property Value</div>
              <div className="docloanprice">
                ₹ {loanDetails.property_value || "0"}
              </div>
            </div>
            <div className="docloancommon">
              <div>Usage Type</div>
              <div className="docloanprice">
                {loanDetails.usage_type || "-"}
              </div>
            </div>
          </div>

          <div className="docloandetailscard">
            <div className="dcoimgcenter">
              <h3 className="docloanname">Your Ambak Loan Advisor</h3>
              <img src={docdummyimg} alt="icon" width={60} height={60} />
              <div className="loandetailsicon">
                <h3 className="docloanname">{user}</h3>
                <a href="tel:+1234567890">
                  <img src={callimg} alt="icon" width={20} height={20} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanDetailsTab;
