import React, { useEffect, useState, useRef } from "react";
import MultiSelect from "../elements/MultiSelect.js";
import { Link, NavLink, Navigate } from "react-router-dom";
import InputField from "../elements/InputField";
import DateFormate from 'dateformat';
import { executeGraphQLMutation, executeGraphQLQuery } from "../../common/partnerExecuteGraphQLQuery";
import { DELETE_PAYOUT_POINT, GET_PAYOUT_POINTS_WITH_FILTERS} from "../../services/payout.gql"
import secureStorage from './../../config/encrypt';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { FINEX_MASTER_LIST,PAYOUT_POINT_DETAILS } from "../../services/scheme.gql";
import { toast } from 'react-toastify';
import Select from 'react-select';
import Pagination from "../elements/pagination";
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../../config/constants';




const BookingPoint = (props) => {

    const [filterData, setFilterData] = useState({ page_no: 1, financier_id: [], start_date:null, end_date:null, point_id:null });
    const [page_no, setPageNo] = useState(1);
    const [payout_points_list, setPayoutPointsList] = useState([]);
    const [paginationdata, setPaginations] = useState({});
    const [financers, setFinancers] = useState([]);
    const [bookingpoints, setBookingPoints] = useState([]);
    const [product_type, setProductType] = useState([]);
    const [payout_points, setPayoutpoints] = useState([])

    let bank_create =  IS_USER_ACCESS_PAGE("payout", "bank_create")?.is_edit_access;
    let bank_update =  IS_USER_ACCESS_PAGE("payout", "bank_update")?.is_edit_access;



const getPayoutDataList =  async() => {
     const result = await executeGraphQLQuery(PAYOUT_POINT_DETAILS());
     const PayoutDataList = []
     result.data.points.map(point => {
        PayoutDataList.push({ id: point.id, name: point.booking_point });
    });
    setPayoutpoints(PayoutDataList)
}

    useEffect(() => {
        updateReduxData()
        getFinexMasterData()
        getPayoutDataList()
        getPayoutPoints()

    }, []);

    const updateReduxData = async () => {
        if (secureStorage.getItem('loginUserInfo')) {
            var loginResponse = secureStorage.getItem('loginUserInfo');
            props.updateStateIntoRedux('LOGIN_USER', loginResponse);
            afterLoginSetState(loginResponse)
        } else {
            return (<Navigate to={"/login"} />);
        }
    }

    const afterLoginSetState = (loginResponse) => {
        let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role || null;
        if (role != "dealercentral") {
            return (<Navigate to={"/"} />);
        }
    }

    const getPayoutPoints = async (page_no) => {
        try {
            filterData.page_no = page_no
            const responseData = await executeGraphQLMutation(GET_PAYOUT_POINTS_WITH_FILTERS, {
                "filter_params": filterData
            })
            if (responseData?.data.payout_points_list) {
                setPayoutPointsList(responseData?.data?.payout_points_list[0]?.data)
                setPaginations(responseData?.data?.payout_points_list[0]?.pagination)
            }else{
                setPayoutPointsList([])
                setPaginations([])
            }

        } catch (error) {
          console.log(error)
        }
    }


    const deletePayoutPoint = async(id)=>{
        try {
            if(window.confirm("Do you want to delete this payout poing?")){
                const responseData = await executeGraphQLQuery(DELETE_PAYOUT_POINT(id))
                if (responseData?.data.delete_payoutpoint && responseData?.data.delete_payoutpoint!="") {
                    toast.success(responseData?.data?.delete_payoutpoint || "Success");
                }else{
                    toast.success("Something went wrong!");
                }
                getPayoutPoints()
            }
        } catch (error) {
            toast.success("Something went wrong!");
        }
    }

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const bookingPointsData = []
            const producttypedata = [];
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const products = data.loan_type && data.loan_type.length > 0 ? data.loan_type : [];

            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
                payout_points.map(point => {
                    bookingPointsData.push({ id: point.lead_status, name: point.point_name });
                });
                setBookingPoints(bookingPointsData);

                if (products && products.length > 0) {
                    producttypedata.push({id:"0", name:"All"})
                    products.map(ptype => {
                        producttypedata.push({ id: ptype.id, name: ptype.label })
                    })
                    setProductType(producttypedata)
                }

            }

        }

    }


    const onPaginate = (pageNumber) => {
        //   setFilterData({ ...filterData, page_no: pageNumber });
        setPageNo(pageNumber)
        getPayoutPoints(pageNumber)
    };


    const handleChange = async (n, v, isMulti, opt, optl) => {
        switch (n) {
            case 'financier_id':
                let financier_ids = [];
                financier_ids = v.map(obj => { return obj.id });
                setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
                console.log("filterData", filterData);
                break;
        }
    }





    const handleSelectBox = (name, selectedOptions) => {
        if (name == 'booking_point') {
            const booking_point = selectedOptions.id;
            setFilterData({ ...filterData, point_id: booking_point });
        }

        if (name == 'product_type') {
            const product_id = selectedOptions.id;
            setFilterData({ ...filterData, product_type: product_id });
        }
       
    };


    const handleInputarray = (e) => {
        const filterDatanew = { ...filterData }
        const name = e.target.name;
        const val = e.target.value;
        filterDatanew[name] = val;
        console.log(filterDatanew)
        setFilterData(filterDatanew);
    };
    const submitFilterForm = (event) => {
        event.preventDefault();
    };

    const resetFilterForm = (event) => {
        window.location.reload(false);
    };


    return (
        <>
            <div className='container-fluid'>
              
                <h2 className="top-heading">Payout Point</h2>
                {/* {JSON.stringify(filterData)} */}
                <div className='search-wrap'>
                    <form onSubmit={submitFilterForm}>
                        <div className='row'>
                            <div className='col sm-2 col-md-2'>
                                <InputField
                                    inputProps={{
                                        id: "start_date",
                                        type: "date",
                                        name: "start_date",
                                        autocompleate: "off",
                                        label: ('Start Date'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>
                            <div className='col-sm-2 col-md-2'>
                                <InputField
                                    inputProps={{
                                        id: "end_date",
                                        type: "date",
                                        name: "end_date",
                                        autocompleate: "off",
                                        label: ('End Date'),

                                    }}
                                    onChange={handleInputarray}
                                />
                            </div>
                            <div className='col-sm-2 col-md-2'>
                                <label>Select Bank</label>
                                <MultiSelect
                                    options={financers}
                                    placeholder={"Banks"}
                                    name={'financier_id'}
                                    label="Bank"
                                    optionLabel="name"
                                    optionValue="id"
                                    values={filterData.financier_id}
                                    onChange={handleChange}
                                    isMulti
                                />
                            </div>
                            <div className='col-sm-2 col-md-2'>
                                <label>Select Booking Point</label>
                                <Select
                                id="product_type"
                                onChange={(e) => handleSelectBox('booking_point', e)}
                                options={payout_points}
                                label={"Booking Point"}
                                name="booking_point"
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            />
                            </div>

                            <div className='col-sm-2 col-md-2'>
                                <label>Select Product Type</label>
                                <Select
                                id="product_type"
                                onChange={(e) => handleSelectBox('product_type', e)}
                                options={product_type}
                               // label={"Booking Point"}
                                name="product_type"
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            />
                            </div>


                            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
                                <div className="btn-submit-reset top-btn-none pull-right">
                                    <button
                                    type="submit"
                                    className="btn btn-primary mrg-r10"
                                    onClick={() => getPayoutPoints(1)}
                                    >
                                    {"Search"}
                                    </button>
                                    <button
                                    type="reset"
                                    className="btn-reset"
                                    onClick={resetFilterForm}
                                    >
                                    {"Reset"}
                                    </button>
                                </div>
                                </div>

                        </div>
                    </form>
                </div>
                <div className="text-right" style={{margin:"30px 0px"}}>
                    {bank_create === 1 && (
                          <NavLink to="/payout/addpayoutpoint" className="add-schemebtn">
                          {('Add Payout points')}
                      </NavLink>
                    )}
                         
                        </div>

                <div className='clearfix'>
                    <div className="card">
                        <div className='table-responsive '>
                            <table className='table table-bordered tablefontsize' >
                                <thead>
                                    <tr>
                                        <th>{('ID')}</th>
                                        <th>{('Bank')}</th>
                                        <th>{('% Payout')}</th>
                                        <th>{('Booking Point')}</th>
                                        <th>{('Product Type')}</th>
                                        <th>{('Start Date')}</th>
                                        <th>{('End Date')}</th>
                                        <th>{('Status')}</th>
                                        <th>{('Action')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* {JSON.stringify(payout_points_list)} */}
                                    {payout_points_list && payout_points_list.length > 0 && payout_points_list.map((point) => (


                                        <tr key={point.id}>
                                            <td>{point.id}</td>
                                            <td>{financers && financers.length > 0 && point.financier_id && financers.filter(bank => bank.id == point.financier_id)[0]?.name}</td>
                                            <td>{point.payout_per}</td>
                                            <td>{payout_points.filter((points) => points.id == point.point_id)[0]?.name}</td>
                                            <td>
                                                {point.product_type =="0" ? "All" : product_type.find(pt => pt.id == point.product_type)?.name || ""}
                                            </td>
                                            <td>{DateFormate(new Date(point.start_date), "dS mmm yyyy")}</td>
                                            <td>{DateFormate(new Date(point.end_date), "dS mmm yyyy")}</td>
                                            <td>{point.status==1? "Active":"In-active"}</td>
                                            <td>

                                            <div style={{display:"flex"}}>
                                            {/* <div className="btn-group btn-group-small mrg-r10">
                                            <Link to={`/payout/edit/${point.id}`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal" style={{color:"gray"}}></i></Link>
                                            </div> */}
                                            {bank_update === 1&& (
                                                <div className="btn-group btn-group-small mrg-r10">
                                                <div onClick={()=>deletePayoutPoint(point.id)}>Remove</div>
                                                </div>
                                            )}
                                            
                                            </div>


                                            {/* <div className="btn-group btn-group-small mrg-r10">
                                            <Link to={`/payout/edit/${point.id}`} className="btn btn-default"><i className="ic-createmode_editedit icons-normal"></i></Link>
                                            </div>
                                            <div className="btn-group btn-group-small mrg-r10">
                                                <button className="btn btn-default" onClick={()=>deletePayoutPoint(point.id)}>Remove</button>
                                                </div> */}
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td colSpan={6}> <Pagination pageCount={paginationdata && paginationdata.totalpage ? paginationdata.totalpage : 0} activePage={filterData.page_no ? filterData.page_no : 1} onPaginate={onPaginate} /></td>
                                        <td>Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords : 0} </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}




const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealer')(connect(mapStateToProps, mapDispatchToProps)(BookingPoint));





