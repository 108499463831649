import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Modal from "../../elements/partnerModal";
import './apf.css';
import InputField from "../../elements/Input";
import { MasterService } from "../../../services";
import { getCurrentUserDetails } from "../../partner/pre-register-lead-manager/utility/getUserInfo";
import { APFContext } from "../context/apfContextApi";
import { toast } from "react-toastify";

const APFEditComponent = () => {
  const { cityList, resultList, isEdit, setIsEdit, allBankList, bankList, isNew, setIsNew, error, setError } = useContext(APFContext);
  const [formData, setFormData] = useState(resultList);
  const [selectedLenders, setSelectedLenders] = useState([]);
  const [oldData, setOldData] = useState({});

  useEffect(() => {
    const data = filterUniqueByProjectId(resultList);
    setFormData(data[0]);
    setOldData(data[0]);
    bankList.forEach((item) => {
      const newLender = {
        value: item.banklang.bank_id,
        label: item.banklang.bank_name,
      };

      setSelectedLenders((prevState) => {
        if (!prevState.some((lender) => lender.value === newLender.value)) {
          return [...prevState, newLender];
        }
        return prevState;
      });
    });
  }, [isEdit]);

  useEffect(() => {
    setOldData({});
    setFormData({});
    setSelectedLenders([]);
  },[isNew]);


  const errorHandler = () => {
    const {
      city_id,
      builder_name,
      project_name,
      lenders,
    } = formData;
  
    let hasError = false;
    let newErrorState = {
      city_id : false,
      builder_name : false,
      project_name : false,
      is_name_matched : false,
      lenders : false,
    };
  
    if (!city_id) {
      toast.error('City Name is required.\n');
      newErrorState.city_id = true;
      hasError = true;
    }
    if (!builder_name) {
      toast.error('Builder name is required.\n');
      newErrorState.builder_name = true;
      hasError = true;
    }
    if (!project_name) {
      toast.error('Project name is required.\n');
      newErrorState.project_name = true;
      hasError = true;
    }
    if (!lenders) {
      toast.error('Lender name is required.\n');
      newErrorState.lenders = true;
      hasError = true;
    } else if(!selectedLenders.length) {
      toast.error('At least one Lender name is required.\n');
      newErrorState.lenders = true;
      hasError = true;
    }
  
    if (hasError) {
      setError((prevError) => ({
        ...prevError,
        ...newErrorState,
      }));
      return hasError;
    }
  
    return false;
  };

  const handleCloseModal = () => {
    setIsEdit(false);
    setIsNew(false);
    setTimeout(() => {
      setSelectedLenders([]);
    }, 1000);
  };

 const filterUniqueByProjectId = (data) => {
    const uniqueProjectIds = [];
    return data.filter(item => {
        if (!uniqueProjectIds.includes(item.project_id)) {
            uniqueProjectIds.push(item.project_id);
            return true;
        }
        return false;
    });
  };

  const selectedCity = cityList?.find((city) => city.id === formData?.city_id) || null;
  const lenderOptions = allBankList.map(lender => ({
        value: lender.banklang.bank_id,
        label: lender.banklang.bank_name
    }));

    const handleLenderChange = (selectedOptions) => {
        setSelectedLenders(selectedOptions);
        setFormData((prevState) => ({
          ...prevState,
            lenders: selectedLenders.map(lender => lender.value)
        }));
    };

    const handleOnChange = (event, city_id) => {
        if(event === 'city_id') {
          setFormData((prevState) => ({
            ...prevState,
             city_id: city_id
           }));
        } else {
          const {id, value} = event?.target;
          console.log("id, valueid, value",id, value);
          setFormData((prevState) => ({
            ...prevState,
              [id]: value,
              lenders: selectedLenders.map(lender => lender.value)
          }));
        }
    };

    const handleSaveApf = () => {
        const {userId} = getCurrentUserDetails();
        const payload = {
            city_id: formData?.city_id,
            builder_id: formData?.builder_id,
            builder_name: checkChnages(oldData?.builder_name, formData?.builder_name) ? formData?.builder_name : undefined,
            project_id: formData?.project_id,
            project_name: checkChnages(oldData?.project_name, formData?.project_name) ?  formData?.project_name : undefined,
            lenders: selectedLenders.map(lender => lender.value),
            updated_by: userId,
            created_by: userId,
        }
        const isCreate = window.confirm(isEdit ? 'Are you sure you want to Update record?' : 'Are you sure you want to create a new record?');
        if(isCreate && !errorHandler()) {
          MasterService.post('/partner/apf-controller/save-apf-details', payload).then((responce) => {
            console.log("responce", responce);
            if(responce.status === 200) {
              toast.success("Success");
              setIsEdit(false);
              setIsNew(false);
              setSelectedLenders([]);
            } else {
              toast.error("Something went wrong");
            }
          }).catch((error) => {
              console.log("error inside save apf details", error);
          })
        }
    };

    const checkChnages = (oldData , newData) => {
      if(oldData === newData) {
        return false;
      } else {
        return true;
      }
    };
    

  return (
    <div>
      <Modal show={isEdit || isNew} handleClose={handleCloseModal}>
        <div className="apf-edit-container">
          <div style={{display:'flex', justifyContent: 'center'}}>
            <h2>{isEdit ? 'Edit APF Record' : 'Add APF Record'}</h2>
          </div>
          <form>
            <div className="form-group">
              <label htmlFor="city">City: *</label>
              <Select
                id="city_id"
                name="city"
                placeholder={"Select city.."}
                options={cityList}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
                value={selectedCity}
                isDisabled={isEdit}
                onChange={(city) => handleOnChange("city_id", city.id)}
                // styles={{
                //   control: (base) => ({
                //     ...base,
                //     borderColor: error.city_id ? 'red' : base.borderColor,
                //   }),
                // }}
              />
              {error.city_id && <span className="error show">{'Please select city'}</span>}
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="builder">Builder: *</label>
                <InputField
                    type="text"
                    id="builder_name"
                    name="builder"
                    placeholder="Builder Name"
                    value={formData?.builder_name}
                    onChange={(e) => handleOnChange(e)}
                    error={error?.builder_name ? 'Please Enter Builder name' : ''}
                />
              </div>
              <div className="form-group">
                <label htmlFor="project">Project: *</label>
                <InputField
                    type="text"
                    id='project_name'
                    name="project" 
                    placeholder="Project Name"
                    value={formData?.project_name}
                    onChange={(e) => handleOnChange(e)}
                    error={error?.project_name ? 'Please Enter Project name' : ''}
                />
              </div>  
            </div>
            <div className="form-group">
            <label htmlFor="lender-search">Search by Lender name: *</label>
                <Select
                    isMulti
                    defaultValue={[selectedLenders[1], selectedLenders[2]]}
                    options={lenderOptions}
                    value={selectedLenders}
                    onChange={handleLenderChange}
                    name="colors"
                    className="basic-multi-select"
                    classNamePrefix="select"
                />
              {error.lenders && <span className="error show">{'Please select Lender'}</span>}
            </div>
            <div className="button-container" style={{ justifyContent: isEdit ? 'space-between' : 'flex-end' }}>
              {isEdit && <button type="button" onClick={handleCloseModal} className="close-button">Close</button>}
              <button type="button" onClick={() => handleSaveApf()} className="save-button">Save</button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default APFEditComponent;
