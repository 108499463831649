export const getStatusUtility = (status) => {

    switch (status) {
      case "0":
        return "unknown";
      case "1":
        return { status: "Verified", color: "green" };
      case "4":
        return { status: "Registered", color: "orange" };
      case "10":
        return { status: "Fresh Lead", color: "purple" };
        case "11":
        return { status: "Lost", color: "red" };
      default:
        return "";
    }
  };
