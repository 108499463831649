import React, { } from "react";
const Modal = ({ handleClose, show, children, removeHeight,width }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';
    return (
        <div className={showHideClassName}>
            <section className='modal-main'  style={{ width: width ? width : 'auto' }}>
                <div className={!removeHeight ? "modal-height":""}>
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
                </div>
            </section>
        </div>
    );
};

export default Modal;




