import React, { useEffect, useMemo, useState } from "react";
import FormsySelect from "../../elements/FormsySelect";
import Formsy from "formsy-react";
import { useSelector } from "react-redux";
import FormsyInputField from "../../elements/FormsyInputField";

const NO_OF_BANKS = [
  { id: 1, value: 1, label: 1 },
  { id: 2, value: 2, label: 2 },
  { id: 3, value: 3, label: 3 },
  { id: 4, value: 4, label: 4 },
  { id: 5, value: 5, label: 5 },
];

const ACCOUNT_TYPES = [
  { id: "saving", value: "saving", label: "Saving" },
  { id: "current", value: "current", label: "Current" },
];

const BreBankIncome = ({
  setBankIncomeDetails,
  customerType = "customer",
  coApplicantOfferDetails = [],
  coApplicantNoOfAccounts,
  coAppIndex = null,
}) => {
  const [bankingIncome, setBankingIncome] = useState({});
  const [bankAccountFields, setBankAccountFields] = useState([]);
  const [isFormTouch, setTouch] = useState({});

  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata.data } : {};
    options["bank_account_type"] = ACCOUNT_TYPES;
    return options;
  }, [masterdata]);

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let bankDetails = [],
        noOfAcc = 0;
      if (customerType === "customer") {
        bankDetails = lead.leadDetail.customer.offer_bank_details || [];
        noOfAcc = lead.leadDetail.customer.no_of_accounts || 0;
      } else {
        bankDetails = coApplicantOfferDetails;
        noOfAcc = coApplicantNoOfAccounts;
      }
      const obj = {};
      for (let i = 0; i < bankDetails.length; i++) {
        obj[`bank_account_type_${i}`] = bankDetails[i].account_type;
        obj[`bank_account_avg_amount_${i}`] = bankDetails[i].amount;
        obj[`bank_details_id_${i}`] = bankDetails[i].id;
      }
      obj["no_of_accounts"] = noOfAcc;
      if (noOfAcc > 0) {
        addBankAccountsFields(noOfAcc);
      }
      setBankingIncome((current) => ({
        ...current,
        ...obj,
      }));
      setBankIncomeDetails(null, null, obj);
    }
  }, [lead, coAppIndex]);

  const addBankAccountsFields = (noOfAccounts) => {
    const fields = [];
    for (let i = 0; i < noOfAccounts; i++) {
      const dropDownObj = {
        id: `bank_account_type_${i}`,
        name: `bank_account_type_${i}`,
        readOnly: false,
        type: `dropdown`,
        label: `Account type`,
        required: false,
        validations: null,
        validationError: "",
        ApplyNumberformat: false,
        optionsKey: `bank_account_type`,
      };
      const inputObj = {
        id: `bank_account_avg_amount_${i}`,
        name: `bank_account_avg_amount_${i}`,
        readOnly: false,
        type: "number-format",
        label: "Amount",
        required: false,
        validations: "isNumeric",
        validationError: "",
        ApplyNumberformat: false,
      };
      fields.push([dropDownObj, inputObj]);
    }
    setBankAccountFields(fields);
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setBankingIncome((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      if (field_name === "no_of_accounts") {
        addBankAccountsFields(data.value);
      }
      const obj = {
        ...bankingIncome,
        [field_name]: data.value,
      };
      setBankIncomeDetails(null, null, obj);
    }
  };

  const handleInputChange = (event) => {
    if (event.target) {
      const { id, value } = event.target;
      setBankingIncome((currentValue) => ({
        ...currentValue,
        [id]: value,
      }));
      const obj = {
        ...bankingIncome,
        [id]: value,
      };
      setBankIncomeDetails(null, null, obj);
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setTouch({
        ...isFormTouch,
        [field_name]: true,
      });
      setBankingIncome((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
      const obj = {
        ...bankingIncome,
        [field_name]: data.value,
      };
      setBankIncomeDetails(null, null, obj);
    }
  };

  return (
    <>
      <h4 className="bankincome">Banking Income</h4>
      <Formsy
        className="lead-form-filed"
        autoComplete="off"
        onValid={() => null}
        onInvalid={() => null}
      >
        <div className="row">
          <fieldset className="single-select col-md-6">
            <FormsySelect
              name={"no_of_accounts"}
              id={"no_of_accounts"}
              inputProps={{
                options: NO_OF_BANKS,
                placeholder: "No of Accounts",
                className: "react-select",
                classNamePrefix: "react-select",
                value: NO_OF_BANKS.filter(
                  ({ value }) => value == bankingIncome["no_of_accounts"]
                ),
                isDisabled: false,
              }}
              required={false}
              value={bankingIncome && bankingIncome["no_of_accounts"]}
              onChange={(data) => handleSelectChange(data, "no_of_accounts")}
            />
          </fieldset>
        </div>
      </Formsy>

      {/* BANK ACCOUNTS FIELDS */}
      {bankAccountFields.map((data, bankIndex) => (
        <>
          <h4 className="bankincome">Account - {bankIndex + 1}</h4>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => null}
            onInvalid={() => null}
            key={bankIndex}
          >
            <div className="row" key={bankIndex}>
              {data?.map((field, index) =>
                ["number-format"].includes(field.type) ? (
                  <fieldset className={"form-filed col-md-6"} key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={(bankingIncome && bankingIncome[field.name]) || ""}
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      maxLength={field.maxLength}
                      label={field.label}
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={
                        isFormTouch[field.name] ? field.validations : null
                      }
                      validationError={
                        bankingIncome[field.name] ||
                        bankingIncome[field.name] == ""
                          ? isFormTouch[field.name]
                            ? field.validationError
                            : ""
                          : ""
                      }
                      readOnly={field.readOnly}
                      onChange={
                        ["number-format"].includes(field.type)
                          ? (data) =>
                              handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset className="single-select col-md-6" key={index}>
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS[field.optionsKey] &&
                          ALL_OPTIONS[field.optionsKey].filter(
                            ({ value }) => value == bankingIncome[field.name]
                          ),
                        isDisabled: field.readOnly,
                      }}
                      required={field.required}
                      value={bankingIncome && bankingIncome[field.name]}
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                  </fieldset>
                ) : null
              )}
            </div>
          </Formsy>
        </>
      ))}
    </>
  );
};

export default BreBankIncome;
