import { useEffect, useState } from "react";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { LEAD_DETAIL_QUERY } from "../../../services/leads.gql";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import crypto from "../../../config/crypto";
import generalService from "../../../services/generalService";
import { toast } from "react-toastify";
import InputField from "../../elements/InputField";
import MobileModal from "../../elements/MobileModal";
import UploadDocsTab from "./UploadDocsTab";
import LoanDetailsTab from "./LoanDetailsTab";
import LoanStatusTab from "./LoanStatusTab";

const DocumentCollect = () => {
  const client = useApolloClient();
  const params = useParams();
  const [customerDetails, setCustomerDetails] = useState({});
  const [coAppDetails, setCoAppDetails] = useState([]);
  const [loanDetails, setLoanDetails] = useState({});
  const [activeTab, setActiveTab] = useState("Upload Docs");
  const [firstRender, setFirstRender] = useState(true);
  const [validateOtpBtnDisable, setValidateOtpBtnDisable] = useState(true);
  const [otpModalVisiblity, setOtpModalVisiblity] = useState(true);

  const lead_id = +crypto.decode(params.lead_id);
  // const user = crypto.decode(params.user);
  const user = params.user;

  const loanTypeMappingObj = {
    1: "Home Loan",
    2: "LAB",
  };

  const getLeadDetail = (lead_id) => {
    executeGraphQLQuery(LEAD_DETAIL_QUERY(lead_id), client)
      .then((response) => {
        if (response?.data) {
          const data = response.data?.get_lead_detail[0];
          const masked_mobile = `XXXXXX${data.customer.mobile?.slice(-4)}`;

          setCoAppDetails(data.co_applicant);
          setCustomerDetails((current) => ({
            ...current,
            customer_id: data.customer.customer_id,
            first_name: data.customer.first_name,
            last_name: data.customer.last_name,
            mobile: data.customer.mobile,
            masked_mobile,
          }));

          setLoanDetails((current) => ({
            ...current,
            loan_amount: data.lead_details.loan_amount,
            loan_type: loanTypeMappingObj[+data.loan_type],
            is_property_identified: data.lead_details.is_property_identified,
            property_type: data.lead_details.property_type,
            property_value: data.lead_details.property_value,
            usage_type: data.lead_details.usage_type,
          }));
        }
      })
      .catch((err) => console.log(err));
  };

  const sendOtp = async () => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
      const data = {
        mobile: customerDetails.mobile,
      };
      const response = await generalService.sendOTP(data, headers);
      if (response.data?.status === 200) {
        toast.success("OTP sent");
        setValidateOtpBtnDisable(false);
        localStorage.setItem("is_otp_send", true);
      } else {
        console.log(response.data?.message);
        toast.error(response.data?.message);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Internal server error");
      localStorage.setItem("is_otp_send", false);
    }
  };

  const validateOtp = async () => {
    try {
      if (customerDetails.otp === undefined || customerDetails?.otp.trim() === "") return toast.error("OTP is required");
      setValidateOtpBtnDisable(true);
      const headers = {
        "Content-Type": "application/json",
      };
      const data = {
        mobile: customerDetails.mobile,
        otp: customerDetails.otp,
        check_valid_mobile: 1,
        kind: "1",
      };
      const response = await generalService.validateOtp(data, headers);
      if (response.data?.status === 200) {
        toast.success("OTP verified successfully!");
        setOtpModalVisiblity(false);
      } else {
        console.log(response.data?.message);
        toast.error("Invalid OTP or mobile number");
        setValidateOtpBtnDisable(false);
      }
    } catch (error) {
      console.log(error.message);
      toast.error("Internal server error");
    }
  };

  useEffect(() => {
    getLeadDetail(lead_id);
  }, []);

  useEffect(() => {
    if (!firstRender) {
      const isOtpSend = localStorage.getItem("is_otp_send");
      if (!isOtpSend) {
        sendOtp();
      } else {
        setOtpModalVisiblity(false);
      }
    } else {
      setFirstRender(false);
    }
  }, [customerDetails.mobile]);

  const handleClick = (buttonName) => {
    setActiveTab(buttonName);
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setCustomerDetails((current) => ({
      ...current,
      otp: `${value}`.replace(/\D/g, ''),
    }));
  };

  return (
    <>
      {otpModalVisiblity && (
        <>
          <div className="view-timeline-popup image-timeline-popup">
            <MobileModal
              show={otpModalVisiblity}
              handleClose={() => toast.error("Need to verify OTP")}
            >
              <div className="modal-header">
                <h2>Authentication</h2>
              </div>
              <div className="modal-body">
                <p>Otp has been sent to {customerDetails.masked_mobile}</p>
                <InputField
                  inputProps={{
                    id: "otp",
                    type: "tel",
                    name: "otp",
                    autocompleate: "off",
                    label: "Enter Otp",
                    value: `${customerDetails.otp || ""}`,
                    maxLength: 6,
                    required: true,
                  }}
                  onChange={handleInputChange}
                />
              </div>
              <div className="modal-footer" style={{ textAlign: "center" }}>
                <button
                  onClick={validateOtp}
                  disabled={validateOtpBtnDisable}
                  className="btn-primary"
                >
                  Verify OTP
                </button>
              </div>
            </MobileModal>
          </div>
        </>
      )}
      <div className="topcontainercard">
        <div className="topcontainer">
          <div
            className={
              activeTab === "Upload Docs" ? "uploaddocs active" : "uploaddocs"
            }
            onClick={() => handleClick("Upload Docs")}
          >
            <button>Upload Docs</button>
          </div>
          <div
            className={
              activeTab === "Loan Details" ? "uploaddocs active" : "uploaddocs"
            }
            onClick={() => handleClick("Loan Details")}
          >
            <button>Loan Details</button>
          </div>
          <div
            className={
              activeTab === "Loan Status" ? "uploaddocs active" : "uploaddocs"
            }
            onClick={() => handleClick("Loan Status")}
          >
            <button>Loan Status</button>
          </div>
        </div>

        {activeTab === "Upload Docs" && (
          <>
            <UploadDocsTab
              lead_id={lead_id}
              customerDetails={customerDetails}
              coAppDetails={coAppDetails}
              user={user}
            />
          </>
        )}

        {activeTab === "Loan Details" && (
          <>
            <LoanDetailsTab
              customerDetails={customerDetails}
              loanDetails={loanDetails}
              user={user}
            />
          </>
        )}

        {activeTab === "Loan Status" && (
          <>
            <LoanStatusTab lead_id={lead_id} />
          </>
        )}
      </div>
    </>
  );
};
export default DocumentCollect;
