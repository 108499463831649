import React, { Component } from "react";
import Modal from "../elements/Modal";
import Manageresult from "./Manageresult";
import { executeGraphQLQueryFinex } from "../../common/executeGraphQLQueryFinex";
import { LEADS_COUNT_DATA } from "../../services/leads.gql";
const LeadData = (props) => {
  console.log("props", props);
  const data = props.LeadsData?.data?.leads[0]?.lead_list;
  console.log("data", data);
  return (
    <>
      <div className="table-responsive">
      <h2 style={{ textAlign: "center", padding: 20, fontWeight: 'bold' }}>
        Leads Shared
      </h2>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Lead ID</th>
              <th>Name</th>
              <th>Status</th>

              <th>Created On</th>
              <th>Updated On</th>
            </tr>
          </thead>
          <tbody>
            {data && data.length > 0 ? (
              data.map((lead) => (
                <tr key={lead.id}>
                  <td>{lead.id}</td>
                  <td>
                    {lead.customer.first_name} {lead.customer.last_name}
                  </td>
                  <td>{lead.status_info.statuslang.status_name}</td>

                  <td>{new Date(lead.created_date).toLocaleString()}</td>
                  <td>{new Date(lead.updated_date).toLocaleString()}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default LeadData;
