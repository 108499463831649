import { gql } from '@apollo/client';

export const SAVE_INSURANCE_LEAD =gql`
mutation save_insurance_lead($SaveInsuranceLeadInput: SaveInsuranceLeadInput!) {
  save_insurance_lead(saveInsuranceLeadInput: $SaveInsuranceLeadInput) {
      lead_id
      insurance_url
    }
}`

export const UPDATE_MIS =gql`
mutation update_mis($updateMIS: UpdateMISListInput!) {
  update_mis(updateMIS: $updateMIS)
}`;