/* eslint-disable */ 
import React, { useState } from "react";
import { toast } from "react-toastify";
// import { Redirect } from "react-router-dom";
import Select from "react-select";
// import RoleActions from "../../../store/action/RoleActions";
import {useDispatch } from "react-redux";
import Loader from "../elements/Loader";
import { SAVE_EDIT_USER } from "../../services/role.gql";
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { InputOption, MultiSelectValueContainer } from "../elements/MultiselectCustomComponent";

const Accordion = (props) => {
    return (
        <div className="accordion">{props.children}</div>
    )
}

const AccordionItemContext = React.createContext({
    expanded: false,
    toggleExpansion: () => { }
});

class AccordionItem extends React.Component {
    constructor(props) {
        super(props)

        this.toggleExpansion = () => {
            this.setState({ expanded: !this.state.expanded })
        }

        this.state = {
            expanded: false,
            toggleExpansion: this.toggleExpansion
        }
    }
    render() {
        return (
            <AccordionItemContext.Provider value={this.state}>
                <div className="accordion-item">
                    {this.props.children}
                </div>

            </AccordionItemContext.Provider>
        )
    }
}

const AccordionHeader = (props) => {
    return (
        <AccordionItemContext.Consumer>
            {({ expanded, toggleExpansion }) => (
                <div className={"accordion-header " + (expanded ? 'active' : '')}>

                    <button onClick={toggleExpansion}>
                        {props.children}
                        {expanded ? '' : ''}
                    </button>
                </div>
            )}
        </AccordionItemContext.Consumer>
    )
}

const AccordionPanel = (props) => {
    return (
        <AccordionItemContext.Consumer>
            {({ expanded }) => <div className={"accordion-panel " + (expanded ? 'expanded' : '')}>{props.children}</div>}
        </AccordionItemContext.Consumer>
    )
}

const AddUser = (props) => {
	const client = useApolloClient();
	let [errors, setErrors] = useState({});
	let [formData, setFormData] = useState({});
	let [activeTab, setActiveTab] = useState(1);
	const [loading, setLoading] = useState(false);
    let {user_list,role_list,vertical_list} = props;
    //const loginUserInfo = secureStorage.getItem('loginUserInfo');
	const mobile_regex = /^[6-9]\d{9}$/;
    const email_regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
	const dispatch = useDispatch();

    const handleChange = async (key, event) => {
        let value = '';
        let target = event.target;
        if (key === 'status') value = formData.status === "1" ? "0" : "1";
        else if (event instanceof Array) value = event.map((e) => e.value);
        else if (target) value = target.value;
        else if (event) value = event.value || event.id;
        formData[key] = value;
        errors[key] = '';
        setFormData({...formData});
        setErrors({...errors});
    }

    const isFormValid = () => {
        let isValid = true;
        if (!formData.vertical_id || formData.vertical_id === '') {
            errors['vertical_id'] = "Field Required";
            isValid = false;
        }
        if (!formData.reporting_user_id || formData.reporting_user_id === '') {
            errors['reporting_user_id'] = "Field Required";
            isValid = false;
        }
        if (!formData.name || formData.name === '') {
            errors['name'] = "Field Required";
            isValid = false;
        }else if(formData && formData.name && (formData.name.split('').length < 3 || formData.name.split('').length > 30)){
			errors['name'] = "Invalid User Name";
			isValid = false;
		}
        if (!formData.mobile || formData.mobile === '') {
            errors['mobile'] = "Field Required";
            isValid = false;
        }else if(!mobile_regex.test(formData.mobile)){
            errors['mobile'] = "Invalid Mobile";
            isValid = false;
        }
        if (!formData.email || formData.email === '') {
            errors['email'] = "Field Required";
            isValid = false;
        }else if(!email_regex.test(formData.email)){
            errors['email'] = "Invalid Email";
            isValid = false;
        }
        if (!formData.password || formData.password === '') {
            errors['password'] = "Field Required";
            isValid = false;
        }else if(!password_regex.test(formData.password)){
            errors['password'] = 'Password should contain atleast 8 character with 1 special char , 1 Uppercase , 1 lowercase and 1 digit';
            isValid = false;
        }
        if(!formData?.role_ids?.length) {
            errors['role_ids'] = 'Field Required';
            isValid=false;
        }
        setErrors({...errors});
        return isValid;

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (await isFormValid(formData)) {
			setLoading(true);
            const variables = {
				action_type: "save",
				SaveUserInput: {
					id: 0,
					email: formData.email || '',
					mobile: formData.mobile || '',
                    name: formData.name || '',
                    password: formData.password || '',
                    vertical_id: formData.vertical_id,
                   // dialer_id: formData.dialer_id,
                    reporting_user_id: formData.reporting_user_id || '',
                    status: "1"
				},
				created_by: props.user_id,
                role_ids: formData.role_ids
			  };
		  
			  try {
				const result = await executeGraphQLMutation(SAVE_EDIT_USER, variables, client);
				if(result){
					toast.success(result?.data?.saveusers?.message || "Success");
					setFormData({ formData: {} });
					props.updateList();
				}
			  } catch (error) {
				toast.error(error.toString().replace('ApolloError:',''));
				console.error(error);
			  }
			setLoading(false);
		}
    }

    const handleTabChange = async (event) => {
        let showTab = event.currentTarget.dataset.key;
        setActiveTab(showTab)
    };

        // if (redirect) {
        //     return <Redirect to={redirect} />;
        // }
        if(formData && formData.reporting_role_id){
            user_list = user_list.filter(e=>{
                if(e.role_ids.includes(String(formData.reporting_role_id)))
                return e;
            })
        }else user_list=[]
        return (
            <div className="popup-outer">
                <div className="add-new-user-popup">
                    <div className="add-new-user-form">
                        <Accordion>
                            <AccordionItem>
                                <AccordionHeader>
                                    1. Business Vertical
                                </AccordionHeader>
                                <AccordionPanel>

                                    <fieldset className="form-filed">
                                        <div className="material select-list">
                                            <label data-label="Business Vertical" className="form-label"></label>
                                            <Select
                                                id="vertical_id"
                                                options={vertical_list}
                                                name="vertical_id"
                                                onChange={handleChange.bind(this, 'vertical_id')}
                                                placeholder="Vertical Name"
                                            />
                                            <span className="error-msg">
                                                {errors.vertical_id || ""}
                                            </span>
                                        </div>
                                    </fieldset>
                                </AccordionPanel>
                            </AccordionItem>
                            
                            <AccordionItem>
                                <AccordionHeader>
                                    2. Assign Role
                                </AccordionHeader>
                                <AccordionPanel>
                                    <fieldset className="form-filed single-select">
                                        <div className="material select-list">
                                            <label data-label="BRO" className="form-label"></label>
                                            <li className="searchitems selectWidth">
                                                <Select components={{ Option: InputOption, ValueContainer: MultiSelectValueContainer }}
                                                    isMulti
                                                    placeholderButtonLabel="Add Role"
                                                    placeholder="Assign Role"
                                                    value={
                                                        (role_list instanceof Array &&
                                                            formData.role_ids &&
                                                            role_list.filter((e) =>
                                                                [
                                                                    ...(formData.role_ids instanceof
                                                                        Array
                                                                        ? formData.role_ids
                                                                        : []),
                                                                ].includes(e.value)
                                                            )) ||
                                                        []
                                                    }
                                                    onChange={handleChange.bind(
                                                        this,
                                                        "role_ids"
                                                    )}
                                                    options={role_list}
                                                    getOptionLabel={({ label }) => label}
                                                    getOptionValue={({ value }) => value}
                                                    closeMenuOnSelect={false}
                                                    hideSelectedOptions={false}
                                                    backspaceRemovesValue={false}
                                                    isSearchable="true"
                                                    isClearable={false}
                                                    className="react-select react-multiselect-opt"
                                                    classNamePrefix="react-select"
                                                />
                                            </li>
                                            <span className="error-msg">
                                                {errors.role_ids || ""}
                                            </span>
                                        </div>
                                    </fieldset>
                                </AccordionPanel>
                            </AccordionItem>


                            <AccordionItem>
                                <AccordionHeader>
                                    3. Reporting
                                </AccordionHeader>
                                <AccordionPanel>
                                    <fieldset className="form-filed">
                                        <div className="material select-list">
                                            <label data-label="Area Manager" className="form-label"></label>
                                            <Select
                                                id="reporting_role_id"
                                                options={role_list}
                                                name="reporting_role_id"
                                                onChange={handleChange.bind(
                                                    this,
                                                    "reporting_role_id"
                                                )}
                                                placeholder="Reporting Role"
                                            />
                                            
                                        </div>
                                    </fieldset>
                                    <fieldset className="form-filed">
                                        <div className="material select-list">
                                            <label data-label="Area Manager" className="form-label"></label>
                                            <Select
                                                id="reporting_user_id"
                                                options={user_list}
                                                name="reporting_user_id"
                                                onChange={handleChange.bind(
                                                    this,
                                                    "reporting_user_id"
                                                )}
                                                placeholder="Reporting Name"
                                            />
                                            <span className="error-msg">
                                                {errors.reporting_user_id || ""}
                                            </span>
                                        </div>
                                    </fieldset>

                                </AccordionPanel>
                            </AccordionItem>

                            <AccordionItem>
                                <AccordionHeader>
                                    4. Basic Details
                                </AccordionHeader>
                                <AccordionPanel>
                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="name" type="text" placeholder=" " name="name" className="form-input" onChange={handleChange.bind(
                                                this,
                                                "name"
                                            )} value={formData.name || ''}/>
                                            <label data-label="Name" className="form-label"></label>
                                            <span className="error-msg">
                                                {errors.name || ""}
                                            </span>
                                        </div>
                                    </fieldset>
                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="" type="email" placeholder=" " name="email" className="form-input" onChange={handleChange.bind(
                                                this,
                                                "email"
                                            )} value={formData.email || ''}/>
                                            <label data-label="Email" className="form-label"></label>
                                            <span className="error-msg">
                                                {errors.email || ""}
                                            </span>
                                        </div>
                                    </fieldset>

                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="password" type="text" placeholder=" " name="password" className="form-input" onChange={handleChange.bind(
                                                this,
                                                "password"
                                            )} value={formData.password || ''}/>
                                            <label data-label="Password" className="form-label"></label>
                                            <span className="error-msg">
                                                {errors.password || ""}
                                            </span>
                                        </div>
                                    </fieldset>

                                    <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="mobile" type="text" placeholder=" " name="mobile" className="form-input" onChange={handleChange.bind(
                                                this,
                                                "mobile"
                                            )} value={formData.mobile || ''}/>
                                            <label data-label="Mobile Number" className="form-label"></label>
                                            <span className="error-msg">
                                                {errors.mobile || ""}
                                            </span>
                                        </div>
                                    </fieldset>

                                    {/* <fieldset className="form-filed">
                                        <div className="material">
                                            <input id="dialer_id" type="text" placeholder=" " name="dialer_id" className="form-input" onChange={handleChange.bind(
                                                this,
                                                "dialer_id"
                                            )} value={formData.dialer_id || ''}/>
                                            <label data-label="Dialer Id" className="form-label"></label>
                                            <span className="error-msg">
                                                {errors.dialer_id || ""}
                                            </span>
                                        </div>
                                    </fieldset> */}


                                </AccordionPanel>
                            </AccordionItem>
                            <button className="btn-primary m-sm-t" onClick={handleSubmit}>
                                Add User
                            </button>
                        </Accordion>
                    </div>
                </div>
                {loading ? <Loader /> : null}
            </div>
        );

}

export default AddUser;
