import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { GET_CAPTCHA_IMG } from "../../services/login.gql";

const Captcha = (props) => {

    const [captcha, setCaptcha] = useState('')
    const captchaInvalid = '';
    const [captchaImg, setCaptchaImg] = useState('')
    const [captchaError, setCaptchaError] = useState(false)
    const client = useApolloClient();
    // const dispatch = useDispatch();

    useEffect(()=>{
        loadCaptchaImage()
    }, [props.reloadCaptcha])

	const loadCaptchaImage = () => {
        setCaptcha("");
        executeGraphQLQuery(GET_CAPTCHA_IMG(),client).then(res=>{
          let img = res.data.get_captcha_img.image
          setCaptchaImg(img)
        }).catch(err=>{
          console.log(err)
          setCaptchaError(true)
        })      
    };

    /**
     * Input Handler
     * @param e (synthatic element)
     */
    const handleInputChange = async e => {
        let captchaVal = e.target.value;
        setCaptcha(captchaVal)
        props.onKeyPressCaptcha(captchaVal);        
    };
    
    return (
      <>
        <div className={captchaInvalid ? "material error": "material"}>
          
                <input
                  id="captcha"
                  type="text"
                  placeholder="Captcha"
                  name="Captcha"
                  className="form-input"
                  value={captcha}
                  onChange={handleInputChange}
                />

                <i className="ic-survey icn-login"></i>
                <label data-label="" className="form-label"></label>
                <span className="erroe-txt" style={{ left: "200px","fontSize": '10px'}}>{captchaInvalid||''}</span>

                <span className="password-show" style={{ top:"3px",left: "225px","fontSize": '20px'}}>
                  {!captchaError ? (
                    (
                      <>
                        <img src={captchaImg} alt="" style={{width:'85%'}}/>
                        <span
                          onClick={loadCaptchaImage}
                          title="reload captcha"
                          className="reload"
                        >
                          &#x21bb;
                        </span>
                      </>
                    )
                  ) : ""}
                </span>
        </div>
      </>
    );
}

export default Captcha;