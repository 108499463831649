import React from "react";
import CibilReportHistory from './CibilReportHistory';
import { numberWithCommas } from "../../../helpers/helpers";

const CibilReportTab = (props) => {

  const LOAN_TYPE = [
    { id: 'home_loan', value: 'Home Loan' },
    { id: 'auto_loan', value: 'Auto Loan' },
    { id: 'gold_loan', value: 'Gold Loan' },
    { id: 'two_Wheeler_loan', value: 'Two Wheeler Loan' },
    { id: 'personal_loan', value: 'Personal Loan' },
    { id: 'business_loan', value: 'Business Loan' },
    { id: 'consumer_loan', value: 'Consumer Loan' }, 
    { id: 'credit_card_account', value: 'Credit Card Loan' }
  ] 
  return (

    <div>
      <div style={{ cursor: 'pointer' }} onClick={() => props.setTabtype(null)}>❰ Back</div><br />
      {
        props.tabType == 'credit_utilization' ?
          <div>
            <h3 className="cibilscoreheading">Credit Card Utilisation</h3>

            <div className="cibilloancard">
              <div className="totalaccount"> Total Credit Cards : <span> {props.cibilRecord?.credit_utilization_details?.active_account_count + props.cibilRecord?.credit_utilization_details?.closed_account_count}</span></div>

              <div className="acountdetails">
                <div className="activeaccounttotal">
                  <div className="accountommon">{props.cibilRecord?.credit_utilization_details?.active_account_count}</div>
                  <div>Active</div>
                </div>
                <div className="activeaccounttotal">
                  <div className="accountommon">{props.cibilRecord?.credit_utilization_details?.closed_account_count}</div>
                  <div>close</div>
                </div>
              </div>
            </div>

            <div className="loanaccountcardtwo">
              <div className="homeloandetails">
                <div>Active Credit Limit</div>
                <div className="commondetails">     ₹ {numberWithCommas(props.cibilRecord?.credit_utilization_details?.active_credit_limit)}</div>
              </div>
              <div className="homeloandetails">
                <div>Active Outstanding</div>
                <div className="commondetails">     ₹ {numberWithCommas(props.cibilRecord?.credit_utilization_details?.active_outstanding)}</div>
              </div>
              <div className="homeloandetails">
                <div>Closed Credit Limit</div>
                <div className="commondetails">     ₹ {numberWithCommas(props.cibilRecord?.credit_utilization_details?.closed_credit_limit)}</div>
              </div>
              <div className="homeloandetails">
                <div>Closed Outstanding</div>
                <div className="commondetails">     ₹ {numberWithCommas(props.cibilRecord?.credit_utilization_details?.closed_outstanding)}</div>
              </div>
            </div>

          </div>
          :
          props.tabType == 'credit_age' ?
            <div className="credit-age">
              <h3 className="cibilscoreheading">Credit Age </h3>

              <div className="loanaccountcardtwo">
                <div className="homeloandetails">
                  <div>Length of Credit History</div>
                  <div className="commondetails">{props.cibilRecord?.credit_age?.length_of_credit_history}</div>
                </div>
                {/* <div className="homeloandetails">
                  <div>Average Account Age</div>
                  <div className="commondetails">0 Years, 0 Months</div>
                </div>
                <div className="homeloandetails">
                  <div>Oldest Account Age</div>
                  <div className="commondetails">{props.cibilRecord?.credit_age?.oldest_loan_type || 'NA'}</div>
                </div>
                <div className="homeloandetails">
                  <div>Most Recent Account</div>
                  <div className="commondetails">{props.cibilRecord?.credit_age?.most_recent_loan_type || 'NA'}</div>
                </div> */}
              </div>

            </div>
            :
            props.tabType == 'enquires' ?

              <div>
                <h3 className="cibilscoreheading">Enquiries</h3>
                <div className="cibilloancard">
                  <div className="totalaccount"> Total Enquiries (in 1 yr) : <span> {props.cibilRecord?.enquiry_details?.enquiries_in_last_1_year}</span></div>

                  <div className="acountdetails">
                    <div className="activeaccount">
                      <div className="accountommon">{props.cibilRecord?.enquiry_details?.enquiries_in_last_7_days}</div>
                      <div>7 Days</div>
                    </div>
                    <div className="activeaccount">
                      <div className="accountommon">{props.cibilRecord?.enquiry_details?.enquiries_in_last_1_month}</div>
                      <div>1 Month </div>
                    </div>
                    <div className="activeaccount">
                      <div className="accountommon">{props.cibilRecord?.enquiry_details?.enquiries_in_last_3_month}</div>
                      <div>3 Months</div>
                    </div>
                    <div className="activeaccount">
                      <div className="accountommon">{props.cibilRecord?.enquiry_details?.enquiries_in_last_6_month}</div>
                      <div>6 Months</div>
                    </div>
                  </div>
                </div>
                <div className="tablelist">
                  <table>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>#Enquiries</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        LOAN_TYPE.filter(ob=>ob.id !='credit_card_account').map(item => {
                          return (
                            <tr>
                              <td> {item.value}</td>
                              <td>{props.cibilRecord?.loan_accounts?.[item.id]?.total_enquiry}</td>
                              <td> ₹ {numberWithCommas(props.cibilRecord?.loan_accounts?.[item.id]?.total_enquiry_amount)}</td>
                            </tr>)
                        })
                      }
                    </tbody>

                  </table>
                </div>

              </div>
              :
              props.tabType == 'loan_accounts' ?
                <div className="loan-account">
                  <h3 className="cibilscoreheading">Loan Account Details</h3>

                  {
                    LOAN_TYPE.map((item) => {
                      if (!props.cibilRecord?.loan_accounts?.[item.id]) {
                        return null
                      }
                      if((props.cibilRecord?.loan_accounts?.[item.id]?.active_account_count + props.cibilRecord?.loan_accounts?.[item.id]?.closed_account_count) ==0){
                        return null
                      }
                      return (<div className="home-laon">
                        <div className="row cc-util-card loan-cd">
                          <div className="col-sm-6">
                            <span className="loan-bold">Account Type</span>
                            <div>{item.value}</div>
                          </div>
                          <div className="col-sm-6">
                            <span className="loan-bold">Account</span>
                            <div>{props.cibilRecord?.loan_accounts?.[item.id]?.active_account_count + props.cibilRecord?.loan_accounts?.[item.id]?.closed_account_count}</div>
                          </div>
                        </div>

                        <div className="row cc-util-card cc-mt loan-cd">
                          <div className="col-sm-4">
                            <span> <span className="loan-active">●</span> Active </span>
                            <p>{props.cibilRecord?.loan_accounts?.[item.id]?.active_account_count}</p>
                          </div>
                          <div className="col-sm-4">
                            Loan Amount
                            <p>₹ {numberWithCommas(props.cibilRecord?.loan_accounts?.[item.id]?.active_total_sanctioned_amt)}</p>
                          </div>
                          <div className="col-sm-4">
                            Outstanding
                            <p>₹ {numberWithCommas(props.cibilRecord?.loan_accounts?.[item.id]?.active_total_outstanding_amt)}</p>
                          </div>
                        </div>

                        <div className="row cc-util-card cc-mt loan-cd">
                          <div className="col-sm-4">
                            <span> <span className="loan-close">●</span> Closed </span>
                            <p>{props.cibilRecord?.loan_accounts?.[item.id]?.closed_account_count}</p>
                          </div>
                          <div className="col-sm-4">
                            Loan Amount
                            <p>₹ {numberWithCommas(props.cibilRecord?.loan_accounts?.[item.id]?.closed_total_sanctioned_amt)}</p>
                          </div>
                          <div className="col-sm-4">
                            Outstanding
                            <p>₹ {numberWithCommas(props.cibilRecord?.loan_accounts?.[item.id]?.closed_total_outstanding_amt)}</p>
                          </div>
                        </div>
                      </div>)
                    })
                  }

                </div>
                :
                props.tabType == 'payment_history' ?
                   <CibilReportHistory cibilRecord={props.cibilRecord} />
                  :
                  <div>oo</div>

      }

    </div>
  );
};
export default CibilReportTab;
