import React, { Component } from "react";
// import InputField from './../../elements/InputField'
// import Button from '../../elements/Button'
// import MasterService from './../../../service/MasterService';
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import InputField from "../../../elements/Input";
import Button from "../../../elements/Button";
import { MasterService } from "../../../../services";

class BlackListPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blackListUpdateData: this.props.blackListUpdateData,
      currentIndex: this.props.currentIndex,
      current_org_name: this.props.current_org_name,
      status: this.props.status,
      text: "",
      errors: {},
      text: '',
      errors: {},
    };
  }

  componentDidMount = () => {
    this.loadAllComponentData();
  };

  loadAllComponentData = async () => {};

  submitBlackListForm = async (event) => {
    event.preventDefault();
    var thisObj = this;
    if (this.validFormData()) {
      const response = await MasterService.post("dealer/dealer/status_change", {
        ...this.state.blackListUpdateData,
      });
      if (response.data.status == 200) {
        toast.success(response.data.message);
        if (typeof thisObj.props.updateDealerListData === "function") {
          thisObj.props.updateDealerListData(
            { ...this.state.blackListUpdateData },
            thisObj.state.currentIndex
          );
        }
        this.props.modalClose();
      } else {
        toast.error(response.data.message);
      }
    }
  };
  validFormData = () => {
    let blackListUpdateData = this.state.blackListUpdateData;
    let errors = {};
    let formIsValid = true;
    if (!blackListUpdateData.blacklist_comment) {
      errors["blacklist_comment"] = this.props.t(
        "Listing.Blacklist_Popup.blacklist_comment_required"
      );
      formIsValid = false;
    }
    this.setState({ errors: errors });
    return formIsValid;
  };
  handleChangetext = (event) => {
    this.setState({ text: event.target.value });
    let fieldRequired = event.target.getAttribute("validationreq");
    let blackListUpdateData = this.state.blackListUpdateData;
    let errors = this.state.errors;
    if (fieldRequired === "yes") {
      if (event.target.value === "") {
        errors[event.target.name] = this.props.t(
          "Listing.Blacklist_Popup.blacklist_comment_required"
        );
        this.setState({ error: 'form error' })
      } else {
        delete errors[event.target.name];
      }
    }
    blackListUpdateData[event.target.name] = event.target.value;
    this.setState({ blackListUpdateData }, () => {});
  };
  render() {
    const { formData, errors, current_org_name } = this.state;
    let css = "";
    if (errors) {
      css = `.form-error {display:block}`;
    }
    let actType = "";
    if (parseInt(this.state.blackListUpdateData.status) == 1) {
      actType = "Active";
    } else if (parseInt(this.state.blackListUpdateData.status) == 2) {
      actType = "Inactive";
    }
    // const containerStyle = {
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   padding: 20,
    // };
    return (
      <div>
        {/* <style type="text/css">{css}</style> */}
        <h2>{this.props.status === '2' ? 'Activate Partner' : 'Inactive Partner' }</h2>
        <br></br>
        {/* <p>Are you sure to {actType} {current_org_name} dealer!</p> */}

        <div
          // style={{
          //   flexDirection: "column",
          //   width: 450,
          //   padding: 10,
          //   display: "flex",
          //   alignItems: "flex-start",
          // }}
        >
          <div
            // style={{
            //   padding: 10,
            // }}
          >
            <h3 style={{marginBottom:20}}>Are you sure to {this.props.status === '2' ? 'Activate' : 'Inactivate' } <b>{current_org_name ?? 'ambak'}</b></h3>
          </div>
          <InputField
           id={"blacklist_comment"}
           name= {"blacklist_comment"}
           type={"text"}
            placeholder={' '}
            value={this.state.text}
            label={'Comments'}
            autocompleate={"off"}
            validationreq= {"yes"}
            validation_error={errors.validFormDataError}
            label_before_input={true}
            // inputProps={{
            //   id: "blacklist_comment",
            //   type: "text",
            //   name: "blacklist_comment",
            //   autocompleate: "off",
            //   validationreq: "yes",
            //   validation_error: errors.blacklist_comment,
            //   label_before_input: "true",
            // }}
            onChange={this.handleChangetext}
          />
        </div>
        <div
            // style={{
            //   flexDirection: "column",
            //   width: 450,
            //   padding: 20,
            //   display: "flex",
            //   alignItems: "flex-end",
            //   justifyContent: "space-evenly",
            // }}
        >
          <Button
            onClick={this.submitBlackListForm}
            colclass="col-sm-12"
            btnClass="btn btn-primary"
            name="addleadbtn"
            id="addleadbtn"
            title={"Save"}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

export default withTranslation("dealers")(
  connect(mapStateToProps)(BlackListPopup)
);
