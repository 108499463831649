import React, { useEffect, useState } from 'react';

const NotFound = () => {

  return (
    <section className="page_404">
     <div class="wrapper">
    <div class="typing-demo">
      404, Page not found.
    </div>
</div>

      <style>{`
       .wrapper {
        height: 100vh;
        /*This part is important for centering*/
        display: grid;
        place-items: center;
      }
      
      .typing-demo {
        width: 22ch;
        animation: typing 2s steps(22), blink .5s step-end infinite alternate;
        white-space: nowrap;
        overflow: hidden;
        border-right: 3px solid;
        font-family: monospace;
        font-size: 2em;
      }
      
      @keyframes typing {
        from {
          width: 0
        }
      }
          
      @keyframes blink {
        50% {
          border-color: transparent
        }
      }
      `}</style>
    </section>
  );
};

export default NotFound;
