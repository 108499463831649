import { gql } from '@apollo/client';
/* export const GET_MASTER_DATA = (vehicle_type=0)=> gql`{
  masterdata(api_called_by: web,vehicle_type:${vehicle_type}) {
      qualification {
          id,
          label,
          value
      },
     
      duration_of_stay {
          id,
          label,
          value
      },
      make_year {
          id,
          label,
          value
      },
      dp_value {
          id,
          label,
          value
      },
      date_type {
          id,
          label,
          value
      },
      customer_type {
          id,
          label,
          value
      },
      marital_status {
          id,
          label,
          value
      },
      nationality {
          id,
          label,
          value
      },
      bank_list {
        id,
        label,
        value
      }
      
      tenure {
        id,
        label,
        value
      }
      sub_status_dates{
        label
        value
      }
  }
}
`; */

export const GET_MASTER_DATA = () => gql`{
	masterdata(api_called_by: web) {
    qualification{
      id,
      label
    },
    tenure{
      id,
      label
    }duration_of_stay{
      id,
      label
    },
    customer_type{
      id,
      label
    },
    company_type{
      id,
      label
    },
    property_type{ 
      id,
      label
    },
    property_sub_type{ 
      id,
      label
    },
    loan_type{ 
      id,
      label
    },
    agreement_type{
      id,
      label
    },
    usage_type{
      id,
      label
    },
    marital_status{
      id,
      label
    },
    fulfillment_type{
      id,
      label
    },
    bank_list{
      id,
      banklang{
        bank_name
      }
    },
    tenure{
      id,
      label
    },
    profession{
      id,
      label
    },
    salary_credit_mode{
      id,
      label
    },
    partner_list{
      value,
      label
    },
    relationship{
      id
      label
      value
    },
    transaction_mode{
      id
      label
      value
    },
    loan_sub_type{
      id
      label
    },
    subvension_type{
      id
      label
      value
    },
    cross_sell_product_type{
      id
      label
      value
    },
    subsource_type{
      id
      source_id
      source_name
      sub_source_name
    },
    additional_income_type{
      id
      label
    },      
    obligation_type{
      id
      label
    },      
    more_detail_type{
      id
      label
    }      
  }
}`;

// export const GET_MASTER_DATA_MMV = (vehicle_type=0)=> gql`{
// 	masterdata(api_called_by: web,vehicle_type:${vehicle_type}) {
//     vehicle_type {
//       id,
//       label,
//       value
//   },
//       make {
//         make
//         id
//       }
//       model {
//         m
//         id
//         mk_id
//         s_year
//         e_year
//       }
//       version {
//         vn
//         vn_id
//         mk_id
//         md_id
//         s_year
//         e_year
//         c_b_type
//       }
// 	}
// }
// `;


export const GET_MASTER_DATA_MMV = () => gql`{
	masterdata(api_called_by: web) {
  qualification {
    id,
    label
  },
  tenure{
    id,
    label
  },
  bank_list {
    id
    banklang{
      bank_name
    }
  }
}
`;
