import FormsyInputField from "../elements/FormsyInputField";
import FormsySelect from "../elements/FormsySelect";
import { SALUTATION } from "../../config/constants";
import React, { useEffect, useState } from "react";
import Formsy from "formsy-react";

const CoApplicantDetails = ({
  index,
  setCASalutation,
  removeCoApplicant,
  additionalCaSalutationData,
}) => {
  const [caDetails, setCaDetails] = useState([{}]);
  let ca_salutation = "ca_salutation";
  let ca_first_name = "ca_first_name";
  let ca_last_name = "ca_last_name";

  useEffect(() => {
    if (additionalCaSalutationData && additionalCaSalutationData.length > 0) {
      setCaDetails(additionalCaSalutationData);
    }
  }, [additionalCaSalutationData]);

  const handleSelectChange = (data, field_name, index) => {
    if (field_name === 'ca_salutation') {
      const updatedCaDetails = [...caDetails];
      while (updatedCaDetails.length <= index) {
          updatedCaDetails.push({});
      }
      updatedCaDetails[index] = {
          ...updatedCaDetails[index],
          ca_salutation: data.value
      };
      setCaDetails(updatedCaDetails);
      setCASalutation(data, index, field_name);
    }
  };

  const handleInput = (data, field_name, index) => {
    setCASalutation(data, index, field_name);
  };

  const handleRemoveCoApplicant = (index) => {
    removeCoApplicant(index);
  };

  return (
    <>
      <Formsy
        className="lead-form-filed"
        autoComplete="off"
        onValid={() => null}
        onInvalid={() => null}
      >
        <div className="row">
          <fieldset className="single-select col-md-6">
            <FormsySelect
              name={ca_salutation}
              id={ca_salutation}
              inputProps={{
                options: SALUTATION,
                placeholder: "Ca Salutation",
                className: "react-select",
                classNamePrefix: "react-select",
                value:
               SALUTATION &&
                  
               SALUTATION.filter(
                   ({ value }) =>
                     value == caDetails[index]?.[ca_salutation]
                 ),
               isDisabled: false,
              }}
              required={false}
              value={caDetails && caDetails[index]?.[ca_salutation]}
              onChange={(data) => handleSelectChange(data, ca_salutation, index)}
            />
          </fieldset>
         
          <fieldset className={"form-filed col-md-6"}>
            <FormsyInputField
              id={ca_first_name}
              name={ca_first_name}
              type={"text"}
              value={caDetails[index]?.[ca_first_name] || ""}
              // thousandsGroupStyle="lakh"
              // thousandSeparator={","}
              // decimalSeparator={"."}
              label={"Ca First Name"}
              format={""}
              validations={null}
              validationError={""}
              readOnly={false}
              required={false}
              onChange={(data) =>
                handleInput(data, ca_first_name, index)
              }
            />
          </fieldset>

          <fieldset className={"form-filed col-md-6"}>
            <FormsyInputField
              id={ca_last_name}
              name={ca_last_name}
              type={"text"}
              value={caDetails[index]?.[ca_last_name] || ""}
              // thousandsGroupStyle="lakh"
              // thousandSeparator={","}
              // decimalSeparator={"."}
              label={"Ca Last Name"}
              format={""}
              validations={null}
              validationError={""}
              readOnly={false}
              required={false}
              onChange={(data) =>
                handleInput(data, ca_last_name, index)
              }
            />
          </fieldset>
          <div
            type="button"
            className="fetchAadharbtn"
            onClick={() => handleRemoveCoApplicant(index)}
          >
            Remove
          </div>
        </div>
      </Formsy>
    </>
  );
};

export default CoApplicantDetails;
