import Q from "q";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { DealerService, InventoryService } from "../../../services/index";
import Button from "../../elements/Button";
import InputField from "../../elements/Input";
import MasterService from "../../../services/MasterService";
import Select, { useStateManager } from "react-select";
import Modal from "./modal/modal";
import ReactPanZoom from "react-image-pan-zoom-rotate";
import uploadSvg from "../../../webroot/images/upload_icn.svg";
import downloadSvg from "../../../webroot/images/download.svg";
import greenTickSvg from "../../../webroot/images/greentick.svg";
import nonVeryfiedSvg from "../../../webroot/images/norverified.svg";
import saveSvg from "../../../webroot/images/saveicon.svg";
import {
  USERS_FOR_VIEW_AMBAK_LEADS,
  IS_USER_ACCESS_PAGE,
} from "../../../config/constants";
import secureStorage from "../../../config/partnerEncrypt";
import { executeGraphQLQueryFinex } from "../../../common/executeGraphQLQueryFinex";
import { FINEX_MASTER } from "../../../services/scheme.gql";
import { formatError } from "graphql";
import { getCurrentUserDetails } from "../pre-register-lead-manager/utility/getUserInfo";

const rejection_reason = [
  { id: 1, name: "Personal details are wrong" },
  { id: 2, name: "Wrong partner type is marked" },
  { id: 3, name: "Phone number is wrong" },
  { id: 4, name: "Email is wrong" },
  { id: 5, name: "Address is currently unservicable" },
  { id: 6, name: "Wrong PAN number" },
  { id: 7, name: "Wrong aadhar" },
  { id: 8, name: "Wrong GSTIN" },
  { id: 9, name: "Wrong MSME" },
  { id: 10, name: "BM not available" },
  { id: 11, name: "Aadhar photo blur" },
  { id: 12, name: "Photo invalid" },
  { id: 13, name: "Name does not match" },
  { id: 14, name: "DOB does not match" },
  { id: 15, name: "Aadhar number is entered wrong" },
  { id: 16, name: "Address wrong" },
  { id: 17, name: "Aadhar verification failed" },
];
const KycDocument = (props) => {
 

  const dealer_id_hash = props.dealer_id_hash;
  const dealer_id = props.dealer_id;
  const kyc_master_id = props.kyc_master_id;
  const basic_detail = props.basic_details;
  const kycDealerData = props.kycDealerData ;
  const bankList = props.bankList;
  const loginUserId = props.loginUserId;
  const [formData, setFormData] = useState({
    doc_number: "",
    comment: "",
    verified_status_new: "1",
    file_url: "",
    kyc_doc_type: "",
    dealer_id: "",
    kyc_master_id: kyc_master_id,
    basic_detail: basic_detail,
    kycDealerData: kycDealerData,
    bankName: bankList,
    reject_reason: 0,
    bank_id: 0,
    reject_reason_arr: [],
  });
 
  const bankData = props.kycDealerData 
  const [dealerDocData, setDealerDocData] = useState({
    kycDealerDocData: [],
  });
  const [Finaldata, setFinalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [aadharDisabled, setAadharDisabled] = useState(false);
  const [aadharVerified, setAadharVerified] = useState(false);
  const [panDisabled, setPanDisabled] = useState(false);
  const [panGSTDisabled, setPanGSTDisabled] = useState(false);
  const [panVerified, setPanVerified] = useState(false);
  const [bankDisabled, setBankDisabled] = useState(false);
  const [bankVerified, setBankVerified] = useState(false);
  const [gstVerified, setGstVerified] = useState(false);
  const [companypPanVerified, setcompanypPanVerified] = useState(false);
  const [reject, setReject] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [imageExtention, setImageExtention] = useState(null);
  const [iconDisabled, setIconDisabled] = useState(false);
  const [verified_icon, setVerified_icon] = useState(false);
  const [banklist, setBankList] = useState([]);
  const [errors, setErrors] = useState({});
  const [kycDealerdata, setKycDealerData] = useState({});
  const [accountLength, setAccountLength] = useState(0);
  const [bankName, setBankName] = useState("");
  const [bankId, setBankID] = useState(null);
  const [acNumber, setAcNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [acHolderName, setAcHolderName] = useState("");
  const [updatedBankData, setUpdatedBankData] = useState(bankData);
  const [rejectReason, setRejectReason] = useState('');
  const [arr, setArr] = useState([])
  const [showError , setShowError] = useState(false)
  const [verify , setAfterVerify] = useState(false)
  const validationData = [
    {
      master_doc_id: 1,
      regex: "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
      length: 12,
    },
    {
      master_doc_id: 2,
      regex: "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
      length: 12,
    },
    {
      master_doc_id: 3,
      regex: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
      length: 10,
    },
    {
      master_doc_id: 4,
      regex: "",
      length: 1,
    },
    {
      master_doc_id: 5,
      regex: "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$",
      length: 15,
    },
    {
      master_doc_id: 6,
      regex: "[A-Z]{5}[0-9]{4}[A-Z]{1}",
      length: 10,
    },
    {
      master_doc_id: 7,
      regex: "",
      length: 5,
    },
  ];

  useEffect(() => {
    setUpdatedBankData(bankData);
  },[bankData])

  const validFormData = (kycDealerData) => {
    let errors = {};
    let formIsValid = true;
    // const aadhaarRegex = /^\d{12}$/;
    // const panCardRegex = /[A-Z,a-z]{5}[0-9]{4}[A-Z,a-z]{1}$/;
    // if (!formData['bank_id']) {
    //     formIsValid = false;
    //     errors["bank_id"] = this.props.t('dealerDetails.kyc_details.validation_texts.bank_name_required');
    // }
    if (!kycDealerData.bank_ifsc_code) {
      errors["bank_ifsc_code"] = this.props.t(
        "dealerDetails.kyc_details.validation_texts.bank_ifsc_code_required"
      );
      formIsValid = true;
    }
    if (!kycDealerData.beneficiary_name) {
      errors["beneficiary_name"] = "Please enter beneficiary name";
      formIsValid = true;
    }
    if (!kycDealerData.bank_account_no) {
      formIsValid = false;
      errors["bank_account_no"] = "Please enter bank account number";
    }

    setErrors(errors);
    return formIsValid;
  };

  const handleBankInfoChange = (basic_detail, kycDealerData) => {
    // e.preventDefault();
    const approve = window.confirm(
      "Are you sure want to change the Bank Info?"
    );
    if (approve === true) {
      setIconDisabled(true);
      saveBankDetail(basic_detail, kycDealerData);
    }
  };

  const handleMsmeGstChange = (data) => {
    const userInfo = getCurrentUserDetails();
    const approve = window.confirm(
      "Are you sure want to change ?"
    );
    if (approve === true) {
      setIconDisabled(true);
      let param = ''
      if(data.gst_number) {
        param ={
          user_id: userInfo?.userId,
          partner_id: basic_detail?.id,
          gst_number: data.gst_number,
        }
        saveMsmeGstDetail(param);
      } else if (data.msme_number) {
        param = {
          user_id: userInfo?.userId,
          partner_id: basic_detail?.id,
          msme_number: data.msme_number,
        }
        saveMsmeGstDetail(param);
      } else {
        toast.warning('No any changes detected')
      }
    }
  }

  const saveMsmeGstDetail = async (param) => {
    MasterService.post('/partner/partner/save-msme-gst-details', param).then((response) => {
      toast.success(response.data.response);
      props.refreshData()
    }).catch((err) => {
      toast.error('something went wrong');
    });
  }

  const handleSaveClick = (basic_detail) => {
    //   e.preventDefault();
    const approve = window.confirm("Are you sure want to change the Address?");
    if (approve === true) {
      setIconDisabled(true);
      saveBasicDetail(basic_detail);
    }
  };
  const reject_kyc = IS_USER_ACCESS_PAGE(
    "partner",
    "reject_kyc"
  )?.is_edit_access;
  const verify_kyc = IS_USER_ACCESS_PAGE(
    "partner",
    "verify_kyc"
  )?.is_edit_access;
  const account_details = IS_USER_ACCESS_PAGE(
    "partner",
    "account_details"
  )?.is_edit_access;
  const change_kyc_docs = IS_USER_ACCESS_PAGE(
    "partner",
    "change_kyc_document"
  )?.is_edit_access;
  const update_msme_gst = IS_USER_ACCESS_PAGE(
    "partner",
    "update_msme_gst"
  )?.is_edit_access;

  const saveBankDetail = async (basic_detail, kycDealerData) => {
    const userInfo = getCurrentUserDetails();
    const {userId} = userInfo;
    if (validFormData(kycDealerData)) {
      const saveDetails = kycDealerData;
      const basicDetails = basic_detail;
      let account_type;
      if (saveDetails?.bank_account_type === "current") {
        account_type = 2;
      }
      if (saveDetails?.bank_account_type === "saving") {
        account_type = 1;
      }
      const requestData = {
        partner_id: basicDetails?.id,
        dealer_id: basicDetails?.id,
        user_id: basicDetails?.user_id,
        account_number: saveDetails?.bank_account_no,
        account_type: account_type,
        bank_name: Number(saveDetails.bank_id),
        beneficiary_name: saveDetails?.beneficiary_name,
        ifsc_code: saveDetails?.bank_ifsc_code,
        upi_id: saveDetails?.upi_id,
        source: "Sangam Backend",
        created_by: userId ?? 0,
      };
      await MasterService.post(
        "/partner/partner/save-bank-details",
        requestData
      )
        .then((response) => {
          setLoading(false);
          if (response.data.status == 200 && response.status == 200) {
            let responseData = response.data;
            let other = {};
            toast.success("Done");
          } else {
            toast.error(
              response.data.message && response.data.message.length > 0
                ? response.data.message[0]
                : "Error"
            );
          }
        })
        .catch((error) => console.log(error));
    } else {
      toast.error("Please fill all the required parameters");
    }
  };

  const saveBasicDetail = async (basic_detail) => {
    if (basic_detail) {
      const saveDetails = basic_detail;
      const requestData = {
        name: saveDetails?.first_name,
        email: saveDetails?.dealership_email,
        mobile: saveDetails?.dealership_contact,
        is_pan_verified: 1,
        //birth_date: saveDetails?.dealership_dob,
        applicant_type_id: saveDetails?.applicant_type
          ? Number(saveDetails?.applicant_type)
          : 1,
        profession_id: saveDetails?.profession_id,
        address: saveDetails?.address,
        pincode: saveDetails?.pincode ? String(saveDetails?.pincode) : "",
        user_type: "1",
        term_condition: true,
        partner_id: saveDetails?.id > 0 ? saveDetails.id : 0,
        user_id: saveDetails?.user_id > 0 ? saveDetails.user_id : 0,
        source: "Sangam Backend",
        created_by: 0,
        pan_no: saveDetails?.pan_no,
        pan_number: saveDetails?.pan_no,
        //sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails.sfa_referral_user_id : saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
        //assigned_bm_id: saveDetails?.assigned_bm_id > 0 ? saveDetails.assigned_bm_id : 0,
        sfa_referral_user_id: saveDetails?.sfa_referral_user_id > 0 ? saveDetails.sfa_referral_user_id : 0,
      };
      if (saveDetails?.latitude) {
        requestData.latitude = saveDetails?.latitude
          ? String(saveDetails?.latitude)
          : "";
      }
      if (saveDetails?.longitude) {
        requestData.longitude = saveDetails?.longitude
          ? String(saveDetails?.longitude)
          : "";
      }
      if (saveDetails?.dealership_dob) {
        let date_of_joining = new Date(saveDetails?.dealership_dob);
        let join_date =
          date_of_joining.getFullYear() +
          "-" +
          (date_of_joining.getMonth() + 1) +
          "-" +
          date_of_joining.getDate();
        requestData.birth_date = join_date;
      }

      if (saveDetails.aadhar_no) {
        requestData.aadhar_no = saveDetails?.aadhar_no;
      }
      if (saveDetails.applicant_type === "2") {
        requestData.company_type = saveDetails?.company_type;
      }

      if (saveDetails.is_aadhar_pan_linked === 1) {
        requestData.is_aadhar_pan_linked = 1;
        requestData.is_pan_verified = 1;
      }

      if (
        saveDetails?.applicant_type === "2" ||
        saveDetails?.applicant_type === "3"
      ) {
        requestData.is_gst = saveDetails?.is_gst;
        requestData.gst_number = saveDetails?.gst_number;
        requestData.is_msme = saveDetails?.is_msme;
        requestData.msme_number = saveDetails?.msme_number;
        requestData.spoc_name = saveDetails?.spoc_name || "";
        requestData.spoc_email = saveDetails?.spoc_email || "";
        requestData.spoc_mobile = saveDetails?.spoc_phone_number || "";
        requestData.spoc_designation = saveDetails?.spoc_designation || "";
      }
      if (loginUserId) {
        requestData.updated_by = loginUserId;
      }
      setLoading(true);
      await MasterService.post(
        "/partner/partner/save-basic-details",
        requestData
      )
        .then((response) => {
          if (response.data.status == 200 && response.status == 200) {
            let responseData = response.data;
            let other = {};
            toast.success("Done");
            let city_name =
              responseData.data &&
              responseData.data.response &&
              responseData.data.response.city_name;
            let state_name =
              responseData.data &&
              responseData.data.response &&
              responseData.data.response.state_name;

            if (city_name) {
              basic_detail.cityname = city_name;
            }
            if (city_name) {
              basic_detail.state_name = state_name;
            }

            setLoading(false);
          } else {
            toast.error(
              response.data.message && response.data.message.length > 0
                ? response.data.message[0]
                : "Error"
            );
          }
        })
        .catch((error) => console.log(error));
    }
  };

  const RejectedPartnerPopup = async () => {
    try {
      if (formData) {
        const requestData = {
          //user_id: formData?.user_id ||,
          kyc_master_id: formData.kyc_master_id,
        };
        setLoading(true);
        await MasterService.post(
          "/partner/partner/get-reject-reasons",
          requestData
        )
          .then((response) => {
            setLoading(false);
            if (
              response &&
              response.data.status == 200 &&
              response.status == 200
            ) {
              setReject(false);
              setFormData({
                ...formData,
                reject_reason_arr: response.data.data.response,
              });
            }
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      toast.error(err);
    }
    setReject(true);
  };


  useEffect(() => {
    if (formData.kyc_master_id) {
      const requestData = {
        kyc_master_id: formData.kyc_master_id,
      };


      setLoading(true);

      MasterService.post("/partner/partner/get-reject-reasons", requestData)
        .then((response) => {
          setLoading(false);

          if (response && response.data.status === 200 && response.status === 200) {
            setArr(response.data.data.response)
            setFormData((prevFormData) => ({
              ...prevFormData,
              reject_reason_arr: response.data.data.response,
            }));
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [formData.kyc_master_id]);

  useEffect(() => {
    const reason = arr?.find((item) => item.id === formData.reject_reason);
    setRejectReason(reason ? reason.reject_reason : '');
  }, [ formData.reject_reason_arr]);


 
  const RejectedPartner = async () => {
    try {
      if (formData) {
        const requestData = {
          partner_id: basic_detail?.id,
          //user_id: formData?.user_id ||,
          kyc_master_id: formData.kyc_master_id,
          reject_reason: formData?.reject_reason ? formData?.reject_reason : 0,
          reject_reason_comment: formData?.reject_reason_comment
            ? formData?.reject_reason_comment
            : "",
        };

        
        
        if (loginUserId) {
          requestData.updated_by = loginUserId;
        }
        await MasterService.post(
          "/partner/partner/save-kyc-rejection",
          requestData
        )
          .then((response) => {
            setLoading(false);
            if (
              response &&
              response.data.status == 200 &&
              response.status == 200
            ) {
              setReject(false);
              toast.success(
                response.data.message ? response.data.message : "Success"
              );
              setFormData({ ...formData });
              props.handleKycIcon().then();
            } else {
              if (
                response.data.message.length > 0 &&
                response.data.statusText != "OK"
              ) {
                toast.error(
                  response.data.message && response.data.message.length > 0
                    ? response.data.message[0]
                    : "Error"
                );
              } else {
                toast.error(
                  response.data.message ? response.data.message : "Error"
                );
              }
            }
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleRejectReasonChange = (ovalue) => {
   setRejectReason(ovalue.reject_reason)
    let reason = ovalue.id;
    setFormData({ ...formData, reject_reason: reason });
  };

  const handletChange = (e, key = "") => {
    const name = key || (e.target && e.target.name);
    const val = key || (e.target && e.target.value);

    if (name === "address") {
      basic_detail.address = val;
    }
    if (name === "pincode") {
      basic_detail.pincode = val;
    }
    setFormData({ ...formData, [name]: val });
  };

  const handleChangeVerifiedDocs = (sname, index) => {
    let is_active = formData.verified_status_new === "1" ? "0" : "1";
    let kycMasterlist_new = [...dealerDocData.kycDealerDocData];
    if (!kycMasterlist_new) {
      kycMasterlist_new = {};
    }
    kycMasterlist_new[sname] = is_active;
    setDealerDocData({ ...dealerDocData, kycDealerDocData: kycMasterlist_new });
    setFormData({ ...formData, verified_status_new: is_active });
  };
  const removecurrentdoc = () => {
    setFormData({
      ...formData,
      file_url: "",
      kyc_doc_type: "",
      //verified_status: "0",
      verified_status_new: "0",
    });
    //setVerified_icon(false);

  };

  const onChangeHandler = (event) => {
    let files = event.target.files;
    let err = [];
    const types = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "image/gif",
      "application/pdf",
      "application/wps-office.pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];

    let previews = [];

    for (var x = 0; x < files.length; x++) {
      if (types.every((type) => files[x].type !== type)) {
        err[x] = files[x].type + " " + "is not a supported format" + " \n";
        const fileType = files.type;
      }
      if (files[x].size && files[x].size > 8000000) {
        err[x] = "File size should be less than 8 MB" + " \n";
      }

      // Read file as data URL
      let reader = new FileReader();
      reader.onload = () => {
        previews.push(reader.result);
        if (previews.length === files.length) {
          // Set image previews in state after all files are read
          setImagePreviews(previews);
          const imageExtension = previews
            ? previews[0].split(";")[0].split("/")[1]
            : ""; // Extract the image extension
          setImageExtention(imageExtension);
        }
      };
      reader.readAsDataURL(files[x]);
    }

    for (var z = 0; z < err.length; z++) {
      event.target.value = null;
      toast.error(err[z]);
    }
  };

  const formDataToJson = (formData) => {
    const data = {};
    formData.forEach((value, key) => {
      data[key] = value;
    });
    return data;
  };

  const validateForm = (event) => {
    const form = event.target;
    const data = new FormData(form);
    const formdata = formDataToJson(data);
    const validationDataObj = validationData.filter(
      (formdata) => formdata.master_doc_id == kyc_master_id
    );
    const regex = validationDataObj.regex;
    const length = validationDataObj.length;
    return true;
  };

  useEffect(() => {
    getDealerDocKycDetail();
    getBankList();
  }, []);

  const getDealerDocKycDetail = async () => {
    DealerService.getDocKycDetails(dealer_id_hash)
      .then((result) => {
        if (result?.data?.status === 200 && result?.data?.data) {
          const doc_data = result?.data?.data;
          setFinalData(doc_data);
          doc_data &&
            doc_data.map((item) => {
              if (item.kyc_master_id == kyc_master_id) {
                setFormData(item);
              }
            });
        } else {
          toast.error(result.data.message || "Some Error Occurred");
        }
      })
      .catch((error) => {
        toast.error(error.message || "Some Error Occurred");
      });
  };

  const validateAadharNumber = async (basic_detail, kyc_id) => {
    setLoading(true);
    try {
      if (basic_detail) {
        const saveDetails = basic_detail;
        const requestData = {
          partner_id: saveDetails?.id,
          user_id: saveDetails?.user_id,
          kyc_master_id: kyc_id,
          aadhar_number: saveDetails?.aadhar_no
            ? String(saveDetails?.aadhar_no)
            : "",
        };
        await MasterService.post(
          "/partner/partner/validate-aadhar-number",
          requestData
        )
          .then((response) => {
            setLoading(false);
            if (response.data.statusCode == 200) {
              toast.success(
                response.data.message ? response.data.message : "Success"
              );
              setAadharVerified(true);
              setAadharDisabled(true);
            } else {
              if (
                response.data.message.length > 0 &&
                response.data.statusText != "OK"
              ) {
                toast.error(
                  response.data.message && response.data.message.length > 0
                    ? response.data.message[0]
                    : "Error"
                );
              } else {
                toast.error(
                  response.data.message ? response.data.message : "Error"
                );
                setAadharVerified(true);
                setAadharDisabled(true);
              }
            }
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const validatePanNumber = async (basic_detail, kyc_id) => {
    setLoading(true);
    try {
      if (basic_detail) {
        const saveDetails = basic_detail;
        const requestData = {
          partner_id: saveDetails?.id,
          user_id: saveDetails?.user_id,
          kyc_master_id: kyc_id,
          panNumber: saveDetails?.pan_no ? String(saveDetails?.pan_no) : "",
        };
        await MasterService.post(
          "/partner/partner/validate-pan-number",
          requestData
        )
          .then((response) => {
            setLoading(false);
            if (
              response.data.statusCode == 200 &&
              response.data.statusCode == 200
            ) {
              let other = {};
              toast.success(
                response.data.message ? response.data.message : "Success"
              );
              setPanVerified(true);
              setPanDisabled(true);
              setcompanypPanVerified(true);
              basic_detail.aadhar_no =
                response.data.data.maskedAadhaar ||
                this.state.basic_details.aadhar_no;
              basic_detail.is_pan_verified = 1;
              basic_detail.is_aadhar_pan_linked = 1;
              setFormData({ ...formData, basic_detail: basic_detail });
            } else {
              if (
                response.data.message.length > 0 &&
                response.data.statusText != "OK"
              ) {
                toast.error(
                  response.data.message && response.data.message.length > 0
                    ? response.data.message[0]
                    : "Error"
                );
              } else {
                toast.error(
                  response.data.message ? response.data.message : "Error"
                );
                setPanVerified(true);
                setPanDisabled(true);
                setcompanypPanVerified(true);
              }
            }
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const validatePanGSTNumber = async (basic_detail, kyc_id) => {
    setLoading(true);
    try {
      if (basic_detail) {
        const saveDetails = basic_detail;
        const requestData = {
          partner_id: saveDetails?.id,
          user_id: saveDetails?.user_id,
          kyc_master_id: kyc_id,
          panNumber: saveDetails?.pan_no ? String(saveDetails?.pan_no) : "",
        };
        await MasterService.post(
          "/partner/partner/validate-pan-gst-number",
          requestData
        )
          .then((response) => {
            setLoading(false);
            if (
              response.data.statusCode == 200 &&
              response.data.statusCode == 200
            ) {
              let other = {};
              toast.success(
                response.data.message ? response.data.message : "Success"
              );
              setGstVerified(true);
              setPanGSTDisabled(true);
            } else {
              if (
                response.data.message.length > 0 &&
                response.data.statusText != "OK"
              ) {
                toast.error(
                  response.data.message && response.data.message.length > 0
                    ? response.data.message[0]
                    : "Error"
                );
              } else {
                setGstVerified(true);
                setPanGSTDisabled(true);
                toast.error(
                  response.data.message ? response.data.message : "Error"
                );
              }
            }
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const validateBankAccount = async (basic_detail, kyc_id, kycDealerData) => {
    setLoading(true);
    try {
      if (basic_detail) {
        const saveDetails = basic_detail;
        const requestData = {
          partner_id: saveDetails?.id,
          user_id: saveDetails?.user_id,
          kyc_master_id: kyc_id,
          beneficiary_name: kycDealerData.beneficiary_name
            ? kycDealerData.beneficiary_name
            : "",
          account_no: kycDealerData?.bank_account_no
            ? String(kycDealerData?.bank_account_no)
            : "",
          ifsc_code: kycDealerData?.bank_ifsc_code
            ? String(kycDealerData?.bank_ifsc_code)
            : "",
        };
        await MasterService.post(
          "/partner/partner/validate-pennyless",
          requestData
        )
          .then((response) => {
            setLoading(false);
            if (
              response.data.statusCode == 200 &&
              response.data.statusCode == 200
            ) {
              let other = {};
              toast.success(
                response.data.message ? response.data.message : "Success"
              );
              setBankVerified(true);
              setBankDisabled(true);
              kycDealerData.is_name_matched = 1;
              setFormData({ ...formData, kycDealerData: kycDealerData });
            } else {
              if (
                response.data.message.length > 0 &&
                response.data.statusText != "OK"
              ) {
                toast.error(
                  response.data.message && response.data.message.length > 0
                    ? response.data.message[0]
                    : "Error"
                );
              } else {
                toast.error(
                  response.data.message ? response.data.message : "Error"
                );
                setBankVerified(true);
                setBankDisabled(true);
              }
            }
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const updateKycStatus = async (partner_id) => {
    try {
      if (partner_id) {
        const requestData = {
          partner_id: partner_id,
          is_verified:1,
          kyc_master_id:kyc_master_id
        };
        if (loginUserId) {
          requestData.updated_by = loginUserId;
        }
        await MasterService.post(
          "/partner/partner/kyc-update-status",
          requestData
        )
          .then((response) => {
            setShowError(true)
            if (
              response.data.statusCode == 200 &&
              response.data.statusCode == 200
            ) {
              //--here--
              let other = {};
              
            } else {
            }
          })
          .catch((error) => console.log(error));
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const saveKycDetail = (event) => {
    event.preventDefault();
    setLoading(true);
    var formIsValid = false;
    var kyc_errors = [];
    var error = {};
    var parentThis = this;
    let key_error_msg = "";
    const form = event.target;
    const data = new FormData(form);
    const formdata = formDataToJson(data);
    setLoading(false);
    let allfiles = document.querySelectorAll('input[type="file"]');
    if (
      allfiles.length > 0 &&
      allfiles[0].files.length == 0 &&
      formdata.saved_file_name == "" &&
      kyc_master_id !== 4
    ) {
      key_error_msg = key_error_msg + "Please upload doc file! ";
      kyc_errors.push({ doc_error: "Please upload doc file!" });
    } else {
    }
    if (formdata.doc_number == "") {
      key_error_msg = key_error_msg + "Document number is blank! ";
      kyc_errors.push({ doc_error: "Please add doc number" });
    }
    switch (kyc_master_id) {
      case 1:
        if (!basic_detail.first_name) {
          toast.error("Invalid Name!");
          return;
        }
        if (!basic_detail.dealership_dob) {
          toast.error("Invalid DOB!");
          return;
        }
        if (!basic_detail.aadhar_no) {
          toast.error("Invalid Aadhar Number");
          return;
        }

        break;
      case 3:
        if (!basic_detail.first_name) {
          toast.error("Invalid Name!");
          return;
        }
        if (!basic_detail.pan_no) {
          toast.error("Invalid PAN Number");
          return;
        }
        break;
      case 4:
        if (!updatedBankData?.bank_account_no) {
          //i am here
          toast.error("Invalid Account Number!");
          return;
        }
        if (!updatedBankData?.bank_ifsc_code) {
          toast.error("Invalid IFSC Code!");
          return;
        }
        if (
          allfiles.length > 0 &&
          allfiles[0].files.length == 0 &&
          formdata.saved_file_name == "" &&
          updatedBankData?.is_name_matched !== 1
        ) {
          toast.error("Please upload doc file!");
          return;
        }

        updatedBankData.bank_id = updatedBankData.bank_id
          ? updatedBankData.bank_id
          : bankId;
        if (!updatedBankData.bank_id) {
          toast.error("Invalid Bank!");
          return;
        }
        break;
      case 5:
        if (!basic_detail.gst_number) {
          toast.error("Invalid GST Number!");
          return;
        }
        if (!basic_detail.first_name) {
          toast.error("Invalid Name!");
          return;
        }
        break;
      case 6:
        if (!basic_detail.organization) {
          toast.error("Invalid Name!");
          return;
        }
        if (!basic_detail.pan_no) {
          toast.error("Invalid PAN Number");
          return;
        }
        // case 5:
        //     if (!gstVerified) {
        //         toast.error("Please validate GST first.");
        //         return;
        //     }
        //     break;
        // case 6:
        //     if (!companypPanVerified) {
        //         toast.error("Please validate Company PAN No first.");
        //         return;
        //     }
        break;
      default:
    }

    const validationDataObj = validationData.filter(
      (formdata) => formdata.master_doc_id == kyc_master_id
    );
    const validRules =
      validationDataObj && validationDataObj.length > 0
        ? validationDataObj[0]
        : "";
    //  if(validRules && validRules.regex!="" && formdata.doc_number!=""){
    //     if (!formdata.doc_number.match(validRules.regex)) {
    //         key_error_msg = key_error_msg + "Document value is wrong! "
    //         kyc_errors.push({"doc_error": "Document value is wrong!"})
    //     }
    //  }
    // formdata && formdata.length > 0 && Object.keys(formdata).forEach((key) => {
    //     if (key == 'kyccategory' || key == 'kycdoc' || key == 'docnumber' || key == "verified_status_new") {
    //         let key_error_msg = '';
    //         if (key == 'kyccategory') {
    //             key_error_msg = 'key category';
    //         } else if (key == 'kycdoc') {
    //             key_error_msg = 'key doc';
    //         } else if (key == 'docnumber') {
    //             key_error_msg = 'key doc number';
    //         }
    //         if (Array.isArray(formdata[key]) && formdata[key].length > 0) {
    //             formdata[key].forEach((fileskyc, index) => {
    //                 let value = formdata[key];
    //                 let key_doc_value = formdata['kycdoc'];
    //                 if (key == 'docnumber') {
    //                     let tempError = {};
    //                     kyc_errors = tempError;
    //                     if (value != '' && key_doc_value == 2) {
    //                         if (!value.match(/^[0-9]{2}\.[0-9]{3}\.[0-9]{3}\.[0-9]\-[0-9]{3}\.[0-9]{3}$/)) {
    //                             error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
    //                             tempError['docnumber'] = this.props.t('dealerDetails.kyc_details.validation_texts.valid_npwp');
    //                             kyc_errors = tempError;
    //                         }
    //                     }
    //                 } else if (!value) {
    //                     error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
    //                     kyc_errors = error;
    //                 }
    //             })
    //         } else {
    //             let value = formdata[key];
    //             if (!value) {
    //                 error[key] = this.props.t('dealerDetails.kyc_details.validation_texts.please_select') + key_error_msg;
    //                 kyc_errors[0] = error;
    //             }
    //         }

    //     }

    // })
    setFormData({ ...formData, kyc_errors });

    setFormData({ ...formData, ["error_msg"]: key_error_msg });
    if (kyc_errors.length === 0) {
      formIsValid = true;
    }

    if (formIsValid) {
      let allfiles = document.querySelectorAll('input[type="file"]');
      s3UploadFiles(allfiles, formData);
      setAfterVerify(true)
    } else {
      toast.error(key_error_msg);
    }
  };
  const getBankList = async () => {
    try {
      const masterData = await executeGraphQLQueryFinex(FINEX_MASTER());
      if (masterData && masterData.data && masterData.data.masterdata) {
        const bankData = [];
        const bank_list = masterData.data.masterdata.bank_list || [];
        bank_list.forEach((bank) => {
          bankData.push({
            bank_id: bank.id,
            bankname: bank.banklang.bank_name,
          });
        });
        setBankList(bankData);
      } else {
        setBankList([]);
      }
    } catch (err) {
      setBankList([]);
    }
  };

  const handletKycDealerDataTextChange = (sname, ovalue) => {
    
    setUpdatedBankData(prevState => {
      if (sname === "bank_id") {
        return { ...prevState, bank_id: ovalue.bank_id };
      } else if (sname === "bank_account_no") {
        return { ...prevState, bank_account_no: ovalue.target.value };
      } else if (sname === "bank_ifsc_code") {
        return { ...prevState, bank_ifsc_code: ovalue.target.value };
      } else if (sname === "beneficiary_name") {
        return { ...prevState, beneficiary_name: ovalue.target.value };
      } else if (sname === "upi_id") {
        return { ...prevState, upi_id: ovalue.target.value };
      }
      return prevState;
    });

    if (kycDealerData) {
      if (sname === "bank_id") {
        kycDealerData.bank_id = ovalue.bank_id;
      } else if (sname === "bank_account_no") {
        kycDealerData.bank_account_no = ovalue.target.value;
      } else if (sname === "bank_ifsc_code") {
        kycDealerData.bank_ifsc_code = ovalue.target.value;
      } else if (sname === "beneficiary_name") {
        kycDealerData.beneficiary_name = ovalue.target.value;
      } else if (sname === "upi_id") {
        kycDealerData.upi_id = ovalue.target.value;
      }
    }
  };

  const handlebankListOptionChange = (ovalue) => {
    // console.log ("-----here---------" , ovalue,'sssssss',ovalue.bank_id)
    // // setBankID(ovalue.bank_id);
    // console.log("ovalue.bank_idovalue.bank_idovalue.bank_id", ovalue.bank_id);
    // console.log("kycDealerDatakycDealerData",kycDealerData);
    // formData.bank_id  =  ovalue.bank_id;
    //  setFormData({ ...formData, formData: formData });
    //   console.log("formDataformDataformDataformData",formData);
    //   console.log("kycDealerDatakycDealerData11111",kycDealerData);
    kycDealerData.bank_id = ovalue.bank_id;
    formData.bank_id = ovalue.bank_id;
  };

  const s3UploadFiles = (allfiles, formPostdata) => {
    let dealerKycDocSaveArr = [...Finaldata];
    if (allfiles.length) {
      setFormData({ ...formData, loading: false });
      let prom = [];
      allfiles.forEach((fileskyc, index) => {
        let filesData = fileskyc.files;
        var formData = new FormData();

        formData.append("upload_type", "dealer_docs");
        formData.append("dealer_id", dealer_id);
        if (filesData.length) {
          formData.append("images", filesData[0]);
          prom.push(
            InventoryService.uploadFilesToS3(formData, {
              "Content-Type": "multipart/form-data",
            })
          );
        } else {
          prom.push(
            Promise.resolve({
              data: { status: 200, message: "Success", data: ["not_updated"] },
            })
          );
        }
      });

      Q.allSettled(prom)
        .then((op) => {
          op.forEach((res, index) => {
            let response = res.value || [];
            if (res.state == "fulfilled") {
              // return true;
              // console.log("dffff", formPostdata);
              if (response.data.data.length) {
                let tempFileName = "";
                let tempFileUrl = "";
                let temp_kyc_master_id = 0;
                let dealerKycDocSaveJson = {};

                if (
                  formPostdata.kyccategory &&
                  formPostdata.kyccategory.length > 1
                ) {
                  dealerKycDocSaveJson = {
                    kyc_master_id: formPostdata.kyccategory,
                    kyc_child_id: formPostdata.kycdoc,
                    doc_number: formPostdata.docnumber
                      ? formPostdata.docnumber
                      : "",
                    comment: formPostdata.Comment ? formPostdata.Comment : "",
                    verified_status: "1",
                    //"verified_status": formPostdata.verified_status_new && (formPostdata.verified_status_new == 'on' || formPostdata.verified_status_new == '1') ? '1' : '0'
                  };
                  if (
                    formPostdata.dc_dealer_kyc_doc != null &&
                    formPostdata.dc_dealer_kyc_doc > 0
                  ) {
                    let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                    dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                  }
                  if (
                    formPostdata.saved_file_name != null &&
                    formPostdata.saved_file_url
                  ) {
                    dealerKycDocSaveJson.file_name =
                      formPostdata.saved_file_name;
                    dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                  }
                  if (
                    formPostdata?.file_url != null &&
                    formPostdata?.file_url &&
                    !formPostdata?.saved_file_url
                  ) {
                    dealerKycDocSaveJson.file_name = formPostdata.file_name;
                    dealerKycDocSaveJson.file_url = formPostdata.file_url;
                  }

                  if (
                    formPostdata.id != null &&
                    formPostdata.id > 0 &&
                    !formPostdata.dc_dealer_kyc_doc
                  ) {
                    let dc_dealer_kyc_doc = formPostdata.id;
                    dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                  }
                } else {
                  dealerKycDocSaveJson = {
                    kyc_master_id: formPostdata.kyc_master_id,
                    kyc_child_id: formPostdata.kycdoc,
                    doc_number: formPostdata.doc_number
                      ? formPostdata.doc_number
                      : "",
                    comment: formPostdata.comment ? formPostdata.comment : "",
                    verified_status: "1",
                    //"verified_status": formPostdata.verified_status_new && (formPostdata.verified_status_new == 'on' || formPostdata.verified_status_new) == '1' ? '1' : '0'
                  };
                  if (
                    formPostdata.dc_dealer_kyc_doc != null &&
                    formPostdata.dc_dealer_kyc_doc > 0
                  ) {
                    let dc_dealer_kyc_doc = formPostdata.dc_dealer_kyc_doc;
                    dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                  }
                  if (
                    formPostdata.saved_file_name != null &&
                    formPostdata.saved_file_url
                  ) {
                    dealerKycDocSaveJson.file_name =
                      formPostdata.saved_file_name;
                    dealerKycDocSaveJson.file_url = formPostdata.saved_file_url;
                  }
                  if (
                    formPostdata?.file_url != null &&
                    formPostdata?.file_url &&
                    !formPostdata?.saved_file_url
                  ) {
                    dealerKycDocSaveJson.file_name = formPostdata.file_name;
                    dealerKycDocSaveJson.file_url = formPostdata.file_url;
                  }
                  if (
                    formPostdata.id != null &&
                    formPostdata.id > 0 &&
                    !formPostdata.dc_dealer_kyc_doc
                  ) {
                    let dc_dealer_kyc_doc = formPostdata.id;
                    dealerKycDocSaveJson.id = dc_dealer_kyc_doc;
                  }
                }
                if (response.data.data[0] != "not_updated") {
                  tempFileName = response.data.data[0].file_name;
                  tempFileUrl = response.data.data[0].file_url;
                  dealerKycDocSaveJson["file_name"] = tempFileName;
                  dealerKycDocSaveJson["file_url"] = tempFileUrl;
                }

                dealerKycDocSaveArr.push(dealerKycDocSaveJson);
                setFinalData(dealerKycDocSaveArr);
              }
            }
          });

          let postData = {
            dealer_id_hash: dealer_id_hash,
            docs: dealerKycDocSaveArr,
          };
          DealerService.saveKycDocDetails(postData)
            .then((result) => {
              if (result && result.data && result.data.status === 200) {
                setLoading(false);
                updateKycStatus(dealer_id);
                setVerified_icon(true);
                props.handleKycIcon();
                //hereiam
                toast.success(result.data.message, {
                  //onClose: () => window.location.reload(true)
                });
                //   setTimeout(() => {
                //       window.location.reload(true);
                //   }, 1000);
              } else {
                setFormData({ loading: false });
                toast.error(result.data.message || "Some Error Occurred");
              }
            })
            .catch((error) => {
              setFormData({ loading: false });
              toast.error(error.message || "Some Error Occurred");
            });
        })
        .catch((error) => {
          setFormData({ loading: false });
          toast.error(error.message || "Some Error Occurred");
        });
    }
  };

  const formatDate = (dateString) => {
    // Create a new Date object from the input string
    const date = new Date(dateString);

    // Extract the components of the date (month, day, and year)
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    // Return the formatted date string (MM/DD/YYYY)
    return `${month}/${day}/${year}`;
  };

  const handleClickDoc = () => {
    const fileInput = document.querySelector('.form-control');
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <>
      <div>
        {/* {JSON.stringify(formData)} */}
        <form
          className={
            loading
              ? "text-left os-animation animated fadeInDown loading"
              : "text-left os-animation animated fadeInDown"
          }
          data-os-animation="fadeInDown"
          data-os-animation-delay="0s"
          method="post"
          id="basicdetail_form"
          onSubmit={saveKycDetail}
          encType="multipart/form-data"
        >
          <div className="row">
            <div className="kyc-heading">
              <h6 className="col-sm-6">{"Images Details"}</h6>
              <h6 className="col-sm-6">{"Document Details"}</h6>
            </div>
          </div>
          <div>
            <div style={{ display: "flex", gap: "20px" }}>
              <input
                type="hidden"
                name="dc_dealer_kyc_doc"
                id="dc_dealer_kyc_doc"
                value={formData.id ? formData.id : ""}
              ></input>
              <input
                type="hidden"
                name="saved_file_name"
                id="saved_file_name"
                value={formData.file_name ? formData.file_name : ""}
              ></input>
              <input
                type="hidden"
                name="saved_file_url"
                id="saved_file_url"
                value={formData.file_url ? formData.file_url : ""}
              ></input>
              <input
                type="hidden"
                name="verified_status"
                id="verified_status"
                value="1"
              ></input>
              {formData.verified_status === "001" ? (
                <div>
                  {/* as per new design <div>{
                                      (formData.file_url != '' && formData.file_url != null) ?
                                          <div className="img-document">
                                              {
                                                  (formData.file_url != '' && formData.file_url != null) ?
                                                      <a href={formData.file_url} target="_blank" download>
                                                          {
                                                              (formData.kyc_doc_type == 'doc') ? <i className="ic-word-file icons-normal"></i> : (formData.kyc_doc_type == 'image') ? <img src={formData.file_url} width={450} height={300}></img> : (formData.kyc_doc_type == 'pdf') ? <i className="ic-pdf-file icons-normal"></i> : ''
                                                          }

                                                      </a>
                                                      : ''
                                              }
                                              {formData.verified_status != '1' ? <span className="delete-docs" onClick={() => removecurrentdoc()}><i className="ic-clearclose"></i></span> : ""}
                                          </div>
                                          : ''
                                  }</div> */}
                  {/* <div className="col-sm-6">
                                      <div style={{ marginBottom: 10 }}>
                                          <label>{(formData.kyc_master_id == 1 || formData.kyc_master_id == 2) ? "Aadhar Number" : (formData.kyc_master_id == 3) ? "PAN Number" : (formData.kyc_master_id == 4) ? "Document Number" : (formData.kyc_master_id == 5) ? "GSTN" : (formData.kyc_master_id == 6) ? "Company PAN" : (formData.kyc_master_id == 7) ? "MSME Certificate" : "Document Number"} </label>
                                          <div style={{ fontWeight: 700 }}>
                                              {formData.doc_number}
                                          </div>
                                      </div>
                                      <div style={{ marginBottom: 10 }}>
                                          <label>Comments </label>
                                          <div style={{ fontWeight: 700, wordWrap: 'break-word' }}>
                                              {formData.comment}
                                          </div>
                                      </div>
                                      <div style={{ marginBottom: 10 }}>{formData.verified_status === '1' ? <img src={require('./../../../webroot/images/tick.png')} alt="verified" width={30} height={30} /> : "Pending"} {formData.verified_date ? DateFormate(formData.verified_date, 'dS mmm yyyy h:MM TT') : ""} </div>
                                  </div> */}
                </div>
              ) : (
                <>
                  <div style={{ width: "50%" }}>
                    <label>
                      {formData.kyc_master_id === 4 &&
                        "Cancelled Cheque Details"}
                      {formData.kyc_master_id !== 4 && "Image"}
                    </label>

                    <div
                      className={
                        formData.file_url != "" && formData.file_url != null
                          ? " img-bx-select-opt form-control hide"
                          : " img-bx-select-opt "
                      }
                    >
                      {/* {formData.file_url === '' || formData.file_url === null ? ( */}
                      <div className="image-bx more-file-upload" onClick={handleClickDoc}>
                        <img src={uploadSvg} alt="" width={30} height={30} />
                        <input
                          type="file"
                          name="fileName"
                          multiple
                          className={
                            formData.file_url != "" && formData.file_url != null
                              ? "form-control hide"
                              : "form-control"
                          }
                          onChange={(e) => onChangeHandler(e)}
                        />
                        <p>Upload Images</p>
                      </div>
                      {/* ) : null} */}
                    </div>

                    {/* <input type="file" name="fileName" multiple className={
                                          (formData.file_url != '' && formData.file_url != null) ?
                                              'form-control hide' : 'form-control'
                                      } onChange={(e) => onChangeHandler(e)} /> */}
                    {formData.file_url != "" && formData.file_url != null ? (
                      <>
                        <div
                          className="kyc-document"
                          style={{ display: "flex" }}
                        >
                          <div
                            style={{
                              width: 500,
                              height: 350,
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            {/* <a href={formData.file_url} target="_blank" download> */}
                            <div className="image-preview-container">
                              <div className="pfd-img-height">
                                {formData.file_url != "" &&
                                formData.file_url != null ? (
                                  formData.kyc_doc_type == "doc" ? (
                                    <i className="ic-word-file icons-normal"></i>
                                  ) : formData.kyc_doc_type == "image" ? (
                                    <ReactPanZoom
                                      image={formData.file_url}
                                      src={formData.file_url}
                                      width={600}
                                      height={300}
                                    />
                                  ) : formData.kyc_doc_type == "pdf" ? (
                                    <i className="ic-pdf-file icons-normal"></i>
                                  ) : (
                                    ""
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                            {/* </a> */}
                          </div>
                          {formData.verified_status != '1' && change_kyc_docs === 1 ? <div className="delete-docss" style={{color:"red",cursor:'pointer'}} title="Change Kyc Doc" onClick={() => removecurrentdoc()}><i className="ic-clearclose"></i></div> : ""} 

                          <a
                            style={{ marginLeft: 20 }}
                            href={formData.file_url}
                            target="_blank"
                            download
                          >
                            <span>
                              {" "}
                              <img
                                style={{ width: 25 }}
                                src={downloadSvg}
                                alt=""
                              />
                            </span>{" "}
                          </a>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    {imageExtention !== null ? (
                      <div
                        style={{
                          width: 500,
                          height: 350,
                          position: "relative",
                          overflow: "hidden",
                        }}
                      >
                        {imagePreviews && imagePreviews.length > 0 && (
                          <div className="image-preview-container">
                            <div className="pfd-img-height">
                              {imagePreviews.map(
                                (preview, index) =>
                                  imageExtention !== "pdf" && (
                                    <ReactPanZoom
                                      key={index}
                                      image={preview}
                                      alt={`Image Preview ${index}`}
                                      className="image-preview"
                                    />
                                  )
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    <div>
                      {formData.kyc_master_id === 1 &&
                        basic_detail.is_pan_verified === 1 &&
                        basic_detail.is_aadhar_pan_linked === 1 && (
                          <div className="verifiedkyc">
                            <div style={{ marginRight: 20 }}>
                              <img src={greenTickSvg} alt="" />
                            </div>
                            <div>Aadhaar and PAN are Linked</div>
                          </div>
                        )}

                      {formData.kyc_master_id === 1 &&
                        basic_detail.is_pan_verified === 1 &&
                        basic_detail.is_aadhar_pan_linked !== 1 && (
                          <div className="notverifiedkyc">
                            <div style={{ marginRight: 20 }}>
                              <img
                                src={nonVeryfiedSvg}
                                alt=""
                                width={20}
                                height={20}
                              />
                            </div>
                            <div>Aadhaar and PAN are not Linked</div>
                          </div>
                        )}
                      {formData.kyc_master_id === 3 &&
                        basic_detail.is_pan_verified === 1 && (
                          <div className="verifiedkyc">
                            <div style={{ marginRight: 20 }}>
                              <img
                                src={greenTickSvg}
                                alt=""
                                width={20}
                                height={20}
                              />
                            </div>
                            <div>
                              PAN No. is valid - Please cross-check the document
                              and verify
                            </div>
                          </div>
                        )}

                      {formData.kyc_master_id === 4 &&
                        kycDealerData &&
                        kycDealerData.is_name_matched === 1 && (
                          <div className="verifiedkyc">
                            <div style={{ marginRight: 20 }}>
                              <img src={greenTickSvg} alt="" />
                            </div>
                            <div>Beneficiary name matched to PAN</div>
                          </div>
                        )}

                      {formData.kyc_master_id === 4 &&
                        kycDealerData &&
                        kycDealerData.is_name_matched === 2 && (
                          <div className="notverifiedkyc">
                            <div style={{ marginRight: 20 }}>
                              <img
                                src={nonVeryfiedSvg}
                                alt=""
                                width={20}
                                height={20}
                              />
                            </div>
                            <div>Beneficiary name not matching to PAN</div>
                          </div>
                        )}
                    </div>
                  </div>
                  {formData.kyc_master_id == 1 ||
                  formData.kyc_master_id == 2 ? (
                    <div style={{ width: "50%" }}>
                      <div className="row">
                        <div className="col-sm-6">
                          <InputField
                            type="text"
                            name="name"
                            id="name"
                            label="Name"
                            placeholder="name"
                            value={basic_detail.first_name}
                            onChange={(e) => handletChange(e)}
                            disabled={true}
                            required={true}
                          />
                        </div>
                        <div className="col-sm-6">
                          <InputField
                            type="text"
                            name="dob"
                            id="dob"
                            label="DOB"
                            placeholder="DOB"
                            value={
                              basic_detail.dealership_dob
                                ? formatDate(basic_detail.dealership_dob)
                                : ""
                            }
                            onChange={(e) => handletChange(e)}
                            disabled={true}
                            required={true}
                          />
                        </div>
                        <div className="col-sm-6">
                          <InputField
                            type="text"
                            name="aadhar_numbar"
                            id="aadhar_numbar"
                            label="Aadhar Numbar"
                            placeholder="Aadhar Numbar"
                            value={basic_detail.aadhar_no}
                            onChange={(e) => handletChange(e)}
                            disabled={true}
                            required={true}
                          />
                        </div>
                        {formData.kyc_master_id === 1 &&
                        basic_detail?.is_aadhar_pan_linked !== 1 ? (
                          <div className="col-sm-6" style={{ marginTop: 25 }}>
                            <div
                              disabled={aadharDisabled}
                              className="kycverification"
                              onClick={() => validatePanNumber(basic_detail, 1)}
                            >
                              {basic_detail?.is_aadhar_pan_linked === 1
                                ? "Approved"
                                : "Validate Aadhaar-PAN Linkage"}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="kycaddress">
                        <h6>Address Details</h6>
                        <div className="row">
                          <div className="col-sm-11">
                            <InputField
                              type="text"
                              name="address"
                              id="address"
                              label="Address"
                              placeholder="address"
                              value={basic_detail.address}
                              onChange={(e) => handletChange(e)}
                              disabled={
                                formData.verified_status == "1" ? true : false
                              }
                            />
                          </div>
                          {formData.verified_status !== "1" && (
                            <div style={{ marginTop: 28 }}>
                              <img
                                src={iconDisabled ? saveSvg : saveSvg}
                                alt=""
                                width={30}
                                height={35}
                                onClick={() => handleSaveClick(basic_detail)}
                                style={{
                                  cursor: iconDisabled
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="row">
                          <div className="col-sm-4">
                            <InputField
                              type="text"
                              name="pincode"
                              id="pincode"
                              label="Pin Code"
                              placeholder="pin code"
                              value={basic_detail.pincode}
                              onChange={(e) => handletChange(e)}
                              disabled={
                                formData.verified_status == "1" ? true : false
                              }
                            />
                          </div>
                          <div className="col-sm-4">
                            <InputField
                              type="text"
                              name="partner_state"
                              id="partner_state"
                              label="State"
                              placeholder="State"
                              value={basic_detail.state_name}
                              onChange={(e) => handletChange(e)}
                              disabled={true}
                            />
                          </div>

                          <div className="col-sm-4">
                            <InputField
                              type="text"
                              name="city"
                              id="city"
                              label="City"
                              placeholder="City"
                              value={basic_detail.cityname}
                              onChange={(e) => handletChange(e)}
                              disabled={true}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <InputField
                            type="text"
                            name="comment"
                            id="comment"
                            label="Comment"
                            placeholder="comment"
                            value={formData.comment}
                            onChange={(e) => handletChange(e)}
                          />
                        </div>
                        </div>
                        {formData.reject_reason &&  (
                          <>
                              <div style={{
        border: '1px solid red',
        padding: '10px',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderRadius: '5px',
        marginTop: '10px'
      }}>
        <div style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '5px' }}>
        Reject Reason: {rejectReason}
        </div>
        <div style={{ fontSize: '12px' }}>
         Comment: {formData.reject_reason_comment}
        </div>
      </div>
     
                              </>
                        )}
                      
                     
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="col-sm-6">
                                 
                                      <InputField
                                          type="text"
                                          name="doc_number"
                                          id="docnumber"
                                          label={(formData.kyc_master_id==1 || formData.kyc_master_id==2) ? "Aadhar Number" : (formData.kyc_master_id==3) ? "PAN Number" : (formData.kyc_master_id==4) ? "Document Number" : (formData.kyc_master_id==5) ? "GSTN" : (formData.kyc_master_id==6)? "Company PAN" : (formData.kyc_master_id==7)? "MSME Certificate" :"Document Number"} 
                                          placeholder={props.t('dealerDetails.kyc_details.document_number')}
                                          value={formData.doc_number}
                                          onChange={(e) => handletChange(e)}
                                      />

                                      <InputField
                                          type="text"
                                          name="comment"
                                          id="comment"
                                          label="Comment"
                                          placeholder={props.t('dealerDetails.kyc_details.comment')}
                                          value={formData.comment}
                                          onChange={(e) => handletChange(e)}
                                      />

                                     
                                      <div style={{ margin: 8.5 }}>
                                          <input
                                              type="checkbox"
                                              name="verified_status_new"
                                              label ="Veify"
                                              id={'verified_status_new'}
                                              key={'verified_status_new'}
                                              onClick={() => handleChangeVerifiedDocs("verified_status_new", 0)}
                                              checked={formData.verified_status_new === '1'}
                                          /> 
                                          <label style={{marginLeft:10}}> Verify </label>

                                      </div>

                                     {formData.error_msg && formData.error_msg!="" ? 
                                      <div style={{ margin: 8.5 }}>
                                          <span className="error show">{formData.error_msg}</span>
                                       </div>
                                     
                                     : ""}

                                  </div> */}
                </>
              )}

              {formData.kyc_master_id == 3 ? (
                <div style={{ width: "50%" }}>
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="name"
                        id="name"
                        label="Name"
                        placeholder="name"
                        value={basic_detail.first_name}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                        required={true}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="pan_number"
                        id="pan_number"
                        label="PAN Number"
                        placeholder="PAN number"
                        value={basic_detail.pan_no}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                        required={true}
                      />
                    </div>

                    <div className="col-sm-12">
                      <InputField
                        type="text"
                        name="comment"
                        id="comment"
                        label="Comment"
                        placeholder="comment"
                        value={formData.comment}
                        onChange={(e) => handletChange(e)}
                      />
                    </div>
                  </div>
                  <div >
                  {formData.reject_reason && (
                          <>
                              <div style={{
        border: '1px solid red',
        padding: '10px',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderRadius: '5px',
        marginTop: '10px'
      }}>
        <div style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '5px' }}>
        Reject Reason: {rejectReason}
          
        </div>
        <div style={{ fontSize: '12px' }}>
         Comment: {formData.reject_reason_comment}
        </div>
      </div>
     
                              </>
                        )}
       
      </div>
  
    </div>
                
              ) : (
                ""
              )}
              {formData.kyc_master_id == 4 ? (
                <div style={{ width: "50%" }}>
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="bank_account_no"
                        id="bank_account_no"
                        label="Account Number"
                        placeholder="ac number"
                        value={
                          updatedBankData ? updatedBankData.bank_account_no : (bankData?.bank_account_no ? bankData?.bank_account_no : "")
                        }
                        onChange={handletKycDealerDataTextChange.bind(
                          this,
                          "bank_account_no"
                        )}
                        // disabled={account_details !== 1}
                        required={true}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="bank_ifsc_code"
                        id="bank_ifsc_code"
                        label="IFSC Code"
                        placeholder="ifsc code"
                        value={
                          updatedBankData ? updatedBankData.bank_ifsc_code : (bankData?.bank_ifsc_code ? bankData?.bank_ifsc_code : "")
                        }
                        onChange={handletKycDealerDataTextChange.bind(
                          this,
                          "bank_ifsc_code"
                        )}
                        // disabled={account_details !== 1}
                        required={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-sm-6">
                                          <InputField
                                              type="text"
                                              name="bank_name"
                                              id="bank_name"
                                              label='Bank Name'
                                              placeholder='bank name'
                                              value={bankList && kycDealerData && bankList.length > 0 && kycDealerData.bank_id > 0 ? bankList.filter(({ bank_id }) => bank_id == kycDealerData.bank_id)[0].bankname : ""}
                                              onChange={(e) => handletChange(e)}
                                            //   disabled={true}
                                              required={true}
                                          />
                                      </div> */}
                    <div className="col-md-6">
                      <label>
                        Bank Name<span style={{ color: "red" }}> *</span>
                      </label>
                      {/* <label>{this.props.t('dealerDetails.kyc_details.bank_name')}<span className="required" style={{ color: 'red' }}>*</span></label> */}

                      <Select
                        id="bank_id"
                        options={bankList}
                        name="bank_id"
                        placeholder="Bank name"
                        value={
                          bankList.find(({ bank_id }) => 
                              bank_id === Number(updatedBankData?.bank_id ?? bankData?.bank_id)
                          ) || ""
                      }                                                getOptionLabel={({ bankname }) => bankname}
                        getOptionValue={({ bank_id }) => bank_id}
                        onChange={(option) => handletKycDealerDataTextChange("bank_id", option)}
                      />
                      {/* {errors.bank_id &&
                                    <div className="error show" >{errors.bank_id}</div>
                                } */}
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="beneficiary_name"
                        id="beneficiary_name"
                        label="Beneficiary Name"
                        placeholder="Beneficiary Name"
                        value={
                          updatedBankData ? updatedBankData.beneficiary_name : (bankData?.beneficiary_name ? bankData?.beneficiary_name : "")
                        }
                        onChange={handletKycDealerDataTextChange.bind(
                          this,
                          "beneficiary_name"
                        )}
                        disabled={account_details !== 1}
                        required={true}
                      />
                    </div>
                  </div>

                  {/* <div className="row" >
                                      <div className="col-sm-6">
                                          <InputField
                                              type="text"
                                              name="partner_state"
                                              id="partner_state"
                                              label='State'
                                              placeholder='State'
                                              value={basic_detail.state_name}
                                              onChange={(e) => handletChange(e)}
                                              disabled={true}
                                          />
                                      </div>

                                      <div className="col-sm-6">
                                          <InputField
                                              type="text"
                                              name="city"
                                              id="city"
                                              label='City'
                                              placeholder='City'
                                              value={basic_detail.cityname}
                                              onChange={(e) => handletChange(e)}
                                              disabled={true}
                                          />
                                      </div>
                                  </div> */}
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="upi_id"
                        id="upi_id"
                        label="UPI Id"
                        placeholder="UPI Id"
                        value={updatedBankData ? updatedBankData.upi_id : ""}
                        onChange={handletKycDealerDataTextChange.bind(
                          this,
                          "upi_id"
                        )}
                        disabled={account_details !== 1}
                      />
                    </div>
                    {formData.verified_status !== "1" &&
                      account_details === 1 && (
                        <div style={{ marginTop: 23, cursor: "pointer" }}>
                          <img
                            src={iconDisabled ? saveSvg : saveSvg}
                            alt=""
                            title="Update Bank Details"
                            width={30}
                            height={35}
                            onClick={() =>
                              handleBankInfoChange(basic_detail, kycDealerData ? kycDealerData : updatedBankData)
                            }
                            style={{
                              cursor: iconDisabled ? "not-allowed" : "pointer",
                            }}
                          />
                        </div>
                      )}
                    {formData.kyc_master_id === 4  ? (
                      <div className="col-sm-4" style={{ marginTop: 18 }}>
                        {/* <div className="kycverification" onClick={''} >Veify Bank Account</div> */}
                        <div
                          disabled={bankDisabled}
                          className="kycverification"
                          onClick={() =>
                            validateBankAccount(basic_detail, 4, kycDealerData)
                          }
                        >
                          {basic_detail?.bank_name === 1
                            ? "Approved"
                            : "Verify A/c Details"}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="col-sm-12">
                      <InputField
                        type="text"
                        name="comment"
                        id="comment"
                        label="Comment"
                        placeholder="comment"
                        value={formData.comment}
                        onChange={(e) => handletChange(e)}
                      />
                    </div>
                  </div>
                  {formData.reject_reason && (
                          <>
                              <div style={{
        border: '1px solid red',
        padding: '10px',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderRadius: '5px',
        marginTop: '10px'
      }}>
        <div style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '5px' }}>
        Reject Reason: {rejectReason}
        </div>
        <div style={{ fontSize: '12px' }}>
         Comment: {formData.reject_reason_comment}
        </div>
      </div>
     
                              </>
                        )}
                </div>
              ) : (
                ""
              )}

              {formData.kyc_master_id == 5 ? (
                <div style={{ width: "50%" }}>
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="gst_number"
                        id="gst_number"
                        label="GSTIN"
                        placeholder="GSTIN"
                        value={formData?.gst_number ?? basic_detail.gst_number}
                        onChange={(e) => handletChange(e)}
                        disabled={!update_msme_gst || formData.verified_status == "1" || verified_icon}
                        required={true}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="name"
                        id="name"
                        label="Name"
                        placeholder="Name"
                        value={basic_detail.first_name}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                        required={true}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="partner_state"
                        id="partner_state"
                        label="State"
                        placeholder="State"
                        value={basic_detail.state_name}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                      />
                    </div>

                    {formData.verified_status != "1" && update_msme_gst ?
                      account_details === 1 && (
                        <div style={{ marginTop: 23, cursor: "pointer" }}>
                          <img
                            src={iconDisabled ? saveSvg : saveSvg}
                            alt=""
                            title="Update GST Details"
                            width={30}
                            height={35}
                            onClick={() =>
                              handleMsmeGstChange(formData)
                            }
                            style={{
                              cursor: iconDisabled ? "not-allowed" : "pointer",
                            }}
                          />
                        </div>
                      ): null}

                    <div className="col-sm-4" style={{ marginTop: 28 }}>
                      {/* <div className="kycverification" onClick={''} >Veify Bank Account</div> */}
                      {/* <div className="kycverification"  >validate GST</div> */}
                      {/* <div disabled={bankDisabled} className="kycverification" onClick={() => validatePanGSTNumber(basic_detail, 5, kycDealerData)}  >{(basic_detail?.is_gst === 1) ? 'Approved' : 'validate GST'}</div> */}
                    </div>

                    <div className="col-sm-12">
                      <InputField
                        type="text"
                        name="comment"
                        id="comment"
                        label="Comment"
                        placeholder="comment"
                        value={formData.comment}
                        onChange={(e) => handletChange(e)}
                      />
                    </div>
                  </div>
                  {formData.reject_reason && (
                          <>
                              <div style={{
        border: '1px solid red',
        padding: '10px',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderRadius: '5px',
        marginTop: '10px'
      }}>
        <div style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '5px' }}>
        Reject Reason: {rejectReason}
        </div>
        <div style={{ fontSize: '12px' }}>
         Comment: {formData.reject_reason_comment}
        </div>
      </div>
     
                              </>
                        )}
                </div>
              ) : (
                ""
              )}

              {formData.kyc_master_id == 6 ? (
                <div style={{ width: "50%" }}>
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="company_name"
                        id="company_name"
                        label="Company Name"
                        placeholder="company name"
                        value={basic_detail.organization}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                        required={true}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="company_pan_number"
                        id="company_pan_number"
                        label="Company PAN Number"
                        placeholder="Company PAN number"
                        value={basic_detail.pan_no}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                        required={true}
                      />
                    </div>

                    {/* <div className="col-sm-6" style={{ marginBottom: 20 }}>
                                          <div className="kycverification" onClick={() => validatePanNumber(basic_detail, 3)}  >Verify Company PAN Details</div>
                                      </div> */}

                    <div className="col-sm-12">
                      <InputField
                        type="text"
                        name="comment"
                        id="comment"
                        label="Comment"
                        placeholder="comment"
                        value={formData.comment}
                        onChange={(e) => handletChange(e)}
                      />
                    </div>
                  </div>

                  {formData.reject_reason && (
                          <>
                              <div style={{
        border: '1px solid red',
        padding: '10px',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderRadius: '5px',
        marginTop: '10px'
      }}>
        <div style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '5px' }}>
        Reject Reason: {rejectReason}
        </div>
        <div style={{ fontSize: '12px' }}>
         Comment: {formData.reject_reason_comment}
        </div>
      </div>
     
                              </>
                        )}
                </div>
              ) : (
                ""
              )}

              {formData.kyc_master_id == 7 ? (
                <div style={{ width: "50%" }}>
                  <div className="row">
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="msme_number"
                        id="msme_number"
                        label="MSME"
                        placeholder="MSME"
                        value={formData?.msme_number ?? basic_detail.msme_number}
                        onChange={(e) => handletChange(e)}
                        disabled={!update_msme_gst || formData.verified_status == "1" || verified_icon}
                        required={true}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="name"
                        id="name"
                        label="Name"
                        placeholder="Name"
                        value={basic_detail.first_name}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                        required={true}
                      />
                    </div>
                    <div className="col-sm-6">
                      <InputField
                        type="text"
                        name="partner_state"
                        id="partner_state"
                        label="State"
                        placeholder="State"
                        value={basic_detail.state_name}
                        onChange={(e) => handletChange(e)}
                        disabled={true}
                      />
                    </div>

                    {formData.verified_status != "1" && update_msme_gst ?
                      account_details === 1 && (
                        <div style={{ marginTop: 23, cursor: "pointer" }}>
                          <img
                            src={iconDisabled ? saveSvg : saveSvg}
                            alt=""
                            title="Update MSME Details"
                            width={30}
                            height={35}
                            onClick={() =>
                              handleMsmeGstChange(formData)
                            }
                            style={{
                              cursor: iconDisabled ? "not-allowed" : "pointer",
                            }}
                          />
                        </div>
                      ): null}

                    <div className="col-sm-12">
                      <InputField
                        type="text"
                        name="comment"
                        id="comment"
                        label="Comment"
                        placeholder="comment"
                        value={formData.comment}
                        onChange={(e) => handletChange(e)}
                      />
                    </div>
                  </div>
                  {formData.reject_reason && (
                          <>
                              <div style={{
        border: '1px solid red',
        padding: '10px',
        backgroundColor: '#f8d7da',
        color: '#721c24',
        borderRadius: '5px',
        marginTop: '10px'
      }}>
        <div style={{ fontWeight: 'bold', fontSize: '14px', marginBottom: '5px' }}>
        Reject Reason: {rejectReason}
        </div>
        <div style={{ fontSize: '12px' }}>
         Comment: {formData.reject_reason_comment}
        </div>
      </div>
     
                              </>
                        )}
                </div>
              ) : (
                ""
              )}
            </div>
            {/* <div className='pull-right' style={{ marginBottom:40 }}>
                                  <div >
                                          <input
                                              type="checkbox"
                                              name="verified_status_new"
                                              label ="Veify"
                                              id={'verified_status_new'}
                                              key={'verified_status_new'}
                                              onClick={() => handleChangeVerifiedDocs("verified_status_new", 0)}
                                              checked={formData.verified_status_new === '1'}
                                          /> 
                                          <label style={{marginLeft:10}}> Verify </label>

                                      </div>

                                     {formData.error_msg && formData.error_msg!="" ? 
                                      <div style={{ margin: 8.5 }}>
                                          <span className="error show">{formData.error_msg}</span>
                                       </div>
                                     
                                     : ""}
                                  </div> */}
          </div>
          <div className="card-footer text-center" style={{ marginTop: 20 }}>
            <div className="btnflexreject">
              {formData.file_url != "" && formData.file_url != null ? (
                <>
                  {/* <div  onClick={RejectedPartnerPopup} >Reject</div> */}
                  <div>
                    {reject_kyc === 1 || reject_kyc == 0 ? (
                      <div
                        className="newrejectbtn mrg-r10"
                        onClick={RejectedPartnerPopup}
                      >
                        Reject
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </>
              ) : (
                ""
              )}
              {verify_kyc === 1 || verify_kyc === 0 ? (
                <>
                  <Button
                    btnClass="approvebtn"
                    disabled={
                      formData.verified_status == "1" || verified_icon === true
                    }
                    title={
                      formData.verified_status == "1" || verified_icon === true ? "Verified" : "Verify"
                    }
                    type="submit"
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
        {/* <Button btnClass="approvebtn" onClick={() => props.handleKycIcon()} /> */}

        <div>
          <Modal isOpen={reject} togglePopup={() => setReject(false)}>
            <div className="modal-body">
              <div className="modal-header">
                <h2 style={{ marginBottom: 10 }}>
                  Are you sure you want to reject this KYC?
                </h2>
              </div>
              <div>
                <fieldset className="form-filed" style={{ marginBottom: 20 }}>
                  <div className="material">
                    <label>
                      Select a reason <span style={{ color: "red" }}>*</span>
                    </label>
                    <Select
                      id="kyc_reject"
                      onChange={(e) => handleRejectReasonChange(e)}
                      options={formData.reject_reason_arr}
                      name="kyc_reject"
                      placeholder="Select Rejection Reason"
                      value={
                        formData.reject_reason_arr &&
                        formData.reject_reason_arr.filter(
                          ({ id }) => id == formData.reject_reason
                        )
                      }
                      getOptionLabel={({ reject_reason }) => reject_reason}
                      getOptionValue={({ id }) => id}
                    />
                  </div>
                </fieldset>

                <fieldset className="form-filed">
                  <div className="material">
                    <textarea
                      name="reject_reason_comment"
                      placeholder=""
                      className="form-input"
                      rows="3"
                      value={(formData && formData.reject_reason_comment) || ""}
                      onChange={(e) => handletChange(e)}
                    ></textarea>
                    <label data-label="Comment" className="form-label"></label>
                  </div>
                </fieldset>

                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <button
                    className="btn btn-primary "
                    onClick={RejectedPartner}
                  >
                    Reject
                  </button>
                  {/* <button className="btn btn-primary mrg-r10 pull-right ">Reject</button> */}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default withTranslation("common")(KycDocument);
