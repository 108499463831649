import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import CheckListForm from './CheckListForm';
import { getCheckList } from '../../store/action/allAction';

const AccordionTabs = (props) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(null);
  const [child, setChild] = useState({
    parent: '',
    data: []
  });
  const { masterdata, leadDetail } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    leadDetail: lead.leadDetail
  }));
  let [saveChecklistData, setSaveChecklistData] = useState([]);

  useEffect(() => {
    if (!masterdata.check_list) {
      dispatch(getCheckList(client))
    }
    if(props.status_id ==2){
      let checkList = (masterdata.check_list) ? masterdata.check_list.filter(ob => ob?.statuslang?.status_id == props?.status_id)?.[0]?.substatus : [];
      checkList = props?.sub_status_id ? checkList.filter(data => data?.substatuslang?.sub_status_id === props?.sub_status_id && data?.checklist.length > 0) : checkList.filter(data => data?.checklist.length > 0);
      if(checkList.length){
        let item = checkList[0];
        setChild({ parent: item?.substatuslang?.sub_status_name, data: item.checklist })
      } 
    }
  }, [])

  useEffect(() => {
    if (leadDetail.checklists.length) setSaveChecklistData(leadDetail.checklists);
  }, [leadDetail?.lead_status_history]);

  const handleAccordionItemClick = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const parentabStatus = (item) => {
    let submitArray = leadDetail.checklists?.filter(ob => ob?.sub_status_id == item?.id)
    console.log(submitArray.length ,'===', item?.checkList?.length)

    let classNames = (submitArray.length === item?.checklist?.length) ? 'completed' : 'active'
    return classNames;
  };

  const tabStatus = (item) => {
    let submitArray = leadDetail.checklists?.filter(ob => ob?.checklist_id == item?.id)

    let classNames = submitArray.length ? 'completed' : 'active';
    return classNames;
  };

  let checkList = (masterdata.check_list) ? masterdata.check_list.filter(ob => ob?.statuslang?.status_id == props?.status_id)?.[0]?.substatus : [];
  checkList = props?.sub_status_id ? checkList.filter(data => data?.substatuslang?.sub_status_id === props?.sub_status_id && data?.checklist.length > 0) : checkList.filter(data => data?.checklist.length > 0);
 
  return (
    <div className="menu-tab-left-panel-new">
      <h2>{child.parent} Sub Status</h2>
      <ul>
        {!child.data.length && checkList?.length && checkList.filter(ob=>ob?.substatuslang?.sub_status_name !='Without OTC').map((item, index) => (
          <li key={index + 'i'}>
            <NavLink style={{ display: "flex", justifyContent: "space-between" }}
              to={item.to}
              className={parentabStatus(item)}
              onClick={() => setChild({ parent: item?.substatuslang?.sub_status_name, data: item.checklist })}
            >
              <span>
                <span className="img-type"></span>
                {item?.substatuslang?.sub_status_name}
              </span>
              <span style={{ fontSize: 25, fontWeight: 500 }}>{activeIndex === index ? ' -' : ' +'}</span>
            </NavLink>
          </li>
        ))}

        {child.data.length ? child.data.map((item, index) => (
          <li key={index + 'j'}>
            <NavLink style={{ display: "flex", justifyContent: "space-between" }}
              to={item.to}
              className={tabStatus(item)}
              onClick={() => handleAccordionItemClick(index)}
            >
              <span>
                <span className="img-type"></span>
                {item?.checklist_name}
              </span>
              <span style={{ fontSize: 25, fontWeight: 500 }}>{activeIndex === index ? ' -' : ' +'}</span>
            </NavLink>
            {activeIndex === index && (
              <div style={{ bottom: 0 }}>
                <CheckListForm checkList={item} checklist_status_id={item.id} togglePopup={props.togglePopup} />
              </div>
            )}
          </li>
        )) : null}
      </ul>
    </div>
  );
};

export default AccordionTabs;
