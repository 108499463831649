import React, { useState } from "react";
import { convertJsonToCsv, downloadFile } from "../../../helpers/helpers";
import { Nav } from "react-bootstrap";
import { RotatingLines } from "react-loader-spinner";
import Modal from "../../elements/Modal";

const BreOfferSection = ({
  checkBankOffers,
  showTabs = false,
  fetchOffer,
  isLoadingOffer,
}) => {
  const getDateAndTimeStr = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const day = now.getDay();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };
  const [showModal, setShowModal] = useState(false);
  const [allFees, setAllFees] = useState({});
  const [checkedOffers, setCheckedOffers] = useState([]);

  const handleExportCSVFile = () => {
    const offersList =
      checkedOffers.length > 0 ? checkedOffers : checkBankOffers;
    const requiredOfferCol = JSON.parse(JSON.stringify(offersList)).map(
      (offer) => {
        offer.bankId && delete offer.bankId;
        offer.bankLogo && delete offer.bankLogo;
        offer.propValue && delete offer.propValue;
        offer.documentSet && delete offer.documentSet;
        offer.messages = offer.messages?.join("||").replaceAll(",", "");
        offer.processingText = offer.processingText?.replaceAll(",", "");
        offer.login_fee = offer.login_fee?.replaceAll(",", "");
        offer.ac_handling_charge = offer.ac_handling_charge?.replaceAll(",", "");
        offer.legal_technical_fee = offer.legal_technical_fee?.replaceAll(
          ",",
          ""
        );
        offer.e_stamp_charge = offer.e_stamp_charge?.replaceAll(",", "");
        offer.due_diligence_charge = offer.due_diligence_charge?.replaceAll(
          ",",
          ""
        );
        offer.CERSAI_charge = offer.CERSAI_charge?.replaceAll(",", "");
        offer.MODT_charge = offer.MODT_charge?.replaceAll(",", "");
        offer.NOI_charge = offer.NOI_charge?.replaceAll(",", "");
        offer.netMonthlyIncome = offer.netMonthlyIncome?.replaceAll(",", "");
        offer.eligibalLoanAmount = offer.eligibalLoanAmount?.replaceAll(",", "");
        offer.monthlyEMI = offer.monthlyEMI?.replaceAll(",", "");

        return { ...offer };
      }
    );
    const csvData = convertJsonToCsv(requiredOfferCol);
    downloadFile(csvData, "text/csv", `BRE_OFFERS_${getDateAndTimeStr()}.csv`);
  };

  const handleTabChange = (propertyConsidered) => {
    fetchOffer(propertyConsidered);
  };

  const handleViewFeeModal = (index) => {
    const feesObj = {};
    const selectedOffer = checkBankOffers[index];
    feesObj["Processing Fees"] = selectedOffer?.processingText || "N/A";
    feesObj["Login Fee"] = selectedOffer?.login_fee || "N/A";
    feesObj["Account handling charges/Other Charges"] =
      selectedOffer?.ac_handling_charge || "N/A";
    feesObj["Legal and Technical Fee"] =
      selectedOffer?.legal_technical_fee || "N/A";
    feesObj["E-Stamp Charges"] = selectedOffer?.e_stamp_charge || "N/A";
    feesObj["Due Diligence Charges"] =
      selectedOffer?.due_diligence_charge || "N/A";
    feesObj["CERSAI Charges"] = selectedOffer?.CERSAI_charge || "N/A";
    feesObj["MODT Charges"] = selectedOffer?.MODT_charge || "N/A";
    feesObj["NOI Charges"] = selectedOffer?.NOI_charge || "N/A";
    setAllFees(feesObj);
    setShowModal(true);
    document.body.classList.add("overflow-hidden");
  };

  const handleCloseFeeModal = () => {
    setShowModal(false);
    document.body.classList.remove("overflow-hidden");
  };

  const handleOfferCheck = (event, index) => {
    if (event.target) {
      const { checked } = event.target;
      const existingList = [...checkedOffers];
      if (checked) {
        const filtered = checkBankOffers[index];
        existingList.push({ ...filtered, offerIndex: index });
      } else {
        const unCheckedIndex = existingList.findIndex(
          (obj) => obj.offerIndex === index
        );
        if (unCheckedIndex !== -1) {
          existingList.splice(unCheckedIndex, 1);
        }
      }
      setCheckedOffers(existingList);
    }
  };

  return (
    <>
      <div className="doument-upload-left-panel">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <Nav variant="pills" className="flex-column">
              <div className="tab-list">
                {showTabs && (
                  <>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="ltv_based"
                        onClick={() => handleTabChange(true)}
                      >
                        LTV/LTR Based Max Loan Amount
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="income_based"
                        onClick={() => handleTabChange(false)}
                      >
                        Income Based Eligible Amount
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </div>
            </Nav>
          </div>
          <div>
            {checkBankOffers.length > 0 && (
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div onClick={handleExportCSVFile}>
                  <i className="ic-upload offeruploadbre"></i>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="breoffercard">
          <div className="breofferheading">
            {checkBankOffers.length === 0 &&
              !isLoadingOffer &&
              "Please fill all the required fields to get offers"}
          </div>

          {isLoadingOffer && (
            <div style={{ textAlign: "center" }}>
              <RotatingLines
                visible={true}
                height="80"
                width="80"
                strokeColor="grey"
                strokeWidth="4"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
              <p>Please wait while generating offer</p>
            </div>
          )}

          {checkBankOffers.map((offer, index) => (
            <div key={index} className="offerbox">
              <div className="offercardflex">
                <div className="offerimg">
                  <img src={offer.bankLogo} alt="" />
                </div>

                <div className="offerwidth">
                  <div className="offerlistingcard">
                    <div>
                      <input
                        type="checkbox"
                        onClick={(event) => handleOfferCheck(event, index)}
                      />
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Eligible Loan Amount</div>
                      <div className="breheading">
                        ₹ {offer.eligibalLoanAmount}
                      </div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Monthly EMI</div>
                      <div className="breheading">₹ {offer.monthlyEMI}</div>
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Interest Rate</div>
                      <div className="breheading">{offer.minRoi} %</div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Tenure</div>
                      <div className="breheading">{offer.maxTenure} years</div>
                    </div>
                  </div>
                </div>

                <div
                  className="breviewfees"
                  onClick={() => handleViewFeeModal(index)}
                >
                  View Fees
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="view-timeline-popup">
        <Modal show={showModal} handleClose={handleCloseFeeModal}>
          <h2>Fees</h2>
          {Object.keys(allFees)?.map((feeKey, index) => (
            <div className="modal-body" key={index}>
              <div className="offerlistingfees">
                <div className="feesheading" style={{ fontSize: 14 }}>
                  <ul style={{ paddingLeft: "20px" }}>
                    <li style={{ listStyle: "initial" }}>
                      {feeKey} :{" "}
                      <span className="feessubheading">{allFees[feeKey]}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </Modal>
      </div>
    </>
  );
};

export default BreOfferSection;
