// NoAccess.js
import React from 'react';
import noAccess from "../webroot/images/no-access.svg";

const NoAccess = () => {
  return (
    <div className='container-fluid'>
           <div className='noaccesspage'>
              <img src={noAccess} alt='logo' width={150} height={100} />
              <h3  className='noaccessheading'>We are Sorry...</h3>
              <p className='noaccesspara'>You do not have access to this page</p>
              <p className='noaccesspara'>Please ask your system administrator for access</p>
            </div>
    </div>
  );
};

export default NoAccess;
