import { gql } from "@apollo/client";

export const SCHEME_LIST = gql`
query {
  getAllSchemsList{
    id
    scheme_name
   
  }
}`;

export const SCHEME_LIST_WITH_FILTERS =  gql`

mutation bank_scheme_list($schemefilterargs:BankSchemeFilterArgsInput!){
  bank_scheme_list(schemefilterargs:$schemefilterargs){
     scheme_list{
      id
      financier_id
      scheme_name
      scheme_for
      approved_status
      start_date
      end_date
      is_deleted
      created_by
      updated_by
      updated_date
      created_date
      scheme_type
      scheme_for
      scheme_code
      is_insurance
      schemefinacergroup{
        id
        product_type
        business_type
        insurance_payin
        schemerange{
            id
            start_range
            end_range
            payout
        }
      }
     
     }
    
    pagination{
      nextpage
      page_no
      totalpage
      totalrecords
    }
    total_count{
      all_schemes
      active_schemes
      expired_schemes
      deleted_schemes
    }
  }
  }

`



export const Add_SCHEME = gql`
mutation add_bank_scheme($addSchemeArgs:AddBankSchemeArgs!){
  add_bank_scheme(addSchemeArgs:$addSchemeArgs)
  {
    msg
    code
    id
    error
  }
}`

export const Add_PARTNER_SCHEME = gql`
mutation saveSchemePartner($schemePartnerInputArgs: AddSchemePartnerInput!){
  saveSchemePartner(schemePartnerInputArgs:$schemePartnerInputArgs){
    status
    msg
  }
  
}`



export const Update_SCHEME = gql`
mutation updateBankScheme($updateSchemeArgs:UpdateBankSchemeArgs!){
  updateBankScheme(updateSchemeArgs:$updateSchemeArgs)
  {
    msg
    code
    id
    error
  }
}`

export const Update_SCHEME_CITIES = gql`
mutation saveSchemeCities($addSchemeCityArgs:AddSchemeCityArgs!){
  saveSchemeCities(addSchemeCityArgs:$addSchemeCityArgs)
}`

export const Add_Save_City_List = gql`
mutation saveSchemeCities($addSchemeCityArgs:AddSchemeCityArgs!){
  saveSchemeCities(addSchemeCityArgs:$addSchemeCityArgs)
}`

export const Add_Builders = gql`
mutation saveSchemeBuilder($schemeBuilderInputArgs: AddSchemeBuilderInput!){
  saveSchemeBuilder(schemeBuilderInputArgs:$schemeBuilderInputArgs)
}`

export const Add_Scheme_Range = gql`
mutation saveSchemeBuilder($schemeBuilderInputArgs: AddSchemeBuilderInput!){
  saveSchemeBuilder(schemeBuilderInputArgs:$schemeBuilderInputArgs)
}`

export const SCHEME_FIINACER_GROUP = gql`
mutation saveBankSchemeFinancerGroup($schemeFinancerGroupArgs:BankSchemeFinancerGroupInput!){
  saveBankSchemeFinancerGroup(schemeFinancerGroupArgs:$schemeFinancerGroupArgs)
}`

export const DELETE_FINANCER_FROM_GROUP_FINANCER = (financier_group_id)  => gql`
query {
  delete_bank_financier_group(financier_group_id:${financier_group_id})
}`


export const DELETE_SCHEME_RANGE  = gql`
mutation deleteSchemeRange($id:Int!){
  deleteSchemeRange(id:$id)
}`

export const DELETE_SCHEME_PATNER  = gql`
mutation deleteSchemePartner($id:Int!){
  deleteSchemePartner(id:$id)
}`

export const SAVE_FINANCE_RANGES = gql `
mutation save_bank_scheme_range($schemeRangeInputArgs: BankSchemeRangeInputArgs!){
  save_bank_scheme_range(schemeRangeInputArgs:$schemeRangeInputArgs)
}`

export const PARTNER_SCHEME_LIST = (partner_id=0) => gql`
{
  getBankSchemesByPartnerId(partner_id:${partner_id}){
      id
      scheme_name
      scheme_code
      scheme_type
      scheme_for
      start_date
      end_date
      schemepartner {
          partner_id
          created_date
          created_by
      }
      status
  }
}`;


export const GET_SCHEME_FINANCER_GROUPS = (scheme_id=0, business_type) => gql`
{
  bankSchemeFinancerGroupbySchemeId(scheme_id:${scheme_id}, business_type:"${business_type}"){
   scheme_id
   id
   product_type
   loan_type
   business_type
   insurance_payin

   schemerange {
       id
       scheme_id
       financer_group_id
       start_range
       end_range
       is_deleted
       payout
   }
  }
}
`;

export const GET_SCHEMA_LIST_BYID = (scheme_id=0) => gql`
query{
  bankSchemeById(scheme_id: ${scheme_id}) {
      id
      scheme_name
      scheme_for
      schemerange {
          id
      }
}
}`;

export const UPDATE_SCHEME_STATUS = (scheme_id=0,approved_status) => gql`
query{
  change_bank_scheme_approval_status(scheme_id: ${scheme_id}, approved_status: ${approved_status})
}`;

export const GET_SCHEMA_LIST_BY_CODE = (scheme_code='') => gql`
query{
  bank_scheme_by_code(scheme_code:"${scheme_code}"){
      id
      scheme_name
      financier_id
      scheme_for
      scheme_type
      scheme_code
      status
      start_date
      end_date
      is_insurance
      created_by
      created_date
      approved_status
      schemerange {
          id
          scheme_id
          start_range
          end_range
          payout
          
      }
  }
}`;


export const SCHEME_CITIES_VARIABLE = (scheme_id=0) => gql`
query{
  schemecitybyschemeid(scheme_id:${scheme_id}){
    city_id
  }
}`;
export const PAYOUT_POINT_DETAILS = () => gql `
{
  points{
  id
  booking_point
  slug
  status
}}
`

export const FINEX_MASTER_LIST = () => gql`
query{
  masterdata{
  bank_list{
    id
    banklang{
        bank_id
        bank_name
    }
  }
  loan_type{
    id
    label
  }
  loan_sub_type{
    id
    label
  }
  fulfillment_type{
    id
    label
  }
  }
}`;

export const FINEX_BANK_LIST = () => gql`
query{
  bank_pa{
  bank_list{
    id
    banklang{
        bank_id
        bank_name
    }
  }
  }
}`;


export const SCHEME_COPY = (id) => gql`
query{
  copy_bank_scheme(scheme_id:${id}){
    id
    code
    msg
  }
}`;

export const FINEX_MASTER = () => gql`
query{
  masterdata(api_called_by:web,is_partnership:"0"){
  bank_list{
    id
    banklang{
        bank_id
        bank_name
    }
  }
  loan_type{
    id
    label
  }
  loan_sub_type{
    id
    label
  }
  fulfillment_type{
    id
    label
  }
  }
}`;


