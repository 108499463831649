import Formsy from "formsy-react";
import React, { useEffect, useMemo, useState } from "react";
import FormsySelect from "../elements/FormsySelect";
import FormsyDatePicker from "../elements/FormsyDatePicker";
import { convertCsvToJson } from "../../helpers/helpers";
import { toast } from "react-toastify";
import { UPDATE_MIS } from "../../services/insurance.gql";
import { executeGraphQLMutation } from "../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";

const INSURANCE_MIS_DROPDOWN = [
  { id: "insurance_mis", label: "Insurance MIS", value: "insurance_mis" },
];

const LEAD_STATUS_MAPPING_OBJ = {
  "Offers Viewed": 1,
  "Offer Selected": 2,
  "Personal Details Shared": 3,
  "Property Details Shared": 4,
  "Checkout Viewed": 5,
  "Payment Failed": 6,
  "Payment Successful": 7,
  "Policy Pending": 8,
  "Policy Generated": 9,
};

const MisUpload = (props) => {
  const client = useApolloClient();
  const [uploadedFileJson, setUploadedFileJson] = useState([]);
  const [misData, setMisData] = useState({});
  const [incorrectStatusRefIds, setIncorrectStatusRefIds] = useState([]);

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      setMisData((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setMisData((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  const handleFileChange = async (event) => {
    const { files } = event.target;
    const fileNameElement = document.getElementById("fileName_misupload");

    if (files.length > 0) {
      const fileName = files[0].name;
      const jsonArray = await convertCsvToJson(files[0]);
      setUploadedFileJson(jsonArray);
      fileNameElement.textContent = "Selected file: " + fileName;
    } else {
      fileNameElement.textContent = "";
    }
  };

  const handleSubmit = () => {
    const incorrectStatusRefList = [];
    if (uploadedFileJson.length === 0) {
      return toast.error("Please upload MIS file");
    }
    const mappedData = uploadedFileJson.map((obj) => {
      if (!LEAD_STATUS_MAPPING_OBJ[obj["Status"]]) {
        incorrectStatusRefList.push(obj["PBleadid"]);
      }
      return {
        ref_id: obj["PBleadid"],
        lead_source: obj["leadsource"],
        utm_source: obj["utm_source"],
        utm_campaign: obj["utm_campaign"],
        structure_sum_insured: obj["Structure_Sum_Insured"],
        content_sum_insured: obj["Content_Sum_Insured"],
        selected_plan: obj["Selected_Plan"],
        selected_supplier: obj["Selected_Supplier"],
        selected_premium: obj["Selected_Premium"],
        lead_status_id: LEAD_STATUS_MAPPING_OBJ[obj["Status"]],
        policy_doc_link: obj["Link of the policy doc"],
        insurance_type: obj["Insurance type"],
        policy_tenure: +obj["Tenure"],
        sale_date: new Date(obj["Date of sale"]),
        insurer_lang_id: obj["Insurer LAN ID"],
        insurance_scheme: obj["Insurance Scheme"],
        expected_payin_amount: obj["Expected Payin Amt."],
        last_updated_date: misData["insurance_date"],
      };
    });

    if (incorrectStatusRefList.length) {
      setIncorrectStatusRefIds(incorrectStatusRefList);
      return;
    }

    const mutation = UPDATE_MIS,
      variables = {
        updateMIS: {
          insuranceLead: mappedData,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.update_mis || null;
        if (response === "Success") {
          toast.success(response);
          return setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
        return toast.error(response);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <>
      <div style={{ padding: 40 }}>
        <div>
          <h2 style={{ marginTop: 20, marginBottom: 20 }}>
            Which MIS do you wish to upload ?
          </h2>

          <div className="form-tab-right-panel">
            <Formsy
              className="lead-form-filed"
              autoComplete="off"
              onValid={() => null}
              onInvalid={() => null}
            >
              <div className="row">
                <fieldset className="single-select col-md-6">
                  <FormsySelect
                    name={"insurance_mis"}
                    id={"insurance_mis"}
                    inputProps={{
                      options: INSURANCE_MIS_DROPDOWN,
                      placeholder: "Insurance MIS",
                      className: "react-select",
                      classNamePrefix: "react-select",
                    }}
                    required={true}
                    value={misData && misData["insurance_mis"]}
                    onChange={(data) =>
                      handleSelectChange(data, "insurance_mis")
                    }
                  />
                </fieldset>
                <fieldset className="form-filed col-md-6">
                  <FormsyDatePicker
                    name="insurance_date"
                    selectedDate={
                      misData["insurance_date"] &&
                      new Date(misData["insurance_date"])
                    }
                    placeholder={"Date"}
                    // maxDate={}
                    // minDate={}
                    value={misData && misData["insurance_date"]}
                    onDateSelect={(date) =>
                      handleDateChange(date, "insurance_date")
                    }
                    dateFormat="dd MMM, yyyy"
                    validations={null}
                    validationError={""}
                    required={true}
                  />
                </fieldset>
              </div>
            </Formsy>

            <div className="csvfileupload">
              <input
                type="file"
                id="misFileUpload"
                onChange={handleFileChange}
              />
              <label htmlFor="misFileUpload" className="file-label">
                <span>+</span>
                <p>Upload MIS now</p>
              </label>
              <p id="fileName_misupload"></p>
            </div>

            {incorrectStatusRefIds.length > 0 && (
              <div style={{ color: "#ff6565" }}>
                <p>Found mis-matched lead status of mentioned ref_ids :</p>
                {incorrectStatusRefIds.map((obj, index) => (
                  <p key={index}>{obj}</p>
                ))}
              </div>
            )}

            <div className="btn-save-remarks">
              <div className="submitbtnflex">
                <button
                  type="button"
                  className="btn-primary"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default MisUpload;
