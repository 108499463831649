import React, { useState, useEffect } from "react";
import "../../../pre-register-lead-manager/pre-register-lead.css";
import { MasterService, DealerService } from "../../../../../services";
import { getCurrentUserDetails } from "../../utility/getUserInfo";
import Formsy from "formsy-react";
import FormsyInputField from "../../../../elements/FormsyInputField";
import { toast } from "react-toastify";
import { getCurrentDateTime, getISODateAndTimeFormat } from "../../utility/dateFormateUtility";

const NewCallDetailCard = (props) => {
  const leadData = props.leadData;
  const [status, setStatus] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [callStatus, setCallStatus] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [values, setValues] = useState({
    partner_id: "",
    status_id: leadData.status_id || "",
    sub_status_id: leadData.sub_status_id || "",
    call_status_id: leadData.call_status_id || "",
    followup_date: leadData.followup_date || "",
    comment: leadData.comment || "",
    source: "Sangam Backend",
    status: 1,
    created_by: "",
    updated_by: "",
    remarks: leadData.remarks || "",
    title: leadData.title || "",
  });

  const userInfo = getCurrentUserDetails();

  useEffect(() => {
    fetchData();
    getStatusList();
    getCallStatus();
  }, []);

  useEffect(() => {
    if (values.status_id) {
      getSubStatusList(values.status_id);
    }
  }, [values.status_id]);

  const fetchData = async () => {
    try {
      const dealerHashId = window.location.pathname.split("/")[2];
      const response = await DealerService.getBasicDetails({
        dealer_id_hash: dealerHashId,
        _with: ["DIF"],
      });
      const data = response.data.data[0];
      setValues((prevValues) => ({
        ...prevValues,
        comment: data.comment || "",
        remarks: data.remarks || "",
        title: data.title || "",
        status_id: data.status_id || "",
        sub_status_id: data.sub_status_id || "",
        followup_date: data.followup_date || "",
      }));
    } catch (error) {
      toast.error("Error fetching data");
    }
  };

  const getStatusList = async () => {
    try {
      const response = await MasterService.get("/partner/partner-call/status");
      setStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching status list:", error);
      toast.error("Error fetching status list");
    }
  };

  const getSubStatusList = async (statusId) => {
    try {
      const response = await MasterService.get(
        `/partner/partner-call/sub-status?status_id=${statusId}`
      );
      setSubStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching sub-status list:", error);
      toast.error("Error fetching sub-status list");
    }
  };

  const getCallStatus = async () => {
    try {
      const response = await MasterService.get(
        "/partner/partner-call/call-status"
      );
      setCallStatus(response.data.data);
    } catch (error) {
      console.error("Error fetching call status list:", error);
      toast.error("Error fetching call status list");
    }
  };

  const handleStatusChange = async (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.target.value);
    setValues((prevValues) => ({
      ...prevValues,
      status_id: selectedId,
    }));
    await getSubStatusList(selectedId);
  };

  const handleSubStatusChange = (event) => {
    event.preventDefault();
    const selectedId = parseInt(event.target.value);
    setValues((prevValues) => ({
      ...prevValues,
      sub_status_id: selectedId,
    }));
  };

  const handleCallStatusChange = (event) => {
    const selectedId = parseInt(event.target.value);
    setValues((prevValues) => ({
      ...prevValues,
      call_status_id: selectedId,
    }));
  };

  const handleDateTimeChange = (event) => {
    const dateTime = event.target.value;
    setValues({
      ...values,
      followup_date: dateTime,
    });
    console.log("dateTimedateTimedateTime", dateTime.length);
    if(dateTime > getCurrentDateTime()) {
      // event.target.blur();
    }
    // setTimeout(() => {
    //   event.target.blur();
    // }, 1000);
  };

  const handleComment = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      comment: e.target.value,
    }));
  };

  const handleRemarks = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      remarks: e.target.value,
    }));
  };

  const handleTitle = (e) => {
    setValues((prevValues) => ({
      ...prevValues,
      title: e.target.value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitted(true);
    if (values.status_id === '') {
      toast.error('Please select a status');
    } else if(values.sub_status_id === '') {
      toast.error('Please select a sub-status');
    } else if(values.status_id !== 4 && values.followup_date < getCurrentDateTime()) {
      toast.error('Followup date should be greater than or equal to current date and time');
    } else {
      // Handle form submission
      const reqData = {
        partner_id: props?.leadData?.id,
        ...(values.status_id !== "" && { status_id: values.status_id }),
        ...(values.sub_status_id !== "" && {
          sub_status_id: values.sub_status_id,
        }),
        ...(values.call_status_id !== "" && {
          call_status_id: values.call_status_id,
        }),
        ...(values.followup_date !== "" && {
          followup_date: values.status_id === 4 ? '' : values.followup_date,
        }),
        ...(values.comment !== "" && { comment: values.comment }),
        ...(values.title !== "" && { title: values.status_id === 4 ? '' : values.title }),
        ...(values.remarks !== "" && { remarks: values.status_id === 4 ? '' : values.remarks }),
        source: "Sangam Backend",
        status: 1,
        created_by: userInfo.userId,
      };
  
      try {
        const response = await MasterService.post(
          "/partner/partner-call/save-partner-call-details",
          reqData
        );
        if (response.status === 200) {
          toast.success("Success");
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        console.error("Error saving details:", error);
        toast.error("Error saving details");
      }
    }
  };

  return (
    <div>
      <div className="mb-b20">Call Status</div>

      <Formsy autoComplete="off">
        <div className="row">
        <div className="btnmarkdoneflex col-sm-12 mb-b20">
          <div className="box">
            <h4 className="dropdown-heading1">Status*</h4>
            <select 
              value={values.status_id} onChange={handleStatusChange} 
              required
            >
              <option value="">Select Status</option>
              {status.map((status) => (
                <option key={status.status_id} value={status.status_id}>
                  {status.status_name}
                </option>
              ))}
            </select>
          </div>
          </div>
          <div className="btnmarkdoneflex col-sm-12 mb-b20">
          <div className="box">
            <h4 className="dropdown-heading2">Sub-Status*</h4>
            <select
              value={values.sub_status_id}
              onChange={handleSubStatusChange}
              required
            >
              <option value="">Select Sub-Status</option>
              {subStatus.map((option) => (
                <option key={option.sub_status_id} value={option.sub_status_id}>
                  {option.sub_status_name}
                </option>
              ))}
            </select>
          </div>
          </div>
          {values.status_id === 4 ? () => null : 
          <>
            <div className="btnmarkdoneflex col-sm-12">
              <div>
                <div className="mb-b20">Set Followup</div>
              </div>
              <div>
                {/* <button className="markdonebutton">Mark as Done</button> */}
              </div>
            </div>

            <fieldset className="col-sm-12 mb-b20">
              <FormsyInputField
                id="followup_time"
                name="followup_time"
                type="datetime-local"
                placeholder=" "
                onChange={handleDateTimeChange}
                value={values.followup_date ? getISODateAndTimeFormat(values.followup_date) : ''}
                label="Date/Time"
                min={getCurrentDateTime()}
                disabled={values.status_id === 4}
              />
            </fieldset>

            <fieldset className="col-sm-12 mb-b20">
              <FormsyInputField
                id="title"
                name="title"
                type="text"
                placeholder=" "
                onChange={handleTitle}
                value={values?.title}
                label="Title"
              />
            </fieldset>

            <fieldset className="col-sm-12 mb-b20">
              <div className="material">
                <textarea
                  name="remarks"
                  id="remarks"
                  placeholder=" "
                  className="form-input"
                  rows="3"
                  onChange={handleRemarks}
                  value={values.remarks}
                />
                <label data-label="Add Remark" className="form-label"></label>
                <span className="error-msg"></span>
              </div>
            </fieldset>
          </>
          }

          <div className="col-sm-12 mb-b20">Comment</div>
          <fieldset className="col-sm-12 mb-b20">
            <div className="material">
              <textarea
                name="comment"
                id="comment"
                placeholder=" "
                className="form-input"
                rows="3"
                onChange={handleComment}
                value={values.comment}
              />
              <label data-label="Comment" className="form-label"></label>
              <span className="error-msg"></span>
            </div>
          </fieldset>
        </div>
      </Formsy>
      {isSubmitted ? (
        <>
        {values.status_id === '' ? <span style={{ color: 'red' }}>Please select a status<br/></span> : null}
        {values.sub_status_id === '' ? <span style={{ color: 'red' }}>Please select a sub-status</span> : null}
        </>
      ): null }
      <button
        className="partnerregisterbtn1"
        type="submit"
        onClick={handleSubmit}
      >
        Save
      </button>

      {/* <div>Selected Status Name: {mapIdToName(values.status_id, status)}</div> */}
    </div>
  );
};

export default NewCallDetailCard;
