import React, { useEffect, useState } from "react";
import "../pre-register-lead.css";
import { MasterService } from "../../../../services";
import Loader from "../../../elements/Loader";
import { PARTNER_CHAT_LIST } from "../../../../services/leads.gql";
import { useApolloClient } from '@apollo/client'
import { executeGraphQLQuery } from "../../../../common/executeGraphQLQuery";
import { Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../utility/dateFormateUtility";
import { getCurrentUserDetails } from "../utility/getUserInfo";

export const WAChatList = (props) => {
  const [chatList, setChatList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const navigate = useNavigate();

  useEffect(() => {
    getNewMessageList();
  }, [props.activeTab]);

  const fetchLeadData = async (mobile) => {
      setIsLoading(true);
      const params = {
        "status_id": ['1', '4', '10', '11'],
        "page_no": 1,
        "is_lead": "1",
        "status": "",
        "list_type": "",
        "search_by": "dealership_contact",
        "search_text": processMobileNumber(mobile),
      }
      MasterService.post('dealer/dealer/dealer-list', {...params})
      .then((response) => {
        const leadData = response.data.data[0]
        if (response.status === 200 && leadData.dealership_contact) {
        setIsLoading(false);
        handleNavigation(leadData);
        }
      }).catch((error) => {
        console.log("error---", error);
        setIsLoading(false);
      });
  }

  const processMobileNumber = (mobile) => {
    console.log("mobilemobilemobile\n\n", mobile);
    
    let mobileStr = mobile.toString();
    // Check if the number is 12 digits long and starts with '91'
    if (mobileStr.length === 12 && mobileStr.startsWith('91')) {
      // Remove the '91' prefix
      mobileStr = mobileStr.substring(2);
    }
    // Get the last 10 digits of the mobile number
    const last10Digits = mobileStr.slice(-10);
  
    console.log("last10Digitslast10Digitslast10Digits", last10Digits);
    
    return last10Digits;
  }

  const handleNavigation = (leadData) => {
    navigate(`/partner-lead-detals/${leadData.dealer_id_hash}`, {
      state: {
        lead: leadData,
        status: getStatusUtility(leadData.status).status,
      },
    });
  };

  const getStatusUtility = (status) => {
    switch (status) {
      case "0":
        return "unknown";
      case "1":
        return { status: "Verified", color: "green" };
      case "4":
        return { status: "Registered", color: "orange" };
      case "10":
        return { status: "Fresh Lead", color: "red" };
        case "11":
        return { status: "Lost", color: "purple" };
      default:
        return "unknown";
    }
  };

  const getNewMessageList = () => {
    setIsLoading(true);
    const userInfo = getCurrentUserDetails();
    const { yoddha_code } = userInfo;
    executeGraphQLQuery(PARTNER_CHAT_LIST(yoddha_code ?? 0), client)
      .then((result) => {
        setIsLoading(false);
        if(result.data.partner_chat_list.length > 0) {
          setChatList(result.data.partner_chat_list);
        }
      })
      .catch((error) => {
      setIsLoading(false);
      });
  };

  const handleLeadClick = async (mobile) => {
    try {
      await fetchLeadData(mobile);
    } catch (error) {
      console.error('Error fetching lead data:', error);
    }
  };

  
  return (
    <>
      {isLoading ? <Loader /> : null}
        <Table striped bordered hover>
        <thead>
          <tr style={{ backgroundColor: "#fff1d6" }}>
              {/* <th>Lead ID</th> */}
              <th>Mobile</th>
              <th>Total Unread</th>
              <th>Latest Message</th>
              <th>Last updated</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {chatList.map((chat, index) => (
              <tr key={index}>
                {/* <td><b>{chat.lead_id}</b></td> */}
                <td>{chat.mobile}</td>
                <td>{chat.message_count}</td>
                <td style={{cursor: 'pointer'}} title={chat.message}>{chat.message.length < 15 ? chat.message : `${chat.message.split(' ').slice(0, 4).join(' ')}...`}</td>
                <td>{formatDate(chat.created_date)}</td>
                {/* <td>{new Date(chat.created_date).toLocaleString()}</td> */}
                <td>
                  <div style={{ display: "flex" }}>
                  <button
                    style={{ color: "gray", alignContent: 'flex-end', backgroundColor: "white" }}
                    className="commoniconbtn"
                    onClick={() => handleLeadClick(chat.mobile)}
                  >
                    <i
                      className="ic-createmode_editedit icons-normal"
                      title="Edit"
                    ></i>
                  </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {!chatList.length &&(
          <div style={{display: 'flex', justifyContent: 'center', padding: 40}}>No unread messages found</div> 
        )}
    </>
  );
};

export default WAChatList;
