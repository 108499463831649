import React, { useEffect, useState } from "react";
import { NavLink,Link } from 'react-router-dom';
import {USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE} from '../config/constants';
import { gql, useApolloClient } from "@apollo/client";
import { toast } from 'react-toastify';
import WhatsAppUnreadComponent from "../view/chat/WhatsAppUnread.component";

const Navigation = () => {

    const client = useApolloClient();
    const handleLogout = () => {

        const user_information = JSON.parse(localStorage.getItem('user_information'));
        const mutation = gql`
          mutation {
            logout_user(user_id: ${user_information.id})
          }
        `;
      
        client
          .mutate({
            mutation,
          })
          .then(() => {
            localStorage.clear()
            window.location.href = '/login';
            toast.success('Logged Out');
          })
          .catch((error) => {
            localStorage.clear()
            window.location.href = '/login';
            console.error(error);
          });
      };
    //const user_information = useSelector((state) => state.user.user_information);
    //const UserInfo = JSON.parse(user_information)
    let user_info = JSON.parse(localStorage.getItem("user_information"));
    let login_status = user_info?.user_data?.login_status || 'available';
    let away_time = user_info?.user_data?.away_time || 0;
    const [setLoginStatus] = useState({login_status: login_status, away_time: away_time});
    const UserInfo = user_info;
    let userReportAccess = (user_info.report_access) ? JSON.parse(user_info.report_access) : {}; 
    const changeUserLoginStatus = async (login_status={}) => {
        if(Object.keys(login_status).length===0){
            login_status={ login_status: 'available', away_time: 0 }
        }
        let user_info = JSON.parse(localStorage.getItem("user_information"));
        const mutation = gql`mutation{
            update_login_status(user_id:${user_info.user_data.user_id},login_status:"${login_status.login_status}",away_minutes:${login_status.away_time}){
            login_status
            away_minutes
            }
        }`
        document.dispatchEvent(new MouseEvent('click'));
        if(user_info.user_data?.login_status != login_status.login_status ||
		(user_info.user_data?.login_status == login_status.login_status && user_info.user_data?.away_time != login_status.away_time)) {
            client.mutate({mutation}).then((res) => {
                if(res?.data) {
                    toast.success("Status Changes Successfully");
                    if(user_info) {
                        user_info.user_data.login_status = login_status.login_status;
                        user_info.user_data.away_time = login_status.away_time;
                        user_info.user_data.away_start_time=new Date();
                        localStorage.setItem("user_information", JSON.stringify(user_info));
                    }
                    setLoginStatus({ login_status: login_status.login_status, away_time: login_status.away_time });
                }
            }).catch((err) => {
                toast.error(err.toString());
            })
        }
	};

    useEffect(function(){
        if(UserInfo?.user_data?.away_time){
            let endtime=new Date(UserInfo.user_data.away_start_time).getTime() + UserInfo.user_data.away_time*60000;
            let starttime=new Date().getTime();
            if(endtime<starttime){
              changeUserLoginStatus()
            }
          }
    },[UserInfo])

    let lead_dashboard =  IS_USER_ACCESS_PAGE("menu", "lead_dashboard")?.is_edit_access;
    let partner_dashboard =  IS_USER_ACCESS_PAGE("menu", "partner_dashboard")?.is_edit_access;
    let manage_dashboard =  IS_USER_ACCESS_PAGE("menu", "manage_leads")?.is_edit_access;
    let scheme_list =  IS_USER_ACCESS_PAGE("menu", "scheme_list")?.is_edit_access;
    let payout_list =  IS_USER_ACCESS_PAGE("menu", "payout_list")?.is_edit_access;
    let payout_point =  IS_USER_ACCESS_PAGE("menu", "payout_point")?.is_edit_access;
    let bank_onboarding =  IS_USER_ACCESS_PAGE("menu", "bank_onboarding")?.is_edit_access;
    let mis_list =  IS_USER_ACCESS_PAGE("menu", "mis_list")?.is_edit_access;
    let payouts =  IS_USER_ACCESS_PAGE("menu", "payouts")?.is_edit_access;
    let add_apf_record =  IS_USER_ACCESS_PAGE("menu", "add_apf_record")?.is_edit_access;
        return (
            <>
            <div className="header-main">
            <div style={{display:"flex",justifyContent:"space-between"}} >
                    <div className="logo">
                        {/* <NavLink to='/dashboard'>Loan BOX</NavLink> */}
                        
                        {(lead_dashboard === 1 || lead_dashboard === 2) ? (
  <li>
    <NavLink to="/dashboard" />
  </li>
) : null}
<NavLink to="/">
  <img src="https://storage.googleapis.com/ambak/logo/ambak_logo_white.svg" alt='logo' width={182} height={56} />
</NavLink>
                    </div>
                    
                    <div className="right-panel-naviganion menupanel">
                        <div className="nav">
                            <ul>
                                {lead_dashboard === 1 || lead_dashboard === 2 ? <li><NavLink to="/dashboard">Lead Dashboard</NavLink></li>: ""}
                                {partner_dashboard === 1 || partner_dashboard === 2 ? <li><NavLink to="/partner-dashboard">Partner Dashboard</NavLink></li>: ""}
                                {add_apf_record === 1 && <li><NavLink to="/apf">APF Finder</NavLink></li>}
                                {manage_dashboard === 1 || manage_dashboard === 2 ?   <li><NavLink to="/lead-list">Manage Leads</NavLink> </li>: ""}
                            

                                {payouts === 1 || payouts === 2 ? 
                                
                                <li className="dropdownmenu">
                                        <NavLink to={{ pathname: '/schemes/list' }}
                                            isActive={(match, location) => {
                                                if (location.pathname == '/schemes' || location.pathname == '/schemes/list'  || location.pathname == '/schemes/addscheme') { return true; }
                                            }}>
                                            Payouts<span className="caret"></span></NavLink>
                                                <ul className="submenu">
                                                <li>
                                                    {scheme_list === 1 && (  <Link to="/schemes/list">Scheme List</Link> )} 
                                                </li>
                                                <li>
                                                    {payout_list === 1 && (   <Link to="/payout/list">Payout List</Link> )}
                                               
                                                </li>
                                                <li>
                                                    {payout_point === 1 && ( <Link to="/payout/points">Payout Points</Link>)}
                                                   
                                                </li>
                                                <li>
                                                    {bank_onboarding === 1 && (<Link to="/payout/bank-onboarding">Bank Onboarding</Link>)}
                                                    
                                                </li>
                                                <li>
                                                    {mis_list === 1 && ( <Link to="/payout/mis-list">MIS List</Link>)}
                                                   
                                                </li>
                                                <li>
                                                    {mis_list === 1 && ( <Link to="/schemes/banklist">Bank Schemes</Link>)}
                                                   
                                                </li>
                                            </ul>
                                    </li>
                                    : ""}
                               
                              
                                {/* <li><NavLink to="/role-list">Role Management</NavLink> </li> */}
                                {userReportAccess && userReportAccess.report_access && userReportAccess.report_access.length > 0 ? 
                                <li className="dropdownmenu"><NavLink>Report</NavLink>
                                    <ul className="submenu">
                                    { userReportAccess.report_access.map((report,index)=>
                                        (<li key={index} onClick={() => {window.location.href = `/report-view/${index}`}}>{report.label}</li>)
                                    )}
                                    </ul>
                                </li> : null }

                                {/* <li className="dropdownmenu"><NavLink>Manage Panel</NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/lead-list">Lead List</NavLink> </li>
                                    </ul>
                                </li>
                               <li className="dropdownmenu"><NavLink>Rule Engine</NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to="/rule-engine">Rule Engine</NavLink> </li>
                                    </ul>
                                </li>
                                <li className="dropdownmenu"><NavLink>More</NavLink>
                                    <ul className="submenu">
                                        {IS_USER_ACCESS_PAGE('role_management','role_management').is_access_page && 
                                            <li><NavLink to="/role-list">Role Management</NavLink> </li>}
                                        {IS_USER_ACCESS_PAGE('financier','financier').is_access_page && 
                                            <li><NavLink to="/financer">Financier List</NavLink> </li>}
                                    </ul>
                                </li> */}
                            </ul>
                        </div>
                        <div className="user-detail ">
                            <ul>

                                <li className="dropdownmenu"><NavLink to="#">
                                    <span className="userarc">
                                        {UserInfo && UserInfo.name?.[0]}
                                    </span>
                                    <div className="username">
                                        <span>{UserInfo && UserInfo.name}</span>
                                        <span className="user-degintion">{UserInfo?.role_names?.join(",") || ""}</span>
                                    </div>
                                </NavLink>
                                    <ul className="submenu">
                                        <li><NavLink to = "#" onClick={handleLogout}>Logout</NavLink> </li>
                                    </ul>
                                </li>

                            </ul>
                        </div>

                        {/*<div className="user-status">
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <div className="status">
                                    <i className={`active-status ${LoginStatus.login_status === 'away' ? 'away-icon' : ''} `}></i>
                                    <div className="status-txt">
                                        <span>{LoginStatus.login_status == 'available' ? 'Active' :
                                            (LoginStatus.login_status == 'offline' ? 'Inactive' : 'Away')}</span>
                                        <span className="sub-status"></span>
                                    </div>
                                </div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <ul className="satus-drop-downlist">
                                    <li className="cursor-pointer">
                                        <i className={LoginStatus.login_status == 'available' ? 'active-status' : 'inactive-status'}></i>
                                        <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'available', away_time: 0 })}>
                                            Active
                                        </span>
                                    </li>
                                    <li className="">
                                        <i className={LoginStatus.login_status == 'away' ? 'active-status' : 'inactive-status'}></i>
                                        <span className="">Set as Away</span>
                                        <ul className="away-time-stamp">
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 30 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 30 })}>
                                                    30 Minutes
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 60 ? 'active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 60 })}
                                                >
                                                    1 Hour
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 120 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 120 })}
                                                >2 Hours
                                                </span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 240 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 240 })}
                                                >4 Hours</span>
                                            </li>
                                            <li className={LoginStatus.login_status == 'away' && LoginStatus.away_time == 480 ? 'cursor-pointer active' : 'cursor-pointer'}>
                                                <span onClick={changeUserLoginStatus.bind('login_status', { login_status: 'away', away_time: 480 })}
                                                >8 Hours</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </Dropdown.Menu>
                        </Dropdown>
                        </div>*/}
                        {/* <div className="language">
                            <ul>
                                <li className="dropdownmenu">
                                    <NavLink to="#">
                                        ENG
                                    </NavLink>
                                    <ul className="submenu">
                                     
                                    </ul>
                                </li>

                            </ul>
                        </div> */}
                    </div>

                </div>

            </div >
            <WhatsAppUnreadComponent />
            </>
        );
    }

export default Navigation;
