import { ApolloClient, InMemoryCache, createHttpLink, useApolloClient,gql } from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import { API_URL, APIV } from '../config/partnerConstant';
import secureStorage from '../config/partnerEncrypt';
import { FINEX_BACKEND_API_KEY } from '../config/partnerConstant'; 
import { getCurrentUserDetails } from '../view/partner/pre-register-lead-manager/utility/getUserInfo';


const httpLink = createHttpLink({
	uri: API_URL.GETWAY_API+"payout/api/v1/graphql",
	fetch: (uri, options) => {
		const timeout = 10000;
		return new Promise((resolve, reject) => {
		  const timer = setTimeout(() => {
			reject(new Error('Request timeout'));
		  }, timeout);
		  fetch(uri, options)
			.then((response) => {
			  clearTimeout(timer);
			  resolve(response);
			})
			.catch((error) => {
			  clearTimeout(timer);
			  reject(error);
			});
		});
	  },
});

const authLink = setContext((_, { headers }) => {
 console.log ("user_data" , secureStorage)
	let fetchHeader = (secureStorage.getItem('loginUserInfo'))
    ? fetchHeader.data.token : null;
  const user_data = secureStorage.user_data
  let headers2send = Object.assign({api_source:'payout'},headers);

  console.log("headers2send" , headers2send);
  let token = JSON.parse(secureStorage.storage.user_information)
	if(token.access_token) {
		headers2send.Authorization = `${token.access_token}`;
		if(user_data && user_data!=null){
      console.log ("abhinav")
			headers2send.user_data = JSON.stringify(user_data);
			localStorage.setItem('isUserDataSet',true);
      console.log("headers2send2" , headers2send);
		}
	}

	return {
	  headers: headers2send
	}
  });

  const userInfo = getCurrentUserDetails();
  const { access_token } = userInfo || {};
  if(!access_token) {
    localStorage.setItem('isToken', false);
  }


const httpLinkFinex = createHttpLink({
  uri: API_URL.GETWAY_API+"finex/api/v1/graphql",
  headers: {
  "api_source":"finex",
  // "apikey": "d77bc90f-2311-47da-9980-2930de73-ambak-finex-beta",
  "authorization":`Bearer ${access_token}`,
   "app_type":"SFA",
   "Accept": "application/json"
  }
});



const defaultOptions = {
	watchQuery: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'ignore',
	},
	query: {
		fetchPolicy: 'no-cache',
		errorPolicy: 'all',
	},
}


const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions
});

const clientfinex = new ApolloClient({
  link: httpLinkFinex,
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions

});


export function executeGraphQLQuery(query) {
  return client.query({
    query: query,
    cache:true   
  })
}

export function executeGraphQLMutation(mutation, variables) {
  return client.mutate({
    mutation: mutation,
    variables,
  })
}

export function executeGraphQLQueryFinex(query) {
  return clientfinex.query({
    query: query,
    cache:true   
  }).catch(err=>console.log("query-error",err))
}

export function executeGraphQLMutationFinex(mutation, variables) {
  return clientfinex.mutate({
    mutation: mutation,
    variables,
  })
}

export function getToken() {
  let fetchHeader = (secureStorage.getItem('loginUserInfo'))
    ? secureStorage.getItem('loginUserInfo')
    : {};
    
  return (fetchHeader && fetchHeader.data && fetchHeader.data.token)
    ? fetchHeader.data.token : null;
}

// const authLink =  setContext((_, { headers }) => {
//   // get the authentication token from local storage if it exists
//   const token = getToken();
//   // return the headers to the context so httpLink can read them
//   return {
//     headers: {
//       ...headers,
//       authorization: token ? `${token}` : "",
//     }
//   }
// });
