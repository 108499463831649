import React, { useEffect, useState } from "react";
import Formsy from "formsy-react";
import FormFields from "../formFields/bankOfferFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import { toast } from "react-toastify";
import { Tab, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { useParams } from "react-router-dom";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import dateFormat from "dateformat";
import { LEAD_DETAIL_QUERY } from "../../../services/leads.gql";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import crypto from "../../../config/crypto";
const LOGGED_IN_FIELDS = FormFields["LOGGED_IN"];
const SANCTIONED_FIELDS = FormFields["SANCTIONED"];
const DISBURSED_FIELDS = FormFields["DISBURSED"];

const LoanDetailsForm = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const client = useApolloClient();
  const [loggedin, setLoggedIn] = useState({});
  const [disbursedDetails, setDisbursedDetails] = useState([]);
  const [lastTranche, setLastTranche] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isTrancheEnabled, setIsTrancheEnabled] = useState(false);
  const [defaultActiveTab, setDefaultActiveTab] = useState("1");

  const { lead } = useSelector(({ lead }) => ({
    lead,
  }));

  const handleChange = () => {
    setChecked(!checked);
  };

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.lead_details) {
      let customerData = { ...lead.leadDetail.lead_details };
      let splitpayment = lead?.leadDetail?.splitpayment?.length && lead?.leadDetail?.splitpayment[0];
      customerData["login_date"] = dateFormat(
        customerData["login_date"],
        "dd mmm, yyyy"
      );
      customerData["approval_date"] = dateFormat(
        customerData["approval_date"],
        "dd mmm, yyyy"
      );
      customerData["disbursed_date"] = dateFormat(
        customerData["disbursed_date"],
        "dd mmm, yyyy"
      );
      setLoggedIn({...customerData, ...splitpayment});
    }
  }, [lead.leadDetail]);

  let lead_id = crypto.decode(params.lead_id);
  useEffect(() => {
    getLeadDetail(parseInt(lead_id));
  }, [lead_id]);

  const getLeadDetail = (lead_id) => {
    if (!lead_id) lead_id = 0;
    executeGraphQLQuery(LEAD_DETAIL_QUERY(lead_id), client)
      .then((response) => {
        if (response && response.data) {
          const leadData = response.data.get_lead_detail[0];
          setDisbursedDetails(response.data.get_lead_detail);
          if (leadData) {
            dispatch({ type: "GET_LEAD_DETAIL", payload: leadData });
            setIsTrancheEnabled(leadData.loan_type === 2);
          } else {
            toast.error("Lead not found");
          }
        } else if (response?.errors?.length) {
          toast.error(response.errors[0].message, { toastId: "timeout" });
        }
      })
      .catch((error) => {
        toast.error(error.toString().replace("ApolloError:", ""));
      });
  };

  const handleInputChange = (event) => {
    if (event.target.id) {
      setLoggedIn((currentValue) => ({
        ...currentValue,
        [event.target.id]: event.target.value,
      }));
    }
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setLoggedIn((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setLoggedIn((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleDisburedChange = (event) => {
    const data = JSON.parse(event.target.getAttribute("data"));
    const index = JSON.parse(event.target.getAttribute("index"));
    setLoggedIn((currentValue) => ({
      ...currentValue,
      disbursed_amount: data.disbursed_amount,
      disbursed_date: data.disbursed_date,
      disbursed_id: data.disbursed_id,
      disbursed_tenure: data.disbursed_tenure,
    }));

    if (index == disbursedDetails[0]?.splitpayment.length) {
      setLastTranche(true);
    } else {
      setLastTranche(false);
    }
  };

  return (
    <div className="image-form-outer">
      {/* LEFT PANEL */}

      <div className="image-tab-panel form-tab-right-panel">
        <div>Logged In</div>
        {/* {JSON.stringify(payload)} */}

        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
        >
          <div className="row">
            {LOGGED_IN_FIELDS.map((field, index) =>
              ["text", "pattern-format", "number"].includes(field.type) ? (
                <fieldset className="form-filed col-md-12" key={index}>
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={
                      field.name === "bank"
                        ? loggedin["bank"]?.banklang?.bank_name
                        : (loggedin && loggedin[field.name]) || ""
                    }
                    placeholder=" "
                    label={field.label}
                    format={field.ApplyNumberformat ? field.format : ""}
                    readOnly={field.readOnly}
                    required={field.required}
                  />
                </fieldset>
              ) : field.type === "date-picker" ? (
                <fieldset className="form-filed col-md-12" key={index}>
                  <FormsyDatePicker
                    name="login_date"
                    selectedDate={
                      loggedin &&
                      loggedin[field.name] &&
                      new Date(loggedin[field.name])
                    }
                    disabled={true}
                    placeholder={field.label}
                    maxDate={field.maxDate}
                    minDate={field.minDate}
                    value={loggedin && loggedin[field.name]}
                    dateFormat="dd MMM, yyyy"
                    required={field.required}
                    hasError={
                      loggedin.hasOwnProperty(field.name) &&
                      !loggedin[field.name]
                        ? true
                        : false
                    }
                  />
                </fieldset>
              ) : null
            )}
          </div>
        </Formsy>
      </div>

      {/* MIDDELE PANEL */}

      <div className="form-tab-right-panel">
        <div>Sanctioned</div>

        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
        >
          <div className="row">
            {SANCTIONED_FIELDS.map((field, index) =>
              ["text", "pattern-format", "number"].includes(field.type) ? (
                <fieldset className="form-filed col-md-12" key={index}>
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={(loggedin && loggedin[field.name]) || ""}
                    placeholder=" "
                    maxLength={field.maxLength}
                    label={field.label}
                    readOnly={field.readOnly}
                    required={field.required}
                  />
                </fieldset>
              ) : field.type === "date-picker" ? (
                <fieldset className="form-filed col-md-12" key={index}>
                  <FormsyDatePicker
                    name="approval_date"
                    selectedDate={
                      loggedin &&
                      loggedin[field.name] &&
                      new Date(loggedin[field.name])
                    }
                    disabled={true}
                    placeholder={field.label}
                    maxDate={field.maxDate}
                    minDate={field.minDate}
                    value={loggedin && loggedin[field.name]}
                    dateFormat="dd MMM, yyyy"
                    required={field.required}
                    hasError={
                      loggedin.hasOwnProperty(field.name) &&
                      !loggedin[field.name]
                        ? true
                        : false
                    }
                  />
                </fieldset>
              ) : null
            )}
          </div>
        </Formsy>
      </div>

      {/* RIGHT PANEL */}

      <div className="form-tab-right-panel">
        <div className="disburedflex">
          <div>Disbursed</div>
          <div className="loanfulfillment">
            <div className="loaninput">
              <input
                type="radio"
                name="tranche"
                id="tranche"
                checked={isTrancheEnabled}
              />
              <label htmlFor="tranche">Tranche Disbursal</label>
            </div>
          </div>
        </div>
        <div>
          <Tab.Container
            id="left-tabs-example"
            activeKey={defaultActiveTab}
            defaultActiveKey={defaultActiveTab}
            onSelect={(tab) => setDefaultActiveTab(tab)}
          >
            <Nav variant="pills" className="flex-column">
              <div className="tab-list">
                {disbursedDetails[0]?.splitpayment?.map((payment, index) => (
                  <Nav.Item key={index}>
                    <Nav.Link
                      eventKey={index + 1}
                      onClick={handleDisburedChange}
                      data={JSON.stringify(payment)}
                      index={index + 1}
                    >
                      Disbursed-{index + 1}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </div>
            </Nav>
          </Tab.Container>
        </div>
        <Formsy
          className="lead-form-filed"
          autoComplete="off"
          onValid={() => null}
          onInvalid={() => null}
        >
          <div className="row">
            {DISBURSED_FIELDS.map((field, index) =>
              ["text", "pattern-format", "number"].includes(field.type) ? (
                <fieldset className="form-filed col-md-12" key={index}>
                  <FormsyInputField
                    id={field.id}
                    name={field.name}
                    type={field.type}
                    value={(loggedin && loggedin[field.name]) || ""}
                    placeholder=" "
                    maxLength={field.maxLength}
                    label={field.label}
                    readOnly={field.readOnly}
                    required={field.required}
                  />
                </fieldset>
              ) : field.type === "date-picker" ? (
                <fieldset className="form-filed col-md-12" key={index}>
                  <FormsyDatePicker
                    name="disbursed_date"
                    selectedDate={
                      loggedin &&
                      loggedin[field.name] &&
                      new Date(loggedin[field.name])
                    }
                    disabled={true}
                    placeholder={field.label}
                    maxDate={field.maxDate}
                    minDate={field.minDate}
                    value={loggedin && loggedin[field.name]}
                    dateFormat="dd MMM, yyyy"
                    required={field.required}
                    hasError={
                      loggedin.hasOwnProperty(field.name) &&
                      !loggedin[field.name]
                        ? true
                        : false
                    }
                  />
                </fieldset>
              ) : null
            )}
          </div>
        </Formsy>

        <div className="loanflex">
          <div>
            <div className="loaninput">
              <input type="checkbox" checked={lastTranche} name="last_tranche" id="last_trance" />
              <label htmlFor="last_trance">Last Tranche</label>
            </div>
          </div>

          <div className="d-flex">
            <label className="toggle-switch">
              <input
                type="checkbox"
                checked={checked}
                onChange={handleChange}
                name="disbursed_with_otc"
                id="disbursed_with_otc"
                value="Yes"
                disabled={true}
              />
              <span className="slider"></span>
            </label>
            <label className="toggle-label">Disbursed with OTC</label>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LoanDetailsForm;
