import React from 'react';

function LoggedinPopup({ isOpen, togglePopup, children }) {
  return (
    <>
      {isOpen && (
        <div className="logginpopup" onClick={togglePopup}>
          <div className="logginpopup-cotant" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={togglePopup}>&times;</span>
            {children}
          </div>
        </div>
      )}
    </>
  );
}

export default LoggedinPopup;
