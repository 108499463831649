import React from "react";
const MobileModal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal display-block" : "modal display-none";
  return (
    <div className={showHideClassName}>
      <section className="modal-mobile-main">
        <div className="modal-mobile-height">
          {children}
          <button onClick={handleClose} className="close_icn">
            <i className="ic-clearclose"></i>
          </button>
        </div>
      </section>
    </div>
  );
};

export default MobileModal;
