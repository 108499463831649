import React, { useEffect, useState } from "react";
import Modal from "../../../../elements/partnerModal";
import "../../pre-register-lead.css";
import { MasterService } from "../../../../../services";
import Loader from "../../../../elements/Loader";
import { getDateAndTimeFormat } from "../../utility/dateFormateUtility";

export const PartnerCallHistory = ({ show, closeModal, leadId}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getActivity();
  }, []);

  const getActivity = async () => {
    const params = {
      partner_id: leadId ,
    };
    setIsLoading(true);
    try {
      const response = await MasterService.get(
        "partner/partner-call/partner-call-details-activity",
        params
      );
      setData(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleOnCloseModal = () => {
    setData([]);
    closeModal();
    setIsLoading(false);
  }

  const customStyle = {
    width: "80%",
  }

  return (
    <Modal show={show} handleClose={handleOnCloseModal} customStyle={customStyle }>
      {isLoading ? <Loader /> : null}
      <div className="pre-lead-activity">
        <h2>Pre-Lead Call History</h2>
        <table className="activity-log-table">
          <thead>
            <tr>
              <th>Call Status</th>
              <th>Title</th>
              <th>Remarks</th>
              <th>Comment</th>
              <th>Activity Date</th>
              <th>Next Follow-up Date</th>
              <th>Activity Done By</th>
            </tr>
          </thead>
          <tbody>
            {data.length ? (
              data.map((log, index) => (
                <tr key={index}>
                  <td>
                    {/* <b></b> */}
                    <span className="activity-details">
                      <strong>Status: </strong>{log.status_name}<br/>
                      <strong>Sub Status: </strong>{log.sub_status_name}<br/>
                    </span>
                  </td>
                  <td>{log.title || "N/A"}</td>
                  <td>{log.remarks || "N/A"}</td>
                  <td>{log.comment || "N/A"}</td>
                  <td>{getDateAndTimeFormat(log.created_date)}</td>
                  <td>{log.followup_date ? getDateAndTimeFormat(log.followup_date): "N/A"}</td>
                  <td>
                    {`AMB${log.created_by}`}
                  <span className="activity-details">
                      <strong>Name: </strong>{log.created_by_name}<br/>
                      <strong>Email: </strong>{log.created_by_email}
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No call activity available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Modal>
  );
};

export default PartnerCallHistory;
