import Formsy from 'formsy-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import { useOutletContext, useNavigate } from 'react-router-dom';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import FormsySelect from '../elements/FormsySelect';
import { UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../config/constants';
import { getCheckList, getLoanDetail } from '../../store/action/allAction';
import PreferedBankModel from './PreferedBankModel';
import MultiSelect from '../elements/MultiSelect';
import FormFields from "./formFields/leadFormField.json"; 
import FormsyInputField from '../elements/FormsyInputField';

const OtherBankOfferForm = FormFields["OTHER_BANK_OFFER"];

const NewleadContactedForm = (props) => {
  // State hooks
  const [newLeadDetails, setNewLeadDetails] = useState({});
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [PreffredBank, setPreffredBank] = useState(false);  
  const [QualifiedLead, setQualifiedLead] = useState(false);  
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);

  const location = useLocation();
  const urlparam = location?.pathname?.split('/');
  const pageName = urlparam?.length && urlparam[urlparam?.length - 2];

  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const dispatch = useDispatch();
  const client = useApolloClient();
  let navigate = useNavigate();

  const DOCS_COLLECTION_OPTIONS = [
    { id: "1", value: "1", label: "Kyc Docs" },
    { id: "2", value: "2", label: "Financial docs" },
    { id: "3", value: "3", label: "Login docs" }
  ];


  let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;

  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  // Context
  const getContext = useOutletContext();
  const documentCategoryList = getContext?.docList || [];

  // Effect hook to update new lead details
  useEffect(() => {
    if (lead && lead.leadDetail && lead?.leadDetail?.lead_details) {
      let newLeadFormData = { ...lead?.leadDetail?.customer, ...lead?.leadDetail?.lead_details, is_qualified: lead?.leadDetail?.is_qualified, docs_collected: lead?.leadDetail?.docs_collected?.split(",") || [] };
      setNewLeadDetails(newLeadFormData);
      let eligibleQualified = (newLeadFormData?.email && newLeadFormData?.dob && newLeadFormData?.profession && newLeadFormData?.loan_amount && newLeadFormData?.is_property_identified === 'yes' && newLeadFormData?.property_type && newLeadFormData?.property_value && newLeadFormData?.property_city) ? true : false
      setQualifiedLead(eligibleQualified)
    }
  }, [lead]);

  useEffect(() => {
    if (!masterdata.check_list) {
      dispatch(getCheckList(client))
    }
  }, [])

  const calling_reasons = useMemo(() => {
    let substatus = (masterdata?.check_list?.length) ? masterdata?.check_list.filter(ob => ob?.statuslang?.status_id == 6)?.[0]?.substatus : [];
    let calling_reason = substatus?.filter(data => data.id === (newLeadDetails.call_status === 'connected' ? 22 : 23))?.map(v => v.calling_reasons)?.[0] || [];
    return calling_reason?.map(v => ({ ...v, value: v.id, label: v.calling_reason }))
  }, [newLeadDetails.call_status]);


  const handleInputChange = (event) => {
    if (event.target.name) {
      setNewLeadDetails((currentValue) => ({
        ...currentValue,
        ...(event.target.name === 'is_calling' && { call_status: '', calling_tag: '', calling_reason_id: 0 }),
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) setNewLeadDetails(currentValue => ({ ...currentValue, [field_name]: data.id }));
  }

  const handleMultiSelectChange = (field_name, value) => {
    const doc_collected = value.map(obj => obj.id);
    setNewLeadDetails((current) => ({
      ...current,
      [field_name]: doc_collected
    }));
  }

  const markAsQualified = event => {
    let checked = "0";
    if (event.target.checked) checked = "1"
    setNewLeadDetails(currentValue => ({
      ...currentValue,
      [event.target.name]: checked
    }))
  }
  const handleSubmit = () => {
    let lead_id = lead?.leadDetail?.id || "";
    let reqBody = {
      is_calling: newLeadDetails.is_calling,
      call_status: newLeadDetails.call_status,
      calling_tag: newLeadDetails.calling_tag,
      calling_reason_id: newLeadDetails.calling_reason_id,
      is_offer: newLeadDetails.is_offer,
      offer_bank: newLeadDetails.offer_bank,
      offer_loan_amount: newLeadDetails.offer_loan_amount,
      offer_roi: newLeadDetails.offer_roi
    }

    let mutation = UPDATE_LEAD_STATUS,
      variables = {
        api_called_by: 'web',
        UpdateLeadStatus: {
          update_type: "status",
          save_type: "save",
          lead_id: lead_id,
          user_id: authUser && authUser.id || 1,
          status_id: (lead?.leadDetail?.status_id === 1 && newLeadDetails.call_status !== 'connected') ? [1] : [6],
          sub_status_id: (newLeadDetails.call_status === 'connected') ? 22 : (newLeadDetails.call_status === 'not_connected') ? 23 : 21,
          is_qualified: newLeadDetails.is_qualified,
          docs_collected: newLeadDetails.docs_collected.join(","),
          new_lead_form: reqBody
        }
      };

    executeGraphQLMutation(mutation, variables, client).then(resp => {
      let response = resp?.data?.UpdateLeadStatus || null;
      if (response && !response.success) {
        throw new Error(response.error || "Something went wrong");
      }
      toast.success("Success");
      dispatch(getLoanDetail(lead_id, client))
    })
      .catch(err => {
        toast.error(err.message);
      })
  }

  const is_submit_disabled = useMemo(() => {
    return (![1, 6].includes(lead?.leadDetail?.status_id)) ? true : false;
  }, [getContext]);

  const showModalSavePreffredBank = () => {      
    setPreffredBank(true);
    document.body.classList.add("overflow-hidden");
  };
  const hideModalPreffredBank = () => { 
    setPreffredBank(false); 
    document.body.classList.remove("overflow-hidden");
  };

  let ALL_OPTIONS = useMemo(() => {
    let options = masterdata ? { ...masterdata.data } : {};
    if (options?.bank_list && options?.bank_list?.length) {
      options.bank_list = options?.bank_list?.map(data => {
        data.value = data?.id || ''
        data.label = data?.banklang?.bank_name || ''
        return data;
      })
    }
    return options;
  }, [masterdata]);

  const handleSelectBankChange = (data, field_name) => {
    if (data && field_name) {
      setNewLeadDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      setNewLeadDetails(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  }

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="1" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="lead-form-heading">
            <h2>{pageName === 'new-lead' ? "New Lead" : "Contacted"}</h2>
          </div>
          {newLeadDetails.first_name ? <div> <h3 style={{ display: "flex", fontWeight: 600 }}>{newLeadDetails.first_name + ' ' + newLeadDetails.last_name}
            {/* <a href={`tel:${newLeadDetails?.mobile}`}><img style={{ marginLeft: 5 }} src={callicon} alt='logo' width={25} height={25} /></a>  */}
          </h3> </div> : ""}
          <br></br>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {/* Radio buttons for call attempt */}
              <fieldset className="form-filed col-md-12 ">
                <div style={{ marginBottom: 10 }}><strong>Did you attempt calling this user?</strong></div>
                <div className='followupradio' style={{ marginBottom: 10 }}>
                  <div className='followupcall'>
                    <input type="radio" required id="is_calling_yes" name="is_calling" value="1" checked={newLeadDetails.is_calling === '1'} disabled={lead?.leadDetail?.lead_details?.call_status != ''} onChange={handleInputChange} />
                    <label htmlFor="is_calling_yes"> Yes</label>
                  </div>
                  <div className='followupcall'>
                    <input type="radio" required id="is_calling_no" name="is_calling" value="0" checked={newLeadDetails.is_calling === '0'} disabled={lead?.leadDetail?.lead_details?.call_status != ''} onChange={handleInputChange} />
                    <label htmlFor="is_calling_no">No</label>
                  </div>
                </div>
              </fieldset>

              {newLeadDetails?.is_calling === '1' ?
                <fieldset className="form-filed col-md-12 ">
                  <div style={{ marginBottom: 10 }}><strong>Was the call connected?</strong></div>
                  <div className='followupradio' style={{ marginBottom: 10 }}>
                    <div className='followupcall'>
                      <input type="radio" required id="call_status_connected" name="call_status" value="connected" checked={newLeadDetails.call_status === 'connected'} onChange={handleInputChange} />
                      <label htmlFor="call_status_connected"> Connected</label>
                    </div>
                    <div className='followupcall'>
                      <input type="radio" required id="call_status_not_connected" name="call_status" value="not_connected" checked={newLeadDetails.call_status === 'not_connected'} onChange={handleInputChange} />
                      <label htmlFor="call_status_not_connected">Not Connected</label>
                    </div>
                  </div>
                </fieldset> : ""}

              {newLeadDetails?.is_calling === '1' && newLeadDetails?.call_status === 'connected' || newLeadDetails.calling_tag && newLeadDetails?.is_calling === '1' ?
                <fieldset className="form-filed col-md-12 ">
                  <div style={{ display: "flex", alignItems: "center" }}>

                    <div style={{ marginBottom: 10 }}><strong>Tag this lead as : </strong></div>
                    <div style={{ marginLeft: 40 }}>
                      <div className='followupradio' style={{ marginBottom: 10 }}>

                        <div className='followupcall'>
                          <input type="radio" id="calling_tag_hot" name="calling_tag" value="hot" checked={newLeadDetails.calling_tag === 'hot'} onChange={handleInputChange} style={{ display: 'none' }} />
                          <label htmlFor="calling_tag_hot" style={{ backgroundColor: newLeadDetails.calling_tag === 'hot' ? 'red' : 'transparent', padding: '5px 10px', borderRadius: '5px', color: newLeadDetails.calling_tag === 'hot' ? 'white' : 'red', cursor: 'pointer' }}>Hot</label>
                        </div>

                        <div className='followupcall'>
                          <input type="radio" id="calling_tag_warm" name="calling_tag" value="warm" checked={newLeadDetails.calling_tag === 'warm'} onChange={handleInputChange} style={{ display: 'none' }} />
                          <label htmlFor="calling_tag_warm" style={{ backgroundColor: newLeadDetails.calling_tag === 'warm' ? '#c5c50b' : 'transparent', padding: '5px 10px', borderRadius: '5px', color: newLeadDetails.calling_tag === 'warm' ? 'black' : '#c5c50b', cursor: 'pointer' }}>Warm</label>
                        </div>

                        <div className='followupcall'>
                          <input type="radio" id="calling_tag_cold" name="calling_tag" value="cold" checked={newLeadDetails.calling_tag === 'cold'} onChange={handleInputChange} style={{ display: 'none' }} />
                          <label htmlFor="calling_tag_cold" style={{ backgroundColor: newLeadDetails.calling_tag === 'cold' ? 'blue' : 'transparent', padding: '5px 10px', borderRadius: '5px', color: newLeadDetails.calling_tag === 'cold' ? 'white' : 'blue', cursor: 'pointer' }}>Cold</label>
                        </div>
                      </div>

                    </div>
                  </div>
                </fieldset> : ""}

              {newLeadDetails?.is_calling === '1' ?
                <fieldset className="single-select col-md-12">
                  <div style={{ marginBottom: 10 }}><strong>Select calling sub status: </strong></div>
                  <FormsySelect
                    name="calling_reason_id"
                    id="calling_reason_id"
                    inputProps={{
                      options: calling_reasons,
                      placeholder: " ",
                      className: "react-select",
                      required: "required",
                      classNamePrefix: "react-select",
                      value: calling_reasons && calling_reasons.filter(({ id }) => id == newLeadDetails['calling_reason_id'])
                    }}
                    value={newLeadDetails && newLeadDetails['calling_reason_id']}
                    onChange={(data) => handleSelectChange(data, 'calling_reason_id')}
                  />
                </fieldset> : ""}
              
              {/* {newLeadDetails?.is_calling === '1' && QualifiedLead ? */}
                <fieldset className="form-filed col-md-12 ">
                  {/* <div style={{ marginBottom: 10 }}><strong>Was the call connected?</strong></div> */}
                  <div className='followupradio' style={{ marginBottom: 10 }}>
                    {/* <div className='followupcall'>
                    <input type="checkbox" id="balancetransfer" name="is_qualified" value="1" disabled={lead?.leadDetail?.is_qualified === '1'} checked={newLeadDetails?.is_qualified === '1'} onChange={markAsQualified} />
                    <label htmlFor='balancetransfer'>Lead Qualified</label>
                    </div> */}

                    <div className='followupcall'>
                      <input type="checkbox" id="balancetransfer" name="is_qualified" value="1" 
                        disabled={
                          lead?.leadDetail?.lead_details?.loan_amount <= 1999999 || 
                          lead?.leadDetail?.lead_details?.agreement_type==4 ||
                          ![67, 69, 188, 196, 327, 1630, 328, 7, 99].includes(lead?.leadDetail?.lead_details?.property_city) 
                        }
                        checked={newLeadDetails?.is_qualified === '1'}
                        onChange={markAsQualified} 
                      />
                      <label htmlFor='balancetransfer'>Lead Qualified</label>
                    </div>

                    {/* <div className='followupcall'>
                    <input type="checkbox" id="top-up" name="docs_collected" value="1" disabled={lead?.leadDetail?.docs_collected === '1'} checked={newLeadDetails?.docs_collected === '1'} onChange={markAsQualified} />
                    <label htmlFor='top-up'>Docs Collected</label>
                    </div> */}

                    <div className="col-sm-6">
                      <fieldset className="multiselect-dropDown">
                        <div className="material">
                          <MultiSelect
                            options={DOCS_COLLECTION_OPTIONS}
                            placeholder={"Select Docs"}
                            name={"docs_collected"}
                            optionLabel="label"
                            optionValue="id"
                            values={newLeadDetails["docs_collected"]}
                            onChange={handleMultiSelectChange}
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </fieldset> 
              {/* : ""} */}

              {/* Does customer have offer from other bank */}
              {newLeadDetails?.call_status === 'connected'? 
                <fieldset className="form-filed col-md-12 ">
                  <div style={{ marginBottom: 10 }}><strong>Does customer have offer from other bank?</strong></div>
                  <div className='followupradio' style={{ marginBottom: 10 }}>
                    <div className='followupcall'>
                      <input type="radio"  id="is_offer_yes" name="is_offer" value="1" checked={newLeadDetails.is_offer === '1'} /*disabled={lead?.leadDetail?.lead_details?.call_status != ''} */onChange={handleInputChange} />
                      <label htmlFor="is_offer_yes"> Yes</label>
                    </div>:
                    <div className='followupcall'>
                      <input type="radio"  id="is_offer_no" name="is_offer" value="0" checked={newLeadDetails.is_offer === '0'} /*disabled={lead?.leadDetail?.lead_details?.call_status != ''} */ onChange={handleInputChange} />
                      <label htmlFor="is_offer_no">No</label>
                    </div>
                  </div>
                </fieldset>:""}

              {newLeadDetails.is_offer==='1' && newLeadDetails.call_status==='connected'?
                <div className="row">
                  {OtherBankOfferForm.map((field) => (
                    ["number", 'number-format'].includes(field.type) ? (
                      <fieldset className="form-filed col-md-6 ">
                        <FormsyInputField
                          id={field.id}
                          name={field.name}
                          type={field.type}
                          value={newLeadDetails && newLeadDetails[field.name]}
                          placeholder=" "
                          thousandsGroupStyle="lakh"
                          thousandSeparator={','}
                          decimalSeparator={'.'}
                          label={field.label}
                          onChange={
                            field.type === "number-format"
                              ? (data) => handlePatternFormatChange(data, field.name)
                              : handleInputChange
                          }
                          format={field.ApplyNumberformat ? field.format : ""}
                          validations={field.validations}
                          validationError={
                            newLeadDetails[field.name] ? field.validationError : ""
                          }
                          readOnly={field.readOnly}
                          required={field.required}
                        />
                      </fieldset>
                    ) : field.type === "dropdown" ? (
                          <fieldset className="single-select col-md-6">
                            <FormsySelect
                              name={field.name}
                              id={field.id}
                              inputProps={{
                                options: ALL_OPTIONS && ALL_OPTIONS.bank_list,
                                placeholder: field.label,
                                className: "react-select",
                                classNamePrefix: "react-select",
                                value: ALL_OPTIONS && ALL_OPTIONS.bank_list && ALL_OPTIONS.bank_list.filter(({ value }) => value == newLeadDetails.offer_bank),
                                isDisabled: field.readOnly
                              }}
                              required={true}
                              value={newLeadDetails.offer_bank}
                              onChange={(data) => handleSelectBankChange(data, 'offer_bank')}
                            />
                          </fieldset>
                        ) : null
                  ))}
                </div>: ""}
            </div>

            {/* Buttons for update and remarks */}
            <div className="btn-save-remarks">
              <button type="submit" className="btn-primary" disabled={is_submit_disabled}>Update</button>
              {lead?.leadDetail?.fulfillment_type ==='ambak' ? 
              <button type="button" disabled={![1,6].includes(lead?.leadDetail?.status_id)} onClick={showModalSavePreffredBank} className="btn-primary">Express Login</button>:""}
            </div>
           
          </Formsy>
        </div>
      </div>
      <div className="mark-lost-popup">
        <PreferedBankModel BankModelShow={PreffredBank} hideModalPreffredBank={hideModalPreffredBank}/>
      </div>
    </div>
  );
}

export default NewleadContactedForm;
