
const masterDataReducer = (state = '', action) => {
    switch (action.type) {
        
        case "GET_MASTER_DATA": 
			return {
				...state,
				data: action.payload,
			};
        case "GET_MASTER_DATA_MMV":
            return {
                ...state,
                masterdatammv: action.payload,
            };
        case "GET_STATE_CITY": 
            return {
                ...state,
                data:{
                    ...state.data,
                    state_list: action.payload.state,
                    city: action.payload.city
                } 
            };
        case "SET_CITY_LIST": 
            return {
                ...state,
                data:{
                    ...state.data, 
                    city_list: action.payload
                } 
            };
        case "GET_STATUS_LIST": 
            return {
                ...state,
                data:{
                    ...state.data, 
                    status_list: action.payload
                } 
            }        
        case "SET_CHECK_LIST": 
            return {
                ...state,
                check_list: action.payload
            };
		default:
            return state
    }
}

export default masterDataReducer;
