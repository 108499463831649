import React, { useState } from "react";
import DateFormate from "dateformat";
import Pagination from "../elements/pagination";
import Modal from "../elements/Modal";
import MismatchDataDetail from "./MismatchDataDetail";
import { toast } from 'react-toastify';
import {  
  executeGraphQLQuery
} from "../../common/partnerExecuteGraphQLQuery";

import {  
  UPDATE_MATCHED_STATUS,  
} from "../../services/payout.gql.js";

const GstTableContent = ({
  gstStatusData,
  gstStatusDataPagination,
  gstStatusDataPageNo = 1,
  setGstDataPageNo,
  gstStatus,
}) => {
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [showDetailData, setShowDetailData] = useState({});
  const [showDetailDataKeys, setShowDetailDataKeys] = useState([]);

  const onPaginate = (pageNo) => {
    setGstDataPageNo(pageNo);
  };

  const handleShowDetail = (event) => {
    const id = event.target.getAttribute("dataid");
    const filteredData = gstStatusData.filter(obj => obj.id == id)[0] || {};
    setShowDetailData(filteredData);
    setShowDetailDataKeys(Object.keys(filteredData));
    setModalVisiblity(true);
  }

  const handleUpdateStatus = async (partner_id,id) => {
    try {
      const responseData = await executeGraphQLQuery(
        UPDATE_MATCHED_STATUS(partner_id,id)
      );
      if (responseData?.data.update_matched_status && responseData?.data.update_matched_status!="") {
        toast.success(responseData?.data?.update_matched_status || "Success");
    }else{
        toast.success("Something went wrong!");
    }
    } catch (error) {
      toast.error("Failed");
      console.error("error", error);
    }
  }

  return (
    <>
      {modalVisiblity && (
        <>
          <div className="view-timeline-popup">
            <Modal
              show={modalVisiblity}
              removeHeight={true}
              handleClose={() => setModalVisiblity(false)}
            >
              <MismatchDataDetail keyArry={showDetailDataKeys} data={showDetailData} />
            </Modal>
          </div>
        </>
      )}

      <div className="clearfix">
        <div className="table-responsive">
          <table className="table table-bordered tablefontsize">
            <thead>
              <tr>                
                {gstStatus === "not matched" ? (
                        <th>Partner Details </th>
                     ) : null}
                {gstStatus === "other vendors" ? (
                        <th>Vendor Details </th>
                     ) : null}     
                <th>GST Claimed</th>
                <th>Claimed Invoice Details</th>
                <th>Claimed Invoice Number</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {gstStatusData &&
                gstStatusData.map((data, index) => (
                  <>
                    <React.Fragment key={index}>
                      <tr>
                        { gstStatus === "not matched" ? (
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Partner Code : <span> AMK{data.partner_id}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Partner Name : <span>{data.partner_name}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                             GSTIN : <span>{data.ctin}</span>
                          </div>
                        </td>
                         ):""  }

                       { gstStatus === "other vendors" ? (
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                          Vendor Name : <span> {data.trdnm}</span>
                          </div>                          
                          <div style={{ opacity: 0.6 }}>
                          Vendor GSTIN : <span>{data.ctin}</span>
                          </div>
                        </td>
                         ):""  }

                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Total :{" "}
                            <span>{+data.igst + +data.cgst + +data.sgst}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            IGST : <span>{data.igst}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            CGST : <span>{data.cgst}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            SGST : <span>{data.sgst}</span>
                          </div>
                        </td>
                        <td>
                          <div style={{ fontSize: 13, fontWeight: 600 }}>
                            Taxable Amt : <span>{data.txval}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Invoice Date : <span>{data.inv_date}</span>
                          </div>
                          <div style={{ opacity: 0.6 }}>
                            Invoice Amt : <span>{data.invoice_amt}</span>
                          </div>
                        </td>
                        <td>
                            {data.inum}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {data.mapping_status === "1" && (
                              <>
                                <button onClick={()=>handleUpdateStatus(data.partner_id,data.id)}>Move to Gst Claimed</button>                                
                              </>
                            )}
                            <button onClick={handleShowDetail} dataid={data.id}>
                              Show Detail
                            </button>
                          </div>
                        </td>
                      </tr>
                    </React.Fragment>
                  </>
                ))}
              <tr>
                <td colSpan={3}>
                  {" "}
                  <Pagination
                    pageCount={
                      gstStatusDataPagination &&
                      gstStatusDataPagination.totalpage
                        ? gstStatusDataPagination.totalpage
                        : 0
                    }
                    activePage={gstStatusDataPageNo}
                    onPaginate={onPaginate}
                  />
                </td>
                <td>
                  Total:{" "}
                  {gstStatusDataPagination &&
                  gstStatusDataPagination.totalrecords
                    ? gstStatusDataPagination.totalrecords
                    : 0}{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GstTableContent;
