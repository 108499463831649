import React, { useEffect, useMemo, useState } from 'react';
import Formsy from 'formsy-react';
import { useDispatch, useSelector } from 'react-redux';
import DocumentGallery from '../DocumentGallery';
import FormFields from "../formFields/customerDetailsFormFields.json";
import FormsyInputField from '../../elements/FormsyInputField';
import FormsyDatePicker from '../../elements/FormsyDatePicker';
import FormsySelect from '../../elements/FormsySelect';
import { useOutletContext } from 'react-router-dom';
import { Nav } from 'react-bootstrap';
import dateFormat from 'dateformat';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { getMasterCityState,getReferencesDetails, getReferencesDetails1,setCityList } from '../../../store/action/allAction'; 
import GeneralService from '../../../services/generalService';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../config/constants';
import { GET_REFERENCE_DETAILS, SAVE_REFERENCE, SAVE_REFERENCE_DETAILS } from '../../../services/reference.gql';

const FORM_FIELDS = FormFields["REFERENCES"];

const Reference = (props) => {
  const [allReferences, setAllReferences] = useState([]);
  const [reference, setReference] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [allowSubmit, setAllowSubmit] = useState(false);
  const { masterdata, lead, remarksHistory } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
    remarksHistory: lead.remarksHistory || []
  }));
  const [isFormTouch,setTouch] = useState({})
  const client = useApolloClient();
  const dispatch = useDispatch();
  let accessCondition = !IS_USER_ACCESS_PAGE("loanDetail", "customer-basic-details")?.is_edit_access;

  if (accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  useEffect(()=>{  
    if(!masterdata.data || !masterdata.data.state_list){
      dispatch(getMasterCityState())
    } 
  },[])
  useEffect(()=>{
    let options = masterdata ? { ...masterdata.data } : {}; 
    if (options?.city && options?.city?.length) { 
      let record  = options.city.filter(ob=>ob.state_id == reference.cra_state).map(data => {
        data.value = data.id
        data.label = data.label
        return data;
      })  
      dispatch(setCityList(record))
    } 
  },[reference?.cra_state]) 

  const getAllReferences = (lead_id, client) => {
    executeGraphQLQuery(GET_REFERENCE_DETAILS(lead_id), client).then(response => {
      setAllReferences(response?.data?.get_references_details);
      setReference({...response?.data?.get_references_details[0]});
    }).catch(err => console.log(err));
  }
 

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.customer) {
      const lead_id = lead.leadDetail.id;
      getAllReferences(lead_id, client);
    }
  }, []);
  
  let ALL_OPTIONS = useMemo(() => { 
    let options = masterdata ? { ...masterdata.data } : {};
    return options;
  }, [masterdata]);

  const handleReferenceChange = (event) => {
    const ref = event.target.getAttribute('data');
    if (allReferences.length) {
      const selectedRef = allReferences[ref] || {};
      setReference(selectedRef);
    }
    const referenceId = parseInt(event.target.getAttribute('data'));
    setActiveTab(referenceId);
  }
  
  const handleInputChange = event => {
    const { id, value } = event.target; 
    setTouch({
      ...isFormTouch,
      [id]:true
    })
    if (id === 'pancard_no') {
      setReference(currentValue => ({
        ...currentValue,
        [id]: value.toUpperCase()
      }));
    } else {  
      if(id =='pincode' && value.length ==6){ 
        setCityStateByPin(value)
      }
      setReference(currentValue => ({
        ...currentValue,
        [id]: value
      })); 
    }
  } 
  const setCityStateByPin = async (pincode)=>{ 
    try {
      let response = await GeneralService.stateCityByPincode(pincode); 
      if (response?.data?.data?.length) {
        let item = response.data.data[0] 
        setReference({
          ...reference,
          pincode: pincode,
          state: item?.state_id,
          city: item?.city_id
        })
      }  
    } catch (error) {
      console.error('Error :', error);
    }
  }

  const handlePatternFormatChange = (data, field_name) => { 
    if (data.value && field_name) {
      // setReference(currentValue => ({
      //   ...currentValue,
      //   [field_name]: data.value
      // }))
    }
  }

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if(field_name.includes('state')){ 
        let options = masterdata ? { ...masterdata.data } : {}; 
        if (options?.city && options?.city?.length) {
          let record  = options.city.filter(ob=>ob.state_id==data.value).map(data => {
            data.value = data.id
            data.label = data.label
            return data;
          })
          dispatch(setCityList(record))
        }
      } 
      setReference(currentValue => ({
        ...currentValue,
        [field_name]: data.value
      }))
    }
  } 
  const handleSubmit = async () => {
    let lead_id = lead?.leadDetail?.id || "";  
    let reqBody = {
      lead_id: lead_id,
      name: reference.name,
      email:reference.email,
      phone: reference.phone,
      pincode: reference.pincode,
      city: reference.city,
      state: reference.state,
      address:reference.address,
      id: reference.id
    }  
    let mutation = SAVE_REFERENCE,
    variables = {
      api_called_by: 'web',
      saveReferenceInput: {
        lead_id: lead_id,
       ...reqBody
      }
    };
    executeGraphQLMutation(mutation, variables, client).then(resp => {
     
      let response = resp?.data?.save_reference_details || null;
      toast.success("Success");
      executeGraphQLQuery(GET_REFERENCE_DETAILS(lead_id), client).then(response => {
        setAllReferences(response?.data?.get_references_details);
      }).catch(err => console.log(err));

    })
      .catch(err => {
        toast.error(err.message);
      })
  }

 
  const  IS_LOST = (lead?.leadDetail?.status_id ==5)? true: false  

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="1" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          {/* {JSON.stringify(reference)} */}
          <div className="lead-form-heading">
            {/* <span className="main-heading">Customer Details</span> */}
            <h2>Reference Details</h2>
          </div>
          <Nav variant="pills" className="flex-column" style={{ overflow: "hidden", overflowX: "auto" }}>
            <div className="tab-list">
                  <Nav.Item>
                    <Nav.Link
                     onClick={handleReferenceChange}
                     data="0"
                     active={activeTab === 0}
                    >
                      Reference - 1 
                    </Nav.Link>
                  </Nav.Item> 
                  <Nav.Item>
                    <Nav.Link
                      onClick={handleReferenceChange}
                      data="1"
                      active={activeTab === 1}
                    >
                      Reference - 2
                    </Nav.Link>
                  </Nav.Item>
             
            </div>
          </Nav>

          {/* <button className='btn-primary' onClick={handleReferenceChange} data="0" style={{marginRight:10,marginBottom:10}}>Reference-1</button>
          <button className='btn-primary' onClick={handleReferenceChange} data="1" style={{marginRight:10,marginBottom:10}}>Reference-2</button> */}
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => setAllowSubmit(true)}
            onInvalid={() => setAllowSubmit(false)}
            onValidSubmit={handleSubmit}
          >
            <div className="row">
              {FORM_FIELDS.map((field,index) => (
                ["text", "pattern-format", "number"].includes(field.type) ? (
                  <fieldset className="form-filed col-md-6 " key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field?.name}
                      type={field.type}
                      value={reference && reference[field?.name] || ''}
                      placeholder=" "
                      maxLength={field.maxLength}
                      label={field.label}
                      onChange={
                        field.type === "pattern-format"
                          ? (data) => handlePatternFormatChange(data, field?.name)
                          : handleInputChange
                      }
                      format={field.ApplyNumberformat ? field.format : ""}
                      validations={isFormTouch[field?.name] ? field.validations :null}
                      validationError={""}
                      readOnly={field.readOnly}
                      required={field.required}
                    />
                  </fieldset>
                ) :
                    field.type === "dropdown" ? (
                      <fieldset className="single-select col-md-6" key={index}>
                        <FormsySelect
                          name={field.name}
                          id={field.id}
                          inputProps={{
                            options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                            placeholder: field.label,
                            className: "react-select",
                            classNamePrefix: "react-select",
                            value: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey] && ALL_OPTIONS[field.optionsKey].filter(({ value }) => value == reference[field.name]),
                            isDisabled: field.readOnly
                          }}
                          required={field.required}
                          value={reference && reference[field.name]}
                          onChange={(data) => handleSelectChange(data, field.name)}
                        />
                      </fieldset>
                    ) : null
              ))}

            </div>

            <div className="btn-save-remarks">
              <button type="submit" className="btn-primary" disabled={!allowSubmit || IS_LOST}>
                Save
              </button>
            </div>
          </Formsy>
        </div>
      </div>
     
    </div>
  );
}
export default Reference;