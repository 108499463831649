import FormFields from "../formFields/breOfferFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import React, { useState, useMemo, useEffect, useRef } from "react";
import FormsySelect from "../../elements/FormsySelect";
import { useSelector } from "react-redux";
import { Nav } from "react-bootstrap";
import Formsy from "formsy-react";
import { AMOUNT_TYPE_OPTIONS } from "../../../config/constants";
import AdditionalIncome from "../../elements/AdditionalIncome";
import BreITRSection from "./BreITRSection";
import BreBankIncome from "./BreBankIncome";
import BreObligations from "./BreObligations";

const CO_APPLICANT_FIELDS = FormFields["CO_APPLICANT"];
const SEP_ID = 2;

const BreCoApplicant = ({ setCoApplicantIncomeDetails }) => {
  const caAddIncRef = useRef(null);
  const caAddIncNoRef = useRef(null);
  const [activeTab, setActiveTab] = useState("coApplicant_0");
  const [isFormTouch, setTouch] = useState({});
  const [mapCoApplicant, setMapCoApplicant] = useState([]);
  const [additionalIncomeFormFields, setAdditionalIncomeFormFields] = useState(
    []
  );
  const [additionalIncomeCount, setAdditionalIncomeCount] = useState(1);
  const [hasAdditionalIncome, setHasAdditionalIncome] = useState(false);

  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));

  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata.data } : {};
    const LIQUID_INCOME_ID = 6;
    options["company_type"] = options["company_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    options["ca_amount_type"] = AMOUNT_TYPE_OPTIONS;
    options["additional_income_type"] =
      options["additional_income_type"]
        ?.map((obj) => ({
          ...obj,
          value: obj.id,
        }))
        ?.filter((obj) => obj.value != LIQUID_INCOME_ID) || [];
    options["ca_filtered_additional_income_type_0"] = options[
      "additional_income_type"
    ]?.map((ob) => ({
      ...ob,
      value: +ob.id,
    }));
    options["profession"] = options["profession"]?.filter(
      (obj) => obj.value !== SEP_ID
    );

    return options;
  }, [masterdata]);

  useEffect(() => {
    if (lead && lead.leadDetail) {
      let co_applicatndata = lead?.leadDetail?.co_applicant || [];
      const activeIndex = activeTab.split("_")[1];
      const newCoApplicants = co_applicatndata.map((data, index) => {
        const newAppObject = {};
        newAppObject.id = data.id;
        newAppObject.ca_profession = data.ca_profession;
        newAppObject.ca_annual_income = data.ca_annual_income;
        newAppObject.ca_gross_monthly_salary = data.ca_gross_monthly_salary;
        newAppObject.ca_monthly_salary = data.ca_monthly_salary;
        newAppObject.ca_first_name = data.ca_first_name;
        newAppObject.ca_is_additional_income = data.ca_is_additional_income;
        newAppObject.ca_offer_bank_details = data.ca_offer_bank_details;
        newAppObject.ca_offer_itr_details = data.ca_offer_itr_details;
        newAppObject.no_of_accounts = data.ca_no_of_accounts;
        newAppObject.ca_income_calculation_mode = data.ca_offer_type;
        newAppObject.ca_existing_emi_amount = data.ca_existing_emi_amount;
        newAppObject.ca_emi_ending_six_month = data.ca_emi_ending_six_month;
        newAppObject.ca_is_obligation = data.ca_is_obligation;
        if (data.ca_offer_itr_details?.length > 0) {
          newAppObject["is_coapplicant_file_itr"] =
            data.ca_offer_itr_details[0].is_itr_filled;
        }
        let ca_additionalIncome = [];
        if (data.ca_is_additional_income === "yes") {
          ca_additionalIncome = data.ca_additional_income?.map((obj) => {
            return {
              id: obj.id,
              lead_id: obj.lead_id,
              customer_id: obj.customer_id,
              customer_type: obj.customer_type,
              ca_income_type_id: obj.income_type_id,
              ca_amount_type: obj.amount_type,
              ca_amount: obj.amount,
            };
          });
        }
        newAppObject.ca_additional_income = ca_additionalIncome;
        return newAppObject;
      });

      if (newCoApplicants[activeIndex]?.["ca_is_additional_income"] === "yes") {
        setHasAdditionalIncome(true);
        const co_app = newCoApplicants[activeIndex].ca_additional_income;
        for (let i = 0; i < co_app.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`ca_filtered_additional_income_type_${i + 1}`] =
            ALL_OPTIONS[`ca_filtered_additional_income_type_${i}`]?.filter(
              (obj) => obj.value !== co_app[i].ca_income_type_id
            );
        }
      }
      setMapCoApplicant(newCoApplicants);
      setCoApplicantIncomeDetails(newCoApplicants);
    }
  }, [lead]);

  const handleInputChange = (event) => {
    if (event.target) {
      const { id, value } = event.target;
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];
      existingCo[activeIndex][id] = value;
      setMapCoApplicant(existingCo);
      setCoApplicantIncomeDetails(existingCo);
    }
  };

  const handlePatternFormatChange = (data, field_name) => {
    if (data.value && field_name) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];
      existingCo[activeIndex][field_name] = data.value;
      setMapCoApplicant(existingCo);
      setCoApplicantIncomeDetails(existingCo);
    }
  };

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];

      if (field_name.indexOf("ca_additional_income_type_") > -1) {
        const arry = field_name.split("_");
        const index = +arry[arry.length - 1];

        ALL_OPTIONS[`ca_filtered_additional_income_type_${index + 1}`] =
          ALL_OPTIONS[`ca_filtered_additional_income_type_${index}`].filter(
            (obj) => obj.value !== data.value
          );
      }
      existingCo[activeIndex][field_name] = data.value;
      setMapCoApplicant(existingCo);
      setCoApplicantIncomeDetails(existingCo);
    }
  };

  const handleAddCoApplicant = () => {
    if (mapCoApplicant.length < 3) {
      const newCoApplicant = {
        ca_first_name: "",
        ca_profession: "",
        ca_monthly_salary: "",
        ca_gross_monthly_salary: "",
      };
      setActiveTab(`coApplicant_${mapCoApplicant.length}`);
      setMapCoApplicant((current) => [...current, newCoApplicant]);
    }
  };

  const handleRadioChange = (event) => {
    if (event.target) {
      const { id, name } = event.target;
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];
      if (id === "yes" && name === "ca_is_additional_income") {
        setHasAdditionalIncome(true);
        handleAddAdditionalIncome(0);
      } else if (id === "no" && name === "ca_is_additional_income") {
        setHasAdditionalIncome(false);
        setAdditionalIncomeCount(0);
        setAdditionalIncomeFormFields([]);
      }

      if (id === "ca_itr") {
        existingCo[activeIndex][name] = "itr";
      } else if (id === "ca_bank") {
        existingCo[activeIndex][name] = "bank";
      } else if (id === "co_app_yes") {
        existingCo[activeIndex][name] = "yes";
      } else if (id === "co_app_no") {
        existingCo[activeIndex][name] = "no";
      } else {
        existingCo[activeIndex][name] = id;
      }

      // add slight delay to state updates and component re-renders
      setTimeout(() => {
        setMapCoApplicant(existingCo);
        setCoApplicantIncomeDetails(existingCo);
      }, 0);
    }
  };

  const handleAddAdditionalIncome = (index) => {
    if (index < 5) {
      const isExisting = additionalIncomeFormFields.filter(
        (obj) => obj.id === `ca_additional_income_type_${index}`
      );
      if (
        isExisting.length === 0 ||
        additionalIncomeFormFields[additionalIncomeFormFields.length - 1]
          .canAddMore
      ) {
        const dropDownObj = { id: `ca_additional_income_type_${index}` };
        setAdditionalIncomeCount(index + 1);
        setAdditionalIncomeFormFields((current) => [...current, dropDownObj]);
      }
    }
  };

  useEffect(() => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...mapCoApplicant];

    if (existingCo[activeIndex]) {
      if (existingCo[activeIndex]?.["ca_is_additional_income"] === "yes") {
        setHasAdditionalIncome(true);
        const co_app = existingCo[activeIndex].ca_additional_income;
        for (let i = 0; i < co_app.length; i++) {
          handleAddAdditionalIncome(i);
          ALL_OPTIONS[`ca_filtered_additional_income_type_${i + 1}`] =
            ALL_OPTIONS[`ca_filtered_additional_income_type_${i}`]?.filter(
              (obj) => obj.value !== co_app[i].ca_income_type_id
            );
        }
      } else {
        if (caAddIncNoRef.current) {
          caAddIncNoRef.current.click();
        }
      }
    }
    if (existingCo.length > 0) {
      setMapCoApplicant(existingCo);
    }
  }, [activeTab]);

  const handleAdditionalIncomeType = (data, index) => {
    if (data) {
      ALL_OPTIONS[`ca_filtered_additional_income_type_${index + 1}`] =
        ALL_OPTIONS[`ca_filtered_additional_income_type_${index}`].filter(
          (obj) => obj.value !== data.value
        );
    }
  };

  const handleSetAdditionalIncomeData = (data) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...mapCoApplicant];
    existingCo[activeIndex]["ca_additional_income"] = data;
    setMapCoApplicant(existingCo);
    setCoApplicantIncomeDetails(existingCo);
  };

  const handleRemoveAdditionalIncome = (index) => {
    const activeIndex = activeTab.split("_")[1];
    const existingCo = [...mapCoApplicant];
    const existingAddIncomeField = [...additionalIncomeFormFields];
    const existingAddIncome = [
      ...mapCoApplicant[activeTab.split("_")[1]]["ca_additional_income"],
    ];
    const removedEle = {
      ...existingAddIncome[index],
      status: "0",
      income_type_id: null,
      amount_type: "",
      amount: "",
    };
    existingAddIncomeField.splice(index, 1);
    if (existingAddIncomeField.length > 0) {
      existingAddIncomeField[
        existingAddIncomeField.length - 1
      ].canAddMore = true;
    }
    existingAddIncome.splice(index, 1);
    existingAddIncome.push(removedEle);
    existingCo[activeIndex]["ca_additional_income"] = existingAddIncome;
    setAdditionalIncomeCount(additionalIncomeCount - 1);
    setAdditionalIncomeFormFields(existingAddIncomeField);
    setMapCoApplicant(existingCo);
    setCoApplicantIncomeDetails(existingCo);
  };

  const handleSetIncomeData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];

      if (obj) {
        existingCo[activeIndex] = { ...existingCo[activeIndex], ...obj };
      } else {
        existingCo[activeIndex][key] = value;
      }
      setMapCoApplicant(existingCo);
      setCoApplicantIncomeDetails(existingCo);
    }
  };

  const handleSetObligationData = (key, value, obj) => {
    if (!(key === null) || !(obj === null)) {
      const activeIndex = activeTab.split("_")[1];
      const existingCo = [...mapCoApplicant];
      if (obj) {
        existingCo[activeIndex] = { ...existingCo[activeIndex], ...obj };
      } else {
        existingCo[activeIndex][key] = value;
      }
      setMapCoApplicant(existingCo);
      setCoApplicantIncomeDetails(existingCo);
    }
  };

  return (
    <>
      {mapCoApplicant.length < 3 && (
        <div style={{ textAlignLast: "right" }}>
          <button className="addcoapplicant" onClick={handleAddCoApplicant}>
            + Co-applicant
          </button>
        </div>
      )}

      <Nav
        variant="pills"
        className="flex-column"
        style={{ overflow: "hidden", overflowX: "auto" }}
      >
        <div className="tab-list">
          {mapCoApplicant.map((coApplicant, index) => (
            <>
              <Nav.Item key={index}>
                <Nav.Link
                  onClick={() => setActiveTab(`coApplicant_${index}`)}
                  eventkey={`coApplicant_${index}`}
                  active={activeTab === `coApplicant_${index}`}
                  key={index}
                >
                  {`Co-applicant - ${index + 1}`}
                </Nav.Link>
              </Nav.Item>
            </>
          ))}
        </div>
      </Nav>

      {mapCoApplicant.length > 0 && (
        <>
          <Formsy
            className="lead-form-filed"
            autoComplete="off"
            onValid={() => null}
            onInvalid={() => null}
          >
            <div className="row">
              {CO_APPLICANT_FIELDS.map((field, index) =>
                (mapCoApplicant[activeTab.split("_")[1]]["ca_profession"] ==
                  1 &&
                  field.name === "ca_annual_income") ||
                (mapCoApplicant[activeTab.split("_")[1]]["ca_profession"] !=
                  1 &&
                  field.name === "ca_monthly_salary") ||
                (mapCoApplicant[activeTab.split("_")[1]]["ca_profession"] !=
                  1 &&
                  field.name === "ca_gross_monthly_salary") ? null : [
                    "text",
                    "number",
                    "number-format",
                  ].includes(field.type) ? (
                  <fieldset className={"form-filed col-md-6"} key={index}>
                    <FormsyInputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      value={
                        mapCoApplicant[activeTab.split("_")[1]][field.name] ||
                        ""
                      }
                      placeholder=" "
                      thousandsGroupStyle="lakh"
                      thousandSeparator={","}
                      decimalSeparator={"."}
                      maxLength={field.maxLength}
                      label={field.label}
                      format={field.ApplyNumberformat ? field.format : ""}
                      required={field.required}
                      validations={
                        isFormTouch[field.name] ? field.validations : null
                      }
                      validationError={
                        mapCoApplicant[activeTab.split("_")[1]][field.name] ||
                        mapCoApplicant[activeTab.split("_")[1]][field.name] ==
                          ""
                          ? isFormTouch[field.name]
                            ? field.validationError
                            : ""
                          : ""
                      }
                      readOnly={field.readOnly}
                      onChange={
                        ["number-format"].includes(field.type)
                          ? (data) =>
                              handlePatternFormatChange(data, field.name)
                          : handleInputChange
                      }
                    />
                  </fieldset>
                ) : field.type === "dropdown" ? (
                  <fieldset className="single-select col-md-6" key={index}>
                    <FormsySelect
                      name={field.name}
                      id={field.id}
                      inputProps={{
                        options: ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                        placeholder: field.label,
                        className: "react-select",
                        classNamePrefix: "react-select",
                        value:
                          ALL_OPTIONS &&
                          ALL_OPTIONS[field.optionsKey] &&
                          ALL_OPTIONS[field.optionsKey].filter(
                            ({ value }) =>
                              value ==
                              mapCoApplicant[activeTab.split("_")[1]][
                                field.name
                              ]
                          ),
                        isDisabled: field.readOnly,
                      }}
                      required={field.required}
                      value={
                        mapCoApplicant[activeTab.split("_")[1]][field.name]
                      }
                      onChange={(data) => handleSelectChange(data, field.name)}
                    />
                  </fieldset>
                ) : null
              )}
            </div>
          </Formsy>

          {mapCoApplicant[activeTab.split("_")[1]]["ca_profession"] == 3 && (
            <>
              <h4 className="additionalincome">
                Generate Offer by which mode ?
              </h4>
              <div className="propertyflex">
                <input
                  type="radio"
                  id="ca_itr"
                  name="ca_income_calculation_mode"
                  onChange={handleRadioChange}
                  checked={
                    mapCoApplicant[activeTab.split("_")[1]][
                      "ca_income_calculation_mode"
                    ] === "itr"
                      ? true
                      : false
                  }
                />
                <label htmlFor="ca_itr">ITR Details</label>

                <input
                  type="radio"
                  id="ca_bank"
                  name="ca_income_calculation_mode"
                  onChange={handleRadioChange}
                  checked={
                    mapCoApplicant[activeTab.split("_")[1]][
                      "ca_income_calculation_mode"
                    ] === "bank"
                      ? true
                      : false
                  }
                />
                <label htmlFor="ca_bank">Bank Income Details</label>
              </div>

              {mapCoApplicant[activeTab.split("_")[1]][
                "ca_income_calculation_mode"
              ] === "itr" && (
                <>
                  <h4 className="additionalincome">
                    Does the co-applicant file ITR ?
                  </h4>
                  <div className="propertyflex">
                    <input
                      type="radio"
                      id="co_app_yes"
                      name="is_coapplicant_file_itr"
                      onChange={handleRadioChange}
                      checked={
                        mapCoApplicant[activeTab.split("_")[1]][
                          "is_coapplicant_file_itr"
                        ] === "yes"
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="co_app_yes">Yes</label>

                    <input
                      type="radio"
                      id="co_app_no"
                      name="is_coapplicant_file_itr"
                      onChange={handleRadioChange}
                      checked={
                        mapCoApplicant[activeTab.split("_")[1]][
                          "is_coapplicant_file_itr"
                        ] === "no"
                          ? true
                          : false
                      }
                    />
                    <label htmlFor="co_app_no">No</label>
                  </div>

                  {mapCoApplicant[activeTab.split("_")[1]][
                    "is_coapplicant_file_itr"
                  ] === "yes" && (
                    <BreITRSection
                      setITRDetails={handleSetIncomeData}
                      customerType="co_applicant"
                      coApplicantOfferDetails={
                        mapCoApplicant[activeTab.split("_")[1]]?.[
                          "ca_offer_itr_details"
                        ] || []
                      }
                    />
                  )}
                </>
              )}
            </>
          )}

          <h4 className="additionalincome">
            Does the co-applicant have any obligation?
          </h4>
          <div className="propertyflex">
            <input
              type="radio"
              id="yes"
              name="ca_is_obligation"
              onChange={handleRadioChange}
              checked={
                mapCoApplicant[activeTab.split("_")[1]]["ca_is_obligation"] ===
                "yes"
                  ? true
                  : false
              }
            />
            <label htmlFor="yes">Yes</label>

            <input
              type="radio"
              id="no"
              name="ca_is_obligation"
              onChange={handleRadioChange}
              checked={
                mapCoApplicant[activeTab.split("_")[1]]["ca_is_obligation"] ===
                "no"
                  ? true
                  : false
              }
            />
            <label htmlFor="no">No</label>
          </div>

          {mapCoApplicant[activeTab.split("_")[1]]["ca_is_obligation"] ===
            "yes" && (
            <BreObligations
              setObligationDetails={handleSetObligationData}
              customerType={"co_applicant"}
              activeCoApplicant={mapCoApplicant[activeTab.split("_")[1]]}
            />
          )}

          {mapCoApplicant[activeTab.split("_")[1]][
            "ca_income_calculation_mode"
          ] === "bank" && (
            <BreBankIncome
              setBankIncomeDetails={handleSetIncomeData}
              customerType="co_applicant"
              coAppIndex={activeTab.split("_")[1]}
              coApplicantOfferDetails={
                mapCoApplicant[activeTab.split("_")[1]]?.[
                  "ca_offer_bank_details"
                ] || []
              }
              coApplicantNoOfAccounts={
                mapCoApplicant[activeTab.split("_")[1]]?.["no_of_accounts"] || 0
              }
            />
          )}

          {(mapCoApplicant[activeTab.split("_")[1]]["ca_profession"] == 2 ||
            mapCoApplicant[activeTab.split("_")[1]]["ca_profession"] == 3) &&
          mapCoApplicant[activeTab.split("_")[1]][
            "ca_income_calculation_mode"
          ] == "bank" ? null : (
            <>
              <h4 className="additionalincome">
                Does the co-applicant have any additional Income?
              </h4>
              <div className="propertyflex">
                <input
                  type="radio"
                  id="yes"
                  name="ca_is_additional_income"
                  onChange={handleRadioChange}
                  ref={caAddIncRef}
                  checked={
                    mapCoApplicant[activeTab.split("_")[1]][
                      "ca_is_additional_income"
                    ] === "yes"
                      ? true
                      : false
                  }
                />
                <label htmlFor="yes">Yes</label>

                <input
                  type="radio"
                  id="no"
                  name="ca_is_additional_income"
                  onChange={handleRadioChange}
                  ref={caAddIncNoRef}
                  checked={
                    mapCoApplicant[activeTab.split("_")[1]][
                      "ca_is_additional_income"
                    ] === "no"
                      ? true
                      : false
                  }
                />
                <label htmlFor="no">No</label>
              </div>
            </>
          )}
        </>
      )}

      {hasAdditionalIncome && (
        <>
          {additionalIncomeFormFields.map((data, index) => (
            <AdditionalIncome
              index={index}
              ALL_OPTIONS={ALL_OPTIONS}
              setAdditionalIncomeType={handleAdditionalIncomeType}
              removeAdditionalIncome={handleRemoveAdditionalIncome}
              setAdditionalIncomeData={handleSetAdditionalIncomeData}
              additionalIncomeData={
                mapCoApplicant[activeTab.split("_")[1]]?.[
                  "ca_additional_income"
                ]
              }
              key={index}
              isCoApp={true}
            />
          ))}

          {additionalIncomeCount < 5 && (
            <div
              className="additionalincome"
              onClick={() => handleAddAdditionalIncome(additionalIncomeCount)}
            >
              <span>+</span>
              <div>Additional Income</div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BreCoApplicant;
