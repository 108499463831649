import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { MasterService } from "../../../../services";
import WhatsAppLogTable from "./whatsappLog";
import Modal from "../../../elements/partnerModal";
import { toast } from "react-toastify";
import VerifyOtpModal from "./verify-otp-popup";
import { Link } from "react-router-dom";
import { getStatusUtility } from "../utility/getStatusUtility";
import right from "../../../../webroot/images/messageicon.svg"

const PreRegLeadList = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [whatsappLog, setWhatsappLog] = useState([]);
  const [leadData, setLeadData] = useState();
  const [currentLead, setCurrentLead] = useState();
  const [showOtpModal, setShowOtpModal] = useState(false);

   const leads = props.leads
   const callStatus = props.callStatus

  useEffect(() => {
    setLeadData(leads);
  }, [leads]);

  const formatDate = (date) => {
    return (
      <>
        {date ? new Date(date).toLocaleDateString() : "N/A"}{" "}
        {date ? new Date(date).toLocaleTimeString() : ""}
      </>
    );
  };

  const sendWhatsappMsg = async (lead) => {
    try {
      const confirm = window.confirm(
        "Are you sure you want to share whatsapp message to this lead ?"
      );

      if (confirm) {
        const params = {
          partner_id: lead?.id,
          mobile: lead?.dealership_contact,
          notification_id: 39,
        };
        const response = await MasterService.post(
          "partner/partner/resend-whatsapp-message",
          params
        );

        if (response.status === 200) {
          toast.success(
            `Status : ${response?.data?.message} on - ${params?.mobile}`
          );
        }
      }
    } catch (error) {
      console.log("error on sendWhatsapp", error);
    }
  };

  const getWhatsappLogs = async (lead) => {
    try {
      MasterService.get(
        "/partner/partner/view-whatsapp-status?" +
          `partnerId=${lead?.id ?? 10057}&notificationId=${39}`
      )
        .then((response) => {
          if (response.data.length) {
            setWhatsappLog(response.data);
            setShowModal(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setShowModal(false);
        });
    } catch (error) {
      console.log("Error inside getwhatsppalog", error);
    }
  };

  const showWhatsappLogModal = async (lead) => {
    await getWhatsappLogs(lead);
    setCurrentLead(lead);
    setShowModal(true);
    document.body.classList.add("overflow-hidden");
  };

  const hideWhatsappLogModal = () => {
    setShowModal(false);
    document.body.classList.remove("overflow-hidden");
  };

  const hideOtpModal = () => {
    setShowOtpModal(false);
    document.body.classList.remove("overflow-hidden");
  };

  const renderShareAndViewButton = (lead) => {
    return (
      <div style={styles.whatsappContainer}>
        <div
          className="commoniconbtn mrg-r10"
          onClick={() => sendWhatsappMsg(lead)}
          title="Re-Send Whatsapp Notification"
        >
          <i className="ic-share"></i>
        </div>
        <div
          className="commoniconbtn"
          onClick={() => showWhatsappLogModal(lead)}
          title="View Whatsapp Notification Log"
        >
          <i className="ic-remove_red_eyevisibility"></i>
        </div>
      </div>
    );
  };


  // change lead status after action taken
  const updateStatusById = (data, id, newStatus) => {
    return data.map((item) => {
      if (item.id === id) {
        return { ...item, status: newStatus };
      }
      return item;
    });
  };



  const capitalizeWords = (input) => {
    if (!input) return "";
    return input
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  const getStatusName = (statusId) => {
    const statusItem = callStatus?.find((item) => item.status_id === statusId);
    return statusItem ? statusItem.status_name : "";
  };

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr style={{ backgroundColor: "#fff1d6" }}>
            <th>Lead Details</th>
            <th>Call Status/
              <br/>
              Sub-Status
            </th>
            <th>Source/Sub-Source</th>
            <th>Status</th>
            <th>Created On/
              <br/>
              Updated On
            </th>
            <th>Follow-Up Date</th>
            <th>Added By</th>
            <th>WhatsApp</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {leadData && leadData.length
            ? leadData.map((lead) => (
                <tr key={lead.id}>
                  <td>
                    <span>
                      <b>{lead.first_name}</b>
                  
                      <br />
                      {lead.dealership_contact}
                      <br />
                      {lead.dealership_email}
                      <br/>
                      {lead.about_dealership  && lead.about_dealership !== null? (
                        <img 
                          style={{ cursor : "pointer"}} 
                          src={right} 
                          alt="icon" 
                          width={20} 
                          height={20} 
                          title={lead.about_dealership}
                        />
                      ) : null}

                    </span> 
                  </td>
                  <td>
                  {lead.status_name} <br/>
                  {lead.sub_status_name}
                  </td>
                  <td>
                    <span>
                      <b>{lead.source ? capitalizeWords(lead.source) : ""}</b>
                      <br />
                      {lead.subsource ?? ""}
                    </span>
                  </td>
                  <td style={{ color: getStatusUtility(lead.status).color }}>
                    {getStatusUtility(lead.status).status}
                  </td>
                  <td>
                  {lead.lead_added_date
                      ? formatDate(lead.lead_added_date)
                      : ""} <br/>
                      {formatDate(lead.updated_date)}
                  </td>
                  <td>{lead.followup_date ? formatDate(lead.followup_date) : ""}</td>
                  <td>{lead.source === 'Website' ? "Self" : lead.sfa_refferal_user_name}</td>
                  <td>{renderShareAndViewButton(lead)}</td>
                  <td>
                    <div style={{ display: "flex" }}>
                      <Link
                        style={{ color: "gray" }}
                        className="commoniconbtn"
                        to={`/partner-lead-detals/${lead.dealer_id_hash}`}
                        state={{
                          lead: lead,
                          status: getStatusUtility(lead.status).status,
                        }}
                      >
                        <i
                          className="ic-createmode_editedit icons-normal"
                          title="Edit"
                        ></i>
                      </Link>
                    </div>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
        <Modal show={showModal} handleClose={() => hideWhatsappLogModal(false)}>
          <WhatsAppLogTable
            lead={currentLead}
            logs={whatsappLog}
            notificationId={39}
          ></WhatsAppLogTable>
        </Modal>
    </>
  );
};

const styles = {
  whatsappContainer: {
    display: "flex",
    cursor: "pointer",
    fontSize: "16px",
  },
  addPartnerButton: {
    backgroundColor: "#6147FF",
    color: "#fff",
    borderRadius: "10px",
    border: "none",
    padding: "10px",
    cursor: "pointer",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
  },
  disabledButton: {
    borderRadius: "10px",
    border: "none",
    padding: "10px",
    fontSize: "10px",
    fontWeight: "bold",
    textAlign: "center",
    color: "#fff",
    cursor: "not-allowed",
    opacity: 0.5,
  },
};
export default PreRegLeadList;
