import DateFormate from "dateformat";
import React, { Component } from "react";
import { toast } from "react-toastify";
import secureStorage from "../../../config/partnerEncrypt";
import { DealerService, InventoryService } from "../../../services/index";
import MasterService from "../../../services/MasterService";
import ApprovePartner from "./ApprovePartner";
import BasicDetails from "./BasicDetails";
import KycDetails from "./KycDetails";
import "./styles/Account.css";
import LoggedinPopup from "../../elements/LoggedinPopup";
import PartnerWhatsAppChat from "../PartnerWhatsAppChat";
import {  GET_UNREAD_MESSAGE_COUNT} from "../../../services/leads.gql";
import { executeGraphQLQueryFinex } from "../../../common/executeGraphQLQueryFinex";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: {},
      role: null,
      redirect: false,
      errors: {},
      loading: true,
      bussiness_approve: false,
      responseMsg: null,
      urlprefix: props.urlprefix || null,
      dealer_hash_id: "",
      dealer_id: null,
      basic_details: props.basic_details || {},
      owner_details: props.owner_details || {},
      dealership_list: [],
      ac_managerList: [],
      outlet_list: props.outlet_list || [],
      StateList: [],
      cityAllList: [],
      localityList: [],
      outlet_errors: [],
      kycMasterlist: [],
      dealership_list_filtered: {},
      getLanguagelist: [],
      getProfessionlist: [],
      dealerPremiumTypeList: [],
      stockCategoryTypeList: [],
      formSubType: [],
      activeTab: "basic",
      isOpen:false,
      kycDealerData : {},
      unreadMessage  : 0
    
    };
    // this.client = useApolloClient();
    this.getBasicDetails = this.getBasicDetails.bind(this);
    this.getOwnerDetails = this.getOwnerDetails.bind(this);
    this.getDealertypeList = this.getDealertypeList.bind(this);
    this.getOutletList = this.getOutletList.bind(this);
  }

  componentDidMount = async () => {
    const parts = window.location.pathname.split('/');
    const dealerHashId = parts[2];
    this.setState({ dealerHashId });
    const dealer_hash_id = parts[parts.length - 1];
    this.setState({ dealer_hash_id }, () => {
    });
    await this.getLanguageList();
    await this.getCityList();
     this.getBasicDetails();
    await this.getKycMasterDetails();
    
  };
  
  refreshData = () => {
    this.getBasicDetails();
  }

  getCityList() {
    InventoryService.cityList({})
      .then((response) => {
        if (response.data.status === 200 && response.status === 200) {
          this.setState({
            StateList: response.data.data.state,
            cityAllList: response.data.data.city,
          });
        } else {
          toast.error(response.data.message ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  getLanguageList() {
    MasterService.getMasters(["lang", "professions_list"], {
      headers: { Authorization: this.props.auth_token },
    })
      .then((response) => {
        if (response.status === 200 && response.data.status === 200) {
          this.setState({
            getLanguagelist: response.data.data.lang,
            dealerPremiumTypeList: response.data.data.dealer_premium_type,
            getProfessionlist: response.data.data.professions_list,
          });
        } else if (response.status === 401 && response.data.status === 401) {
          this.setState({ redirect: true });
        }
      })
      .catch((error) => {});
  }

  getBasicDetails =   () => {
    if (this.state.dealer_hash_id) {
    let postdata = {
      dealer_id_hash: this.state.dealer_hash_id,
      _with: ["DIF"],
    };

     DealerService.getBasicDetails(postdata)
      .then((response) => {
        if (response.data.status === 200 && response.status === 200) {
          let responsedata = response.data;

          let datasend = {};
          if (responsedata && responsedata.data.length > 0) {
            datasend = responsedata.data[0];
            this.setState(
              {
                basic_details: datasend,
                dealer_id: datasend.id,
                loading: false,
                
              },
             
              () => {
                
                this.getOutletList();
                this.getOwnerDetails();
                this.getUnreadMessage(responsedata.data[0].dealership_contact)
              }
             
            );
          } else {
            this.setState({ redirect: "/" });
          }
        } else {
          // toast.error(response.data.message ? response.data.message : "Error");
          this.setState({ loading: false })
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
    } else {
        this.setState({ loading: false })
    }
  };

  getKycMasterDetails() {
    DealerService.getKycMasterDetails()
      .then((response) => {
        if (response.data.status === 200 && response.status === 200) {
          let responsedata = response.data;
          let kycMasterlist = responsedata.data;

          this.setState({ kycMasterlist: kycMasterlist }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  getOwnerDetails() {
    if (this.state.basic_details?.id > 0) { 
      
      let postdata = { dealer_id: this.state.basic_details.id };
      DealerService.getOwnerDetails(postdata)
        .then((response) => {
          if (response.data.status === 200 && response.status === 200) {
            let responsedata = response.data;
            let datasend = {};
            if (responsedata.data.length > 0) {
              datasend = responsedata.data[0];
            }

            this.setState({ owner_details: datasend }, () => {});
          } else {
            toast.error(
              response.data.message ? response.data.message : "Error"
            );
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    }
  }
  getAccountManagerList() {
    DealerService.getAccountManagerList()
      .then((response) => {
        if (response.data.status === 200 && response.status === 200) {
          let responsedata = response.data;
          let ac_manager_list = responsedata.data;
          this.setState({ ac_managerList: ac_manager_list }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  getDealertypeList() {
    DealerService.getDealertypeList()
      .then((response) => {
        if (response.data.status === 200 && response.status === 200) {
          let responsedata = response.data;
          let dealershiplist = responsedata.data;
          this.setState({ dealership_list: dealershiplist }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }


  getKycMasterDetails() {
    DealerService.getKycMasterDetails()
      .then((response) => {
        if (response.data.status === 200 && response.status === 200) {
          let responsedata = response.data;
          let kycMasterlist = responsedata.data;

          this.setState({ kycMasterlist: kycMasterlist }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  getOutletList() {
    let postdata = {
      dealer_id: this.state.dealer_id,
      _with: [
        "id",
        "dealer_id",
        "name",
        "address",
        "description",
        "type",
        "contact_number",
        "is_primary",
        "city_id",
        "state_id",
        "locality_id",
        "pincode",
        "latitude",
        "longitude",
        "is_lat_long_verified",
        "website_url",
        "opening_timings",
        "lead_preference_cities",
        "can_lead_cities",
      ],
    };
    DealerService.getOutletList(postdata)
      .then((response) => {        if (response.data.status === 200 && response.status === 200) {
          let responsedata = response.data;
          let datasend = responsedata.data;
          this.setState({ outlet_list: datasend }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  validateAndSaveBasicinfo = (event, owner_details, basic_details) => {
    let loginResponse = secureStorage.getItem("loginUserInfo");
    let role =
      loginResponse &&
      loginResponse.data &&
      loginResponse.data.user_data &&
      loginResponse.data.user_data.role;
    this.setState({ loading: true }, () => {});
    var parentObj = this;
    event.preventDefault();
    if (owner_details.date_of_joining) {
      let date_of_joining = new Date(owner_details.date_of_joining);
      let join_date =
        date_of_joining.getFullYear() +
        "-" +
        (date_of_joining.getMonth() + 1) +
        "-" +
        date_of_joining.getDate();
      owner_details.date_of_joining = join_date;
    }

    if (owner_details.birth_date) {
      let birth_date = new Date(owner_details.birth_date);
      let birthdate =
        birth_date.getFullYear() +
        "-" +
        (birth_date.getMonth() + 1) +
        "-" +
        birth_date.getDate();
      owner_details.birth_date = birthdate;
    }
    basic_details.default_lang_id = 1;
    if (!basic_details.parent_dealer_type) {
      basic_details.parent_dealer_type = "1";
    }

    if (role === "dealercentral") {
      basic_details.parent_partner_hash =
        loginResponse &&
        loginResponse.data &&
        loginResponse.data.dealer_data &&
        loginResponse.data.dealer_data[0] &&
        loginResponse.data.dealer_data[0].dealer_id_hash;
    }

    let data2save = Object.assign({}, basic_details);
    data2save.owner = owner_details;
    //data2save.owner.id = '';
    var METHOD = "post";
    if (data2save.dealer_id_hash) {
      METHOD = "put";
    }
    DealerService.saveBasicinfo(data2save, METHOD)
      .then((response) => {
        this.setState({ loading: false }, () => {});

        if (response.data.status === 200 && response.status === 200) {
          let responseData = response.data;
          let dealer_id_hash =
            responseData.data &&
            responseData.data[0] &&
            responseData.data[0].dealer_id_hash;
          let other = {};
          if (METHOD === "post") {
            if (dealer_id_hash) {
              basic_details.dealer_id_hash = dealer_id_hash;
              other = {
                urlprefix: "/dealer/" + basic_details.dealer_id_hash,
                dealer_hash_id: basic_details.dealer_id_hash,
              };
            } else {
              return toast.error("Error In Create");
            }
          }

          this.setState(
            {
              basic_details: basic_details,
              owner_details: owner_details,
              ...other,
            },
            () => {
              toast.success(
                response.data.message ? response.data.message : "Success"
              );

              if (METHOD === "post") {
              }
            }
          );
        } else {
          this.setState(
            { basic_details: basic_details, owner_details: owner_details },
            () => {
              toast.error(
                response.data.message ? response.data.message : "Error"
              );
            }
          );
        }
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({ loading: true }, () => {});
      });
  };


  getUnreadMessage = async (mobileNumber) => {
    const mobile = parseFloat(mobileNumber);
    await executeGraphQLQueryFinex(GET_UNREAD_MESSAGE_COUNT(mobileNumber))
      .then((response) => {
        const messageCount = response?.data?.unread_message_list[0]?.message_count || 0;
        this.setState({ unreadMessage: messageCount });
      })
      .catch((error) => {
        console.error("GraphQL error:", error);
      });
  };

  saveoutletInfo = (event, idx, formdata) => {
    this.setState({ loading: true }, () => {});
    var parentObj = this;
    event.preventDefault();
    let saveData = Object.assign({}, this.state.outlet_list[idx]);
    saveData.name = formdata.name;
    let fulladdress = formdata.address;
    if (formdata.address2) {
      fulladdress = fulladdress + "|" + formdata.address2;
    }
    saveData.address = fulladdress;
    saveData.city_id = formdata.city_id;
    saveData.contact_number = formdata.contact_number;
    saveData.state_id = formdata.state_id;
    saveData.city_id = formdata.city_id;
    if (formdata.locality_id) {
      saveData.locality_id = 0;
    } else {
      saveData.locality_id = 0;
    }

    saveData.pincode = formdata.pincode;
    saveData.website_url = formdata.website_url;
    if (formdata.is_primary) {
      saveData.is_primary = formdata.is_primary;
    } else {
      saveData.is_primary = 0;
    }
    saveData.latitude = formdata.latitude;
    saveData.longitude = formdata.longitude;
    if (formdata.is_lat_long_verified) {
      saveData.is_lat_long_verified = formdata.is_lat_long_verified;
    }

    let save_outlet_url = "dealer/showroom/add";
    if (formdata.id) {
      save_outlet_url = "dealer/showroom/edit";
    }

    if (saveData.id) {
      if (isNaN(saveData.id)) {
        saveData.id = "";
      }
    }

    DealerService.saveoutletInfo(saveData, save_outlet_url)
      .then((response) => {
        if (response.data.status === 200 && response.status === 200) {
          let responseData = response.data;
          this.setState(
            { responseMsg: response.data.message, loading: false },
            () => {
              toast.success(
                response.data.message ? response.data.message : "Success"
              );
              this.getOutletList();
            }
          );
        } else {
          this.setState({ loading: false });
          toast.error(response.data.message ? response.data.message : "Error");
        }
      })
      .catch((error) => {
        this.setState({ loading: false });
        toast.error(error.message);
      });
  };
  handleClickNav = (name, e) => {
    const { dealer_hash_id } = this.state;
    if (!dealer_hash_id || dealer_hash_id === "basic-details") {
      e.preventDefault();
    } else {
      this.getBasicDetails();
    }
  };
  setActiveTab = (tab) => {
    this.setState({ activeTab: tab });
}

redirectToKYC = () => {
  this.setState({ activeTab: 'kyc' })
}

redirectToApproval = () => {
  this.setState({ activeTab: 'approval' }, () => {
      
  });
}
togglePopup = () => {
  this.setState({ isOpen: !this.state.isOpen });
  if (!this.state.isOpen) {
    document.body.classList.add("overflow-hidden");
  } else {
    document.body.classList.remove("overflow-hidden");
  }
};

  render() {
    const { activeTab } = this.state;
    const {dealer_id} = this.state;
    return (
      <div className="container-fluid">
        { this.state.dealer_hash_id != "dealer" ? 
        
        <div  style={{ marginTop: 10 }}>
          <div className="search-wrap">
            <div className="partnerflex">
              <div>
                <div className="partnername">
                  {this.state.basic_details.first_name}{" "}
                  <span>({this.state.basic_details.agent_code})</span>
                </div>
                <div className="commonpartner">
                  {this.state.basic_details.dealership_email}
                </div>
                <div className="commonpartner" style={{display:"flex",alignItems:"center"}}>
                  {this.state.basic_details.dealership_contact}
                  {/* <i className="ic-whatsapp icons-normal whatsappchat" onClick={this.togglePopup}></i> */}
                </div>
              </div>
              <div>
                <div className="commontype">
                  Type :{" "}
                  <span>
                    {this.state.basic_details.applicant_type === "1"
                      ? "Individual"
                      : this.state.basic_details.applicant_type === "2"
                      ? "Company"
                      : ""}
                  </span>
                </div>
              </div>
              <div>
                <div className="commontype">
                  City : <span>{this.state.basic_details.cityname}</span>
                </div>
                <div className="commontype">
                  PinCode : <span>{this.state.basic_details.pincode}</span>
                </div>
              </div>
              <div>
                <div className="commontype">
                  Status:{" "}
                  <span>
                    {this.state.basic_details.is_payout_approve === 1
                      ? "(Verified for payout)"
                      : this.state.basic_details.is_bussiness_approve === 1
                      ? "(Verified for Bussiness)"
                      : "(Pending)"}
                  </span>
                </div>
                <div className="commonpartner">
                  Created on :{" "}
                  <span>
                    {DateFormate(
                      this.state.basic_details.created_date,
                      "dS mmm yyyy h:MM TT"
                    )}
                  </span>
                </div>
              </div>
              <div>
                <div className="commontype">
                  BM Name :{" "}
                  <span>
                    {this.state.basic_details.sfa_refferal_user_name || ""}
                  </span>
                </div>
                <div className="commonpartner">
                  BM Mobile :{" "}
                  <span>
                    {this.state.basic_details.sfa_refferal_user_mobile || ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> : "" }
        
        <div>
            
          <div className="search-wrap">
            <ul className="pagepartner">
              <li>
                <a className={activeTab === "basic" ? "active" : ""}>
                  Basic Information
                </a>
              </li>
              <li>
                <a className={activeTab === "kyc" ? "active" : ""}>
                  KYC Documents
                </a>
              </li>

              <li>
                <a className={activeTab === "approval" ? "active" : ""}>
                  Approval
                </a>
              </li>
            </ul>
            <div className="progress-bar">
              <div className="step" key={1}>
                <div className="stepsflex">
                  <span
                    className={
                      activeTab ? "steppagecount active" : "steppagecount"
                    }
                    onClick={() => this.setActiveTab("basic")}
                  >
                    1
                  </span>
                  <div className="step-line"></div>
                </div>
              </div>
              <div className="step" key={2}>
                <div className="stepsflex">
                  <span
                    className={
                      activeTab ? "steppagecount active" : "steppagecount"
                    }
                    onClick={() => this.setActiveTab("kyc")}
                  >
                    2
                  </span>
                  <div className="step-line"></div>
                </div>
              </div>
              <div className="step" key={3}>
                <div className="stepsflex">
                  <span
                    className={
                      activeTab ? "steppagecount active" : "steppagecount"
                    }
                    onClick={() => this.setActiveTab("approval")}
                  >
                    3
                  </span>
                </div>
              </div>
            </div>
          </div>

          {activeTab === "basic" && (
            
            <BasicDetails
              loading={this.state.loading}
              getLanguagelist={this.state.getLanguagelist}
              getAccountManagerList={this.state.ac_managerList}
              dealerPremiumTypeList={this.state.dealerPremiumTypeList}
              stockCategoryTypeList={this.state.stockCategoryTypeList}
              nextpage={this.state.urlprefix + "/bank-details"}
              basic_details={this.state.basic_details}
              owner_details={this.state.owner_details}
              dealership_list={this.state.dealership_list}
              responseMsg={this.state.responseMsg}
              onChange={this.handleChangeEvent}
              permission={this.state.permission}
              getProfessionlist={this.state.getProfessionlist}
              redirectToKYC={this.redirectToKYC}
              dealerid = {dealer_id}
              unreadMessage = {this.state.unreadMessage}
              
            />
          )}

          {activeTab === "kyc" && (
            <KycDetails
              basic_details={this.state.basic_details}
              outlet_list={this.state.outlet_list}
              dealer_id_hash={this.state.dealer_hash_id}
              StateList={this.state.StateList}
              cityAllList={this.state.cityAllList}
              localityList={this.state.localityList}
              kycMasterlist={this.state.kycMasterlist}
              dealer_id={this.state.dealer_id}
              loading={this.state.loading}
              kycDealerData = {this.state.kycDealerData}
              refreshData = {this.refreshData}
             
            />
          )}

          {activeTab === "approval" && (
            <ApprovePartner
              basic_details={this.state.basic_details}
              dealer_id_hash={this.state.dealer_hash_id}
              StateList={this.state.StateList}
              cityAllList={this.state.cityAllList}
              localityList={this.state.localityList}
              kycMasterlist={this.state.kycMasterlist}
              dealer_id={this.state.dealer_id}
              loading={this.state.loading}
            />
          )}

                <LoggedinPopup isOpen={this.state.isOpen} togglePopup={this.togglePopup}>
                <PartnerWhatsAppChat/>
                </LoggedinPopup>
        </div>
      </div>
    );
  }
}

export default Account;
