import axios from "axios";
import {API_URL} from '../config/constants';

export default {
  async uploadFilesToS3(postdata, headers) {
      headers = headers || {};  
      return axios.post(`${API_URL}core/commonservice/docs_upload`,
        postdata,
        { headers }
      );
    },
    async stateCityList() { 
      return axios.get(`${API_URL}core/commonservice/state_city_all`);
    },
    async stateCityByPincode(pincode) { 
      return axios.post(`${API_URL}core/commonservice/state_city_pincode`,{pincode});
    },
    getThousandsGroupRegex(thousandsGroupStyle) {
      switch (thousandsGroupStyle) {
        case "lakh":
          return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;
  
        case "wan":
          return /(\d)(?=(\d{4})+(?!\d))/g;
  
        case "thousand":
        default:
          return /(\d)(?=(\d{3})+(?!\d))/g;
      }
    },
    phNumber (str) {
        str=str.toString();
        let lastThree = str.substring(str.length-3);
        let otherNumbers = str.substring(0,str.length-3);
        if(otherNumbers != '') lastThree = ',' + lastThree;
        return otherNumbers.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + lastThree;
    },
    convertInputNumberIntoPh (str) {
      let str1 = String(str).toString();
      let formatedStr = str1.replaceAll(",", "");
      if (formatedStr.indexOf('.') > -1) {
          let firstPart = formatedStr.substring(0, formatedStr.indexOf('.'));
          let lastPart = formatedStr.substring(formatedStr.indexOf('.'), formatedStr.length);
          lastPart = lastPart.length > 2 ? lastPart.substring(0, 3) : lastPart;   
          return this.phNumber(firstPart)+lastPart;
      }
      return this.phNumber(formatedStr);
    },
    numberFormatWithDots(str) {
      let phNumberResult = this.phNumber(str);
      return this.convertInputNumberIntoPh(phNumberResult);
    },
    getPowerBiAccess(postdata, headers){
      headers = headers || {};  
      return axios.post(`${API_URL}powerbi/powerbi/getEmbededReport`,
        postdata,
        { headers }
      );
    }
  }