import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  executeGraphQLMutation,
  executeGraphQLQuery,
} from "../../common/partnerExecuteGraphQLQuery";
import secureStorage from "../../config/encrypt";
import { Navigate } from "react-router-dom";
import MultiSelect from "../../view/elements/MultiSelect";
import { FINEX_MASTER_LIST } from "../../services/scheme.gql";
import InputField from "../elements/InputField";
import { MIS_LIST, UPLOAD_MIS_LIST } from "../../services/mis.gql";
import Pagination from "../elements/pagination";
import DateFormate from "dateformat";
// import ModalPopup from "../elements/Popup";
import Select from "react-select";
import { InventoryService } from "../../services/index";
import { toast } from "react-toastify";
import Modal from "../elements/Modal";

const MisList = (props) => {
  const [financers, setFinancers] = useState([]);
  const [misList, setMisList] = useState([]);
  const [pagination, setPagination] = useState({});
  const [bankMappingObj, setBankMappingObj] = useState({});
  const [modalVisiblity, setModalVisiblity] = useState(false);
  const [filterParams, setFilterParams] = useState({});
  const [uploadedFile, setUploadedFile] = useState(null);
  const [bankId, setBankId] = useState(null);

  const afterLoginSetState = (loginResponse) => {
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
      return <Navigate to={"/"} />;
    }
  };

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
      return <Navigate to={"/login"} />;
    }
  };

  const getFinexMasterData = async () => {
    const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(
      (err) => console.log(err)
    );
    if (masterData && masterData.data && masterData.data.masterdata) {
      const bankData = [],
        bankObj = {};
      const data = masterData.data.masterdata;
      const bank_list =
        data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
      if (bank_list && bank_list.length > 0) {
        bank_list.map((banks) => {
          bankData.push({
            id: banks.banklang.bank_id,
            name: banks.banklang.bank_name,
          });
          bankObj[banks.banklang.bank_id] = banks.banklang.bank_name;
        });
        setFinancers(bankData);
        setBankMappingObj(bankObj);
      }
    }
  };

  const getMisList = async (filter_params, page_number = 1) => {
    try {
      const variables = { filter_params };
      variables.page_no = page_number
      const response = await executeGraphQLMutation(
        MIS_LIST(page_number),
        variables
      );

      if (response.data?.mis_list) {
        const listOfMis = response.data?.mis_list?.list || [];
        setMisList(listOfMis);
        setPagination(response.data?.mis_list?.pagination || {});
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
    }
  };

  useEffect(() => {
    updateReduxData();
    getFinexMasterData();
    getMisList(filterParams);
  }, []);

  const resetFilter = () => {
    window.location.reload();
  };

  const handlePagination = (pageNumber) => {
    getMisList(filterParams, pageNumber);
  };

  const handleModalOpen = () => {
    setModalVisiblity(true);
  };

  const handleModalClose = () => {
    setModalVisiblity(false);
  };

  const handleMultiSelectChange = (
    fieldName,
    selectedOptArr,
    isMultiSel,
    OptVal,
    OptLabel
  ) => {
    const selectedBanks = selectedOptArr.map((obj) => obj[OptVal]);
    setFilterParams((current) => ({
      ...current,
      bank_id: selectedBanks,
    }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setFilterParams((current) => ({
      ...current,
      [id]: id == "lead_id" || id == "tranche_id" ? +value : value,
    }));
  };

  const handleFilterApply = () => {
    getMisList(filterParams);
  };

  const handleFileChange = (event) => {
   setUploadedFile(event.target.files[0]);
    console.log(event.target.files[0]);
  };

  const handleSelectFinancer = (event) => {
    const { id } = event;
    console.log("id", id)
    setBankId(id);
  };

  const handleUploadFile = async () => {
    try {
      console.log("uploadedFile",uploadedFile)
      if (uploadedFile && bankId) {
        let formData = new FormData();
        formData.append("bank_id", bankId);
        formData.append("upload_type", "finance_docs");
        formData.append("images", uploadedFile);
        formData.append("lead_id", bankId);
        const response = await InventoryService.uploadFilesToS3(formData, {
          "Content-Type": "multipart/form-data",
        });

        if (response.data.status === 200) {
          const fileUrl = response?.data?.data[0]?.file_url;
          const variables = {
            uploadPaymentArgs: {
              csv_file: fileUrl,
              update_for: "mis",
              bank_id: bankId,
            },
          };
          const resp = await executeGraphQLMutation(UPLOAD_MIS_LIST, variables);
          
          const  csvData = resp.data?.mis_upload
          if (!csvData) return;
          const blob = new Blob([csvData], { type: 'text/csv' });
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = 'updated_mis_data.csv';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
          toast.success("Uploaded Successfully");
          
          setTimeout(() => {
            resetFilter();
          }, 2000);
        } else {
          toast.error("Failed to upload");
        }
      } else {
        toast.error("Please select financer or file");
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
      toast.error("Internal server error");
    }
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="top-heading">MIS List</h2>
        <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Select Bank</label>
              <MultiSelect
                options={financers}
                placeholder={"Banks"}
                name={"financier_id"}
                label="Bank"
                optionLabel="name"
                optionValue="id"
                id="multiSelect"
                values={filterParams["bank_id"] || []}
                onChange={handleMultiSelectChange}
                isMulti
              />
            </div>
            <div className="col sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "created_at_from",
                  type: "date",
                  name: "created_at_from",
                  label: "Created From",
                }}
                onChange={handleInputChange}
                value={filterParams["created_at_from"] || ""}
              />
            </div>
            <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "created_at_to",
                  type: "date",
                  name: "created_at_to",
                  autocompleate: "off",
                  label: "Created To",
                }}
                onChange={handleInputChange}
                value={filterParams["created_at_to"] || ""}
              />
            </div>
            <div className="col-sm-2">
            <InputField
                inputProps={{
                  id: "loan_id",
                  type: "text",
                  name: "loan_id",
                  placeholder:"Loan Id",
                  autocompleate: "off",
                  label: "Enter Loan Id",
                }}
                //onChange={handleInputChange}
                value={''}
              />
              {/* <InputField
                type="text"
                name="loan_id"
                id="loan_id"
                label={"Enter Loan Id"}
                placeholder="Loan Id"
                value={""}
                // onChange={handleFilterByDSA}
              /> */}
            </div>
            <div className="col-sm-2">
            <InputField
                inputProps={{
                  id: "lead_id",
                  type: "text",
                  name: "lead_id",
                  placeholder:"Lead Id",
                  autocompleate: "off",
                  label: "Enter Lead Id",
                }}
                onChange={handleInputChange}
                value={filterParams["lead_id"] || ""}
              />
              {/* <InputField
                type="text"
                name="lead_id"
                id="lead_id"
                label={"Enter Lead Id"}
                placeholder="Lead Id"
                value={filterParams["lead_id"] || ""}
                onChange={handleInputChange}
              /> */}
            </div>
            <div className="col-sm-2 col-md-2">
            <InputField
                inputProps={{
                  id: "tranche_id",
                  type: "text",
                  name: "tranche_id",
                  placeholder:"Tranche Id",
                  autocompleate: "off",
                  label: "Enter Tranche Id",
                }}
                onChange={handleInputChange}
                value={filterParams["tranche_id"] || ""}
              />
              {/* <InputField
                type="text"
                name="tranche_id"
                id="tranche_id"
                label={"Enter Tranche Id"}
                placeholder="Tranche Id"
                value={filterParams["tranche_id"] || ""}
                onChange={handleInputChange}
              /> */}
            </div>

            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={handleFilterApply}
                >
                  {"Search"}
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilter}
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "right",
            marginBottom: "5px",
          }}
        >
          <a style={{marginRight: "10px"}} className="add-schemebtn" href="https://ambak.storage.googleapis.com/partner/undefined/1717054332973.csv" target="_blank" >Sample Mis</a>
          
          <button
             className="add-schemebtn"
            type="button"
            onClick={handleModalOpen}
          >
            Upload MIS
          </button>

        </div>

        <div className="view-timeline-popup">
          <Modal show={modalVisiblity} handleClose={handleModalClose}>
            <>
              <div className="row">
                <div className="col-sm-6 col-md-6">
                  <label>Select Financer</label>
                  <Select
                    id="financer_id"
                    onChange={handleSelectFinancer}
                    options={financers}
                    label={"Financer Id"}
                    name="financer_id"
                    getOptionLabel={({ name }) => name}
                    getOptionValue={({ id }) => id}
                  />
                </div>
                <div className="col-sm-6 form-group">
                  <label>Upload</label>
                  <div className="misinput">
                  <input
                    type="file"
                    name="uploadMis"
                    id="uploadMis"
                    placeholder="Upload MIS"
                    label="Upload CSV"
                    onChange={handleFileChange}
                  />
                  </div>
                  
                </div>
              </div>
              <div className="text-center" style={{marginTop:20}}>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleUploadFile}
                >
                  Save
                </button>
              </div>
            </>
          </Modal>
          </div>    

        <div className="clearfix">
          <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize">
                <thead>
                  <tr>
                    <th>{"Lead Id"}</th>
                    <th>{"Tranche Id"}</th>
                    <th>{"Bank"}</th>
                    <th>{"Loan Type"}</th>
                    <th>{"Disbursed Amount"}</th>
                    <th>{"Monthly Cycle Payin"}</th>
                    <th>{"Payin Base Per"}</th>
                    <th>{"Base Payin Amount"}</th>
                    <th>{"Booster Payin Per"}</th>
                    <th>{"Booster Payin Amount"}</th>
                    <th>{"Total Amount"}</th>
                    <th>{"Booster Payin Confirmed"}</th>
                    <th>{"Confirmed Amount"}</th>
                    <th>{"Comments"}</th>
                    <th>{"Created At"}</th>
                    <th>{"Updation"}</th>
                  </tr>
                </thead>
                <tbody>
                  {misList.map((point, index) => (
                    <>
                      <tr key={index}>
                        <td>{point.lead_id}</td>
                        <td>{point.tranche_id}</td>
                        <td>{bankMappingObj[point.bank_id]}</td>
                        <td>{point.loan_type}</td>
                        <td>{point.disbursed_amount}</td>
                        <td>{point.monthly_cycle_payin}</td>
                        <td>{point.payin_base_per}</td>
                        <td>{point.base_payin_amount}</td>
                        <td>{point.booster_payin_per}</td>
                        <td>{point.booster_payin_amount}</td>
                        <td>{point.total_amount}</td>
                        <td>{point.booster_payin_confirmed}</td>
                        <td>{point.confirmed_amount}</td>
                        <td>{point.comments}</td>
                        <td>
                          {DateFormate(point.created_at, "dS mmm yyyy h:MM TT")}
                        </td>
                        <td>{point.is_calculed ? "Done" : "Pending"}</td>
                      </tr>
                    </>
                  ))}
                  <tr>
                    <td colSpan={14}>
                      {" "}
                      <Pagination
                        pageCount={
                          pagination && pagination.totalpage
                            ? pagination.totalpage
                            : 0
                        }
                        // activePage={filterData.page_no ? filterData.page_no : 1}
                        onPaginate={handlePagination}
                      />
                    </td>
                    <td>
                      Total:{" "}
                      {pagination && pagination.totalrecords
                        ? pagination.totalrecords
                        : 0}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(MisList)
);
