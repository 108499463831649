import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import { Tab, Nav } from 'react-bootstrap';
import UploadedDocumentList from './UploadedDocumentList';
import UploadedTagDocs from './UploadedTagDocs';
import UploadedDocContentList from './UploadedDocContentList';
import Loader from '../../elements/Loader';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../../common/executeGraphQLQuery';
import { getLoanDetail } from '../../../store/action/allAction';
import { gql, useApolloClient } from '@apollo/client';
import { GET_DOC_REQUEST_LIST, UPDATE_DOC_REQUEST } from "../../../services/customer.gql";

const UploadDocumentview = (props) => {
    const dispatch = useDispatch();
    const client = useApolloClient();
    const [activeDocTab, setActiveDocTab] = useState(props.activeDocTab);
    const [activeTab, setActiveTab] = useState(props.activeTab);
    const [filterData, setFilterData] = useState({ doc_id: 0, file_id: 0, parent_id: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const { leadDetail, leadFiles, userInfo } = useSelector(({ lead, user }) => ({
        leadFiles: lead && lead.leadDetail && lead.leadDetail.leaddocs || [],
        userInfo: user.user_information || {},
        leadDetail: lead && lead.leadDetail
    }));
    const [UploadedFiles, setUploadedFiles] = useState([]);
    const user_information = localStorage.getItem('user_information');
    let authUser = user_information && JSON.parse(user_information);

    useEffect(() => {
         setUploadedFiles(leadFiles)
    }, [leadFiles])

    const handleSelectTab = (eventKey) => {
        setActiveDocTab(eventKey);
        setFilterData({ doc_id: 0, file_id: 0, parent_id: 0 });
    }
    const setFilter = (params) => {
        setFilterData(params);
        setActiveDocTab('tagimage');
    }
    const tagDoc = (obj, file_id) => { 
        if (obj.count < obj.max_upload) {
            handleChangeOption(file_id, obj.id, obj.parent_id);
        } else {
            toast.error('Tagged Already Max Image');
        }

    }

    const removeTag = (file_id) => {
        handleChangeOption(file_id, 0, 0);
    }

    const handleChangeOption = (id, doc_id, parent_id) => {
        let UploadedFilesNew = UploadedFiles.map((v) => {
            if (v.id === id) {
                v.doc_id = doc_id;
                v.parent_doc_id = parent_id
            }
            return v;
        });
        setUploadedFiles(UploadedFilesNew);
        setIsLoading(true);

        let uType = 'customer';
        let uId = leadDetail?.customer?.customer_id;
        if(props.customerId && props.customerId !=''){  
            if (props.customerId?.split('-')?.[0] =='co'){ 
                uId = props.customerId?.split('-')?.[1]   
                uType = 'co_applicant'
            }    
        }  

        let variables = {
            updateDocInput: {
                id: id,
                lead_id: leadDetail.id,
                parent_doc_id: parent_id,
                doc_id: doc_id,
                doc_source: 'web',
                tab_name: activeTab || "",
                status: '1',
                user_id: authUser && authUser.id || 0,
                customer_type: uType,
                customer_id: +uId
            }
        }; 
        const mutation = gql`
            mutation update_doc($updateDocInput: UpdateDocInput!) {
                update_doc(updateDocInput: $updateDocInput) {
                id,
                lead_id
                doc_id
                parent_doc_id
                customer_type
                customer_id
                }
            }`;

        executeGraphQLMutation(mutation, variables, client).then(result => {
            if (result && result.data) {
                setFilterData((filterData) => ({ ...filterData, doc_id: 0, file_id: id }))
                toast.success("Success");
                setIsLoading(false);
                dispatch(getLoanDetail(leadDetail.id, client));

                let UploadedFilesNew = UploadedFiles.map((v) => {
                    if (v.id === id) {
                        v.doc_id = doc_id;
                        v.parent_doc_id = parent_id
                    }
                    return v;
                });
                setUploadedFiles(UploadedFilesNew);

            } else {
                setIsLoading(false);
            }
        }).catch(error => {
            setIsLoading(false);
        });

        executeGraphQLQuery(GET_DOC_REQUEST_LIST(leadDetail.id), client)
        .then((response) => {
            const requestDoc = response.data?.get_doc_request_list;

            if (requestDoc.length > 0) {
                const taggingDocObj = requestDoc.filter(reqObj => reqObj.parent_doc_id === parent_id && reqObj.doc_id === doc_id)[0] || {};
                if (Object.keys(taggingDocObj).length > 0) {
                    const docReqId = taggingDocObj.id;
                    const doc_upload_url = filterFiles.filter(file => file.id === id)[0].doc_path;
                    const variables_doc_req = {
                        updateDocRequest: {
                            lead_id: leadDetail.id,
                            doc_id,
                            parent_doc_id: parent_id,
                            doc_upload_url
                        },
                        docReqId,
                    }
                    executeGraphQLMutation(UPDATE_DOC_REQUEST, variables_doc_req, client)
                    .then((result) => {
                        if (result.data.update_doc_request === "SUCCESS") {
                            console.log("Success");
                        } else {
                            console.log(result.data);
                        }
                    })
                    .catch((error) => console.log(error.message));
                }
            }
        })
        .catch((err) => console.log(err));
    }

    const send4TagFile = (file_id) => {
        setFilterData((filterData) => ({ ...filterData, doc_id: 0, file_id: file_id }))
        setActiveDocTab('tagimage');
    }

    let exDocCategoryList = props.exDocCategoryList || []; 
    let document_list = props.docCategoryList || []; 
    let co_app_document_list = props.coAppdocCategoryList || []; 
    let other_doc_category_list = props.otherdocCategoryList || []; 
    let co_app_other_doc_category_list = props.coAppotherdocCategoryList || []; 
    
    let filter_ex_doc_list = exDocCategoryList.filter(data => data.tab_name === 'first_submit');
    let filter_doc_list = document_list.filter(data => data.tab_name === 'first_submit');
    let filter_co_app_doc_list = co_app_document_list.filter(data => data.tab_name === 'first_submit');
    let filter_other_doc_list = other_doc_category_list.filter(data => data.tab_name === 'first_submit');
    let filter_co_app_other_doc_list = co_app_other_doc_category_list.filter(data => data.tab_name === 'first_submit');
    let filter_doc_child_list = [], filter_ex_doc_child_list = [], filter_co_app_doc_child_list = [];
    let filter_other_doc_child_list = [], filter_co_app_other_doc_child_list = [];
    filter_doc_list && filter_doc_list.map(data => {
        filter_doc_child_list = [...filter_doc_child_list, ...data.child]
        if(data.child.length === 0) filter_doc_child_list.push(data);
    })
    
    filter_co_app_doc_list && filter_co_app_doc_list.map(data => {
        filter_co_app_doc_child_list = [...filter_co_app_doc_child_list, ...data.child]
        if(data.child.length === 0) filter_co_app_doc_child_list.push(data);
    })
    
    filter_ex_doc_list && filter_ex_doc_list.map(data => {
        filter_ex_doc_child_list = [...filter_ex_doc_child_list, ...data.child]
        if(data.child.length === 0) filter_ex_doc_child_list.push(data);
    })

    filter_other_doc_list && filter_other_doc_list.map(data => {
        filter_other_doc_child_list = [...filter_other_doc_child_list, ...data.child]
        if(data.child.length === 0) filter_other_doc_child_list.push(data);
    })
    
    filter_co_app_other_doc_list && filter_co_app_other_doc_list.map(data => {
        filter_co_app_other_doc_child_list = [...filter_co_app_other_doc_child_list, ...data.child]
        if(data.child.length === 0) filter_co_app_other_doc_child_list.push(data);
    })

    let filterFiles = UploadedFiles.filter(o1 => filter_doc_child_list.some(o2 => (o1.doc_id === o2.id || o1.doc_id === 0)));
 
    let uType = 'customer';
    let uId = leadDetail?.customer?.customer_id;
    let caType = '';
    if(props.customerId && props.customerId !=''){ 
        let newData = ''; 
        if (props.customerId?.split('-')?.[0] !='co'){
            newData = UploadedFiles.filter(ob=>ob.customer_type == 'customer' || (ob.doc_id == 0 && ob.parent_doc_id ==0)); 
        }else{
            let id = props.customerId?.split('-')?.[1]  
            newData = UploadedFiles.filter(ob=>(ob.customer_type == 'co_applicant' && ob.customer_id ==id) || (ob.doc_id == 0 && ob.parent_doc_id ==0) );

            uId = id;
            uType = 'co_applicant'
            caType = leadDetail.co_applicant.filter(ob=>ob.id ==uId)?.[0]?.['ca_type']; 
        }    
        filterFiles = newData; 
    }   
    
    return (
        <div className="uplaod-view-tabs" key={`${activeTab}${activeDocTab}${filterFiles.length}`}>
            {
                (isLoading) ? <Loader /> : null
            }
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeDocTab} onSelect={handleSelectTab}>
                <Nav variant="pills" className="flex-column tab-line">
                    <div className="tab-list">
                        <Nav.Item className='nav-item-new'>
                            <Nav.Link active={activeDocTab == 'upload'} eventKey="upload">Upload</Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='nav-item-new'>
                            <Nav.Link active={activeDocTab == 'tagimage'} eventKey="tagimage">Tag Image</Nav.Link>
                        </Nav.Item>
                    </div>
                </Nav>
                <Tab.Content>
                    <Tab.Pane active={activeDocTab == 'upload'} eventKey="upload">
                        <UploadedDocumentList filterFiles={filterFiles} filter_doc_child_list={filter_ex_doc_child_list} document_list={exDocCategoryList} key={UploadedFiles.length} getDocumentlist={UploadedFiles} send4TagFile={send4TagFile} activeTab={activeTab} markAsFreezed={props.markAsFreezed} uType={uType} uId={uId}/>
                    </Tab.Pane>
                    <Tab.Pane active={activeDocTab == 'tagimage'} eventKey="tagimage">
                        <UploadedTagDocs filter_doc_list={filter_ex_doc_list} filter_doc_child_list={filter_ex_doc_child_list} UploadedFiles={UploadedFiles} document_list={exDocCategoryList} activeTab={activeTab} key={UploadedFiles.length} filterFiles={filterFiles} filterData={filterData} tagDoc={tagDoc} setFilterData={setFilterData} removeTag={removeTag} user_id={authUser && authUser.id || 0} leadDetail={leadDetail} markAsFreezed={props.markAsFreezed} uType={uType} uId={uId} caType={caType}/>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
            <div className="document-list-panel">
                <UploadedDocContentList filter_doc_list={filter_doc_list} filter_co_app_doc_list={filter_co_app_doc_list} filter_other_doc_list={filter_other_doc_list} filter_co_app_other_doc_list={filter_co_app_other_doc_list} UploadedFiles={UploadedFiles} document_list={document_list} co_app_document_list={co_app_document_list} activeTab={activeTab} key={UploadedFiles.length} setFilter={setFilter} leadDetails={leadDetail} markAsFreezed={props.markAsFreezed} uType={uType} uId={uId} caType={caType} client={client}/>
            </div>
        </div>
    )
}
export default UploadDocumentview;