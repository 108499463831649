import React, { useEffect, useMemo, useRef, useState } from "react";
import Formsy from "formsy-react";
import { useDispatch, useSelector } from "react-redux";
import FormFields from "../formFields/bankOfferFormFields.json";
import FormsyInputField from "../../elements/FormsyInputField";
import FormsySelect from "../../elements/FormsySelect";
import { useApolloClient } from "@apollo/client";
import {
  CHECK_ASSIGN_USER,
  IS_USER_ACCESS_PAGE,
} from "../../../config/constants";
import { Tab, Nav } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import GeneralService from "../../../services/generalService";
import { SAVE_BANK_OFFER } from "../../../services/customer.gql";
import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import { setCityList } from "../../../store/action/allAction";
import { GET_BANK_OFFERS } from "../../../services/bank.gql";
import OfferSection from "./OfferSection";
import FormsyDatePicker from "../../elements/FormsyDatePicker";
import dateFormat from "dateformat";

const BASIC_PROFILE_FIELDS = FormFields["BASIC_PROFILE"];
const PROPERTY_PROFILE_FIELDS = FormFields["PROPERTY_PROFILE"];
const INCOME_PROFILE_FIELDS = FormFields["INCOME_PROFILE"];
const DOB_MAX_DATE = new Date().setFullYear(new Date().getFullYear() - 18);
const DOB_MIN_DATE = new Date().setFullYear(new Date().getFullYear() - 75);

const BankOfferForm = (props) => {
  const [bankOffer, setBankOffer] = useState({});
  const [saveBtn, setSaveBtn] = useState(true);
  const [checkOfferBtn, setCheckOfferBtn] = useState(true);
  const [checkBankOffers, setCheckBankOffers] = useState([]);
  const [tabOptionsDisabled, setTabOptionsDisabled] = useState({
    basic_profile: false,
    property_profile: true,
    income_profile: true,
  });
  const { masterdata, lead } = useSelector(({ masterdata, lead }) => ({
    masterdata,
    lead,
  }));
  const user_information = localStorage.getItem('user_information');
  let authUser = user_information && JSON.parse(user_information);
  let user_id = authUser?.id || 1;
  const [isFormTouch, setTouch] = useState({});
  const [defaultActiveTab, setDefaultActiveTab] = useState("basic_profile");
  const client = useApolloClient();
  const dispatch = useDispatch();
  let accessCondition = !IS_USER_ACCESS_PAGE(
    "loanDetail",
    "customer-basic-details"
  )?.is_edit_access;

  if (!accessCondition !== 1) {
    accessCondition = CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
  }

  useEffect(() => {
    if (lead && lead.leadDetail && lead.leadDetail.customer) {
      let basic_profile = {
        first_name: lead.leadDetail.customer.first_name,
        last_name: lead.leadDetail.customer.last_name,
        phone_number: lead.leadDetail.customer.mobile,
        dob: dateFormat(lead.leadDetail.customer.dob, "dd mmm, yyyy"),
        loan_amount: lead.leadDetail.lead_details?.loan_amount,
        occupation: lead.leadDetail.lead_details?.profession,
        work_experience: lead.leadDetail.lead_details?.work_experience,
        company_name: lead.leadDetail.lead_details?.company_name,
        property_type: lead.leadDetail.lead_details?.property_type,
        usage_type: lead.leadDetail.lead_details?.usage_type,
        agreement_type: lead.leadDetail.lead_details?.agreement_type,
        cra_pincode: lead.leadDetail.lead_details?.property_pincode,
        cra_state: lead.leadDetail.lead_details?.property_state,
        cra_city: lead.leadDetail.lead_details?.property_city,
        address: lead.leadDetail.lead_details?.property_address1,
        bureau_score: lead.leadDetail.lead_details?.cibil_score,
        monthly_salary: lead.leadDetail.lead_details?.monthly_salary,
        company_type: lead?.leadDetail?.lead_details?.company_type,
      };

      setBankOffer(basic_profile);
    }
  }, [lead]);

  useEffect(() => {
    if (
      bankOffer.first_name !== "" &&
      bankOffer.dob !== "" &&
      bankOffer.occupation !== "" &&
      bankOffer.loan_amount !== ""
    ) {
      setTabOptionsDisabled({
        ...tabOptionsDisabled,
        property_profile: false,
        income_profile: false,
      });
      setSaveBtn(false);
    } else {
      setTabOptionsDisabled({
        ...tabOptionsDisabled,
        property_profile: true,
        income_profile: true,
      });
      setSaveBtn(true);
    }
  }, [
    bankOffer.first_name,
    bankOffer.dob,
    bankOffer.occupation,
    bankOffer.loan_amount,
  ]);

  useEffect(() => {
    if (bankOffer.bureau_score !== "" && bankOffer.monthly_salary !== "") {
      setCheckOfferBtn(false);
    } else {
      setCheckOfferBtn(true);
    }
  }, [bankOffer.bureau_score, bankOffer.monthly_salary]);

  let ALL_OPTIONS = useMemo(() => {
    const options = masterdata ? { ...masterdata.data } : {};
    options["company_type"] = options["company_type"]?.map((obj) => ({
      ...obj,
      value: obj.id,
    }));
    return options;
  }, [masterdata]);

  const handleSelectChange = (data, field_name) => {
    if (data && field_name) {
      if (field_name.includes("state")) {
        let options = masterdata ? { ...masterdata.data } : {};
        if (options?.city && options?.city?.length) {
          let record = options.city
            .filter((ob) => ob.state_id == data.value)
            .map((data) => {
              data.value = data.id;
              data.label = data.label;
              return data;
            });
          dispatch(setCityList(record));
        }
      }
      setBankOffer((currentValue) => ({
        ...currentValue,
        [field_name]: data.value,
      }));
    }
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    if (id == "cra_pincode" && value.length == 6) {
      setCityStateByPin(value);
    }
    setBankOffer((currentValue) => ({
      ...currentValue,
      [id]: value,
    }));
  };

  const handleSwitchChange = (event) => {
    const { id, name, checked } = event.target;
  };

  const handleDateChange = (date, field_name) => {
    if (date && field_name) {
      setBankOffer((currentValue) => ({
        ...currentValue,
        [field_name]: date,
      }));
    }
  };

  const setCityStateByPin = async (pincode) => {
    try {
      let response = await GeneralService.stateCityByPincode(pincode);
      if (response?.data?.data?.length) {
        let item = response.data.data[0];
        setBankOffer({
          ...bankOffer,
          cra_pincode: pincode,
          cra_state: item?.state_id,
          cra_city: item?.city_id,
        });
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const handleBasicSave = () => {
    const lead_id = lead?.leadDetail?.id || "";
    const customer = {
      lead_id,
      first_name: bankOffer.first_name,
      last_name: bankOffer.last_name,
      mobile: bankOffer.phone_number,
      dob: dateFormat(bankOffer.dob, "yyyy-mm-dd"),
    };
    const lead_details = {
      lead_id,
      loan_amount: bankOffer.loan_amount,
      profession: bankOffer.occupation,
      company_type: bankOffer.company_type,
      company_name: bankOffer.company_name,
      work_experience: +bankOffer.work_experience,
    };
    let mutation = SAVE_BANK_OFFER(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          customer,
          lead_details,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        setTabOptionsDisabled({
          ...tabOptionsDisabled,
          property_profile: false,
          income_profile: false,
        });
        // setDefaultActiveTab("property_profile");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handlePropertySave = () => {
    const lead_id = lead?.leadDetail?.id || "";
    const lead_details = {
      lead_id,
      agreement_type: bankOffer.agreement_type,
      property_type: bankOffer.property_type,
      usage_type: bankOffer.usage_type,
      property_pincode: bankOffer.cra_pincode,
      property_state: bankOffer.cra_state,
      property_city: bankOffer.cra_city,
      property_address1: bankOffer.address,
    };
    let mutation = SAVE_BANK_OFFER(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
        setTabOptionsDisabled({
          ...tabOptionsDisabled,
          property_profile: false,
          income_profile: false,
        });
        // setDefaultActiveTab("property_profile");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const handleCheckBankOffers = async () => {
    const {
      dob,
      monthly_salary,
      occupation,
      loan_amount,
      bureau_score,
      phone_number,
      monthly_emi,
      gross_annual_salary
    } = bankOffer;

    const lead_id = lead?.leadDetail?.id || "";

    const reqBody = {
      bankOfferInput: {
        loan_type: "1",
        lead_details: {
          loan_amount: loan_amount,
          profession: `${occupation}`,
          cibil_score: +bureau_score,
          ...(occupation == "1" ? {monthly_salary} : {annual_income: gross_annual_salary}),
          ...(monthly_emi && { existing_emi_amount: `${monthly_emi}` }),
        },
        customer: {
          mobile: phone_number,
          dob: dateFormat(dob, "dd/mm/yyyy"),
        },
      },
    };

    executeGraphQLMutation(GET_BANK_OFFERS, reqBody, client)
      .then((resp) => {
        if (resp?.data?.get_bank_offers) {
          const jsonResp = JSON.parse(resp?.data?.get_bank_offers);
          setCheckBankOffers(Object.values(jsonResp.data));
        } else {
          console.log(resp?.data);
          toast.error("Something went wrong");
        }
      })
      .catch((err) => {
        console.log(`🔻 ERROR : ${err.message}`);
        toast.error("Internal Server Error");
      });

    const lead_details = {
      lead_id,
      monthly_salary,
      cibil_score: bureau_score,
    };
    let mutation = SAVE_BANK_OFFER(user_id),
      variables = {
        api_called_by: "web",
        LeadAdditionalInput: {
          lead_id: lead_id,
          lead_details,
        },
      };

    executeGraphQLMutation(mutation, variables, client)
      .then((resp) => {
        let response = resp?.data?.save_customer_basic_details || null;
        if (response && !response.success) {
          throw new Error(response.error || "Something went wrong");
        }
        toast.success("Success");
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  return (
    <div
      className="document-upload-tabs"
      key={lead.leadDetail && lead.leadDetail?.status_id}
    >
      {/* ---- LEFT PANEL ---- */}
      <OfferSection checkBankOffers={checkBankOffers} />

      {/* RIGHT PANEL */}
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={defaultActiveTab}
            onSelect={(tab) => setDefaultActiveTab(tab)}
          >
            <Nav variant="pills" className="flex-column">
              <div className="tab-list">
                <Nav.Item>
                  <Nav.Link
                    disabled={tabOptionsDisabled["basic_profile"]}
                    eventKey="basic_profile"
                  >
                    Basic Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    disabled={tabOptionsDisabled["property_profile"]}
                    eventKey="property_profile"
                  >
                    Property Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    disabled={tabOptionsDisabled["income_profile"]}
                    eventKey="income_profile"
                  >
                    Income Profile
                  </Nav.Link>
                </Nav.Item>
              </div>
            </Nav>

            <Tab.Content>
              {/* BASIC PROFILE */}
              <Tab.Pane key="basic_profile" eventKey="basic_profile">
                <Formsy
                  className="lead-form-filed"
                  autoComplete="off"
                  onValid={() => null}
                  onInvalid={() => null}
                  onValidSubmit={handleBasicSave}
                >
                  <div className="row">
                    {BASIC_PROFILE_FIELDS.map((field, index) =>
                      ["text", "pattern-format", "number"].includes(
                        field.type
                      ) ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "form-filed col-md-12"
                              : "form-filed col-md-6"
                          }
                          key={index}
                        >
                          <FormsyInputField
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            value={(bankOffer && bankOffer[field.name]) || ""}
                            placeholder=" "
                            maxLength={field.maxLength}
                            label={field.label}
                            format={field.ApplyNumberformat ? field.format : ""}
                            validations={
                              isFormTouch[field.name] ? field.validations : null
                            }
                            validationError={
                              bankOffer[field.name] ||
                              bankOffer[field.name] == ""
                                ? isFormTouch[field.name]
                                  ? field.validationError
                                  : ""
                                : ""
                            }
                            readOnly={field.readOnly}
                            required={field.required}
                            onChange={handleInputChange}
                          />
                        </fieldset>
                      ) : field.type === "dropdown" ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "single-select col-md-12"
                              : "single-select col-md-6"
                          }
                          key={index}
                        >
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: [field.optionsKey],
                              options:
                                ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value:
                                ALL_OPTIONS &&
                                ALL_OPTIONS[field.optionsKey] &&
                                ALL_OPTIONS[field.optionsKey].filter(
                                  ({ value }) => value == bankOffer[field.name]
                                ),
                              isDisabled: field.readOnly,
                            }}
                            required={field.required}
                            value={bankOffer && bankOffer[field.name]}
                            onChange={(data) =>
                              handleSelectChange(data, field.name)
                            }
                          />
                        </fieldset>
                      ) : field.type === "date-picker" ? (
                        <fieldset className="form-filed col-md-6 " key={index}>
                          <FormsyDatePicker
                            name="dob"
                            selectedDate={
                              bankOffer &&
                              bankOffer[field.name] &&
                              new Date(bankOffer[field.name])
                            }
                            placeholder={field.label}
                            maxDate={DOB_MAX_DATE}
                            minDate={DOB_MIN_DATE}
                            value={bankOffer && bankOffer[field.name]}
                            onDateSelect={(date) =>
                              handleDateChange(date, field.name)
                            }
                            dateFormat="dd MMM, yyyy"
                            validations={field.validations}
                            validationError={
                              bankOffer[field.name] ? field.validationError : ""
                            }
                            required={field.required}
                            hasError={
                              bankOffer.hasOwnProperty(field.name) &&
                              !bankOffer[field.name]
                                ? true
                                : false
                            }
                          />
                        </fieldset>
                      ) : null
                    )}
                  </div>

                  <div className="btn-save-remarks">
                    <button
                      disabled={saveBtn}
                      type="submit"
                      className="btn-primary"
                    >
                      Save
                    </button>
                  </div>
                </Formsy>
              </Tab.Pane>
              <Tab.Pane key="property_profile" eventKey="property_profile">
                {/* PROPERTY PROFILE */}

                <div className="propertyflex">
                  <label>Property Details : </label>

                  <Form.Check
                    type="radio"
                    id="yes"
                    label="Yes"
                    name="property_identify"
                  />
                  <Form.Check
                    type="radio"
                    id="no"
                    label="No"
                    name="property_identify"
                  />
                </div>
                <Formsy
                  className="lead-form-filed"
                  autoComplete="off"
                  onValid={() => null}
                  onInvalid={() => null}
                  onValidSubmit={handlePropertySave}
                >
                  <div className="row">
                    {PROPERTY_PROFILE_FIELDS.map((field, index) =>
                      ["text", "pattern-format", "number"].includes(
                        field.type
                      ) ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "form-filed col-md-12"
                              : "form-filed col-md-6"
                          }
                          key={index}
                        >
                          <FormsyInputField
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            value={(bankOffer && bankOffer[field.name]) || ""}
                            placeholder=" "
                            maxLength={field.maxLength}
                            label={field.label}
                            onChange={handleInputChange}
                            format={field.ApplyNumberformat ? field.format : ""}
                            validations={
                              isFormTouch[field.name] ? field.validations : null
                            }
                            validationError={
                              bankOffer[field.name] ||
                              bankOffer[field.name] == ""
                                ? isFormTouch[field.name]
                                  ? field.validationError
                                  : ""
                                : ""
                            }
                            readOnly={field.readOnly}
                            required={field.required}
                          />
                        </fieldset>
                      ) : field.type === "dropdown" ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "single-select col-md-12"
                              : "single-select col-md-6"
                          }
                          key={index}
                        >
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: [field.optionsKey],
                              options:
                                ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value:
                                ALL_OPTIONS &&
                                ALL_OPTIONS[field.optionsKey] &&
                                ALL_OPTIONS[field.optionsKey].filter(
                                  ({ value }) => value == bankOffer[field.name]
                                ),
                              isDisabled: field.readOnly,
                            }}
                            required={field.required}
                            value={bankOffer && bankOffer[field.name]}
                            onChange={(data) =>
                              handleSelectChange(data, field.name)
                            }
                          />
                        </fieldset>
                      ) : field.type === "textarea" ? (
                        <fieldset className="col-md-12" key={index}>
                          <div className="material">
                            <textarea
                              id={field.id}
                              name={field.name}
                              type={field.type}
                              label={field.label}
                              value={(bankOffer && bankOffer[field.name]) || ""}
                              placeholder=""
                              className="form-input"
                              onChange={handleInputChange}
                            ></textarea>
                            <label
                              data-label={field.label}
                              className="form-label"
                            ></label>
                          </div>
                        </fieldset>
                      ) : null
                    )}
                  </div>

                  <div className="btn-save-remarks">
                    <button type="submit" className="btn-primary">
                      Save
                    </button>
                  </div>
                </Formsy>
              </Tab.Pane>
              <Tab.Pane key="income_profile" eventKey="income_profile">
                {/* INCOME PROFILE */}
                <Formsy
                  className="lead-form-filed"
                  autoComplete="off"
                  onValid={() => null}
                  onInvalid={() => null}
                  onValidSubmit={handleCheckBankOffers}
                >
                  <div className="row">
                    {INCOME_PROFILE_FIELDS.map((field, index) =>
                      (bankOffer.occupation == "1" &&
                        field.id === "gross_annual_salary") ||
                      (bankOffer.occupation != "1" &&
                        field.id === "monthly_salary") ? null : [
                          "text",
                          "pattern-format",
                          "number",
                        ].includes(field.type) ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "form-filed col-md-12"
                              : "form-filed col-md-6"
                          }
                          key={index}
                        >
                          <FormsyInputField
                            id={field.id}
                            name={field.name}
                            type={field.type}
                            value={(bankOffer && bankOffer[field.name]) || ""}
                            placeholder=" "
                            maxLength={field.maxLength}
                            label={field.label}
                            format={field.ApplyNumberformat ? field.format : ""}
                            validations={
                              isFormTouch[field.name] ? field.validations : null
                            }
                            validationError={
                              bankOffer[field.name] ||
                              bankOffer[field.name] == ""
                                ? isFormTouch[field.name]
                                  ? field.validationError
                                  : ""
                                : ""
                            }
                            readOnly={field.readOnly}
                            required={field.required}
                            onChange={handleInputChange}
                          />
                        </fieldset>
                      ) : field.type === "dropdown" ? (
                        <fieldset
                          className={
                            field.fullWidth
                              ? "single-select col-md-12"
                              : "single-select col-md-6"
                          }
                          key={index}
                        >
                          <FormsySelect
                            name={field.name}
                            id={field.id}
                            inputProps={{
                              options: [field.optionsKey],
                              options:
                                ALL_OPTIONS && ALL_OPTIONS[field.optionsKey],
                              placeholder: field.label,
                              className: "react-select",
                              classNamePrefix: "react-select",
                              value:
                                ALL_OPTIONS &&
                                ALL_OPTIONS[field.optionsKey] &&
                                ALL_OPTIONS[field.optionsKey].filter(
                                  ({ value }) => value == bankOffer[field.name]
                                ),
                              isDisabled: field.readOnly,
                            }}
                            required={field.required}
                            value={bankOffer && bankOffer[field.name]}
                            onChange={(data) =>
                              handleSelectChange(data, field.name)
                            }
                          />
                        </fieldset>
                      ) : // field.type === "switch" ? (
                      //   <Form.Check
                      //     id={field.id}
                      //     name={field.name}
                      //     label={field.label}
                      //     type={field.type}
                      //     className="col-md-12"
                      //     key={index}
                      //     onChange={handleSwitchChange}
                      //   />
                      // ) :
                      null
                    )}
                  </div>

                  <div className="btn-save-remarks">
                    <button
                      type="submit"
                      disabled={checkOfferBtn}
                      className="btn-primary"
                    >
                      Check Bank Offers
                    </button>
                  </div>
                </Formsy>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};
export default BankOfferForm;
