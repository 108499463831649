import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DocumentGallery from './DocumentGallery';
import { useOutletContext } from 'react-router-dom';
import { LEAD_PAYOUT_HISTORY } from './../../services/leads.gql';
import { useApolloClient } from '@apollo/client';
import Loader from '../elements/Loader';
import { executeGraphQLQuery } from './../../common/executeGraphQLQuery';
import right from "../../webroot/images/down-angle.svg";
import LoggedinPopup from '../elements/LoggedinPopup';
import LoggedinTabs from './LoggedinTabs';
import rightchecklist from "../../webroot/images/angle-right.svg";

const PayoutHistory = (props) => {
  const [payoutHistory, setPayoutHistory] = useState([]);
  let [loading, setIsLoading] = useState(false);
  const client = useApolloClient();
  const { lead } = useSelector(lead => lead);
  const [totalPayout, setTotalPayout] = useState(true)
  const [pendingPayout, setPendingPayout] = useState(false)
  const [showAll, setShowAll] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  let updatedStatusList = lead?.leadDetail?.lead_status_history?.map(data => data.status_id) || [];

  const ShowPayout = () => {
    setTotalPayout(!totalPayout)
  }

  const ShowPendingPayout = () => {
    setPendingPayout(!pendingPayout)
  }

  const togglePopup = () => { 
    setIsOpen(!isOpen);
    if(!isOpen){
        document.body.classList.add("overflow-hidden");
    }else{
        document.body.classList.remove("overflow-hidden");
    }
  }


  const getPayoutHistory = async (lead_id, partner_id) => {
    executeGraphQLQuery(LEAD_PAYOUT_HISTORY(lead_id, partner_id), client).then((result) => {
      setPayoutHistory(JSON.parse(result.data.payout_history))
    }).catch((error) => {
      console.error('Query error:', error);
    });
  }

  useEffect(() => {
    let lead_id = lead?.leadDetail?.id || "";
    let partner_id = lead?.leadDetail?.partner_id || "";
    getPayoutHistory(lead_id, partner_id)
  }, [lead]);

  const getContext = useOutletContext();
  const documentCategoryList = getContext.docList || []

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: amount % 1 === 0 ? 0 : 2
    }).format(amount);
  }

  // const handleSubmit = (mark_verify=null) => {
  //   let lead_id = lead?.leadDetail?.id || "";   
  //   setIsLoading(true);
  //   let activetabIndex = activeTab.split('_');
  //   activetabIndex = activetabIndex[1];
  //   let saveDisbursalData = disburseDetails[activetabIndex] ? [disburseDetails[activetabIndex]] : [];
  //   let reqBody = saveDisbursalData.map((trans) => {
  //     return {
  //       id: trans.id,
  //       is_payout_verified: "1",
  //     }
  //   });

  //   let mutation = UPDATE_LEAD_STATUS,
  //     variables = {
  //       api_called_by: 'web',
  //       UpdateLeadStatus: {
  //         save_type: (mark_verify === 'tranch_verify') ? "update" : "save",
  //         update_type: "pdd",
  //         status_id: [4],
  //         sub_status_id: 15,
  //         lead_id: lead_id,
  //         user_id: user_id,
  //         pdd_form: reqBody && reqBody.length && reqBody[0]
  //       }
  //     };

  //   executeGraphQLMutation(mutation, variables, client).then(resp => {
  //     let response = resp?.data?.UpdateLeadStatus || null;
  //     if (response && !response.success) {
  //       throw new Error(response.error || "Something went wrong");
  //     }
  //     toast.success("Success");
  //     dispatch(getLoanDetail(lead_id, client))
  //     if (reqBody?.[0]?.disbursal_remark) dispatch((getLeadRemarks({ lead_id: lead_id, source: 'WEB' }, client)))
  //     setIsLoading(false);
  //   })
  //     .catch(err => {
  //       setIsLoading(false);
  //       toast.error(err.message);
  //     })
  // }

  return (
    <div className="image-form-outer" key={lead.leadDetail && lead.leadDetail?.status_id}>
      {loading ? <Loader /> : null}
      <div className="image-tab-panel">
        <DocumentGallery documentCategoryList={documentCategoryList} doc_id="44" />
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className="payout-form-heading">
            <h2>Payout Eligible</h2>
          </div>
          {([4].filter(status_id => updatedStatusList.includes(status_id)).length >= 1) ?
          <div className='loggedinbox' onClick={togglePopup}>
            <div className='loggedinflex'>
              <div style={{ fontSize: 20, fontWeight: "bold" }}>
                <i class="ic-pending-application"><i class="path1"></i><i class="path2"></i><i class="path3"></i><i class="path4"></i><i class="path5"></i></i>
              </div>
              <div style={{ fontSize: 13, marginLeft: 10 }}>Payout checklist</div>
            </div>
            <img src={rightchecklist} alt='icon' width={10} height={10} />
          </div> :""}
          <div>
            <div className='payout'>
              <div className='payoutcard' >
                <div className="payoutflex" onClick={ShowPayout}>
                  <h2 className='payoutheading'>Total Payout</h2>
                  <img className={totalPayout ? 'rotated' : ''} src={right} alt='icon' width={10} height={10} />
                </div>

                {totalPayout && payoutHistory.map((item, index) => (
                  <div key={index} style={{ marginTop: 10 }}>
                    {item.disbursals.map((disbursal, idx) => (
                      <div className='payoutid' key={idx} style={{ display: showAll || idx === 0 ? 'block' : 'none' }}>
                        <div className='commonpayout'>
                          <div className='commonheading'>Disbursed Amount </div>
                          <div className='commonamount'>{formatCurrency(disbursal.disbursal_amount)}</div>
                        </div>
                        <div className='commonpayout'>
                          <div className='commonheading'>Payout % of disbursal</div>
                          <div className='commonamount'>{disbursal.payouts[0]?.payout_per}%</div>
                        </div>
                        <div className='commonpayout'>
                          <div className='commonheading'>Slab Rate(Additional)</div>
                          <div className='commonamount'>0</div>
                        </div>
                        <div className='commonpayout'>
                          <div className='commonheading'> Payout Amount</div>
                          <div className='commonamount'>+ {formatCurrency(disbursal.payouts[0]?.payout_amount ? disbursal.payouts[0]?.payout_amount : 0)}</div>
                        </div>
                        {/* <div className='commonpayout'>
                          <div className='commonheadingone'> In process(sub slab)</div>
                          <div className='commonamountsub'>- ₹0</div>
                        </div>
                        <div className='commonpayout'>
                          <div className='commonheading'> Subvention</div>
                          <div className='commonamount'>- ₹0</div>
                        </div>
                        <div className='commonpayout'>
                          <div className='commonheading'>Adjustment</div>
                          <div className='commonamount'>- ₹0</div>
                        </div> */}
                        <div className='commonpayout'>
                          <div className='commonheading'>Total Payout</div>
                          <div className='commonamount'>+ {formatCurrency(disbursal?.payout_total)}</div>
                        </div>
                        {/* <div className='commonpayout'>
                          <div className='commonheading'>GST@18%</div>
                          <div className='commonamount'>+ ₹0</div>
                        </div> */}
                        <div className='commonpayout'>
                          <div className='commonheading'>TDS@{disbursal?.payouts[0]?.tds_per || 0}%</div>
                          <div className='commonamount'>- {formatCurrency(disbursal?.payouts[0]?.tds || 0)}</div>
                        </div>
                        <div className='payouttotal'>
                          {/* <div className='netpayout'>Net payout</div>
                          <div className='netpayout'>+ ₹0</div> */}
                        </div>
                        <div className='commonpayout'>
                          <div className='commonheading'>Paid</div>
                          <div className='paidamount'>+ {formatCurrency(disbursal?.payout_paid)}</div>
                        </div>
                        <div className='commonpayout'>
                          <div className='commonheading'>Pending</div>
                          <div className='commonamountsub'>{formatCurrency(disbursal?.payout_pending)}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}

                {(!showAll && payoutHistory?.length > 1) ? (
                  <button className='viewmorebtn' onClick={() => setShowAll(true)}>View More</button>
                ) : ''}
              </div>
              {/* <div className='pendingcard' >
                <div className="payoutflex" onClick={ShowPendingPayout}>
                  <h2 className='payoutheading'>Pending Payout</h2>
                  <img className={pendingPayout ? 'rotated' : ''} src={right} alt='icon' width={10} height={10} />
                </div>
                {pendingPayout && (
                  <div style={{ marginTop: 30, marginBottom: 30 }}>
                    <div className="btn-save-remarks">
                      <button type="button" onClick={() => handleSubmit('tranch_verify')} className="btn-primary">Mark as Payout</button>
                    </div>
                  </div>
                )}
              </div> */}
            </div>
          </div>
          {/* <div className="btn-save-remarks">
            <div style={{ marginTop: 30, marginBottom: 30 }}>
              <button type="submit" className="btn-primary">Mark as Payout</button>
              <button type="button" className="btn-primary">Reject</button>
            </div>
          </div> */}
        </div>
        <LoggedinPopup isOpen={isOpen} togglePopup={togglePopup}> 
        <LoggedinTabs status_id={4} sub_status_id={9} togglePopup={togglePopup}/>
      </LoggedinPopup>
      </div>
    </div>
  );
}
export default PayoutHistory;