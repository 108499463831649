import React, { useState } from 'react';
import Modal from '../elements/Modal'
import Select from 'react-select';
import {  useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import crypto from "../../config/crypto";


const PreferedBankModel = ({ BankModelShow, hideModalPreffredBank }) => {
    const client = useApolloClient();

    const { masterdata, leadDetail, userInfo } = useSelector(({ masterdata, lead, user }) => ({
        masterdata,
        leadDetail: lead.leadDetail,
        userInfo: user.user_information,
    }));
    let navigate = useNavigate();

    const [inputField, setField] = useState({
        bank_id: leadDetail?.lead_details?.bank_id,
    })
    const [errField, setErrField] = useState({
        bank_id: '',
    })
    let userDetail = userInfo && JSON.parse(userInfo)
    let user_id = userDetail && userDetail.id || 0;

    const handleChangeOption = (e, key) => {
        setErrField({ ...errField, [key]: '' })
        setField((prev) => ({
            ...prev,
            [key]: e.value
        }))
    }

    const validateForm = () => {
        let errOb = {}
        if (inputField.bank_id == '') {
            errOb.bank_id = 'Please select bank list'
        }
        setErrField(errOb)
        return Object.keys(errOb).length ? true : false
    }

    const handleSubmit = () => {
        if (!validateForm()) {
            let lead_id = leadDetail?.id || "";
            let reqBody = { bank_id: inputField.bank_id || 0 }
            let mutation = UPDATE_LEAD_STATUS,
                variables = {
                    api_called_by: 'web',
                    UpdateLeadStatus: {
                        lead_id: lead_id,
                        user_id: user_id,
                        update_type: "status",
                        save_type: "update",
                        status_id: [1],
                        login_form: reqBody
                    }
                };

            executeGraphQLMutation(mutation, variables, client).then(resp => {
                let response = resp?.data?.UpdateLeadStatus || null;
                if (response && !response.success) {
                    throw new Error(response.error || "Something went wrong");
                }
                window.location.href = (`/lead-detail/express-login/${crypto.encode(leadDetail?.id)}`);
                // navigate(`/lead-detail/express-login/${crypto.encode(leadDetail?.id)}`);
            })
                .catch(err => {
                    toast.error(err.message);
                })
        }
    }

    const closeModal = () => {
        setField({ bank_id: '' })
        setErrField({ bank_id: '' })
        document.body.classList.remove("overflow-hidden");
        hideModalPreffredBank();
    };

    let bankList = (masterdata?.data?.bank_list) ? masterdata.data.bank_list : []

    return (
        <Modal show={BankModelShow} handleClose={closeModal} removeHeight={true} >
            <div className="modal-header">
                <h2>Choose Preferred Bank</h2>
            </div>
            <div className="modal-body">
                <fieldset className="single-select">
                    <div className="material">
                        <Select
                            options={bankList}
                            placeholder="Bank List"
                            className="react-select"
                            classNamePrefix="react-select"
                            onChange={(e) => handleChangeOption(e, 'bank_id')}
                            value={bankList.filter(ob => inputField.bank_id == ob.value)}
                        />
                        {errField.bank_id ?
                            <div className="error-msg-txt">Please select bank</div> : null
                        }
                    </div>
                </fieldset>
                <button className="btn-primary" onClick={handleSubmit}>Continue</button>
            </div>
        </Modal>
    )
}

export default PreferedBankModel;