import { gql } from "@apollo/client";

export const SAVE_FOLLOWUP = (user_id) => gql`
mutation saveFollowup($CreateFollowupInput: CreateFollowupInput!) {
    save_followup(createFollowupInput:$CreateFollowupInput,api_called_by:web,user_id:${user_id}){
        id
        lead_id
        comment
        followup_date
        followup_type
        followup_with
    }
}
`;

export const UPDATE_FOLLOWUP = (user_id) => gql`
mutation updateFollowup($UpdateFollowupInput: UpdateFollowupInput!) {
  update_followup(updateFollowupInput: $UpdateFollowupInput,api_called_by: web, user_id: ${user_id}) {
    message
  }
}`;


export const MARK_AS_DONE = () => gql`
mutation followupStatusDone($FollowupMarkInput: FollowupMarkInput!) {
  followup_status_done(followupMarkInput: $FollowupMarkInput) {
   message
  }
}`;