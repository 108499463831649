import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import {
  BANK_ONBOARDING_PAYIN_LIST,
  UPDATE_BANK_ONBOARDING_PAYIN,
} from "../../services/mis.gql";
import CheckBox from "../elements/Radiobox";
import { toast } from "react-toastify";
import Select from "react-select";
import InputField from "../elements/InputField";
import secureStorage from "../../config/encrypt";
import { Navigate } from "react-router-dom";
import Pagination from "../elements/pagination";

const BankOnboarding = (props) => {
  const [bankOnboardList, setBankOnboardList] = useState([]);
  const [bankOnboardPoints, setBankOnboardPoints] = useState({});
  const [financerIdList, setFinancerIdList] = useState();
  const [filterParams, setFilterParams] = useState({});

  const getBankOnboardingPayinList = async (filter_params = {}) => {
    try {
      const variables = {
        filter_params,
      };

      const response = await executeGraphQLMutation(
        BANK_ONBOARDING_PAYIN_LIST,
        variables
      );

      if (response.data?.payin_config_list) {
        const payInList = response.data?.payin_config_list?.payinlist,
          mappedObj = {},
          mappedList = [];
        for (let i = 0; i < payInList.length; i++) {
          mappedList.push({
            id: payInList[i].financer_id,
            name: payInList[i].bank_name,
          });
          mappedObj[payInList[i].financer_id] = {
            dsa_code: payInList[i].dsa_code || "",
            is_on_payin: payInList[i].is_on_payin || 0,
            save_btn: true,
          };
        }
        setBankOnboardPoints(mappedObj);
        setFinancerIdList(mappedList);
        setBankOnboardList(payInList);
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
    }
  };

  const afterLoginSetState = (loginResponse) => {
    let role =
      (loginResponse &&
        loginResponse.data &&
        loginResponse.data.user_data &&
        loginResponse.data.user_data.role) ||
      null;
    if (role != "dealercentral") {
        return <Navigate to={"/"} />;
    }
  };

  const updateReduxData = async () => {
    if (secureStorage.getItem("loginUserInfo")) {
      var loginResponse = secureStorage.getItem("loginUserInfo");
      props.updateStateIntoRedux("LOGIN_USER", loginResponse);
      afterLoginSetState(loginResponse);
    } else {
        return <Navigate to={"/login"} />;
    }
  };

  useEffect(() => {
    updateReduxData();
    getBankOnboardingPayinList();
  }, []);

  const handleChange = (event) => {
    const { id, value, type, checked } = event.target;
    const financer_id = +id.split("_")[0];
    let payin = +id.split("_")[1];

    if (type === "text") {
      const dsaChange = {
        ...bankOnboardPoints[financer_id],
        dsa_code: value.toUpperCase(),
        save_btn: false,
      };
      setBankOnboardPoints((current) => ({
        ...current,
        [financer_id]: dsaChange,
      }));
    } else {
      payin = checked ? 1 : 0;
      const payinChange = {
        ...bankOnboardPoints[financer_id],
        is_on_payin: payin,
        save_btn: false,
      };
      setBankOnboardPoints((current) => ({
        ...current,
        [financer_id]: payinChange,
      }));
    }
  };

  const handleUpdateOnboardPoint = async (event) => {
    try {
      const { id } = event.target;
      const variables = {
        addpayinconfigargs: {
          financer_id: +id,
          dsa_code: bankOnboardPoints[id].dsa_code,
          is_on_payin: `${bankOnboardPoints[id].is_on_payin}`,
        },
      };

      const response = await executeGraphQLMutation(
        UPDATE_BANK_ONBOARDING_PAYIN,
        variables
      );

      if (response.data?.save_on_payin) {
        toast.success(response.data?.save_on_payin);
      }
    } catch (error) {
      console.log("Error 🔻 ", error.message);
      toast.error(error.message);
    }
  };

  const handleFinancerFilter = (event) => {
    const { id } = event;

    setFilterParams((current) => ({
      ...current,
      financer_id: id,
    }));
  };

  const handleFilterByDSA = (event) => {
    const { value } = event.target;

    setFilterParams((current) => ({
      ...current,
      dsa_code: value.toUpperCase(),
    }));
  };

  const handleFilterApply = () => {
    const { financer_id, dsa_code } = filterParams;
    const condition =
      financer_id && dsa_code
        ? "both"
        : financer_id
        ? "financer_id"
        : dsa_code
        ? "dsa_code"
        : null;
    let filteredData = [];
    switch (condition) {
      case "both":
        filteredData = bankOnboardList.filter(
          (point) =>
            point.financer_id == filterParams.financer_id &&
            point.dsa_code.replace(/\s/g, "").toUpperCase() ==
              filterParams.dsa_code.replace(/\s/g, "").toUpperCase()
        );
        break;
      case "financer_id":
        filteredData = bankOnboardList.filter(
          (point) => point.financer_id == filterParams.financer_id
        );
        break;
      case "dsa_code":
        filteredData = bankOnboardList.filter(
          (point) =>
            point.dsa_code?.replace(/\s/g, "").toUpperCase() ==
            filterParams.dsa_code.replace(/\s/g, "").toUpperCase()
        );
        break;
    }

    filteredData.length > 0 && setBankOnboardList(filteredData);
    !financer_id && !dsa_code && toast.error("No filter selected");
  };

  const resetFilter = () => {
    window.location.reload();
  };

  return (
    <>
      <div className="container-fluid">
        <h2 className="top-heading">Bank Onboarding</h2>
        <div className="search-wrap">
          <div className="row">
            <div className="col-sm-2 col-md-2">
              <label>Select Financer</label>
              <Select
                id="financer_id"
                onChange={handleFinancerFilter}
                options={financerIdList}
                label={"Financer Id"}
                name="financer_id"
                getOptionLabel={({ name }) => name}
                getOptionValue={({ id }) => id}
              />
            </div>
            <div className="col-sm-2 col-md-2">
              <InputField
                inputProps={{
                  id: "payout_per",
                  type: "text",
                  name: "payout_per",
                  autocompleate: "off",
                  label: "Enter DSA Code",
                  placeholder: "DSA Code",
                }}
                onChange={handleFilterByDSA}
                value={filterParams["dsa_code"] || ""}
              />

              {/* <InputField
                type="text"
                name="dsa_code"
                id="dsa_code"
                label={"Enter DSA Code"}
                placeholder="DSA Code"
                value={filterParams["dsa_code"] || ""}
                onChange={handleFilterByDSA}
              /> */}
            </div>

            <div className="col-sm-2 col-md-2" style={{ marginTop: 18 }}>
              <div className="btn-submit-reset top-btn-none pull-right">
                <button
                  type="submit"
                  className="btn btn-primary mrg-r10"
                  onClick={handleFilterApply}
                >
                  {"Search"}
                </button>
                <button
                  type="reset"
                  className="btn-reset"
                  onClick={resetFilter}
                >
                  {"Reset"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="clearfix">
          <div className="card">
            <div className="table-responsive ">
              <table className="table table-bordered tablefontsize">
                <thead>
                  <tr>
                    <th>{"Financer Id"}</th>
                    <th>{"Bank Name"}</th>
                    <th>{"DSA Code"}</th>
                    <th>{"Is Payin"}</th>
                    <th>{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {bankOnboardList.map((point, index) => (
                    <>
                      <tr key={index}>
                        <td>{point.financer_id}</td>
                        <td>{point.bank_name}</td>
                        <td>
                          <div className="col-md-6">
                            <InputField
                              inputProps={{
                                id: `${point.financer_id}_${point.is_on_payin}`,
                                type: "text",
                                name: "name",
                                autocompleate: "off",
                                placeholder: "",
                                value:
                                  bankOnboardPoints[point.financer_id]
                                    ?.dsa_code || "",
                              }}
                              onChange={handleChange}
                            />
                          </div>
                        </td>
                        <td>
                          {
                            <CheckBox
                              type="checkbox"
                              name="checkall"
                              id={`${point.financer_id}_${point.is_on_payin}`}
                              checked={
                                isNaN(
                                  +bankOnboardPoints[point.financer_id]
                                    ?.is_on_payin
                                )
                                  ? 0
                                  : +bankOnboardPoints[point.financer_id]
                                      ?.is_on_payin
                              }
                              onChange={handleChange}
                            />
                          }
                        </td>
                        <td>
                          <div>
                            <button
                              className="add-schemebtn"
                              onClick={handleUpdateOnboardPoint}
                              disabled={
                                bankOnboardPoints[point.financer_id]?.save_btn
                              }
                              type="button"
                              id={point.financer_id}
                            >
                              Update
                            </button>
                          </div>
                        </td>
                      </tr>
                                      {/* <tr>
                                        <td colSpan={6}> <Pagination pageCount={totalPage} activePage={filterData.page_no ?filterData.page_no:1 } onPaginate={onPaginate} /></td>
                                        <td>Total: {paginationdata && paginationdata.totalrecords ? paginationdata.totalrecords:0} </td>
                                      </tr> */}
                    </>
                  ))}

                                      
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) =>
      dispatch({ type: actionType, componentState: stateData }),
  };
};

export default withTranslation("dealer")(
  connect(mapStateToProps, mapDispatchToProps)(BankOnboarding)
);
