import React, { useEffect, useState, useCallback, forwardRef, useImperativeHandle } from "react";

import RadioBox from '../elements/Radiobox'
import InputField from "../elements/InputField";
import Select from 'react-select';
import { toast } from 'react-toastify';
import MasterService from "../../services/MasterService";
import { Navigate, NavLink, BrowserRouter as Router } from 'react-router-dom';
import { Add_SCHEME, Add_Scheme_Range, GET_SCHEMA_LIST_BYID, Update_SCHEME, GET_SCHEMA_LIST_BY_CODE, FINEX_MASTER_LIST } from "../../services/bankscheme.gql.js";
import { executeGraphQLQuery, executeGraphQLMutation } from "../../common/partnerExecuteGraphQLQuery";
import DateFormate from 'dateformat';
import { useParams } from "react-router-dom";
import { USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE } from '../../config/constants';
import MultiSelect from "../elements/MultiSelect.js";
const BankSchemedetails = (props) => {
    const param = useParams()
    const { handleTabClick } = props;
    const [formData, setFormData] = useState({ "scheme_type": "standard" });
    const [selectstatus, setselectstatus] = useState("");
    const [cityAllList, setcityAllList] = useState([]);
    const [errors, setErrors] = useState("");
    const [fulfillmenttypes, setFulfillmentTypes] = useState([])
    const [financers, setFinancers] = useState([]);
    const [formValues, setFormValues] = useState([
        { "start_range": null, "end_range": null, "payout": null }
    ]);
    const [schemeCities, setSchemeCities] = useState({
        status: 1,
        cities: [],
        scheme_id: props.schemedetailsData && props.schemedetailsData ? props.schemedetailsData.id : 0,
        citiesids: []
    })

    let scheme_view = IS_USER_ACCESS_PAGE("payout", "scheme_view")?.is_edit_access;
    const handleInput = (e, key = "") => {
        const formDataNew = {...formData}
        const name = e.target ? e.target.name : key;
        const val = e.target ? e.target.value : e.id;
        console.log("val", val)
        setFormData({ ...formDataNew, [name]: val })
        let newErrors = { ...errors };
        newErrors[name] = ('');
        setErrors(newErrors);

    }

    // const handleTabs = (tabId) => {
    //     console.log(props.handleTabClick)
    //     props.handleTabClick(tabId)
    // };

    useEffect(() => {
        //  getCityList()
        if (props.schemedetailsData && props.schemedetailsData.scheme_name != "") {
            setFormData(props.schemedetailsData)
            //  getSchemeCitities(props.schemedetailsData.id)
        }
        getFinexMasterData()
    }, [props.schemedetailsData]);

    const getCityList = () => {
        MasterService.get('core/commonservice/state_city_all')
            .then(function (response) {
                if (response.data.status == 200) {
                    setcityAllList(response.data.data.city)
                }
            })
            .catch(function (response) {
            });
    }

    const AddSchemeSave = async (e) => {
        e.preventDefault();
        if (param.id) {
            updateScheme();
        } else {
            addScheme();
        }
    }

    const handelCheckbox = (value) => {
        let newFormData = { ...formData };
        newFormData['scheme_type'] = value;
        setFormData(newFormData);
    }

    const handleCheckboxall = (e, keyname) => {
        const formvalues = { ...formData }
        const checked = e.target.checked
        if (checked) {
            formvalues[keyname] = 1;
        } else {
            formvalues[keyname] = 0;
        }
        setFormData(formvalues)

    }

    const handleCityOptionChange = (name, selectedOptions) => {
        const schemeCitiesdata = { ...schemeCities }
        const cities = []
        const citiesids = []
        selectedOptions && selectedOptions.length > 0 && selectedOptions.map((selectedData) => {
            cities.push({ city_id: selectedData.id })
            citiesids.push(selectedData.id)
        })
        setSchemeCities({ ...schemeCitiesdata, cities: cities, citiesids: citiesids });
    };

    const addScheme = async () => {
        const formDataNew = { ...formData };
        formDataNew.status = selectstatus?.value;
        const data = {};
        const variables = {
            addSchemeArgs: {
                financier_id: formData.financier_id,
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: formData.status,
                is_insurance: formData.is_insurance ? formData.is_insurance : '0'
            },
        };
        data.addSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Add_SCHEME, variables);
                if (result && result.data && result.data.add_bank_scheme && result.data.add_bank_scheme.code) {
                    if (result?.data?.add_bank_scheme?.error) {
                        toast.error(result?.data?.add_bank_scheme?.msg || "Success");
                    } else {
                        toast.success(result?.data?.add_bank_scheme?.msg || "Success");
                        setTimeout(redirectpage("/bankscheme/" + result?.data?.add_bank_scheme?.code + "/details"), 5000);
                    }

                    setFormData({ ...formData });
                }
            } catch (error) {
                toast.error("Failed");
            }
        }
    }

    const updateScheme = async () => {
        const data = {}
        // if (schemeCities.cities.length > 0) {
        //     updateSchemeCities()
        // }
        const variables = {
            updateSchemeArgs: {
                id: formData.id,
                financier_id: formData.financier_id,
                scheme_type: formData.scheme_type || null,
                scheme_name: formData.scheme_name,
                start_date: formData.start_date,
                end_date: formData.end_date,
                scheme_for: formData.scheme_for ? formData.scheme_for : null,
                status: formData.status,
                is_insurance: formData.is_insurance ? formData.is_insurance : '0',
                approved_status: formData.approval_status ? formData.approval_status.toString() : '0'
            },
        };
        data.updateSchemeArgs = variables;
        if (validateForm()) {
            try {
                const result = await executeGraphQLMutation(Update_SCHEME, variables);


                if (result) {
                    if (result?.data?.updateBankScheme?.error) {
                        toast.error(result?.data?.updateBankScheme?.msg);
                    } else {
                        toast.success(result?.data?.updateBankScheme?.msg || "Success");
                    }

                    handleTabClick("details")
                }
            } catch (error) {
                toast.error("Failed");
            }
        }
    }


    const validateForm = () => {
        let validForm = true;
        let newErrors = { ...errors };
        let newFormData = { ...formData };

        if (!newFormData['scheme_name'] || newFormData['scheme_name'] === '') {
            newErrors['scheme_name'] = ('Please enter scheme name');
            validForm = false;
        }
        if (!newFormData['start_date'] || newFormData['start_date'] === '') {
            newErrors['start_date'] = ('Please enter start date');
            validForm = false;
        }
        if (!newFormData['end_date'] || newFormData['end_date'] === '') {
            newErrors['end_date'] = ('Please enter end date');
            validForm = false;
        }
        setErrors(newErrors);
        return validForm;
    };

    const status = [
        { id: 1, name: "Active" },
        { id: 2, name: "Inactive" }
    ]

    const calculation = [
        { id: "count", name: "Count" },
        { id: "amount", name: "Amount" }
    ]


    const handleStatusSelect = (selectedOptions) => {
        setselectstatus(selectedOptions);
    };

    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST()).catch(err => console.log(err));
        if (masterData && masterData.data && masterData.data.masterdata) {
            const fulfillmenttypeData = []
            const bankData = []
            const data = masterData.data.masterdata;
            const fulfillmenttype = data.fulfillment_type && data.fulfillment_type.length > 0 ? data.fulfillment_type : []
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];

            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
            }
            if (fulfillmenttype && fulfillmenttype.length > 0) {
                fulfillmenttype.map(ptype => {
                    fulfillmenttypeData.push({ id: ptype.id, name: ptype.label })
                })
                setFulfillmentTypes(fulfillmenttypeData)
            }
        }
    }


    const handleChange = async (n, v, isMulti, opt, optl) => {
        switch (n) {

            case 'financier_id':
                let financier_ids = [];
                financier_ids = v.map(obj => { return obj.id });
                // setFilterData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
                setFormData({ ...formData, ['financier_id']: financier_ids });
                break;

        }
    }

    const redirectpage = (url) => {
        window.location.href = url
    }

    const handleCheckBoxDefault = (n, e) => {
        const checked = e.target.checked;
        switch (n) {
            case 'is_insurance':
                setFormData({ ...formData, [n]: checked ? '1' : '0' });
                break;
            case 'approved_status':
                setFormData({ ...formData, [n]: checked ? 0 : 2 });
                break;
        }
    };

    return (
        <>
            <div className="card">

                <div className="card-body">
                    <div className="panlinkedflex">
                        <div style={{ marginRight: 100, marginTop: 10, marginBottom: 10 }}>
                            <RadioBox
                                type="radio"
                                name="scheme_type"
                                id="scheme_type"
                                value="standard"
                                label={('Standard')}
                                checked={(formData.scheme_type === "standard" || (!formData.scheme_type)) ? true : false}
                                onChange={() => handelCheckbox("standard")}

                            />
                        </div>

                        <div style={{ marginRight: 20, marginTop: 10, marginBottom: 10 }}>
                            <RadioBox
                                type="radio"
                                name="promotional"
                                id="promotional"
                                value="promotional"
                                label={('Promotional')}
                                checked={(formData.scheme_type === "promotional" || (!formData.scheme_type)) ? true : false}
                                onChange={() => handelCheckbox("promotional")}
                            />
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-sm-3">
                            <label>Financier</label>
                            <Select
                                id="financier_id"
                                name="financier_id"
                                options={financers}
                                value={financers && financers.length > 0 && formData.financier_id && formData.financier_id != "" && financers.filter((items) => items.id == formData.financier_id)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                onChange={(e) => handleInput(e, 'financier_id')}
                                dataerror={errors.financier_id}
                            >
                            </Select>

                        </div>
                        <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "scheme_name",
                                    type: "text",
                                    name: "scheme_name",
                                    label: ('Scheme Name'),
                                    value: formData.scheme_name || '',
                                    placeholder: "Please Enter Scheme Name",
                                    dataerror: errors.scheme_name || '',
                                    validationreq: "true",
                                    label_before_input: "true"

                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>

                        <div className="col-sm-3">

                            <InputField
                                inputProps={{
                                    id: "start_date",
                                    type: "date",
                                    name: "start_date",
                                    label: ('Start Date'),
                                    value: formData.start_date ? DateFormate(new Date(formData.start_date), 'yyyy-mm-dd') : '',
                                    dataerror: errors.start_date || '',
                                    validationreq: "true",
                                    label_before_input: "true"
                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>
                        <div className="col-sm-3">
                            <InputField
                                inputProps={{
                                    id: "end_date",
                                    type: "date",
                                    name: "end_date",
                                    label: ('End Date'),
                                    value: formData.end_date ? DateFormate(new Date(formData.end_date), 'yyyy-mm-dd') : '',
                                    dataerror: errors.end_date || '',
                                    validationreq: "true",
                                    label_before_input: "true"
                                }}
                                onChange={(e) => handleInput(e)}
                            />
                        </div>



                        <div className="col-sm-3">
 
                            <label>Scheme For</label>
                            <Select
                                id="scheme_for"
                                name="scheme_for"
                                options={calculation}
                                value={calculation && calculation.length > 0 && formData.scheme_for && calculation.filter((items) => items.id == formData.scheme_for)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                dataerror={errors.scheme_for}
                                onChange={(e) => handleInput(e, 'scheme_for')}
                            >
                            </Select>
                        </div>

                        <div className="col-sm-3">
                            <label>Status</label>
                            <Select
                                id="status"
                                name="status"
                                options={status}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                                value={status && status.length > 0 && formData.status && status.filter((items) => items.id == formData.status)}
                                onChange={(e) => handleInput(e, 'status')}
                                dataerror={errors.status}
                            >
                            </Select>
                        </div>

                        <div className="col-sm-3">
                            <label>Is Insurance</label><br />
                            <input
                                type="checkbox"
                                name="is_default"
                                label="Is Insurance"
                                id={'is_insurance'}
                                key={'is_insurance'}
                                value={formData.is_insurance}
                                checked={formData.is_insurance == '1' ? true : false}
                                onClick={(e) => handleCheckBoxDefault('is_insurance', e)}
                            />
                        </div>
                        {formData.approved_status == '2' ?
                            <div className="col-sm-3">
                                <label>Send For Approval</label><br />
                                <input
                                    type="checkbox"
                                    name="approved_status"
                                    label="Send For Approval"
                                    id={'approved_status'}
                                    key={'approved_status'}
                                    value={formData.approval_status}
                                    //  checked={formData.is_default==0?true:false}
                                    onClick={(e) => handleCheckBoxDefault('approved_status', e)}
                                />
                            </div> : null}
                    </div>

                    <div>
                        {formData.id && formData.id > 0 ?
                            <div className="text-center">
                                {scheme_view === 1 && (
                                    <button style={{ marginBottom: 10, marginTop: 20 }}
                                        onClick={(e) => updateScheme(e)}
                                        className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Update</b>}</button>
                                )}

                            </div>
                            :
                            <div className="text-center">
                                <button style={{ marginBottom: 10, marginTop: 20 }}
                                    onClick={(e) => AddSchemeSave(e)}
                                    className="btn btn-primary">{param.id > 0 ? <b>Update</b> : <b>Create</b>}</button>
                            </div>
                        }

                    </div>


                </div>
            </div>
        </>
    )
};


export default BankSchemedetails;

