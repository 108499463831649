import React from "react";

const WhatsAppLogTable = ({ lead, logs, notificationId }) => {
  const checkStatusUtility = (status) => {
    switch (status) {
      case "0":
        return "Pending";
      case "1":
        return "Sent";
      case "2":
        return "Delivered";
      case "3":
        return "Read";
      case "4":
        return "Clicked";
      case "9":
        return "Failed";
      default:
        return "N/A";
    }
  };

  const getStatusColorUtility = (status) => {
    switch (status) {
      case "0":
        return "gray";
      case "1":
        return "black";
      case "2":
        return "green";
      case "3":
        return "blue";
      case "4":
        return "green";
      case "9":
        return "red";
      default:
        return "gray";
    }
  };

  const cleanedDataTime = (date) => {
    return (
      <>
        {date ? new Date(date).toLocaleDateString() : "N/A"}{" "}
        {date ? new Date(date).toLocaleTimeString() : ""}
      </>
    );
  };

  const messageTemplate = [
  {
    notificationId : 39,
    message: `🎉 Greetings from Ambak! 🏠
      Hi {{1}}! 😊

      Ambak is a platform specializing in home financing. Partnering with over 30+ lenders like HDFC, ICICI, Axis, and Bank of Baroda, we provide the best home loan options for your customer’s needs.

      Here's what makes us stand out:
      🕠Precise Payouts -On-Time, Every-Time!
      🔐Unmatched Data Security For Your Customers
      💰3x Earnings With Enhanced Efficiency And Cross Selling
      🏁 One Stop Solution For Home Financing

      Excited to learn more about us?
      Click Below to download Ambak Saathi App.

      Warm Regards,
      {{2}}`
    },
    {
      notificationId: 40,
      message: `Hi {{1}} 👋 ... has onboarded you as an Ambak Channel Partner.
      Download the Ambak Saathi App https://ambak.page.link/Riddhi-68 to manage your home loan leads, get free credit reports, and track each payout 🚀
      
      App already downloaded? Login to your account and unlock incredible earning opportunities in no time 💰`
    }]

    const getMessage = (name, notificationId) => {
      const messageItem = messageTemplate.find(item => item.notificationId === notificationId);
      if (messageItem) {
        let message = messageItem.message;
        message = message.replace('{{1}}', name).replace('{{2}}', 'Ambak Team');
        return message;
      } else {
        console.log("Notification ID not found");
        return null;
      }
    };

  return (
    <div className="container mt-5">
      <h2 style={{ textAlign: "center", padding: 20, fontWeight: "bold" }}>
        WhatsApp Log
      </h2>
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="thead-dark">
            <tr>
              <th>Notification</th>
              <th>Request Date</th>
              <th>Sent Date</th>
              <th>Delivered Date</th>
              <th>Read Date</th>
              <th>Clicked Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {logs?.map((log, index) => (
              <tr key={index}>
                <td style={styles.notificationTitle} title={getMessage(lead ? lead.first_name : '', notificationId)}>{'🎉 Greetings..'}</td>
                <td>{cleanedDataTime(log.created_date)}</td>
                <td>{cleanedDataTime(log.sent_datetime)}</td>
                <td>{cleanedDataTime(log.delivered_datetime)}</td>
                <td>{cleanedDataTime(log.read_datetime)}</td>
                <td>{cleanedDataTime(log.clicked_datetime)}</td>
                <td style={{ color: getStatusColorUtility(log.status) }}>
                  {checkStatusUtility(log.status)}
                </td>
              </tr>
            ))}
            {!!logs || !logs ? 
            <tr>
              <td colSpan="7">Log not found!</td>
            </tr>
            : null}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  notificationTitle: {
    cursor: "pointer",
  },
}

export default WhatsAppLogTable;
