import { executeGraphQLMutation } from "../../../common/executeGraphQLQuery";
import { useApolloClient } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import React, { useState } from 'react';
import { toast } from "react-toastify";
import './CustomCardSlider.css';
import { UPDATE_FOLLOWUP } from "../../../services/followup.gql";
import { getLoanDetail } from "../../../store/action/allAction";


const CustomCardSlider = ({ followUpCalls, setScheduleData,setStatusDone }) => {
    const [ currentIndex, setCurrentIndex ] = useState(0);
    const [ markasdone, setMarkAsDone ] = useState({is_show: false});

    const [comment, setComment] = useState('');

    const handleInputChange = (event) => {
      setComment(event.target.value);
    };

    const dispatch = useDispatch();

    const { lead } = useSelector(({ masterdata, lead }) => ({masterdata,lead,leadDetail: lead?.leadDetail?.id}));
    const client = useApolloClient();

    const prevSlide = () => {
        setMarkAsDone(false)
        setStatusDone(true)
        const obj = {
            comments: "",
            followup_date: "",
            followup_with: "",
        };
        setScheduleData(obj);
        setCurrentIndex((prevIndex) => 
            prevIndex === 0 ? followUpCalls.length - 1 : prevIndex - 1
        );
    };

    const nextSlide = () => {
        setMarkAsDone(false)
        setStatusDone(true)
        const obj = {
            comments: "",
            followup_date: "",
            followup_with: "",
        };
        setScheduleData(obj);
        setCurrentIndex((prevIndex) => 
            prevIndex === followUpCalls?.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handleScedule = (index) => {
        const data = followUpCalls[index];
        const obj = {
            id: data.id,
            comments: data?.comment,
            followup_date: data?.followup_date,
            followup_with: data?.followup_with
        };
        setScheduleData(obj);
    }

    const markAsDone = (flag) => {
        setStatusDone(false)
        setMarkAsDone({is_show: flag})
        // let lead_id = lead?.leadDetail?.id || "";
        // const data = followUpCalls[index];
        // let reqBody = {
        //   lead_id: lead_id,
        //   id: data.id,
        //   followup_status: "1"
        // };
        // let mutation = MARK_AS_DONE(),
        //   variables = { FollowupMarkInput: reqBody };
    
        // executeGraphQLMutation(mutation, variables, client)
        //   .then((resp) => {
        //     let response = resp?.data?.MARK_AS_DONE || null;
        //     if (response && !response.success) {
        //       throw new Error(response.error || "Something went wrong");
        //     }
        //     toast.success("Success");
        //   })
        //   .catch((err) => {
        //     toast.error(err.message);
        //   });
    };

    
    const statusDone = (index) => {
        const data = followUpCalls[index];
        let lead_id = lead?.leadDetail?.id || "";
        let user_id = lead?.leadDetail?.customer.customer_id || "";
        let reqBody = {
            id:data.id,
            lead_id: lead_id,
            comment: comment
        };
        let mutation = UPDATE_FOLLOWUP(user_id);
        let variables = { UpdateFollowupInput: reqBody };
        executeGraphQLMutation(mutation, variables, client)
          .then((resp) => {
            let response = resp?.data?.MARK_AS_DONE || null;
            if (response && !response.success) {
              throw new Error(response.error || "Something went wrong");
            }
            toast.success("Success");
            dispatch(getLoanDetail(lead_id, client));
          })
          .catch((err) => {
            toast.error(err.message);
          });
    }
    
    return (
        <div className="slider-container">
            <button className="slider-button left" onClick={prevSlide}>
                &#10094;
            </button>
            <div 
                className="slider-content"
                style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
                {followUpCalls?.map((call, index) => (
                    <div
                        key={index}
                        className="card"
                    >
                        <div className="followupnewcardtwo">
                            <div className="followupschedule">
                                <div><b>Scheduled for:</b> <span>{call.followup_date}</span></div>
                                 {!markAsDone.is_show ? <div className="followupdone" onClick={() => { markAsDone(true)}}><i className="ic-check"></i> {call.status}</div> 
                                 : <div className="followupdone" onClick={() => { markAsDone(false)}}><i className="ic-close"></i> {call.status}</div>}
                            </div>
                            <div><b>followup with</b></div>
                            <div className="followupcustomer">{call.followup_with}</div>
                            <div><b>Comments while scheduling</b></div>
                            <div className="followupcustomer">{call.comment}</div>
                            <div className="followupschedule">
                                <div><b>Status: </b><span className={`followupcoming ${call.upcomingStatus === 'Completed' ? 'completed' : ''}`}>{call.upcomingStatus}</span></div>
                                <div className="followupschedulehistory" onClick={() => { handleScedule(index); }}><i className="ic-history"></i> Reschedule</div>
                            </div>
                            
                        </div>
                        {markasdone.is_show?
                        <div>
                            <div className="col-sm-12" style={{"display": markasdone.is_show ? "block" : "none"}}>
                                <fieldset className="form-filed">
                                    <div className="material">
                                        <textarea
                                        id="comment"
                                        name="comment"
                                        type="text"
                                        value={comment}
                                        placeholder=""
                                        onChange={handleInputChange}
                                        className="form-input"
                                        ></textarea>
                                        <label data-label="Comment" className="form-label"></label>
                                    </div>
                                </fieldset>
                            </div>
                            { markasdone.is_show ? <button className="btn-primary" onClick={() => { statusDone(index); }} > Mark As Done </button> : ""}
                        </div>:""}
                    </div>
                ))}
            </div>
            <button className="slider-button right" onClick={nextSlide}>
                &#10095;
            </button>
        </div>
    );
};

export default CustomCardSlider;
