/* eslint-disable */ 
import React, { useState, useEffect, useMemo } from "react";
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { toast } from "react-toastify";
// import { Redirect } from "react-router-dom";
import { SAVE_EDIT_ROLE } from "../../services/role.gql";
import { useDispatch } from "react-redux";
import _ from 'lodash';
import Loader from "../elements/Loader";
import { Nav, Tab } from "react-bootstrap";
import EditFeatureList from "./EditFeatureList";
import { ADMIN_ROLE_ID } from "../../../src/config/constants";
import UserList from "./userList.json";
import FeatureList from "./featureList.json";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { GET_FEATURE_LIST } from "../../services/role.gql";
import { GET_USER_LIST } from "../../services/role.gql";
import { gql, useApolloClient } from "@apollo/client";

const EditRole = (props) => {
    const client = useApolloClient();
    let [errors, setError] = useState({});
    let [formData, setFormData] = useState({...props.roleDetails});
    let [user_list, setUserList] = useState([]);
    let [feature_list, setFeatureList] = useState([]);
    let [activeTab, setActiveTab] = useState(1);
    let [activeProductTab, setActiveProductTab] = useState('UCF');
    const [loading, setLoading] = useState(false);
    const tabs = [
        {
            id: 1,
            title: 'User Assigned Tab'
        },
        {
            id: 2,
            title: 'Feature Assigned Tab'
        }
    ];

    let loginData = JSON.parse(localStorage.getItem('user_information'));
	 let isSuperAdmin = loginData && loginData?.role_id?.includes(1);
    
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchData = async () => {
            await getUserList();
            await getFeatureList();
        };
        fetchData();
    }, []);
    
    // useEffect(() => {
    //     setFormData(formData => ({
    //         ...formData,
    //         search_feature: ''
    //     }));
    // }, [activeProductTab])

    const handleChange = async (key, event, val) => {
        let target = event.target;
        if (key === 'status') formData[key] = formData.status === "1" ? "0" : "1";
        else if (key === 'user_ids') {
            let isKeyExist = _.findKey(user_list, function (v) {
                return (
                    Number(v.value) === Number(event)
                );
            });
            if(isKeyExist) user_list[isKeyExist].isChecked = val.target.checked;
        }
        else if (key === 'feature_data') {
            let isKeyExist = _.findKey(feature_list, function (v) {
                return (
                    Number(v.value) === Number(event)
                );
            });
            if(isKeyExist) feature_list[isKeyExist].isChecked = val.target.checked;
            

        }
        else if (key === 'additional_feature_radio') {
            let isKeyExist = _.findKey(feature_list, function (v) {
                return (
                    Number(v.value) === Number(event)
                );
            });
            if (isKeyExist) feature_list[Number(isKeyExist)].access_type = Number(feature_list[isKeyExist].access_type) === 1 ? 0 : 1;
        }
        else if (target) formData[key] = target.value;
        errors[key] = '';
        setError({ ...errors });
        setFormData({ ...formData });
    }

    const isFormValid = async (formData) => {
        let isValid = true;
        if (!formData.name || formData.name === '') {
            errors['name'] = 'Required Field';
            isValid = false;
        } else if (formData && formData.name && (formData.name.split('').length < 3 || formData.name.split('').length > 30)) {
            errors['name'] = 'Invalid Role Name';
            isValid = false;
        }
        setError(errors);
        return isValid;

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let postData = await getFormData();
        if (await isFormValid(postData)) {
            setLoading(true);
            const variables = {
				action_type: "edit",
				SaveRoleInput: {
					id: postData.id || 0,
					name: postData.name || '',
					description: postData.description || '',
                    status: postData.status
				},
				user_id: props.user_id,
                user_ids: postData.user_ids,
				feature_data: postData.feature_data
			  };
		  
			  try {
				const result = await executeGraphQLMutation(SAVE_EDIT_ROLE, variables, client);
				if(result){
					toast.success(result?.data?.save_role?.message || "Success");
					setFormData({});
					props.updateList();
				}
			  } catch (error) {
                formData.status = '1';
                setFormData(formData);
				toast.error("Failed");
				console.error(error);
			  }
            setLoading(false);
        }
    }

    const getUserList = async () => {
        setUserList([]);
		let user_list = [];
        await executeGraphQLQuery(GET_USER_LIST(),client).then((response) => {
            if (response && response.data && response.data.get_user_list) {
                user_list = response.data.get_user_list.filter(obj => obj.status == '1');
            }
        }).catch((error) => {
            console.log(error);
        });

        if (user_list && user_list.length) {
            let userList = [], assignedUser = [];
            if (formData && formData.user_assigned_ids) assignedUser = formData.user_assigned_ids.split(',');
            userList = user_list && user_list.length &&
                user_list.map(e => {
                    let isChecked = assignedUser.length ? assignedUser.includes(String(e.user_id)) : false
                    return {
                        value: e.user_id,
                        label: e.name,
                        isChecked: isChecked
                    }
                })
            setUserList([...userList]);
        }
    };

    const getFeatureList = async () => {
        let response_data = [];
        await executeGraphQLQuery(GET_FEATURE_LIST(),client).then((response) => {
            console.log ("responseresporesponseresporesponseresporesponseresporesponserespo" , response)
            if (response && response.data && response.data.get_feature_list) {
                response_data = response.data.get_feature_list;
                console.log("response_dataresponse_dataresponse_dataresponse_dataresponse_data" , response_data);
            }
         })
         .catch((error) => {
            console.log(error);
         });

        if (response_data.length) {
            let featureList = [];
            featureList = response_data.length &&
            response_data.map(e => {
                     
                    let isFeaturesExist = formData.feature_access_ids && formData.feature_access_ids.length ? _.find(formData.feature_access_ids,{'id':String(e.id)}) : false;
                    let isFeaturePredefined = formData.id !== ADMIN_ROLE_ID ? (formData.id === e.features_role_id ? true :false) : ""; //variable for checkbox enabled or disabled in feature list
                    let isEditDisabled = formData.id !== ADMIN_ROLE_ID ? (e.features_role_id === 0 ? false : true) : false; //variable for edit button enabled or disabled in feature list
                    return {
                        value: e.id,
                        label: e.name,
                        product_type: e.product_type,
                        feature_type: e.feature_type || '',
                        access_type:isFeaturesExist ? +isFeaturesExist.access_type : 0,
                        isChecked: isFeaturesExist ? true : false,
                        isCheckboxEnabled : isFeaturePredefined && isFeaturesExist ? isFeaturePredefined : "",
                        isEnabled : isFeaturePredefined ? isFeaturePredefined : "",
                        isEditDisabled : e.is_change_allowed
                        === 1 ? true : false
                    }
                })
            setFeatureList([...featureList]);
            setActiveProductTab('UCF');
        }
    };

    const handleTabChange = async (event) => {
        let actTab = event.currentTarget.dataset.key;
        setActiveTab(actTab);
    };

    const getFormData = async () => {
        let postData = {};
        if (formData && formData.id) postData['id'] = formData.id;
        if (formData && formData.name) postData['name'] = formData.name;
        if (formData && formData.description) postData['description'] = formData.description;
        if (formData && formData.status) postData['status'] = formData.status;
        postData['user_ids'] = user_list.length ? user_list.filter(f => f.isChecked).map(e => e.value) : [];
        postData['feature_data'] = feature_list.length ? feature_list.filter(f => f.isChecked).map(e => {
            return { id: e.value, access_type: String(e.access_type) }
        }) : []
        return postData;
    }
    let filteredUserList = useMemo(() => {
        if (formData && formData.search_user) {
            return user_list.filter(e => e.label.toLowerCase().includes(formData.search_user.toLowerCase()))
        }
        return user_list;
    }, [user_list, formData]);

    let filteredFeatureList = useMemo(() => {
        if (formData && formData.search_feature) {
            return feature_list.filter(e => e.label.toLowerCase().includes(formData.search_feature.toLowerCase()))
        }
        return feature_list;
    }, [feature_list, formData])

    return (
        <div className="edit-role-outer">
            <div className="edit-role-main mg80">
                <h2>Edit Role</h2>
                <div className="role-name-status">
                    <fieldset className="role-status">
                        <div className="material">

                            <input id="name" type="text" placeholder=" " name="name" className="form-input" onChange={handleChange.bind(this, 'name')} value={formData.name || ''} />
                            <label data-label="Role Name" className="form-label"></label>
                            <span className="text-danger">
                                {errors.name || ""}
                            </span>
                        </div>
                        <div className="toggleApp">
                            <div onClick={handleChange.bind(this, 'status')} className="ToggleSwitch">
                                <div className={formData && formData.status && Number(formData.status) === 1 ? 'knob active' : 'knob'} />
                                <div className="toggle-status">
                                    {formData && formData.status && Number(formData.status) === 1 ? 'Active' : 'Inactive'}
                                </div>
                            </div>
                        </div>

                    </fieldset>
                    <fieldset className="">
                        <div className="material">
                            <input id="" type="text" placeholder=" " name="RoleName" className="form-input" value={formData.description || ''} onChange={handleChange.bind(this, 'description')} />
                            <label data-label="Description of role 1" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>

                <div className="tabs rolemanagtab">
                    <div className="tab-main-outer">
                        <div className="tab-buttons">
                        
                            {tabs.map((e, index) => (
                                <button key={index} className={(Number(activeTab) === Number(e.id)
                                    ? "active"
                                    : "")} data-key={e.id} onClick={handleTabChange} >
                                    {e.title}
                                </button>
                            ))}

                        </div>
                        <div className="tab-content">
                            {Number(activeTab) === 1 ?
                                <div className="tab-data">
                                    <div className="search-bx">
                                        <div className="material">
                                            <input type="" id="" placeholder="Search User"
                                                onChange={handleChange.bind(this, "search_user")}
                                            />
                                            <i className="ic-search"></i>
                                        </div>
                                    </div>
                                    <div className="user-list">
                                        <ul>
                                            {filteredUserList.length > 0 ?
                                                filteredUserList.map((data, index) =>
                                                    <React.Fragment key={data.value}>
                                                        <li className="custom-control custom-checkbox" key={index}>
                                                            <input
                                                                id={'user_' + data.value}
                                                                type="checkbox"
                                                                className="custom-control-input"
                                                                onChange={handleChange.bind(this, "user_ids", data.value)}
                                                                checked={data.isChecked}
                                                            />
                                                            <label htmlFor={'user_' + data.value} className="custom-control-label">
                                                                {data.label}
                                                            </label>
                                                        </li>

                                                    </React.Fragment>
                                                )
                                                : null}

                                        </ul>
                                    </div>

                                </div>
                                :
                                <div className="tab-data">
                                    <Tab.Container
                                        id="left-tabs-example"
                                        activeKey={activeProductTab} 
                                        onSelect={(tab) => setActiveProductTab(tab)}
                                    >
                                        <Nav variant="pills" className="flex-column nav nav-pills">
                                            <div className="tab-list">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="UCF">Sangam</Nav.Link>
                                                </Nav.Item>
                                            </div>
                                            <div className="tab-list">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="Partner">Partner</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="menu">Menu</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="payout">Payout</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="PartnerHierachy">Partner-Hierachy</Nav.Link>
                                                </Nav.Item>
                                            </div>
                                        </Nav>
                                        <Nav >
                                           
                                        </Nav>
                                        <div className="search-bx">
                                            <div className="material">
                                                <input type="" id="" placeholder="Search Feature"
                                                    onChange={handleChange.bind(this, "search_feature")} 
                                                    value={formData ? formData.search_feature : ''}
                                                    />
                                                <i className="ic-search"></i>
                                            </div>
                                        </div>
                                        <EditFeatureList 
                                            feature_list={filteredFeatureList}
                                            activeProductTab={activeProductTab}
                                            handleChange={handleChange}
                                            loading={loading}
                                        />
                                    </Tab.Container>
                                </div>


                            }
                        </div>
                    </div>

                </div>
                {/* { formData?.id!==1 || isSuperAdmin ?
                <span>
                    <button className="btn-primary m-sm-r" onClick={handleSubmit}>Save Changes</button>
                </span>
                :""} */}

                <span>
                    <button className="btn-primary m-sm-r" onClick={handleSubmit}>Save Changes</button>
                </span>

                <a className="close-icn" onClick={props.closeModal}>
                    <i className="ic-clearclose"></i>
                </a>

            </div>
            {loading ? <Loader /> : null}
        </div>
    );

}
export default (EditRole);
