import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { Nav, Tab } from 'react-bootstrap';

import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE } from '../../../src/config/constants';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { DOCUMENT_LIST, SEND_DOC_TO_FINANCEIR, UPDATE_LEAD_STATUS } from '../../services/leads.gql';
import { getLoanDetail } from '../../store/action/allAction';
import Loader from '../elements/Loader';
import MultiSelect from '../elements/MultiSelect';


const SendDoc = (props) => {
  const { docList, markAsFreezed } = useOutletContext();
  const [allowSubmit, setAllowSubmit] = useState(false);
  const [loading, setLoading] = useState(false);
  let { lead_id } = useParams();
  let navigate = useNavigate();
  const leadDetail = useSelector(state => state.lead.leadDetail);
  let availabledoc = [];
  leadDetail?.leaddocs?.length > 0 && leadDetail.leaddocs.forEach((leaddoc) => {
    availabledoc.push(leaddoc.doc_id)
  });

  docList && docList.forEach((doc, key) => {
    docList[key]['child_ids'] = doc.child.map((dc) => { return dc.id })
    docList[key]['child_ids_exists'] = availabledoc.some((v) => { return docList[key]['child_ids']?.indexOf(v) !== -1 })
  });

  const user_information = useSelector((state) => state.user.user_information);
  const bank_list = useSelector((state) => state?.masterdata?.data?.bank_list);
  const UserInfo = JSON.parse(user_information)
  const requeredDoc = [10, 11, 14, 15]

  const [displayBlock, setDisplayBlock] = useState('DocumentList');
  const [FinancerEmail, SetFinancerEmail] = useState(false);
  const [templateList, SetTemplateList] = useState([]);
  const [BankerEmails, setBankerEmails] = useState([]);
  const [templateObject, setTemplateObject] = useState({});
  const [selectedfinanciers, setSelectedfinanciers] = useState([{ id: 1, bank_id: 1, document_category_ids: [], send_mode_arr: ['EMAIL'] }]);
  const [currentFinancer, setCurrentFinancer] = useState();
  let accessCondition = !IS_USER_ACCESS_PAGE(props.template_for, props.template_for)?.is_edit_access;
  let isApplicationCreated = leadDetail?.lead_status_history?.find(history => history.status_id === 1);
  if (!accessCondition) {
    accessCondition = CHECK_ASSIGN_USER(leadDetail?.assign_to);
  }

  const showModalFinancerEmail = (bank_id) => {
    setCurrentFinancer(bank_id)
    SetFinancerEmail(true);
    document.body.classList.add("overflow-hidden");
  }

  const client = useApolloClient();
  const dispatch = useDispatch();

  const selectdocument = (e, bank_id) => {
    let selectedFinancerTemp = selectedfinanciers;
    let document_category_ids = selectedFinancerTemp[0]?.document_category_ids;
    console.log(selectedFinancerTemp)
    if (e.target.getAttribute('parent_id')) {
      if (e.target.checked) {
        if (document_category_ids) {
          const index = document_category_ids.indexOf(e.target.value);
          if (index === -1) {
            document_category_ids.push(Number(e.target.value));
          }
        } else {
          document_category_ids = [Number(e.target.value)];
        }
      } else {
        const index = document_category_ids.indexOf(Number(e.target.value));
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }

      let parent_doc_id = Number(e.target.getAttribute('parent_id'));
      let rowdata = docList.filter((data) => data.id === parent_doc_id);
      let allFounded = rowdata[0].child.every(ai => document_category_ids.includes(ai.id))
      if (allFounded) {
        document_category_ids.push(parent_doc_id);
      } else {
        const index = document_category_ids.indexOf(parent_doc_id);
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }
      let uniqueselectedTagImageIdsData = [...new Set(document_category_ids)];
      selectedFinancerTemp[0].document_category_ids = uniqueselectedTagImageIdsData;
      setSelectedfinanciers([...selectedfinanciers]);
    }
    else {
      if (e.target.checked) {
        let rowdata = docList.filter((data) => data.id === Number(e.target.value));
        //document_category_ids.push(Number(e.target.value));

        if (document_category_ids) {
          const index = document_category_ids.indexOf(e.target.value);
          if (index === -1) {
            document_category_ids.push(Number(e.target.value));
          }
        } else {
          document_category_ids = [Number(e.target.value)];
        }

        rowdata[0].child.map((ch, i) => {
          return document_category_ids.push(ch.id);
        })
      } else {
        let rowdata = docList.filter((data) => data.id === Number(e.target.value));
        rowdata[0].child.map((ch, i) => {
          const index = document_category_ids.indexOf(ch.id);
          if (index > -1) {
            return document_category_ids.splice(index, 1);
          }
          return true;
        });
        const index = document_category_ids.indexOf(Number(e.target.value));
        if (index > -1) {
          document_category_ids.splice(index, 1);
        }
      }
      let uniqueselectedTagImageIdsData = [...new Set(document_category_ids)];
      selectedFinancerTemp[0].document_category_ids = uniqueselectedTagImageIdsData;
      setSelectedfinanciers([...selectedfinanciers]);
    }
  }

  const replaceAll = (str, mapObj) => {
    var re = new RegExp(Object.keys(mapObj).join("|"), "gi");

    return str.replace(re, function (matched) {
      return mapObj[matched];
    });
  }

  useEffect(() => {
    dispatch(getLoanDetail(leadDetail?.id, client));
  }, [leadDetail?.id])

  useEffect(() => {
    executeGraphQLQuery(DOCUMENT_LIST(leadDetail?.lead_details?.bank_id || 0, 'express_login'), client).then((result) => {
      let templates = result.data.financierListbyID;
      let send_mode_checked = {}, send_mode_disabled = {}, templateInfo = {};
      let loan_sub_type = leadDetail?.loan_sub_type?.split(',') || [];
      let loandetailsfield = {
        "bank_name": leadDetail?.lead_details?.bank?.banklang?.bank_name || "",
        "loan_type": (leadDetail?.loan_type === 1) ? 'Home Loan' : (leadDetail?.loan_type === 2) ? "LAP" : "",
        "loan_sub_type": (loan_sub_type.includes('1') && loan_sub_type.includes('2')) ? "BT, TOP UP" : (loan_sub_type.includes('1')) ? 'BT' : (loan_sub_type.includes('2')) ? "TOP UP" : "NONE",
        "dsa_code": leadDetail?.lead_details?.bank?.dsa_code || "",
        "customer_name": leadDetail?.customer?.first_name + ' ' + leadDetail?.customer?.last_name || "",
        "customer_mobile_number": leadDetail?.customer?.mobile || "",        
        "customer_email_id": leadDetail?.customer?.email || "",
        "property_value": leadDetail?.lead_details?.property_value || "",
        "required_loan_amount": leadDetail?.lead_details?.loan_amount || "",
        "is_co_applicant": leadDetail?.co_applicant?.length ? "Yes" : "No",
        "co_applicant_name": leadDetail?.co_applicant?.length && (leadDetail?.co_applicant[0]?.ca_first_name + ' ' + leadDetail?.co_applicant[0]?.ca_last_name) || "",
        "co_applicant_phone_number": leadDetail?.co_applicant?.length && leadDetail?.co_applicant[0]?.ca_mobile || "",
        "co_applicant_email": leadDetail?.co_applicant?.length && leadDetail?.co_applicant[0]?.ca_email || "",
        "applicant_income_consider": leadDetail?.co_applicant?.length && leadDetail?.co_applicant[0]?.ca_type || "",
        "co_applicant_income": leadDetail?.co_applicant?.length && leadDetail?.co_applicant[0]?.ca_annual_income || "",
      };
      
      setTemplateObject(loandetailsfield);

      let mapObjeConstant = {}
      for (const property in loandetailsfield) {
        if (loandetailsfield[property] === undefined || loandetailsfield[property] === null) {
          mapObjeConstant[`{{${property}}}`] = "";
        } else {
          mapObjeConstant[`{{${property}}}`] = loandetailsfield[property];
        }
      }

      let tempdata = [];
      templateInfo['bank_id'] = 1;
      for (const element of templates.filter((temp) => temp.bank_id === 1)) {
        if (element.template_type === 'email') {
          templateInfo['email_enable'] = true;
          let pasttemplate = leadDetail?.lead_template_history?.filter((th) => th.template_type === 'email' && th.bank_id === 1 && th.page_type === props.template_for);

          if (pasttemplate && pasttemplate.length > 0)
            element.template_body = pasttemplate?.[0]?.send_html_template;
          else
            element.template_body = replaceAll(element.template_body, mapObjeConstant);
          templateInfo['email'] = element;


          send_mode_checked.email = false;
          send_mode_disabled.email = false;

          setDisplayBlock("DocumentList");
        }
        tempdata.push(templateInfo);
      }
      SetTemplateList(tempdata);
    })
      .catch((errors) => {
        toast.error("Somethig went wrong ,please try again")
      });
  }, [leadDetail])


  const verifyuploadeddocument = () => {
    let checkuploaddoc = true;
    checkuploaddoc = requeredDoc.every(v => availabledoc.includes(v));
    return checkuploaddoc;
  }
  
  const hasNoEmptyKeys = (obj) => {
    if(obj.is_co_applicant === 'No') {
      delete obj.co_applicant_name
      delete obj.co_applicant_email
      delete obj.co_applicant_phone_number
      delete obj.applicant_income_consider
      delete obj.co_applicant_income
    }
    for (let key in obj) {
      if (obj[key] === "" || obj[key] === null || obj[key] === undefined) {
          return true;
      }
  }
  return false;
  }

  const getDocname = (doc_id) =>{
    let docname = '';
    docList?.map(data=>{
      if(!docname) { 
        let docnameArray = data?.child?.filter(ch => ch.id == doc_id)[0];        
        docname = docnameArray?.doclang?.name || null
      }
    })
    return docname;
  }

  const senddocumenttofinanceir = (bank_id = 1) => {
    if (hasNoEmptyKeys(templateObject)) {
      toast.warning("Email template missing data. Please update first.");
      return;
    }
    
    if (!verifyuploadeddocument()) {
      toast.warning("Please upload all required documents.");
      return;
    }   

    let links = '<strong>Lead Documents </strong><br>';
    let docdata = { lead_id: leadDetail.id, user_email: [], page_type: props.template_for, user_id: UserInfo?.id, "template_type": [], "template_id": [], "bank_id": [], "send_html_template": [], "doc_ids": [], remarks: "test", status: "1", created_by: UserInfo?.id, updated_by: UserInfo?.id };

    let sf = selectedfinanciers.filter((sf) => sf.bank_id === 1)?.[0];
    let financertemplate = templateList?.filter((tempdata) => tempdata.bank_id === sf.bank_id);


    const filteredArray = sf?.document_category_ids?.filter(value => availabledoc.includes(value));
    let checkDoc = requeredDoc.every(v => filteredArray.includes(v));
    if (!checkDoc) {
      toast.warning("Please select all required documents.");
      return;
    }
    leadDetail?.leaddocs.forEach((v) => {
      if (filteredArray.includes(v.doc_id))
        links += getDocname(v.doc_id)+': <br>'+ v.doc_path + "<br>";
    });

    if (sf?.send_mode_arr?.length > 0) {
      setLoading(true);
      if (sf.send_mode_arr.includes("EMAIL")) {
        docdata.bank_id.push(sf.bank_id);
        docdata.template_type.push("email");
        docdata.send_html_template = `${financertemplate?.[0]?.['email'].template_body.replaceAll("\n", "<br>") + '<br><br>' + links}`;
        docdata.template_id.push(`${financertemplate?.[0]?.['email'].id}`);
        docdata.user_email = BankerEmails?.bankers_email //.push(financertemplate?.[0]?.['email'].bank_group_email);
        docdata.doc_ids.push(filteredArray.join());
        docdata.customer_name = leadDetail?.customer?.first_name + ' ' + leadDetail?.customer?.last_name;
        docdata.bank_name = leadDetail?.lead_details?.bank?.banklang?.bank_name || ""

      }
      docdata.send_html_template = JSON.stringify(docdata.send_html_template);
    }

    let variables = {
      sendTemplateNotificationinput: docdata
    };

    executeGraphQLMutation(SEND_DOC_TO_FINANCEIR, variables, client).then((result) => {
      toast.success("Success");
      dispatch(getLoanDetail(leadDetail.id, client));
      let redirecturl = ''
      if (props.template_for === 'express_login') {
        redirecturl = `/lead-detail/approval/${lead_id}`;
      }
      setLoading(false);
      if (redirecturl !== '' && props.template_for !== "express_login")
        navigate(redirecturl)
    }).catch((err) => {
      setLoading(false);
      toast.error(err.toString().replace('ApolloError:', '')
      );
    });

  }

  const handleMultiSelectChange = async (n, v, isMulti, opt) => {
    setBankerEmails((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
  }

  let financertemplates = templateList?.filter((tempdata) => tempdata.bank_id === 1);
  let financertemplate = financertemplates?.[0]?.['email']?.template_body;
  let BankerEmail = financertemplates?.[0]?.['email']?.bankers_email?.split(',')?.map(email => ({ id: email, value: email, label: email }));

  return (
    <div className="image-form-outer">
      <div className="image-tab-panel emailer-outer">
        <div className="email-templete-sec p-lg-t">
          <div>
            <div className="heading-icn">
              <h3>Email Template for Express Login</h3>
            </div>
            <div dangerouslySetInnerHTML={{ __html: (financertemplate) }} />
          </div>
        </div>
      </div>
      <div className="form-tab-right-panel">
        <div className="lead-detail-from-outer">
          <div className={" switch-toggle"}>
            <div className="lead-form-heading send-financer-heading sent-financer-outer">
              <h2>Express Login</h2>
            </div>
            <div className="lead-form-filed send-financer m-sm-t financer-sending-mode">
              <div>
                {/* <h3>Doc Attached</h3> */}
                <div className="financer-doc-list ">
                  <Tab.Container
                    id="customer_coborrower-tabs-example"
                    defaultActiveKey="borrower"
                  >
                    <Nav variant="pills" className="flex-column tab-line m-lg-b doc-attach-tab">
                      <div className="tab-list">
                        {props.pagetab.map((pg, key) => (
                          <Nav.Item key={key}>
                            <Nav.Link eventKey={pg.type}>
                              {pg.key}
                            </Nav.Link>
                          </Nav.Item>
                        ))}

                      </div>
                    </Nav>
                    <Tab.Content>
                      {props.pagetab.map((pg, key) => (
                        <Tab.Pane eventKey={pg.type} key={key}>
                          <Accordion allowMultipleExpanded="true" allowZeroExpanded>
                            {docList &&
                              docList.length > 0 && availabledoc.length > 0 &&
                              docList.map((element) => pg.doc.split(',').includes(String(element.id)) ?
                                (
                                  <AccordionItem key={element.id}>
                                    <AccordionItemHeading>
                                      <AccordionItemButton>
                                        <div className=" custom-control custom-checkbox">
                                          <input
                                            aria-label="doc lang name"
                                            id="pending"
                                            type="checkbox"
                                            value={element.id}
                                            className="custom-control-input" onChange={(e) => selectdocument(e, 1)}
                                            checked={selectedfinanciers.filter((fnfilter) => fnfilter.bank_id === 1)?.[0]?.document_category_ids?.includes(Number(element.id)) ? true : false}

                                          />
                                          <label className="custom-control-label">
                                            {element.doclang.name} {[1, 2, 3].includes(element.id) ? <span style={{ "color": "red" }}>*</span> : ''}
                                          </label>
                                        </div>
                                      </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                      <div className="sub-list">
                                        <ul className="">
                                          {element.child && element.child.length > 0 &&
                                            element.child.map((subdoc) =>
                                              subdoc.parent_id === element.id && availabledoc.includes(subdoc.id) ? (
                                                <li key={subdoc.id}>
                                                  <div className=" custom-control custom-checkbox">
                                                    <input
                                                      aria-label="document change"
                                                      id="pending"
                                                      type="checkbox"
                                                      className="custom-control-input"
                                                      value={subdoc.id}
                                                      parent_id={element.id}
                                                      onChange={(e) => selectdocument(e, 1)}
                                                      checked={selectedfinanciers.filter((fnfilter) => fnfilter.bank_id === 1)?.[0]?.document_category_ids?.includes(Number(subdoc.id)) ? true : false}

                                                    />
                                                    <label className="custom-control-label">
                                                      {subdoc.doclang.name}{requeredDoc.includes(subdoc.id) ? <span style={{ "color": "red" }}>*</span> : ''}
                                                    </label>
                                                  </div>
                                                </li>
                                              ) : null
                                            )}
                                        </ul>
                                      </div>
                                    </AccordionItemPanel>
                                  </AccordionItem>

                                ) : null)}
                          </Accordion>
                          {!availabledoc.length ? <center style={{ "color": "red" }}> Please upload required documents first!!</center> : null}
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
                <div className='row'>
                  <h3>Select Bankers Email<span style={{ "color": "red" }}>*</span></h3>
                  <fieldset className="single-select col-md-12">
                    <MultiSelect
                      options={BankerEmail}
                      placeholder="Select Bankers Eamil"
                      name="bankers_email"
                      id="bankers_email"
                      optionLabel="label"
                      optionValue="id"
                      values={BankerEmails?.bankers_email}
                      onChange={handleMultiSelectChange}
                    />
                  </fieldset>
                </div>
                {loading ? <Loader /> : ''}
                <div className="btn-save-remarks m-md-t">
                  <button type="button" disabled={!BankerEmails?.bankers_email?.length || ![1,6].includes(leadDetail.status_id) || leadDetail?.fulfillment_type !== 'ambak'} onClick={(e) => { senddocumenttofinanceir(1) }} className="btn-primary">Send</button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SendDoc;