/* eslint-disable */ 
import React, { useState, useEffect } from "react";
import { executeGraphQLMutation } from '../../common/executeGraphQLQuery';
import { toast } from "react-toastify";
// import { Redirect } from "react-router-dom";
import { SAVE_EDIT_ROLE } from "../../services/role.gql";
import { useDispatch } from "react-redux";
import Loader from "../elements/Loader";
import { useApolloClient } from '@apollo/client';

const AddRole = (props) => {
	const client = useApolloClient();
    let [errors, setError] = useState({});
    let [formData, setFormData] = useState({});
    let [redirect, setRedirect] = useState('');
	const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

	const handleChange = async (key, event) => {
		let  value = '';
		let target = event.target;
		if (key === 'status') value = formData.status === "1" ? "0" : "1";
		else if (event instanceof Array) value = event.map((e) => e.value);
		else if (target) value = target.value;

		formData[key] = value;
		errors[key] = '';
		setError({ ...errors });
        setFormData({ ...formData });
	}

	const isFormValid = () => {
		let isValid = true;
		if (!formData.name || formData.name === '') {
			errors['name'] = 'Field Required';
			isValid = false;
		}else if(formData && formData.name && (formData.name.split('').length < 3 || formData.name.split('').length > 30)){
			errors['name'] = 'Invalid Role Name';
			isValid = false;
		}
		if(formData && formData.description && (formData.description.split('').length < 3 || formData.description.split('').length > 200)){
			errors['description'] = 'Invalid Description Name';
			isValid = false;
		}
		setError({ ...errors });
		return isValid;

	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (isFormValid()) {
			setLoading(true);
			  const variables = {
				action_type: "save",
				SaveRoleInput: {
					id: 0,
					name: formData.name || '',
					description: formData.description || '',
					status: '1'
				},
				user_id: props.user_id,
				user_ids: [],
				feature_data:[]
			  };
		  
			  try {
				const result = await executeGraphQLMutation(SAVE_EDIT_ROLE, variables, client);
				if(result){
					toast.success(result?.data?.save_role?.message || "Success");
					setFormData({ formData: {} });
					props.updateList();
				}
			  } catch (error) {
				toast.error("Failed");
				console.error(error);
			  }
			setLoading(false);
		}
	}

		// if (redirect) {
		// 	return <Redirect to={redirect} />;
		// }

		return (
			<div className="popup-outer">
				<div className="add-role-popup" style={{padding:'1px'}}>
					{/* <h2>Add Role</h2> */}
					<div className="add-role-form">
						<fieldset className="form-filed">
							<div className="material">
								<input id="name" type="text" placeholder=" " name="name" className="form-input" onChange={handleChange.bind(this, 'name')} value={formData.name || ''} />
								<label data-label="Role Name" className="form-label"></label>
								<span className="error-msg">
									{errors.name || ""}
								</span>
							</div>
						</fieldset>
						<fieldset className="">
							<textarea placeholder="Description" value={formData.description || ''} onChange={handleChange.bind(this, 'description')}></textarea>
							<span className="error-msg">
								{errors.description || ""}
							</span>
						</fieldset>
						<button className="btn-primary m-md-t" onClick={handleSubmit}>Add Role</button>
					</div>
				</div>
				{loading ? <Loader /> : null}
			</div>
		);
	
}

export default AddRole;
