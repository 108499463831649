import React from "react";

const CibilReportHistory = (props) => {
  const LOAN_TYPE = [
    { id: 'home_loan', value: 'Home Loan' },
    { id: 'auto_loan', value: 'Auto Loan' },
    { id: 'gold_loan', value: 'Gold Loan' },
    { id: 'two_Wheeler_loan', value: 'Two Wheeler Loan' },
    { id: 'personal_loan', value: 'Personal Loan' },
    { id: 'business_loan', value: 'Business Loan' },
    { id: 'consumer_loan', value: 'Consumer Loan' },
    { id: 'credit_card_account', value: 'Credit Card Loan' }
  ]

    const total_paid = +props.cibilRecord?.payment_history?.total_on_time_instalments_paid_till_date;
    const total_late_paid = +props.cibilRecord?.payment_history?.total_late_instalments_paid_till_date;
    const total_installment = parseInt(total_paid) + parseInt(total_late_paid);
    const paid_per = total_paid / total_installment * 100;
    const unpaid_per = 100 - parseInt(paid_per);
    const outerRadius = 140;
    const outerCircumference = 2 * Math.PI * outerRadius;
    const outerStrokeDashoffset = outerCircumference * ((100 - paid_per) / 100);
    const innerRadius = 100;
    const innerCircumference = 2 * Math.PI * innerRadius;
    const innerStrokeDashoffset = innerCircumference * ((100 - unpaid_per) / 100);
    const templateData = {
        outerStrokeDashoffset: outerStrokeDashoffset.toFixed(2),
        innerStrokeDashoffset: innerStrokeDashoffset.toFixed(2),
        paid_per: paid_per,
        unpaid_per: unpaid_per,
    };
    
    const dynamicStyle = { 
      strokeDashoffset : innerStrokeDashoffset
    };
    const dynamicStyle2 = {
      strokeDashoffset :  outerStrokeDashoffset
    };

  return (

    <React.Fragment>
      <h2 className="cibilscoreheading">Payment History </h2>
      <div className="paymentcard"> 
          <div className="paymenthistorycircle">
            <div className="circle-container">
              <svg className="circle" width="300" height="300" viewBox="0 0 300 300"> 
                <circle className="background" cx="150" cy="150" r="140" />
                <circle className="foreground" cx="150" cy="150" r="140" style={dynamicStyle}  />

                <circle className="backgroundtwo" cx="150" cy="150" r="100" />
                <circle className="foregroundtwo" cx="150" cy="150" r="100" style={dynamicStyle2} />

                <circle className="inner-circle" cx="150" cy="150" r="60" />
              </svg>
              <div className="payment-text">
                <div className="paymentpercente">{parseInt(unpaid_per)}%</div>
                <div className="paymentlatepercente">{parseInt( paid_per)}%</div>
              </div>
            </div>
          </div>

          <div className="paymentstatus">

            <div className="statuscolor"> Good</div>
            <h3 className="mainheaingpayout">{props.cibilRecord?.payment_history?.total_on_time_instalments_paid_till_date}</h3>

            <div className="homeloanmonth">
              <span className="paymenttype"></span>
              <div>Paid on Time</div>

            </div>
            <h3 className="mainheaingpayout">{props.cibilRecord?.payment_history?.total_late_instalments_paid_till_date}</h3>
            <div className="homeloanmonth">
              <span className="latepaymenttype"></span>
              <div>Late Payment</div>
            </div> 
        </div>
      </div>
      {
        LOAN_TYPE.map(item => {
          if(props.cibilRecord?.payment_history_details?.[item.id]?.ontime_payment?.last_6_month ==0 && props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month ==0){
            return <></>
          }
          return (
            <React.Fragment>
              <div>
                <h3 className="cibilscoreheading">{item.value} </h3>
                <div>
                  <div>Last 6 Month</div>

                  <div className="homeloanmonthdetails">
                    <div className="homeloanmonth">
                      <span className="paymenttype"></span>
                      <div>On Time Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.ontime_payment?.last_6_month}</span>
                    </div>
                    <div className="homeloanmonth">
                      <span className="latepaymenttype"></span>
                      <div>Late Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month}</span>
                    </div>
                  </div>

                  <div className="latepaymentsummarycard">
                    <div>
                      <h3 className="cibilscoreheading">Late Payments Summary</h3>
                    </div>

                    <div className="paymentinfolist">
                      <div>0-29 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['0_29_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['0_29_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>30-59 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['30_59_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['30_59_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>60-89 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['60_89_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['60_89_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>90-179 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['90_179_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['90_179_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>180+ days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['above_180_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_6_month?.['above_180_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_6_month)*100).toFixed(2) : 0}%</div>
                    </div>

                  </div>
                </div>
                <br />
                <div>
                  <div>Last 1 Year</div>
                  <div className="homeloanmonthdetails">
                    <div className="homeloanmonth">
                      <span className="paymenttype"></span>
                      <div>On Time Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.ontime_payment?.last_1_year}</span>
                    </div>
                    <div className="homeloanmonth">
                      <span className="latepaymenttype"></span>
                      <div>Late Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year}</span>
                    </div>
                  </div>
                  <div className="latepaymentsummarycard">
                    <div>
                      <h3 className="cibilscoreheading">Late Payments Summary</h3>
                    </div>

                    <div className="paymentinfolist">
                      <div>0-29 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['0_29_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['0_29_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>30-59 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['30_59_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['30_59_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>60-89 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['60_89_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['60_89_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>90-179 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['90_179_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['90_179_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>180+ days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['above_180_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_1_year?.['above_180_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_1_year)*100).toFixed(2) : 0}%</div>
                    </div>

                  </div>
                </div>

                <br />
                <div>
                  <div>Last 2 Year</div>
                  <div className="homeloanmonthdetails">
                    <div className="homeloanmonth">
                      <span className="paymenttype"></span>
                      <div>On Time Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.ontime_payment?.last_2_year}</span>
                    </div>
                    <div className="homeloanmonth">
                      <span className="latepaymenttype"></span>
                      <div>Late Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year}</span>
                    </div>
                  </div>
                  <div className="latepaymentsummarycard">
                    <div>
                      <h3 className="cibilscoreheading">Late Payments Summary</h3>
                    </div>

                    <div className="paymentinfolist">
                      <div>0-29 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['0_29_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['0_29_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>30-59 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['30_59_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['30_59_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>60-89 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['60_89_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['60_89_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>90-179 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['90_179_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['90_179_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>180+ days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['above_180_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_2_year?.['above_180_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_2_year)*100).toFixed(2) : 0}%</div>
                    </div>

                  </div>
                </div>

                <br />
                <div>
                  <div>Last 3 Year</div>
                  <div className="homeloanmonthdetails">
                    <div className="homeloanmonth">
                      <span className="paymenttype"></span>
                      <div>On Time Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.ontime_payment?.last_3_year}</span>
                    </div>
                    <div className="homeloanmonth">
                      <span className="latepaymenttype"></span>
                      <div>Late Payment</div>
                      <span className="paymentcount">{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year}</span>
                    </div>
                  </div>
                  <div className="latepaymentsummarycard">
                    <div>
                      <h3 className="cibilscoreheading">Late Payments Summary</h3>
                    </div>

                    <div className="paymentinfolist">
                      <div>0-29 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['0_29_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['0_29_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>30-59 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['30_59_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['30_59_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>60-89 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['60_89_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['60_89_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year)*100).toFixed(2) : 0}%</div>
                    </div>

                    <div className="paymentinfolistrow">
                      <div>90-179 days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['90_179_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['90_179_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year)*100).toFixed(2) : 0}%</div>
                    </div>
 
                    <div className="paymentinfolistrow">
                      <div>180+ days</div>
                      <div>{props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['above_180_days']}</div>
                      <div>{ props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year? ((props.cibilRecord?.payment_history_details?.[item.id]?.late_payment_breakup?.last_3_year?.['above_180_days']/props.cibilRecord?.payment_history_details?.[item.id]?.late_payment?.last_3_year)*100).toFixed(2) : 0}%</div>
                    </div>

                  </div>
                </div>

              </div>
              <br /><br />
            </React.Fragment>
          )
        })
      } 
    </React.Fragment>
  );
};
export default CibilReportHistory;
