
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
  
    const isToday = date.toDateString() === now.toDateString();
  
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = date.toDateString() === yesterday.toDateString();
  
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    if (isToday) {
      return `Today, ${date.toLocaleString('en-US', options)}`;
    } else if (isYesterday) {
      return `Yesterday, ${date.toLocaleString('en-US', options)}`;
    } else {
      return date.toLocaleString('en-US');
    }
  };
  

  export const getDateAndTimeFormat = (date) => {
    return (
      <>
        {date ? new Date(date).toLocaleDateString() : "N/A"},{" "}
        {date ? new Date(date).toLocaleTimeString() : ""}
      </>
    );
  };

  export const getISODateAndTimeFormat = (isoDate) => {
    const date = new Date(isoDate);

    if (isNaN(date.getTime())) {
      console.error('Invalid Date');
    }

    try {
      const localDate = date.toISOString().split('T')[0];
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      const localDateTime = `${localDate}T${hours}:${minutes}`;
      return localDateTime;
    } catch (error) {
      console.error(error);
    }
  };


  export const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };