import React, { useState } from "react";
import Formsy from "formsy-react";
import FormsyInputField from "../elements/FormsyInputField";
import { Tab, Nav } from "react-bootstrap";
import sendicon from "../../webroot/images/sendicon.png";

const PartnerTemplate = (props) => {

  const setTemplate = (value , key)=>{
    props.hideTemplate()
    props.setInputValue(value)
    props.onData(key); 

}

  return (
    <>
  <div className="modal-header">
           <h2>Choose a template for sending to the user</h2>
            </div>
            {
                props.list && props.list.length ? props.list.map((ob,key)=>{
                    return <div title={ob.template} className="sendstatus" onClick={()=>setTemplate(ob.template , ob.name)} key={key}>{ob.name} : {`${ob.template.split(' ').slice(0,4).join('')}...`}</div>
                })  
                : null
            } 
    </>
  );
};

export default PartnerTemplate;
