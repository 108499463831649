import { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import UploadDocumentView from "./UploadDocumentView";
// import App from '../../../component/FileUpload'
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useApolloClient } from "@apollo/client";
import { SEND_NOTIFICATION_TO_CUSTOMER } from "../../../services/customer.gql";
import { executeGraphQLQuery } from "../../../common/executeGraphQLQuery";
import { toast } from "react-toastify";
import { IS_USER_ACCESS_PAGE } from "../../../config/constants";

function DocumentUpload(props) {
  const client = useApolloClient();
  // let history = useHistory();
  let params = useParams();
  const [activeTab, setActiveTab] = useState("first_submit");
  const [ActiveTabDoc, setActiveTabDoc] = useState("first_submit");
  let documentCollect = IS_USER_ACCESS_PAGE(
    "document_collect",
    "document_collect"
  )?.is_edit_access;

  const { leadStatusHistory, leadDocs, authUser, leadInfo, leadDetail } =
    useSelector(({ lead, user }) => ({
      leadDocs: (lead && lead.leadDetail && lead.leadDetail.leaddocs) || [],
      authUser: user.user_information || {},
      leadStatusHistory:
        (lead.leadDetail && lead.leadDetail.lead_status_history) || [],
      leadInfo: lead && lead.leadDetail,
      leadDetail: lead.leadDetail,
    }));
  let subStatusMarkedTillnow =
    leadStatusHistory && leadStatusHistory.length
      ? leadStatusHistory.map((v) => v.sub_status_id)
      : [];

  useEffect(() => {
    setActiveTab("first_submit");
  }, [params]);

  const handleSelectTab = (eventKey) => {
    setActiveTab(eventKey);
    //if (history) history.push(`/lead-detail/doc/${eventKey}`);
  };
  const handleDocumentChange = (index) => {
    setActiveTabDoc(index);
  };

  const sendWhatsappNotification = (customerName, mobile, url, lead_id) => {
    try {
      executeGraphQLQuery(
        SEND_NOTIFICATION_TO_CUSTOMER(customerName, mobile, url, lead_id),
        client
      )
        .then((response) => {
          console.log(response);
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleShareLink = async () => {
    try {
      const hostname = window.location.hostname;
      const protocol =
        hostname === "loan-stage.ambak.com" || hostname === "localhost"
          ? "http"
          : "https";
      const endPointUrl = window.location.pathname;
      const endPoinUrlArry = endPointUrl.split("/");
      const encryptedLeadId = endPoinUrlArry[endPoinUrlArry.length - 1];
      const docUploadUrl = `${protocol}://${hostname}/customer-zone/${encryptedLeadId}`;
      const mobile = `91${leadDetail.customer?.mobile}`;
      const customerName = `${leadDetail.customer?.first_name} ${leadDetail.customer?.last_name}`;
      const lead_id = leadDetail.id;
      sendWhatsappNotification(customerName, mobile, docUploadUrl, lead_id);
      toast.success("Success");
    } catch (error) {
      console.log(error.message);
      toast.error("Internal server error");
    }
  };

  return (
    <div className="document-upload-tabs" key={activeTab}>
      <div className="doument-upload-left-panel">
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey={activeTab}
          onSelect={handleSelectTab}
        >
          <Nav variant="pills" className="flex-column">
            <div className="tab-list">
              <Nav.Item className="nav-item">
                <Nav.Link
                  active={ActiveTabDoc == "first_submit"}
                  eventKey={"first_submit"}
                  onClick={() => handleDocumentChange(`first_submit`)}
                  disabled={false}
                >
                  {" "}
                  Customer
                </Nav.Link>
              </Nav.Item>

              {leadInfo.co_applicant?.length
                ? leadInfo.co_applicant.map((ob, key) => {
                    return (
                      <Nav.Item className="nav-item" key={key}>
                        <Nav.Link
                          active={ActiveTabDoc == "co-" + ob.id}
                          eventKey={"co-" + ob.id}
                          onClick={() => handleDocumentChange(`co-${ob.id}`)}
                        >
                          {ob.ca_first_name} co applicant
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })
                : null}
            </div>
          </Nav>
          {/* <div className="sendlinkflex">
            <button className="sendlink" onClick={handleShareLink}>
              Send Link
            </button>
          </div> */}
          <Tab.Content>
            <Tab.Pane active={activeTab ? true : false} eventKey={activeTab}>
                <div className="file-upload-bx">
                  {/* <App /> */}
                  <UploadDocumentView
                    activeDocTab={"upload"}
                    activeTab={activeTab}
                    key={activeTab}
                    customerId={activeTab}
                    docCategoryList={props.docCategoryList}
                    coAppdocCategoryList={props.coAppdocCategoryList}
                    exDocCategoryList={props.exDocCategoryList}
                    otherdocCategoryList={props.otherdocCategoryList}
                    coAppotherdocCategoryList={props.coAppotherdocCategoryList}
                    markAsFreezed={props.markAsFreezed}
                  />
                </div>
              </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
}
export default DocumentUpload;
