import React from 'react';
import { useState, useEffect } from 'react';

const EmiCharts = ({ emi, total}) => {
  
  const [circumference, setCircumference] = useState(0);
  const [progress, setProgress] = useState(0);

  const size = 200; // Diameter of the circle
  const strokeWidth = 20; // Width of the progress line
  const center = size / 2; // Center of the circle
  const radius = (size - strokeWidth) / 2; // Radius of the circle

  useEffect(() => {
    // Calculate the circumference of the circle
    setCircumference(2 * Math.PI * radius);

    // Calculate the progress based on current step and total steps
    setProgress(((emi) / total) * circumference);
  }, [emi, total, circumference]);
  

  return (
    <div style={{ position: 'relative', width: size, height: size }}>
      <svg width={size} height={size}>
        <circle
          stroke="#1AC18F" // Background color of the circle
          fill="none"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          stroke="#D2CBFB" // Progress color
          fill="none"
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference - progress}
          // Rotate the progress circle to start from the top
          transform={`rotate(-90 ${center} ${center})`}
          
        />
      </svg>
      
    </div>
  );
};

export default EmiCharts;
