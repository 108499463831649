import React from "react";

const OfferSection = ({ checkBankOffers }) => {
  return (
    <>
      <div className="doument-upload-left-panel">
        <div className="breoffercard">
          <div className="breofferheading">
            {checkBankOffers.length === 0 &&
              "Please fill all the required fields to get offers"}
          </div>
          {checkBankOffers.map((offer, index) => (
            <div key={index} className="offerbox">
              <div className="offercardflex">
                <div className="offerimg">
                  <img src={offer.bankLogo} alt="" />
                </div>

                <div className="offerwidth">
                  <div className="offerlistingcard">
                    <div className="offerlisting">
                      <div className="brename">Eligible Loan Amount</div>
                      <div className="breheading">
                        ₹ {offer.eligibalLoanAmount}
                      </div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Monthly EMI</div>
                      <div className="breheading">₹ {offer.monthlyEMI}</div>
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Interest Rate</div>
                      <div className="breheading">{offer.minRoi} %</div>
                    </div>

                    <div className="offerlisting">
                      <div className="brename">Tenure</div>
                      <div className="breheading">{offer.maxTenure} years</div>
                    </div>
                    <div className="offerlisting">
                      <div className="brename">Processing Fees</div>
                      <div className="breheading" style={{ fontSize: 14 }}>
                        {offer.processingText
                          ?.replace("Processing Fee:", "")
                          .trim()}
                      </div>
                    </div>
                  </div>

                  {/* <div className="offermeterflex">
                    <div className="offermeter">
                      <div className="confidencename">Confidence Meter</div>
                      <div className="commonchance">Low</div>
                      <div className="inputrange">
                        <input
                          type="range"
                          min="0"
                          max="100"
                          value={offer.confidenceMeter}
                          // onChange={this.handleSliderChange}
                        />
                      </div>
                      <div style={{ marginLeft: 10, fontSize: 10 }}>
                        {" "}
                        Excellent
                      </div>
                    </div>

                    <input
                      type="checkbox"
                      className="toggle-checkbox"
                      id="toggle-tips"
                    />
                    <label htmlFor="toggle-tips">
                      <div className="offermeternew" tabindex="0">
                        <div className="confidencename">
                          Explore tips to improve loan offer
                        </div>
                        <i
                          className="ic-arrow_drop_down"
                          style={{ fontSize: 25 }}
                        ></i>
                        <div className="tips-list">
                          <div>
                            <ul>
                              {offer.messages.map((msg) => (
                                <li>{msg}</li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </label>

                    <div className="offerdocument">
                      <div className="offerdocumentname">Documents List</div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OfferSection;