import { useApolloClient } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { io } from 'socket.io-client';
import { executeGraphQLMutation, executeGraphQLQuery } from '../../common/executeGraphQLQuery';
import { WS_URL } from '../../config/constants';
import crypto from "../../config/crypto";
import { GET_UNREAD_MESSAGE_LIST, UPDATE_CHAT_READ_STATUS } from '../../services/leads.gql';
import './../assets/css/WhatsAppUnread.css'; // CSS file for styling
import whatsappIcon from './../assets/images/icons/whatsapp-icon.png';

const MAX_RETRIES = 3;

const WhatsAppUnreadComponent = () => {
  const client = useApolloClient();
  const location = useLocation();
  const current_url_main = window.location.href;

  const [isOpen, setIsOpen] = useState(false);
  const [connectWebsocket, setConnectWebsocket] = useState(false);
  const [showWhatsAppIcon, setShowWhatsAppIcon] = useState(true);
  const [newMessages, setNewMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [currentUrlPath, setCurrentUrlPath] = useState(null);
  const [retryCount, setRetryCount] = useState(0);

  const userInfo = JSON.parse(localStorage.getItem("user_information"));

  // WebSocket Code Initialize
  useEffect(() => {
    if (connectWebsocket && userInfo?.user_data?.user_id) {
      const identifierType = "unreadMessage";
      const connectionId = Math.floor(Date.now() / 1000);
      let socketConnection;
      const socketData = {
        transports: ['websocket'],
        query: { type: identifierType, identifier: userInfo.user_data.user_id, connectionId }
      }
      const connectSocket = () => {

        socketConnection = io(`${WS_URL}/socket`, socketData);

        // socketConnection.on('connect_error', () => {
        //   console.log(`\n\n useEffect >> connectSocket -> connect_error >> retryCount => `, retryCount);
        //   console.log(`\n\n useEffect >> connectSocket -> connect_error >> MAX_RETRIES => `, MAX_RETRIES);
        //   if (retryCount < MAX_RETRIES) {
        //     setRetryCount((prevRetryCount) => prevRetryCount + 1);
        //     socketConnection.disconnect();
        //     setTimeout(connectSocket, 1000 * retryCount); // Exponential backoff
        //   } else {
        //     console.error('Failed to connect to WebSocket after multiple attempts.');
        //   }
        // });

        socketConnection.on(`${identifierType}-${userInfo.user_data.user_id}_${connectionId}`, (msg) => {
          if (msg) {
            setNewMessages(true);
            setMessages((prevMessages) => [...prevMessages, { ...msg }]);
          }
          setRetryCount(0); // Reset retry count on successful connection
        });
      };

      connectSocket();

      // Lising all unread messages
      getUnreadMessageList(userInfo.user_data.user_id);

      return () => {
        if (socketConnection) {
          socketConnection.disconnect();
        }
      };
    }
  }, [connectWebsocket]);
  // WebSocket Code Ends


  useEffect(() => {

    // Logic to handle changes when URL changes
    console.log('WhatsAppUnreadComponent -> location.pathname => ', location.pathname);
    console.log('WhatsAppUnreadComponent -> currentUrlPath => ', currentUrlPath);
    if (currentUrlPath != location.pathname) {
      setMessages([]);

      // Lising all unread messages
      getUnreadMessageList(userInfo.user_data.user_id);
    }

    const currentPath = location.pathname.trim().split('/');
    console.log('WhatsAppUnreadComponent -> currentPath => ', currentPath);
    if (currentPath[1] === 'lead-detail' && currentPath[2] === 'whatsapp') {
      setShowWhatsAppIcon(false)
      setIsOpen(false);
    } else {
      setShowWhatsAppIcon(true);
      setConnectWebsocket(true);
    }

    // You can trigger any update or fetch data based on URL change
    // For example, fetch data or update state here
    setCurrentUrlPath(location.pathname);
  }, [location.pathname]);

  const handleIconClick = () => {
    if (messages.length) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(false);
    }
  };

  const updateReadStatus = (msgId, userId) => {
    executeGraphQLMutation(UPDATE_CHAT_READ_STATUS, { chatId: msgId, userId }, client)
      .then(result => {
        console.log("\n\n updateReadStatus >> executeGraphQLQuery => result -> ", result)
      })
      .catch((error) => {
        console.error("\n\n updateReadStatus >> executeGraphQLQuery => error -> ", error)
      });
  }

  const clickMessage = (msgObject) => {
    const newMessages = messages.filter((_val, _idx) => (_val.identifier.msgId != msgObject.identifier.msgId))
    setMessages(newMessages)
    setIsOpen(!isOpen);
    setNewMessages(!!newMessages.length);
    updateReadStatus(msgObject.identifier.msgId, msgObject.identifier.userId);
    window.open(`/lead-detail/whatsapp/${crypto.encode(msgObject.identifier.leadId)}`, '_blank')
  }

  const getUnreadMessageList = (userId) => {
    console.log("\n\n getUnreadMessageList >> executeGraphQLQuery => userId -> ", userId)
    executeGraphQLQuery(GET_UNREAD_MESSAGE_LIST(0, userId), client).then((result) => {
      console.log("\n\n getUnreadMessageList >> executeGraphQLQuery => result -> ", result)
      if (result?.data?.unread_message_list?.length) {
        const transformedMessages = result.data.unread_message_list.map(message => ({
          identifier: {
            msgId: message.id, // Replace with actual value if available
            mobile: message.mobile,
            userId: message.user_id,
            leadId: message.lead_id,
            customerId: message.customer_id,
            customerName: message.customer_name
          },
          message: {
            message: message.message,
            chat_type: message.chat_type,
            mode_type: message.mode_type,
            created_date: new Date(message.created_date).toLocaleString() // Converts to local string format
          }
        }));

        if (transformedMessages.length) {
          setMessages(transformedMessages);
          setNewMessages(true);
        }
      }
    }).catch((error) => {
      console.error("\n\n getUnreadMessageList >> executeGraphQLQuery => error -> ", error)
    });
  };

  return (
    <div className="whatsapp-container">
      {showWhatsAppIcon && (
        <div className="whatsapp-icon-wrapper" onClick={handleIconClick}>
          {newMessages && <span className="new-badge">New</span>}
          <img src={whatsappIcon} alt="WhatsApp Icon" className="whatsapp-icon" />
        </div>
      )}
      {(isOpen) && (
        <ul className="message-list">
          {messages.map((message, index) => (
            <li
              style={{ cursor: "pointer" }}
              key={index}
              onClick={() => { clickMessage(message); }}
            >
              {/* {message.message.message} */}
              {message?.identifier?.customerName ?? 'Customer'} (ID: {message.identifier.leadId})
            </li>
          ))}
        </ul>
      )}
    </div>
  );

};

export default WhatsAppUnreadComponent;
