import React, { useEffect, useState, useCallback } from "react";
import InputField from "../elements/InputField";
import Select from 'react-select';
import { toast } from 'react-toastify';
import { SCHEME_FIINACER_GROUP, Update_SCHEME, DELETE_FINANCER_FROM_GROUP_FINANCER, GET_SCHEME_FINANCER_GROUPS, DELETE_SCHEME_RANGE, SAVE_FINANCE_RANGES, FINEX_MASTER_LIST } from "../../services/bankscheme.gql";
import { executeGraphQLMutation, executeGraphQLQuery, executeGraphQLQueryFinex } from "../../common/partnerExecuteGraphQLQuery";
import { NavLink, Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { USERS_FOR_VIEW_AMBAK_LEADS, IS_USER_ACCESS_PAGE } from '../../config/constants';
const BankPayoutRanges = (props) => {
    const param = useParams()
    const [formData, setFormData] = useState({});
    const [rangeArray, setRangeArray] = useState([]);
    const [financerGroupList, setFinancerGroupList] = useState([]);
    const [financers, setFinancers] = useState([]);
    const [productType, setProductType] = useState([]);
    const [loanType, setLoanType] = useState([]);
    const [is_disabled, setIsDisabled] = useState(false)
    const [pageloading, setpageloading] = useState(false)
   // const [amountRange, setAmountRange] = useState(props.rangeAmount)
    const [rangeErrors, setRangeErrors] = useState({
        range_overlap_error: "",
        payout: [],
        rangeblank: []
    });
   const  amountRange = props.rangeAmount
    const [rangeIndex, setRangeIndex] = useState(0);
    // const financerGroupListDataNew = useRef([]);
    const { handleTabClick } = props;
    const [financersData, setFinancersData] = useState({
        scheme_id: 0,
        product_type: null,
        loan_type: "all",
        business_type: props.business_type,
        insurance_payin: null
    });
    const [formValues, setFormValues] = useState([
        { "start_range": null, "end_range": null, "payout": null }
    ]);

   

    let scheme_view = IS_USER_ACCESS_PAGE("payout", "scheme_view")?.is_edit_access;
    const handleInputarray = (parentIndex, childIndex, e, field_name = "") => {
        let name = "";
        let val = "";
        if (field_name && (field_name == 'start_range' || field_name == 'end_range')) {
            name = field_name;
            val = e.id;
        } else {
            name = e.target.name;
            val = e.target.value;
        }

        const financerGroupListData = [...financerGroupList]
        const rangeList = financerGroupListData[parentIndex].schemerange && financerGroupListData[parentIndex].schemerange.length > 0 ? financerGroupListData[parentIndex].schemerange : [];
        const currentRangeList = [...rangeList]
        const childListData = { ...currentRangeList[childIndex] }
        childListData[name] = val;
        currentRangeList[childIndex] = childListData;
        const schemeRangeNew = { ...financerGroupListData[parentIndex] }
        const schemeRannne = { ...schemeRangeNew, schemerange: currentRangeList }
        financerGroupListData[parentIndex] = schemeRannne;
        setFinancerGroupList(financerGroupListData);
    };

    const addMoreFields = (parentIndex, financer_group_id) => {
        const financerGroupListData = [...financerGroupList]
        const rangeList = financerGroupListData[parentIndex].schemerange && financerGroupListData[parentIndex].schemerange.length > 0 ? financerGroupListData[parentIndex].schemerange : [];
        const currentRangeList = [...rangeList]
        currentRangeList.push({
            id: 0,
            financer_group_id: financer_group_id,
            start_range: 0,
            end_range: 0,
            is_deleted: 0,
            payout: "0"
        })
        const schemeRangeNew = { ...financerGroupListData[parentIndex] }
        const schemeRannne = { ...schemeRangeNew, schemerange: currentRangeList }
        financerGroupListData[parentIndex] = schemeRannne;
        setFinancerGroupList(financerGroupListData);

    }

    const removeFieled = (parentIndex, childIndex, id = 0) => {
        if (window.confirm("Do you want to delete this range!")) {
            const financerGroupListData = [...financerGroupList]
            const rangeList = financerGroupListData[parentIndex].schemerange && financerGroupListData[parentIndex].schemerange.length > 0 ? financerGroupListData[parentIndex].schemerange : [];
            const currentRangeList = [...rangeList]
            currentRangeList.splice(childIndex, 1)
            const schemeRangeNew = { ...financerGroupListData[parentIndex] }
            const schemeRannne = { ...schemeRangeNew, schemerange: currentRangeList }
            financerGroupListData[parentIndex] = schemeRannne;
            setFinancerGroupList(financerGroupListData);
            if (id) {
                deleteSchemeRange(id)
            }
        }
    }

    const deleteSchemeRange = async (id) => {
        const data = {}
        const variables = { id: id };
        data.updateSchemeArgs = variables;
        try {
            const result = await executeGraphQLMutation(DELETE_SCHEME_RANGE, variables);
            if (result) {
                toast.success(result?.data?.deleteSchemeRange?.message || "Delete Scheme Range Successfully");
            }
        } catch (error) {
            toast.error("Failed");
        }
    }


    useEffect(() => {
        if (props.schemedetailsData && props.schemedetailsData.id != "") {
            setFormData(props.schemedetailsData)
            const end_date_time = new Date(props.schemedetailsData?.end_date).getTime();
            const current_date_time = new Date().getTime();
            if (end_date_time < current_date_time) {
                setIsDisabled(true)
            }
            console.log("props.schemedetailsData.id", props.schemedetailsData.id)
            getSchemeFinacerGroups(props.schemedetailsData.id)
            if (props.schemedetailsData.scheme_for == 'count') {
                setRangeArray(countRange)
            } else {
                setRangeArray(amountRange)
            }
           
        }
        getFinexMasterData()
    }, [props.schemedetailsData]);

    const saveSchemeRanges = async (index) => {
        setpageloading(true)
        const data = {}
        const financerGroupListData = [...financerGroupList]
        const rangeList = financerGroupListData[index].schemerange;
        const product_type = financerGroupListData[index].product_type;
        const checkRanges = checkOverLapRanges(rangeList, 0);
        const rangesDataList = []
        const payoutError = []
        const rangeBlankError = []
        let isError = false;
        setRangeIndex(index)
        if (checkRanges) {
            setRangeErrors({ range_overlap_error: "Ranges are overlapped!" })
            setpageloading(false)
            return false
        } else {
            setRangeErrors({ range_overlap_error: "" })
        }

        if (rangeList && rangeList.length > 0) {
            rangeList.map(rangeItem => {
                if (rangeItem.end_range > 0 && parseFloat(rangeItem.payout) > 0) {
                    rangesDataList.push({
                        id: rangeItem.id,
                        //  scheme_id: formData.id,
                        //financer_group_id: rangeItem.financer_group_id,
                        start_range: parseInt(rangeItem.start_range),
                        end_range: parseInt(rangeItem.end_range),
                        payout: rangeItem.payout.toString(),
                        status: 1
                    });
                    payoutError.push({ error: "" })
                    rangeBlankError.push({ error: "" })
                } else {
                    if (parseFloat(rangeItem.payout) > 0) { payoutError.push({ error: "" }) } else { payoutError.push({ error: "Payout Should be greaterthan 0!" }) }
                    if (!rangeItem.end_range || (rangeItem.end_range == rangeItem.start_range)) { rangeBlankError.push({ error: "Payout range is wrong!" }) } else { rangeBlankError.push({ error: "" }) }
                    isError = true;

                }

            }
            )
            if (isError) {
                setRangeErrors({ ...payoutError, ["payout"]: payoutError, rangeblank: rangeBlankError })
                setpageloading(false)
                return false

            }

            const variables = {
                schemeRangeInputArgs: {
                    scheme_id: formData.id,
                    //  product_type: product_type,
                    financer_group_id: rangeList[0].financer_group_id,
                    schemerange: rangesDataList
                },
            };
            try {
                const result = await executeGraphQLMutation(SAVE_FINANCE_RANGES, variables);

                if (result) {
                    toast.success(result?.data?.saveSchemeRanges?.message || "Financers Ranges Saved Successfully");
                }
            } catch (error) {
                toast.error("Failed");
            }
            setpageloading(false)
        } else {
            toast.error("Please add ranges!");
        }

    }


    const checkOverLapRanges = (ranges, k) => {
        // Sort the array of pairs by the start point of each range
        // const  pairs = JSON.parse(rangelist)
        const rangesArray = [...ranges]
        let pairs = rangesArray.sort((a, b) => a.start_range - b.start_range);

        // Keep track of the maximum endpoint of k ranges seen so far
        let maxEndpoint = pairs[0].end_range;
        let overlapCount = 0;

        for (let i = 1; i < pairs.length; i++) {
            // If the current range overlaps with the k ranges seen so far

            if (pairs[i].start_range <= maxEndpoint) {
                overlapCount++;
                maxEndpoint = Math.max(maxEndpoint, pairs[i].end_range);
            } else {
                // If k overlapping ranges have been found
                if (overlapCount > k) {
                    return true;
                }
                // Reset the counters for the next set of ranges
                overlapCount = 0;
                maxEndpoint = pairs[i].end_range;
            }
        }
        // Check if the last set of ranges overlap k times or more
        if (overlapCount > k) {
            return true;
        }

        // No k overlapping ranges found
        return false;
    }



    const countRange = {
        fromArray: [
            { id: 0, name: '0', isdisabled: true },
            { id: 11, name: '11' },
            { id: 16, name: '16' },
            { id: 21, name: '21' },
            { id: 26, name: '26' },
        ],
        toArray: [
            { id: 10, name: '10' },
            { id: 15, name: '15' },
            { id: 20, name: '20' },
            { id: 25, name: '25' },
            { id: 3000, name: '30+' }
        ]
    };


    const insurance_payout_array = [
        { id: 'standalone', name: 'standalone' },
        { id: 'disbursal', name: 'disbursal' }
    ];


    const saveFinacerGroup = async () => {
        const formDataNew = { ...financersData, scheme_id: formData.id };
        formDataNew.product_type = formDataNew.product_type;
        formDataNew.loan_type = "all";
        formDataNew.schemefinancers = formDataNew.financier_id;
        delete formDataNew.financier_id
        delete formDataNew.financersids
        delete formDataNew.loan_type_ids
        delete formDataNew.product_type_ids
        delete formDataNew.employment_type_ids
        const variables = {
            schemeFinancerGroupArgs: formDataNew,
        };

        try {
            const result = await executeGraphQLMutation(SCHEME_FIINACER_GROUP, variables);
            if (result && result.data && result.data.saveBankSchemeFinancerGroup) {
                toast.success(result?.data?.saveBankSchemeFinancerGroup || "Success");
                setFinancersData({
                    scheme_id: formData.scheme_id,
                    schemefinancers: [],
                    financersids: [],
                    product_type: [],
                    product_type_ids: ['ALL'],
                    loan_type: [],
                    loan_type_ids: ['ALL'],
                    employment_type_ids: ['ALL'],
                    employment_type: [],
                    financier_id: [],
                })
            }
            await getSchemeFinacerGroups(formData.id)

        } catch (error) {
            toast.error("Failed");
        }
    }

    const getSchemeFinacerGroups = async (scheme_id) => {
        try {
            if (scheme_id && scheme_id > 0 && props.business_type) {
                const schemeFGDataList = await executeGraphQLQuery(GET_SCHEME_FINANCER_GROUPS(scheme_id, props.business_type.toString()), {
                    notifyOnNetworkStatusChange: true,
                });
                console.log("schemeFGDataList", schemeFGDataList)
                if (schemeFGDataList && schemeFGDataList.data && schemeFGDataList.data.bankSchemeFinancerGroupbySchemeId) {
                    const financerGroupListNew = schemeFGDataList.data.bankSchemeFinancerGroupbySchemeId;
                    setFinancerGroupList(financerGroupListNew)
                }
            }
        } catch (error) {
            console.log(error)
        }

    }



    const handleProductTypeOptionChange = (name, selectedOptions) => {
        const rangeFinancersdata = { ...financersData }
        const value = selectedOptions.id;
        setFinancersData({ ...rangeFinancersdata, [name]: value });
    };


    const getFinexMasterData = async () => {
        const masterData = await executeGraphQLQuery(FINEX_MASTER_LIST());
        if (masterData && masterData.data && masterData.data.masterdata) {
            const bankData = []
            const producttypedata = []
            const loan_type = []
            const data = masterData.data.masterdata;
            const bank_list = data.bank_list && data.bank_list.length > 0 ? data.bank_list : [];
            const product_type = data.loan_type && data.loan_type.length > 0 ? data.loan_type : []
            const loan_sub_type = data.loan_sub_type && data.loan_sub_type.length > 0 ? data.loan_sub_type : []
            if (bank_list && bank_list.length > 0) {
                bank_list.map(banks => {
                    bankData.push({ id: banks.banklang.bank_id, name: banks.banklang.bank_name })
                })
                setFinancers(bankData)
            }
            if (product_type && product_type.length > 0) {
                product_type.map(ptype => {
                    producttypedata.push({ id: ptype.id, name: ptype.label })
                })
                setProductType(producttypedata)
            }
            if (loan_sub_type && loan_sub_type.length > 0) {
                loan_type.push({ id: "all", name: "All" })
                loan_type.push({ id: "0", name: "Fresh Loan" })
                loan_sub_type.map(ptype => {
                    loan_type.push({ id: ptype.id.toString(), name: ptype.label })
                })
                setLoanType(loan_type)
            }
        }


    }
    const handleChange = async (n, v, isMulti, opt, optl) => {
        switch (n) {

            case 'financier_id':
                let financier_ids = [];
                financier_ids = v.map(obj => { return obj.id });
                setFinancersData((prev) => ({ ...prev, [n]: v.map(obj => obj[opt]) }));
                break;
        }
    }

    return (
        <> {is_disabled ? <div className="card"> <div className="card-body"><div className="row">  <div className="col-sm-12"> Scheme End date validation Failed! You can not add/update ranges of scheme! </div></div></div></div> :

            <div className="card">
                <div className="card-body">
                    <div className="row">
                        {props.business_type == 'insurance' ?
                            <>
                                <div className="col-sm-2">
                                    <label>Insurance Payout Calc.<span className="required" style={{ color: 'red' }}>*</span></label>
                                    <Select
                                        id="insurance_payin"
                                        onChange={(e) => handleProductTypeOptionChange('insurance_payin', e)}
                                        options={insurance_payout_array}
                                        label={"Select Product Type"}
                                        name="insurance_payin"
                                        value={insurance_payout_array && insurance_payout_array.length > 0 && financersData.insurance_payin && insurance_payout_array.filter((items) => items.id == financersData.insurance_payin)}
                                        getOptionLabel={({ name }) => name}
                                        getOptionValue={({ id }) => id}
                                    />
                                </div>
                            </> : null}



                        <div className="col-sm-2">
                            <label>Product Type</label>
                            <Select
                                id="product_type"
                                onChange={(e) => handleProductTypeOptionChange('product_type', e)}
                                options={productType}
                                label={"Select Product Type"}
                                name="product_type"
                                value={productType && productType.length > 0 && financersData.product_type && productType.filter((items) => items.id == financersData.product_type)}
                                getOptionLabel={({ name }) => name}
                                getOptionValue={({ id }) => id}
                            />
                        </div>


                        {/* <div className="col-sm-2">

                                     <label>Sub Product Type</label>
                                        <Select
                                            id="loan_type"
                                            onChange={(e) => handleLoanTypeOptionChange('loan_type', e)}
                                            options={loanType}
                                            label={"Select Sub Product Type"}
                                            name="loan_type"
                                            value={loanType && loanType.length > 0 && financersData.loan_type.length > 0 && loanType.find(({ id }) => financersData.loan_type_ids.includes(id))}
                                            getOptionLabel={({ name }) => name}
                                            getOptionValue={({ id }) => id}
                                        />
                                    </div> */}

                        {/* <div className="col-sm-2" style={{ marginTop: 30 }}>
                              
                                   <CheckBox type="checkbox" name="is_on_payin" id="is_on_payin" value="1" label="Payout on Payin by Financer" checked={financersData.is_on_payin ? true : false} onChange={(e) => handleCheckboxall(e)} />
                             </div> */}
                        {financersData.product_type ? <button style={{ marginTop: 30 }} className="btn btn-primary" onClick={() => saveFinacerGroup()}> New Finance Group</button> : ""}

                    </div>




                </div>
            </div>}

    <div className="card">
        {
            financerGroupList && financerGroupList.length > 0 && financerGroupList.map((groupData, parentIndex) => {
                return (

                    <div>
                        {/* {JSON.stringify(rangeErrors)} */}
                        <div className="card-body">
                            <div className="row" style={{ marginBottom: 20 }}>
                                {groupData.insurance_payin ?
                                    <div className="col-sm-2">
                                        <div>
                                            Insurance Payout Calc. With
                                        </div>
                                        <div style={{ marginTop: 10 }}>
                                            <b>{groupData.insurance_payin}</b>
                                        </div>
                                    </div> : null}
                                <div className="col-sm-2">
                                    <div >
                                        Product Type
                                    </div>
                                    <div style={{ marginTop: 10 }}>
                                        <b>{productType && productType.length > 0 && productType.filter((type) => type.id == groupData.product_type)[0]?.name}</b>
                                    </div>
                                </div>
                            </div>
                            <>
                                {groupData.schemerange && groupData.schemerange.length > 0 ? <div className="row">
                                    <div className="col-sm-3 payouttable">
                                        <div>From</div>
                                    </div>
                                    <div className="col-sm-3 payouttable">
                                        <div>To</div>
                                    </div>
                                    <div className="col-sm-3 payouttable">
                                        <div>Payout(%)</div>
                                    </div>

                                </div> : ""}

                                <div>

                                    {groupData.schemerange && groupData.schemerange.length > 0 && groupData.schemerange.map((rangedata, childIndex) => {
                                        return (
                                            <>
                                                <div className="payout-row">

                                                    <div className="row" style={{ marginBottom: 10 }}>

                                                        <div className="col-sm-3">
                                                            <Select
                                                                id="start_range"
                                                                onChange={(e) => handleInputarray(parentIndex, childIndex, e, 'start_range')}
                                                                options={childIndex == 0 ? rangeArray.fromArray : rangeArray.fromArray.filter((item) => item.id >= groupData.schemerange[childIndex - 1]?.end_range)}
                                                                label={"Select Financers"}
                                                                name="start_range"
                                                                value={rangeArray && rangeArray.fromArray.length > 0 && rangeArray.fromArray.find((item) => item.id == rangedata.start_range)}
                                                                getOptionLabel={({ name }) => name}
                                                                getOptionValue={({ id }) => id}
                                                                error={rangeErrors[childIndex] && rangeErrors[childIndex].rangeblank ? rangeErrors[childIndex].rangeblank : ''}
                                                                isDisabled={is_disabled}
                                                            />

                                                        </div>
                                                        <div className="col-sm-3">
                                                            <Select
                                                                id="end_range"
                                                                onChange={(e) => handleInputarray(parentIndex, childIndex, e, 'end_range')}
                                                                options={rangeArray.toArray.filter((item) => item.id >= groupData.schemerange[childIndex]?.start_range)}
                                                                label={"Select Financers"}
                                                                name="end_range"
                                                                value={rangeArray && rangeArray.toArray.length > 0 && rangeArray.toArray.length > 0 && rangeArray.toArray.find((item) => item.id == rangedata.end_range)}
                                                                getOptionLabel={({ name }) => name}
                                                                getOptionValue={({ id }) => id}
                                                                isOptionDisabled={(option) => option.id <= groupData.schemerange[childIndex] && groupData.schemerange[childIndex].start_range ? parseInt(groupData.schemerange[childIndex].start_range) : 0}
                                                                error={rangeErrors[childIndex] && rangeErrors[childIndex].rangeblank ? rangeErrors[childIndex].rangeblank : ''}
                                                                // isOptionDisabled={}
                                                                isDisabled={is_disabled}
                                                            />
                                                            {rangeErrors && rangeErrors.rangeblank && rangeErrors.rangeblank.length > 0 && rangeErrors.rangeblank[childIndex] && parentIndex == rangeIndex ? <div className="error show"> {rangeErrors.rangeblank[childIndex].error}</div> : ""}
                                                        </div>
                                                        <div className="col-sm-3">
                                                            {console.log(rangedata)}
                                                            <InputField
                                                                inputProps={{
                                                                    type: "text",
                                                                    name: "payout",
                                                                    id: "payout",
                                                                    maxLength: "4",
                                                                    value: rangedata['payout'] ? rangedata.payout : ""
                                                                }}
                                                                onChange={(e) => handleInputarray(parentIndex, childIndex, e)}
                                                            />
                                                            {rangeErrors && rangeErrors.payout && rangeErrors.payout.length > 0 && rangeErrors.payout[childIndex] && parentIndex == rangeIndex ? <div className="error show"> {rangeErrors.payout[childIndex].error}</div> : ""}
                                                        </div>

                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                    {rangeErrors.range_overlap_error && rangeIndex == parentIndex ?
                                        <div className="col-sm-12 error show">{rangeErrors.range_overlap_error}</div> : ""
                                    }
                                </div>
                                {scheme_view === 1 && (
                                    <div className="btn-submit-reset top-btn-none">
                                        {groupData.schemerange && groupData.schemerange.length > 0 && !is_disabled ? <span className="btn btn-reset-new" onClick={() => removeFieled(parentIndex, (groupData.schemerange.length - 1), groupData.schemerange[groupData.schemerange.length - 1].id)}><i style={{ fontSize: 21 }} className="ic-delete"></i></span> : ""}

                                        {!is_disabled ? <button style={{ fontSize: 20 }} className="plusbtn-new" onClick={() => addMoreFields(parentIndex, groupData.id)}>+</button> : ""}
                                        {groupData.schemerange.length > 0 && !is_disabled ? <button style={{ marginLeft: 10 }} className="btn btn-reset-new" onClick={() => saveSchemeRanges(parentIndex)} disabled={groupData.schemerange.length > 0 ? false : true}>Save Ranges</button> : ""}
                                    </div>
                                )}

                            </>
                        </div>
                    </div>
                )

            })
        }
    </div>
            {/* </Accordion> */}

        </>
    )
};

const mapStateToProps = state => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id
    };
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData })
    }
}

export default withTranslation('dealer')(connect(mapStateToProps, mapDispatchToProps)(BankPayoutRanges));