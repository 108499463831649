import axios from 'axios';
import { API_URL, APIV } from '../config/partnerConstant';
import secureStorage from '../config/partnerEncrypt';
import { toast } from 'react-toastify';

// axios.interceptors.response.use(
//     (response) => {
//         console.log ("responseresponseresponseresponseresponseresponseresponseresponseresponse" , response)
//         if (response.data && response.data.status != 200) {
//             console.error(`${response.data.status} - ${response.data.message}`, response.data)
//             // if(response.data.status == 400)
//             //     window.commonErrorToast(response.data.message)
//             //     toast.error(`${response.data.status} - ${response.data.message}`);
//         }
//         return response;
//     },

//     (error) => {
//         // handle error
//         if (error.response) {
//             console.error(`${error.response.status} - ${error.response.statusText}`, error.response)
//             if (error.response.status == 503) {
//                 // toast.error(`${error.response.status} - ${error.response.statusText}`);
//             } else if (error.response.status == 401) {
//                 // if(error.response.data.errors == "TokenEmptyInCache") 
//                 //toast.error(`${error.response.data.status} - ${error.response.data.message}`);
//                 window.location.href = '/logout';
//             }
//         }

//         return error.response;
//     });

window.commonErrorToast = function (error) {
    if(typeof error == "string") toast.error(error);
    else if(error.message) toast.error(error.message);
}
export default {
    getMasters(list) {
        return this.get("/core/commonservice/master", { master: list });
    },
    getToken() {
        let token = JSON.parse(secureStorage.storage.user_information)
     
        let fetchHeader = (secureStorage.getItem('loginUserInfo'))
            ? secureStorage.getItem('loginUserInfo')
            : {};
        return (token && token.access_token)
            ? token.access_token : null;
    },
    delete(URL, body, headers) {
         headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
         return axios.delete(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
 

    async post(URL, body, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = headers['Authorization'] ? headers['Authorization'] : this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang') ? secureStorage.getItem('lang') : "en";
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
    
        try {
        
            const data = await axios.post(`${API_URL.GETWAY_API}${URL}`, body, { headers });
          
            return data;
        } catch (error) {
            
            return error.response;
        }
    } ,
    
    put(URL, body, headers) {
        headers = headers || {};
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
        return axios.put(`${API_URL.GETWAY_API}${URL}`, body, {headers})
    },
    get(URL, query, headers) {
        headers = headers || {}
        if(APIV) headers['apiv'] = APIV;
        headers['Authorization'] = this.getToken();
        headers['Accept-Language'] = secureStorage.getItem('lang');
        URL = (URL[0] == '/') ? URL.substr(1) : URL;
       

        return axios.get(`${API_URL.GETWAY_API}${URL}`,
            { params: query, headers })
            
    },
    getWithoutHeaders(URL) {
        return axios.get(`${API_URL.GETWAY_API}${URL}`)
    }
}
